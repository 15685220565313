import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import theme from 'components/themes';

import 'react-datepicker/dist/react-datepicker.css';

export default function MonthPicker({ value, onChange, dateFormat = 'MM/yyyy' }) {
  return (
    <DatepickerWrapper>
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat={dateFormat}
        showMonthYearPicker
        showFullMonthYearPicker
      />
    </DatepickerWrapper>
  );
}

const DatepickerWrapper = styled.div`
  .SingleDatePickerInput {
    ${(props) => props.error
    && `
          box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
          border: 1px solid ${theme.colors.notificationRed};
    `};
  }

  .react-datepicker__input-container input {
    height: 38px;
    width: 100%;
    border: 1px solid ${theme.colors.background};
    background-color: ${theme.colors.inputs};
    color: ${theme.colors.white};
    font-size: 16px;
    padding: 10px 16px;
  }

  .react-datepicker__input-container input:focus {
    outline: none;
  }

  .react-datepicker {
    padding: 15px;
  }

  .react-datepicker, .react-datepicker__header, .react-datepicker__triangle {
    background-color: ${theme.colors.datePickerBackground};
    color: ${theme.colors.white};
    border: none;
  }

  .react-datepicker__month-text {
    width: 5rem;
    padding: 0.5rem;
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${theme.colors.datePickerSelected};
  }

  .react-datepicker__month-text:hover {
    background-color: ${theme.colors.purple};
    border: none;
    color: ${theme.colors.white};
  }

  .react-datepicker__navigation {
    margin-top: 1.2rem;
  }

  input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
