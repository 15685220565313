import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box } from '@rebass/grid';
import Card from './Card';
import Icon, { Icons } from './Icon';
import Button from './forms/Button';
import CheckBoxInput from './forms/CheckboxInput';

export default function ContactCard({ data, openDeleteModal, canUpdateClient, setModalOpen }) {
  const { t: tg } = useTranslation('general');
  const checked = data.isDefaultBilling || false;

  return (
    <>
      <Box
        width={0.25}
        p={2}
      >
        <Card style={{ border: '1px solid #23233a', padding: '10px' }}>
          <ContactFieldsWrapper>
            <FieldsWrapper>
              <HeaderWrapper>
                {canUpdateClient ? (
                  <>
                    <ActionButton onClick={() => setModalOpen({ open: true, data })}>
                      <Icon icon={Icons.faPencilAlt} />
                    </ActionButton>
                    <ActionButton
                      onClick={() => openDeleteModal(data)}
                    >
                      <Icon
                        icon={Icons.faTrashAlt}
                      />
                    </ActionButton>
                  </>
                )
                  : null }
              </HeaderWrapper>
              {data.firstName || data.lastName ? (<span>{`${data.firstName} ${data.lastName}`}</span>) : null}
              {data.company ? (<span>{data.company}</span>) : null}
              {data.street1 ? (<span>{data.street1}</span>) : null}
              {data.street2 ? (<span>{data.street2}</span>) : null}
              {data.city || data.region || data.postalcode ? (<span>{`${data.city}, ${data.region} ${data.postalcode}`}</span>) : null}
              {data.phoneNumber ? (<span>{data.phoneNumber}</span>) : null}
              {data.emailAddress ? (<span>{data.emailAddress}</span>) : null}
            </FieldsWrapper>
            <FooterWrapper>
              <CheckBoxInput
                label={tg('billing')}
                value={checked}
                style={{ marginLeft: '0', marginBottom: '0' }}
              />
            </FooterWrapper>
          </ContactFieldsWrapper>
        </Card>
      </Box>
    </>
  );
}

const ContactFieldsWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
`;

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
`;

const ActionButton = styled(Button)`
  min-width: 25px;
  height: 25px;
  padding: 0px 5px;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
`;
