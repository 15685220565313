import { styled } from '@mui/system';

export const TdComponent = styled('td')`
  background-color: #1a1a32;

  font-size: 16px;
  letter-spacing: 0.6px;
  word-break: break-all;

  color: ${(props) => props.theme.colors.white};
  text-align: ${(props) => props.cellAlignment || 'left'};

  padding: 15px 20px 15px 20px;
`;
