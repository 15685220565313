import React, { useEffect, useMemo } from 'react';
import { dequal } from 'dequal';
import { useTranslation } from 'react-i18next';
import Select from '../Select';

export const operators = [
  { id: '$eq', outputType: 'text' },
  { id: '$neq', outputType: 'text' },
  { id: '$in', outputType: 'array' },
  { id: '$nin', outputType: 'array' },
  { id: '$has', outputType: 'boolean' },
  { id: '$gt', outputType: 'number' },
  { id: '$gte', outputType: 'number' },
  { id: '$lt', outputType: 'number' },
  { id: '$lte', outputType: 'number' },
  { id: '$ct', outputType: 'text' },
  { id: '$range', outputType: 'array' },
];

export const operatorsById = operators.reduce((acc, operator) => {
  acc[operator.id] = operator;
  return acc;
}, {});

const Operator = React.memo(
  (props) => {
    const { t } = useTranslation('ui', { keyPrefix: 'queryBuilder' });
    const { field, value } = props;
    const options = useMemo(() => {
      if (!field) return [];
      return field.operators
        .map((id) => ({ ...operatorsById[id], label: t(`operators.${id}`) }))
        .filter((operator) => operator);
    }, [field]);

    useEffect(() => {
      if (!field) return;
      const availableOperators = operators.filter(({ id }) => field.operators.includes(id));
      if (!availableOperators.length) return;

      // current selected operator does not exist for new field --> select first available filter
      if (!availableOperators.find(({ id }) => id === value)) {
        props.onChange({ operator: availableOperators[0].id });
      }
    }, [field]);

    return (
      <Select
        fullWidth
        valueKey="id"
        options={options}
        variant="outlined"
        size="small"
        label={t('operator')}
        style={{ minWidth: 200 }}
        value={value}
        onChange={(newValue) => {
          if (operatorsById[value]?.outputType !== operatorsById[newValue?.target?.value]?.outputType) {
            props.onChange({ operator: newValue?.target?.value, value: null });
            return;
          }
          props.onChange({ operator: newValue?.target?.value });
        }}
      />
    );
  },
  // Skip re-rendering if the operator didn't change.
  (prevProps, nextProps) => dequal(prevProps, nextProps),
);

Operator.whyDidYouRender = false;

export default Operator;
