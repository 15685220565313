import React from 'react';
import styled from 'styled-components';
import theme from 'components/themes';

function Button({
  className = '',
  onClick = null,
  link = false,
  children,
  disabled = false,
  outline = false,
  type = 'button',
  icon = null,
  noHover = false,
  iconOnly = false,
}) {
  return (
    <BaseButton
      className={className}
      link={link}
      outline={outline}
      type={type}
      disabled={disabled}
      onClick={onClick}
      noHover={noHover}
    >
      {!!icon && iconOnly
        ? (
          <>
            {icon}
            {children}
          </>
        )
        : (
          <>
            {!!icon && <IconContainer>{icon}</IconContainer>}
            {children}
          </>
        )}
    </BaseButton>
  );
}
const BaseButton = styled.button`
  border: ${theme.borderStyle.normal};
  border-radius: ${theme.borderRadius.lightCurve};
  font-size: 16px;
  padding: 0 16px;
  min-width: 160px;
  height: 44px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background: ${(props) => {
    if (props.link) {
      return props.disabled ? theme.colors.card : theme.colors.transparent;
    }
    if (props.outline) {
      return props.disabled ? theme.colors.card : theme.colors.transparent;
    }
    return props.disabled ? theme.colors.card : theme.colors.purple;
  }};
  border-color: ${(props) => {
    if (props.link) {
      return theme.colors.transparent;
    }
    if (props.outline) {
      return props.disabled ? theme.colors.darkGrey : theme.colors.white;
    }
    return props.disabled ? theme.colors.darkGrey : theme.colors.purple;
  }};
  color: ${(props) => (props.disabled ? theme.colors.grey : theme.colors.white)};
  cursor: ${(props) => (props.disabled ? 'not-allowed !important' : 'auto')};

  :focus {
    outline: 0;
  }
  
  :hover {
    background-color: ${(props) => {
    if (props.noHover || props.disabled) {
      return 'inherit';
    }
    if (props.outline) {
      return 'rgba(255, 255, 255, 0.2)';
    }
    return theme.colors.lighterPurple;
  }};
    transition: 0.5s;
`;
const IconContainer = styled.span`
  margin-top: 1px;
  margin-right: 8px;
`;
export default Button;
