import { styled } from '@mui/system';

export const TrComponent = styled('tr')`
  padding-bottom: 1px;

  border-top: 1px solid ${(props) => props.theme.table.colors.borderColor};

  ${(props) => props.highlightFirstCell
    && `
    td:first-of-type {
      background-color: #181831;

      color: ${props.theme.colors.lightGrey};
    }
  `}
`;
