/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  Typography,
} from '@mui/material';
import { PlatformContext } from '@upptic/module-directory';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  actionItemCategoriesGql,
  actionItemsGql,
  addActionItemMemberGql,
  addUserActionItemMemberGql,
  createActionItemGql,
  currentUserActionItemsGql,
  deleteActionItemGql,
  deleteActionItemMemberGql,
  deleteUserActionItemGql,
  deleteUserActionItemMemberGql,
  updateActionItemGql,
  updateActionItemMemberGql,
  updateUserActionItemGql,
  updateUserActionItemMemberGql,
} from '../gql';
import SingleActionItemCard from './SingleActionItemCard';
import FormHeader from '../Forms/FormHeader';
import ConfirmationDialog from '../ConfirmationDialog';
import ActionItemFormModal from './ActionItemFormModal';

const ActionItemPanel = ({
  title,
  linkedEntity,
  expandedOnInit,
  isCollapse,
  contentSx,
  externalFilter,
  isUserSpecific,
  showEntityLink,
  categoryFilter,
}) => {
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore?.currentClient?.code;
  const currentUser = platformStore?.currentUser;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const { t } = useTranslation('hubOverview', { keyPrefix: 'actionItems' });
  const { t: tui } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: thub } = useTranslation('hubOverview');
  const { t: tg } = useTranslation('general');
  const { t: talt } = useTranslation('applications');
  const [actionItemOpen, setActionItemOpen] = useState({ open: false });
  const [expandedActionItems, setExpandedActionItems] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState({ open: false });
  const [completeOneOpen, setCompleteOneOpen] = useState({ open: false });
  const [completeAllOpen, setCompleteAllOpen] = useState({ open: false });
  const [expanded, setExpanded] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const hasOnlyNonTerminalStatus = { status: { $nin: ['completed'] } };
  const [filter, setFilter] = useState({ $and: [hasOnlyNonTerminalStatus] });

  useEffect(() => {
    if (expandedOnInit) setExpanded(true);
  }, [expandedOnInit]);

  useEffect(() => {
    if (externalFilter) {
      if (showCompleted) {
        setFilter({ $and: [...externalFilter] });
        return;
      }
      setFilter({ $and: [...externalFilter, hasOnlyNonTerminalStatus] });
    }
  }, [externalFilter]);

  useEffect(() => {
    if (showCompleted) {
      if (externalFilter) {
        setFilter({ $and: [...externalFilter] });
        return;
      }
      setFilter({});
      return;
    }
    const hasStatusInFilter = filter?.$and?.findIndex((item) => Object.keys(item).includes('status'));
    if (hasStatusInFilter === undefined) {
      setFilter({ $and: [{ status: { $nin: ['completed'] } }] });
      return;
    }
    if (hasStatusInFilter === -1) {
      setFilter((prevState) => ({ $and: [...prevState?.$and, { status: { $nin: ['completed'] } }] }));
    }
  }, [showCompleted]);

  const { data: actionItemsData, loading: actionItemsLoading } = useQuery(actionItemsGql, {
    skip: isUserSpecific,
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      filter,
    },
  });

  const { data: userSpecificActionItemsData, loading: userActionItemsLoading } = useQuery(currentUserActionItemsGql, {
    skip: !isUserSpecific,
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      filter,
    },
  });

  const { data: categoryData, loading: categoriesLoading } = useQuery(actionItemCategoriesGql, {
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
    },
  });

  const data = useMemo(() => {
    if (isUserSpecific && userSpecificActionItemsData) return userSpecificActionItemsData?.currentUserActionItems;
    if (actionItemsData) return actionItemsData?.actionItems;
    return [];
  }, [actionItemsData, userSpecificActionItemsData, isUserSpecific]);

  const [createActionItem] = useMutation(createActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [updateActionItem] = useMutation(updateActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [updateUserActionItem] = useMutation(updateUserActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [deleteActionItem, { loading: deleteLoading }] = useMutation(deleteActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [deleteUserActionItem, { loading: deleteUserLoading }] = useMutation(deleteUserActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [addActionItemMember, { loading: addLoading }] = useMutation(addActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [updateActionItemMember] = useMutation(updateActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [deleteActionItemMember] = useMutation(deleteActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [addUserActionItemMember, { loading: addUserLoading }] = useMutation(addUserActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [updateUserActionItemMember] = useMutation(updateUserActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [deleteUserActionItemMember] = useMutation(deleteUserActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: isUserSpecific ? ['currentUserActionItems'] : ['actionItems'],
    awaitRefetchQueries: true,
  });

  const loading = actionItemsLoading
    || userActionItemsLoading
    || categoriesLoading
    || deleteLoading
    || addLoading
    || addUserLoading
    || deleteUserLoading;

  const handleDeleteConfirm = async () => {
    setDeleteModalOpen({ open: false });
    if (isUserSpecific) {
      await deleteUserActionItem({
        variables: {
          clientCode,
          actionItemCode: deleteModalOpen?.actionItem?.code,
        },
      });
      return;
    }
    await deleteActionItem({
      variables: {
        clientCode,
        actionItemCode: deleteModalOpen?.actionItem?.code,
      },
    });
  };

  const handleCompleteMemberClick = async () => {
    setCompleteOneOpen({ open: false });
    const userAssigneeRecord = completeOneOpen?.userAssigneeRecord;
    const actionItemCode = completeOneOpen?.actionItem?.code;
    if (userAssigneeRecord?.status !== 'completed') {
      const payload = {
        variables: {
          clientCode,
          actionItemCode,
          emailAddress: currentUser?.emailAddress,
          type: 'assignee',
          member: {
            status: 'completed',
          },
        },
      };
      if (isUserSpecific) return updateUserActionItemMember(payload);
      return updateActionItemMember(payload);
    }
    const payload = {
      variables: {
        clientCode,
        actionItemCode,
        emailAddress: currentUser?.emailAddress,
        type: 'assignee',
        member: {
          status: 'open',
        },
      },
    };
    if (isUserSpecific) return updateUserActionItemMember(payload);
    return updateActionItemMember(payload);
  };

  const handleCompleteAllClick = async () => {
    setCompleteAllOpen({ open: false });
    const members = [];
    const assignees = completeAllOpen?.assignees;
    const watchers = completeAllOpen?.watchers;
    if (watchers?.length !== 0) {
      for (const member of watchers) {
        members.push({
          status: member.status,
          type: member.type,
          user: { emailAddress: member.user.emailAddress },
        });
      }
    }
    for (const member of assignees) {
      members.push({
        status: 'completed',
        type: member.type,
        user: { emailAddress: member.user.emailAddress },
      });
    }
    const actionItemCode = completeAllOpen?.actionItem?.code;
    const payload = {
      variables: {
        clientCode,
        actionItemCode,
        actionItem: {
          members,
        },
      },
    };
    if (isUserSpecific) {
      await updateUserActionItem(payload);
      return;
    }
    await updateActionItem(payload);
  };

  const renderActionItemCards = useMemo(() => {
    if (isUserSpecific && data && data?.length !== 0) {
      const grouped = {};
      for (const item of data) {
        const client = item?.client;
        if (grouped[client?.code]) {
          grouped[client?.code].actionItems.push(item);
          // eslint-disable-next-line no-continue
          continue;
        }
        grouped[client?.code] = { ...client, actionItems: [item] };
      }
      // eslint-disable-next-line arrow-body-style
      return Object.keys(grouped)?.map((client) => {
        return (
          <React.Fragment key={client}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              borderBottom="1px solid"
              mb={1.5}
            >
              <Typography>{grouped[client]?.name}</Typography>
            </Box>
            {grouped[client].actionItems.map((item, index) => (
              <SingleActionItemCard
                key={index}
                data={item}
                expandedActionItems={expandedActionItems}
                setExpandedActionItems={setExpandedActionItems}
                clientCode={client}
                currentUser={currentUser}
                showEntityLink={showEntityLink}
                setDeleteModalOpen={setDeleteModalOpen}
                onAddActionItemMember={(params) => {
                  if (isUserSpecific) return addUserActionItemMember(params);
                  return addActionItemMember(params);
                }}
                updateActionItemMember={(params) => {
                  if (isUserSpecific) return updateUserActionItemMember(params);
                  return updateActionItemMember(params);
                }}
                onDeleteActionItemMember={(params) => {
                  if (isUserSpecific) return deleteUserActionItemMember(params);
                  return deleteActionItemMember(params);
                }}
                setCompleteOneOpen={setCompleteOneOpen}
                setCompleteAllOpen={setCompleteAllOpen}
                setActionItemOpen={setActionItemOpen}
                isUserSpecific={isUserSpecific}
              />
            ))}
          </React.Fragment>
        );
      });
    }
    if (data && data?.length !== 0) {
      return data.map((item, index) => (
        <SingleActionItemCard
          key={index}
          data={item}
          expandedActionItems={expandedActionItems}
          setExpandedActionItems={setExpandedActionItems}
          clientCode={clientCode}
          currentUser={currentUser}
          categoryData={categoryData}
          showEntityLink={showEntityLink}
          setDeleteModalOpen={setDeleteModalOpen}
          onAddActionItemMember={(params) => {
            if (isUserSpecific) return addUserActionItemMember(params);
            return addActionItemMember(params);
          }}
          updateActionItemMember={(params) => {
            if (isUserSpecific) return updateUserActionItemMember(params);
            return updateActionItemMember(params);
          }}
          onDeleteActionItemMember={(params) => {
            if (isUserSpecific) return deleteUserActionItemMember(params);
            return deleteActionItemMember(params);
          }}
          setCompleteOneOpen={setCompleteOneOpen}
          setCompleteAllOpen={setCompleteAllOpen}
          setActionItemOpen={setActionItemOpen}
        />
      ));
    }
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>{tui('noActionItems')}</Typography>
      </Box>
    );
  }, [data, showEntityLink, isUserSpecific, expandedActionItems, clientCode, currentUser, categoryData]);

  const formatMembersForSubmit = (values) => {
    const members = [];
    if (values?.assignees && values?.assignees?.length !== 0) {
      for (const assignee of values?.assignees) {
        members.push({
          status: assignee?.status || 'open',
          type: 'assignee',
          user: {
            emailAddress: assignee?.emailAddress || assignee?.user?.emailAddress,
          },
        });
      }
    }
    if (values?.watchers && values?.watchers?.length !== 0) {
      for (const watcher of values?.watchers) {
        members.push({
          status: watcher?.status || 'open',
          type: 'watcher',
          user: {
            emailAddress: watcher?.emailAddress || watcher?.user?.emailAddress,
          },
        });
      }
    }
    return members;
  };

  const handleActionItemSubmit = async (values, actionItemCode) => {
    if (actionItemCode) {
      const actionItem = {
        actionItemCategory: { code: values?.actionItemCategory.code },
        endDate: values?.endDate ? new Date(values?.endDate) : undefined,
        name: values?.name,
        startDate: values?.startDate ? new Date(values?.startDate) : undefined,
        status: values?.status,
        type: values?.type,
        description: values?.description,
        members: values?.assignees || values?.watchers ? formatMembersForSubmit(values) : undefined,
        application: values?.application ? { code: values.application?.code } : undefined,
      };

      let result;
      if (isUserSpecific) {
        result = await updateUserActionItem({
          variables: {
            clientCode,
            actionItemCode,
            actionItem,
          },
        });
      } else {
        result = await updateActionItem({
          variables: {
            clientCode,
            actionItemCode,
            actionItem,
          },
        });
      }
      if (!result?.errors) setActionItemOpen({ open: false });
      return;
    }
    const actionItem = {
      actionItemCategory: { code: values?.actionItemCategory.code },
      endDate: values?.endDate ? new Date(values?.endDate) : undefined,
      name: values?.name,
      startDate: values?.startDate ? new Date(values?.startDate) : undefined,
      status: values?.status,
      type: values?.type,
      description: values?.description,
      members: values?.assignees || values?.watchers ? formatMembersForSubmit(values, true) : undefined,
      application: { code: selectedApplicationCode },
      linkedEntity,
    };
    const { errors } = await createActionItem({
      variables: {
        clientCode,
        actionItem,
      },
    });
    if (!errors) setActionItemOpen({ open: false });
  };

  return (
    <Box
      height="100%"
      sx={{
        border: '1px solid rgba(255, 255, 255, 0.23)',
      }}
    >
      {isCollapse ? (
        <Box
          width="100%"
        >
          <FormHeader
            title={title || tui('titleComments')}
            buttons={!isUserSpecific ? [
              {
                switch: true,
                label: tui('showCompleted'),
                checked: showCompleted,
                onChange: setShowCompleted,
              },
              {
                name: tg('add'),
                onClick: () => setActionItemOpen({ open: true }),
                sx: { minWidth: '60px', padding: '0px', height: '25px' },
              },
              {
                expand: true,
                setExpanded: () => setExpanded((prevState) => !prevState),
                expanded,
                sx: { height: '30px', width: '30px' },
              },
            ] : [
              {
                switch: true,
                label: tui('showCompleted'),
                checked: showCompleted,
                onChange: setShowCompleted,
              },
            ]}
            sx={{
              border: '1px solid rgba(255, 255, 255, 0.23)',
              padding: '8px',
              minHeight: '45px',
            }}
          />
          <Collapse
            in={expanded}
            timeout="auto"
          >
            <Box
              height={600}
              width="100%"
              p={1}
              sx={{
                overflowY: 'auto',
                ...contentSx,
              }}
            >
              {loading ? (
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress size="3rem" />
                </Box>
              ) : (
                renderActionItemCards
              )}
            </Box>
          </Collapse>
        </Box>
      ) : (
        <>
          <Box
            width="100%"
          >
            <FormHeader
              title={title || tui('titleComments')}
              buttons={!isUserSpecific ? [
                {
                  switch: true,
                  label: tui('showCompleted'),
                  checked: showCompleted,
                  onChange: setShowCompleted,
                },
                {
                  name: tg('add'),
                  onClick: () => setActionItemOpen({ open: true }),
                  sx: { minWidth: '60px', padding: '0px' },
                },
              ] : [
                {
                  switch: true,
                  label: tui('showCompleted'),
                  checked: showCompleted,
                  onChange: setShowCompleted,
                },
              ]}
              sx={{
                border: '1px solid rgba(255, 255, 255, 0.23)',
                padding: '8px',
                minHeight: '45px',
                marginBottom: '8px',
              }}
            />
          </Box>
          <Box
            height="calc(100% - 59px)"
            width="100%"
            p={1}
            sx={{
              overflowY: 'auto',
              ...contentSx,
            }}
          >
            {loading ? (
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size="3rem" />
              </Box>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                {renderActionItemCards}
              </Box>
            )}
          </Box>
        </>
      )}
      <ConfirmationDialog
        open={deleteModalOpen?.open}
        onClose={() => setDeleteModalOpen({ open: false })}
        title={t('deleteTitle')}
        text={thub('actionItems.delete', { item: deleteModalOpen?.actionItem?.name })}
        secondaryText={talt('effect')}
        buttons={[
          {
            text: tg('cancel'),
            onClick: () => {
              setDeleteModalOpen({ open: false });
            },
          },
          {
            text: tg('confirm'),
            onClick: () => handleDeleteConfirm(),
          },
        ]}
      />
      <ConfirmationDialog
        open={completeOneOpen?.open}
        onClose={() => setCompleteOneOpen({ open: false })}
        title={completeOneOpen?.userAssigneeRecord?.status === 'completed'
          ? tui('confirmations.completeOneReopenTitle')
          : tui('confirmations.completeOneTitle')}
        text={completeOneOpen?.userAssigneeRecord?.status === 'completed'
          ? tui('confirmations.completeOneReopen', { item: completeOneOpen?.actionItem?.name })
          : tui('confirmations.completeOneTask', { item: completeOneOpen?.actionItem?.name })}
        secondaryText={talt('effect')}
        buttons={[
          {
            text: tg('cancel'),
            onClick: () => {
              setCompleteOneOpen({ open: false });
            },
          },
          {
            text: tg('confirm'),
            onClick: () => handleCompleteMemberClick(),
          },
        ]}
      />
      <ConfirmationDialog
        open={completeAllOpen?.open}
        onClose={() => setCompleteAllOpen({ open: false })}
        title={tui('confirmations.completeAllTitle')}
        text={tui('confirmations.completeAllTasks', { item: completeAllOpen?.actionItem?.name })}
        secondaryText={talt('effect')}
        buttons={[
          {
            text: tg('cancel'),
            onClick: () => {
              setCompleteAllOpen({ open: false });
            },
          },
          {
            text: tg('confirm'),
            onClick: () => handleCompleteAllClick(),
          },
        ]}
      />
      <ActionItemFormModal
        open={actionItemOpen?.open}
        onClose={() => setActionItemOpen({ open: false })}
        data={actionItemOpen?.data}
        onSubmit={handleActionItemSubmit}
        isUserSpecific={isUserSpecific}
        linkedEntity={linkedEntity}
        categoryFilter={categoryFilter}
      />
    </Box>
  );
};

export default ActionItemPanel;
