import React from 'react';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers-pro';
import InfoIcon from '@mui/icons-material/Info';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { Badge } from '@mui/material';

const ActionItemDay = (props) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? <InfoIcon color="primary" /> : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
};

const CalendarPicker = ({
  onChange,
  highlightedDays,
  value,
  setValue,
  ...rest
}) => (
  <LocalizationProvider
    dateAdapter={AdapterMoment}
  >
    <DateCalendar
      sx={{
        '& .MuiDayCalendar-weekContainer': {
          margin: '7px 0',
        },
        '& .MuiDayCalendar-slideTransition': {
          minHeight: '265px',
        },
      }}
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        if (onChange) onChange(newValue);
      }}
      slots={{
        day: ActionItemDay,
      }}
      slotProps={{
        day: {
          highlightedDays,
        },
      }}
      timezone="UTC"
      {...rest}
    />
  </LocalizationProvider>
);

export default CalendarPicker;
