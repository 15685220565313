/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Typography,
  Link as MUILink,
  CircularProgress,
} from '@mui/material';
import { PlatformContext } from '@upptic/module-directory';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReportCategory } from './ReportCategory';
import ReportCard from './ReportCard';
import { Error } from '../../components/Error';

const ReportPage = ({
  onReportChange,
  categoryData,
  selectedReport,
  error,
  loading,
}) => {
  const platformStore = useContext(PlatformContext);
  const { reportCode } = useParams();
  const { t } = useTranslation('ui', { keyPrefix: 'reports' });
  const [sectionValue, setSectionValue] = useState(null);

  useEffect(() => {
    if (!sectionValue && reportCode) {
      setSectionValue(reportCode);
    }
  }, [reportCode]);

  const renderCategories = () => {
    if (categoryData?.length !== 0) {
      return categoryData.map((category) => (
        <ReportCategory
          data={category}
          onChange={onReportChange}
          sectionValue={sectionValue}
          setSectionValue={setSectionValue}
        />
      ));
    }
    return null;
  };

  const renderReport = () => {
    if (selectedReport) {
      return (
        <ReportCard
          load={false}
          expandOnInit
          selectedReport={selectedReport}
          expansionParams={{
            type: 'analytics',
            onClick: () => {
              setSectionValue(null);
              onReportChange(null);
            },
          }}
        />
      );
    }
    return null;
  };
  if (platformStore.applications.length < 1) {
    return (
      <Typography
        variant="h4"
        textAlign="center"
        mt={4}
      >
        {t('noApplications')}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
      >
        <CircularProgress size="6rem" />
      </Box>
    );
  }

  if (categoryData?.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          mb={2}
          mt={4}
        >
          <Typography variant="h5">{t('zeroStateText')}</Typography>
        </Box>
        <Box>
          <MUILink
            component={Link}
            to={`/${platformStore.currentClient.code}/ua/integrations`}
          >
            {t('integrationLink')}
          </MUILink>
        </Box>
      </Box>
    );
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box
      flexGrow={1}
      p={3}
      style={{ textAlign: 'center' }}
    >
      <AppBar position="static" sx={{ padding: '8px', marginBottom: '8px' }}>
        <Toolbar disableGutters>
          <Grid
            container
            spacing={2}
          >
            {renderCategories()}
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
      >
        <Grid
          item
          xs={12}
        >
          {selectedReport ? (
            renderReport()
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportPage;
