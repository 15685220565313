import React from 'react';
import {
  FormHelperText,
  TextareaAutosize,
  styled,
} from '@mui/material';

const StyledTextarea = styled(TextareaAutosize)(
  ({ error, disabled, theme }) => `
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid ${error ? theme.palette.error.main : 'rgba(255, 255, 255, 0.23)'};
  color: #fff;

  &:hover {
    border-color: ${disabled ? 'none' : 'rgb(255, 255, 255)'};
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const TextArea = ({
  placeholder,
  minRows = 3,
  maxRows = 6,
  error,
  isDisabled,
  ...rest
}) => (
  <>
    <StyledTextarea
      minRows={minRows}
      maxRows={maxRows}
      placeholder={placeholder}
      error={error}
      onClick={(e) => e.stopPropagation()}
      disabled={isDisabled}
      {...rest}
    />
    {error ? (
      <FormHelperText
        error
        required
        variant="filled"
      >
        {error}
      </FormHelperText>
    ) : null}
  </>
);

export default TextArea;
