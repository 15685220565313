export default {
  ui: {
    queryBuilder: {
      rule: 'Rule',
      group: 'Group',
      field: 'Field',
      operator: 'Operator',
      value: 'Value',
      searchTerm: 'Search Term',
      range: {
        start: 'Start',
        end: 'End',
      },
      reset: 'Reset',
      search: 'Search',
      combinators: {
        and: 'AND',
        or: 'OR',
      },
      operators: {
        $eq: 'equal to',
        $neq: 'not equal to',
        $in: 'in',
        $nin: 'not in',
        $has: 'exists',
        $gt: 'greater than',
        $gte: 'greater or equal to',
        $lt: 'less than',
        $lte: 'less or equal to',
        $ct: 'contains',
        $range: 'is between',
      },
    },
    actionItems: {
      title: 'Action Items',
      titleComments: 'Action Items & Comments',
      noActionItems: 'No Action Items Found',
      noOptions: 'No Users To Display',
      showCompleted: 'Show Completed',
      goTo: 'Go to',
      entityTypes: {
        user: 'User',
        subCampaign: 'Sub-Campaign',
        conceptAsset: 'Concept Asset',
        concept: 'Concept',
        clientIntegration: 'Integration',
        campaign: 'Campaign',
        application: 'Application',
        appPlatform: 'App Platform',
      },
      users: 'Users',
      due: 'Due: {{date}}',
      watching: 'Watching',
      addComment: 'Add Comment',
      rejectPrefix: 'Reject - ',
      approvePrefix: 'Approve - ',
      labels: {
        email: 'Email',
        name: 'Title',
        watchers: 'Watchers',
        assignees: 'Assignees',
        startDate: 'Start Date',
        endDate: 'End Date',
        type: 'Type',
        category: 'Category',
        dueDate: 'Due Date',
        description: 'Description',
        application: 'Linked Application',
      },
      type: {
        action: 'Action',
        comment: 'Comment',
        duration: 'Duration',
        milestone: 'Milestone',
      },
      status: {
        completed: 'Completed',
        onHold: 'On Hold',
        open: 'Open',
        parkingLot: 'Parking Lot',
      },
      tooltips: {
        delete: 'Delete Action Item',
        watcher: 'Add Self to Watchers',
        isWatcher: 'Remove Self from Watchers',
        completeSelf: 'Complete Personal Task',
        completeAll: 'Complete Action Item',
        reopen: 'Re-open personal task',
        enableNotifications: 'Enable Notifications',
        muteNotifications: 'Mute Notifications',
        edit: 'Edit Action Item',
        assigned: 'Assigned to your User',
      },
      confirmations: {
        completeOneTitle: 'Complete User Task',
        completeOneReopenTitle: 'Reopen User Task',
        completeOneTask: 'Are you sure you want to mark your task on the Action item: {{item}} as completed?',
        completeOneReopen: 'Are you sure you want to re-open your task on the Action item: {{item}}?',
        completeAllTitle: 'Complete All User Tasks',
        completeAllTasks: 'Are you sure you want to mark all User Tasks on the Action item: {{item}} as completed?',
      },
      modal: {
        title: 'Add Action Item',
        editTitle: 'Edit Action Item',
      },
    },
    reports: {
      zeroStateText: 'No Integrations have been configured for the selected Application',
      growthZeroStateText: 'No Growth Integrations have been configured for the selected Application',
      integrationLink: 'Please configure at least one Integration for the Application',
      noApplications: 'No applications exist, please check account configuration.',
    },
    colors: {
      preview: 'Color Preview',
      '#17806D': 'Dark Green',
      '#1CAC78': 'Green',
      '#71BC78': 'Light Green',
      '#2B6CC4': 'Dark Blue',
      '#1DACD6': 'Blue',
      '#77DDE7': 'Light Blue',
      '#B22222': 'Dark Red',
      '#FF0000': 'Red',
      '#FF6347': 'Light Red',
      '#FF4500': 'Orange Red',
      '#FF8C00': 'Dark Orange',
      '#FFA500': 'Orange',
      '#B8860B': 'Dark Gold',
      '#DAA520': 'Gold',
      '#FFD700': 'Light Gold',
      '#F0E68C': 'Beige',
      '#808000': 'Olive',
      '#FFFF00': 'Yellow',
      '#AFEEEE': 'Pale Blue',
      '#00FFFF': 'Aqua',
      '#ADD8E6': 'Grey Blue',
      '#FFC0CB': 'Pink',
      '#A0522D': 'Brown',
      '#D2691E': 'Orange Brown',
      '#CD853F': 'Light Brown',
      '#D2B48C': 'Tan',
      '#708090': 'Slate',
      '#C0C0C0': 'Silver',
      '#F5F5F5': 'Smoke',
    },
    currency: {
      USD: '$',
      EUR: '€',
      CAD: 'C$',
      AUD: 'A$',
      JPY: '¥',
    },
    dateRange: {
      range: '{{startDate}} to {{endDate}}',
    },
    privacyModel: {
      skan: 'Privacy Model v2',
      mmp: 'MMP',
      label: 'Model',
    },
    calendar: {
      event: 'Event',
      fields: {
        name: 'Event Name',
        description: 'Description',
        startDate: 'Start Date',
        endDate: 'End Date',
      },
    },
    dataGrid: {
      localeText: {
        conceptSearch: 'Please enter an existing Concept Name or Concept ID above.',
      },
    },
    search: {
      title: 'Search',
      searchItems: 'Search Items',
    },
    select: {
      only: 'Only',
    },
    selectionList: {
      onlyGroup: 'Only Group',
      onlySearch: 'Only Search',
    },
    errors: {
      required: 'Required.',
    },
  },
};
