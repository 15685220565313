/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import NotificationPanel from '../../Notifications/NotificationPanel';

const NotificationElement = ({ widget }) => (
  <NotificationPanel
    isCollapse={widget?.params?.collapsable}
    contentSx={{
      minHeight: 64,
      height: 'auto',
      maxHeight: 436,
    }}
  />
);

export default NotificationElement;
