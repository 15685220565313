import React from 'react';
import styled from 'styled-components';
import themes from 'components/themes';

const MultiToggle = (props) => {
  const handleClick = (singleOption) => {
    singleOption.enabled = !singleOption.enabled;
    props.onChange(props.options);
  };
  return (
    <Wrapper>
      {props.options
        ? props.options.map((singleOption) => {
          const IconComponent = singleOption?.icon;
          const isActive = singleOption.enabled;
          return (
            <Button
              key={singleOption.value}
              onClick={() => handleClick(singleOption)}
              active={isActive}
              className={isActive ? 'active' : undefined}
            >
              <LabelWrapper>
                {singleOption.icon ? (
                  <IconWrapper
                    fill="#fff"
                    isActive={isActive}
                  >
                    <IconComponent style={{ width: '18px', marginRight: 10 }} />
                  </IconWrapper>
                ) : null}
                {singleOption.label}
              </LabelWrapper>
            </Button>
          );
        })
        : null}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Button = styled.button`
  color: ${themes.colors.white};
  background-color: ${themes.colors.backgroundDark};
  border: 1px solid ${themes.colors.purple};
  font-size: 12px;
  width: 130px;
  padding: 10px 0;
  letter-spacing: 0.5px;
  font-weight: 600;
  &:focus {
    outline: none;
  }
  &:hover {
    border: 1px solid #ac68ee;
  }

  ${(props) => props.active
    && `
    background-color: ${themes.colors.purple};
    &:hover {
      background-color: #ac68ee;
    }
  `}
  ${(props) => !props.active
  && `
    &:hover svg {
      fill: ${themes.colors.purple};
    }
    &:hover {
      color: ${themes.colors.purple};
    }
  `}
`;

const IconWrapper = styled.div`
  min-width: 25px;
  height: 25px;
  svg {
    fill: ${(props) => props.fill};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default MultiToggle;
