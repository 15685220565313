import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import themes from 'components/themes';
import Icon, { Icons } from 'components/Icon';

function MessageBox({ visible = true, closeable = true, transparent = false, children, className = '', style }) {
  const [isVisible, setVisible] = useState(visible);
  return (
    <>
      {isVisible && (
      <Container
        style={style}
        transparent={transparent}
        className={className}
      >
        <IconBox>
          <Icon
            icon={Icons.faInfoCircle}
            color={themes.colors.brightCyan}
          />
        </IconBox>
        <ContentBox>{children}</ContentBox>
        {closeable && (
        <IconBox>
          <span onClick={() => setVisible(false)}>
            <Icon
              icon={Icons.faWindowClose}
              color={themes.colors.white}
            />
          </span>
        </IconBox>
        )}
      </Container>
      )}
    </>
  );
}
const Container = styled(Flex)`
  background-color: ${(props) => (props.transparent ? 'transparent' : themes.colors.backgroundLight)};
  border: ${(props) => (props.transparent ? 'none' : '1px solid #31558d')};
  border-radius: 8px;
  padding: 16px 20px 16px 0;
  width: 100%;
  align-items: center;
`;
const ContentBox = styled(Box)`
  color: ${themes.colors.white};
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.43;
`;
const IconBox = styled(Box)`
  padding: 0 20px;
`;
export default MessageBox;
