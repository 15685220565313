import React from 'react';
import {
  Step,
  Stepper,
  Stack,
  Button,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function NonLinearStepper({
  steps,
  activeStep,
  completed,
  children,
  buttons,
  nonLinear,
  alternativeLabel,
  stepperIcons,
}) {
  const createButtons = () => {
    const buttonArray = buttons[activeStep];
    return buttonArray.map((button, index) => {
      if (button === undefined) return null;
      return (
        <Button
          key={index}
          variant="contained"
          sx={{ minWidth: '130px' }}
          type={button?.type || 'button'}
          onClick={button?.onClick}
          disabled={button?.disabled ? button?.disabled : false}
        >
          {button?.name}
        </Button>
      );
    });
  };

  const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(151, 67, 235) 0%,rgb(158, 80, 236) 50%,rgb(172, 104, 238) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(151, 67, 235) 0%,rgb(158, 80, 236) 50%,rgb(172, 104, 238) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.primary.dark,
    }),
  }));

  const StepIconComponent = (props) => {
    // console.log(props, 'props');
    const { active, completed, className, icon } = props;

    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <CheckIcon color="success" sx={{ fontSize: '33px' }} /> : stepperIcons[icon - 1]}
      </StepIconRoot>
    );
  };

  return (
    <>
      <Stepper
        nonLinear={nonLinear}
        activeStep={activeStep}
        alternativeLabel={alternativeLabel}
        connector={stepperIcons ? <Connector /> : false}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
          >
            <StepLabel
              // color="inherit"
              StepIconComponent={stepperIcons ? StepIconComponent : undefined}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
        {buttons ? (
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            pt={2}
            direction="row"
            justifyContent="flex-end"
            position="absolute"
            bottom={0}
          >
            {createButtons()}
          </Stack>
        ) : null}
      </Stepper>
      {children}
    </>
  );
}
