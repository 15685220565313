import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import ClipboardTooltipButton from './ActionCellClipboardButton';

/**
 * @param {object} param
 * @param {object[]} param.buttons
 * @param {string} param.buttons[].to
 * @param {string} param.buttons[].icon
 * @param {component} param.buttons[].muiIcon
 * @param {function} param.buttons[].onClick
 * @param {boolean} param.buttons[].disabled
 * @param {string} param.buttons[].value
 * @param {object} param.buttons[].tooltip
 * @param {string} param.buttons[].tooltip.title
 * @param {string} param.buttons[].tooltip.placement
 */

const renderButton = (button, index, sx) => {
  const handleButtonClick = (event) => {
    event.stopPropagation();
    button.onClick();
  };
  if (button.to) {
    if (button.tooltip) {
      return (
        <Tooltip
          title={button?.tooltip?.title}
          placement={button?.tooltip?.placement || 'top'}
          disableInteractive
          aria-label="tooltip"
        >
          <Button
            variant="contained"
            key={index}
            component={Link}
            to={button.to}
            disabled={button.disabled}
            sx={sx}
          >
            {button.icon ? (
              <Icon icon={button.icon} />
            ) : (
              button.muiIcon
            )}
          </Button>
        </Tooltip>
      );
    }
    return (
      <Button
        variant="contained"
        key={index}
        component={Link}
        to={button.to}
        disabled={button.disabled}
        sx={sx}
      >
        {button.icon ? (
          <Icon icon={button.icon} />
        ) : (
          button.muiIcon
        )}
      </Button>
    );
  }
  if (button.tooltip) {
    if (button.value) {
      return (
        <ClipboardTooltipButton
          index={index}
          button={button}
          sx={sx}
        />
      );
    }
    return (
      <Tooltip
        title={button?.tooltip?.title}
        placement={button?.tooltip?.placement || 'top'}
        disableInteractive
      >
        <Button
          variant="contained"
          key={index}
          onClick={button.onClick ? (event) => handleButtonClick(event) : undefined}
          disabled={button.disabled}
          sx={sx}
        >
          {button.icon ? (
            <Icon icon={button.icon} />
          ) : (
            button.muiIcon
          )}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      variant="contained"
      key={index}
      onClick={button.onClick ? (event) => handleButtonClick(event) : undefined}
      disabled={button.disabled}
      sx={sx}
    >
      {button.icon ? (
        <Icon icon={button.icon} />
      ) : (
        button.muiIcon
      )}
    </Button>
  );
};

const ActionCell = ({ buttons, small }) => {
  const sx = useMemo(() => ({
    minWidth: small ? '27px' : '40px',
    height: small ? '27px' : '40px',
    padding: '0 5px',
    '& svg': small ? {
      fontSize: '18px',
    } : undefined,
  }), [small]);
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {buttons.map((button, index) => (
        <Box
          sx={{
            '&:not(:last-of-type)': {
              marginRight: '15px',
            },
          }}
          key={index}
        >
          {renderButton(button, index, sx)}
        </Box>
      ))}
    </Box>
  );
};

export default ActionCell;
