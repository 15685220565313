/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlatformContext } from '@upptic/module-directory';
import FormSectionWFields from '../Forms/FormSectionWFields';
import { validators } from '../../utils';

const ActionItemFormSection = ({
  values,
  includeAudit,
  includeApplications,
  hasLinkedEntity,
  actionItemCategories,
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);
  const applications = platformStore?.applications;

  const fields = (values) => {
    const fields = [
      {
        name: 'name',
        label: t('labels.name'),
        xs: 12,
        validate: validators.required,
      },
      {
        name: 'type',
        type: 'select',
        label: t('labels.type'),
        options: [
          { value: 'action', label: t('type.action') },
          { value: 'comment', label: t('type.comment') },
          // Leave commented out till future Date per Mike
          // { value: 'duration', label: t('type.duration') },
          // { value: 'milestone', label: t('type.milestone') },
        ],
        validate: validators.required,
        isDisabled: includeAudit,
      },
      {
        name: 'status',
        type: 'select',
        label: tg('status.name'),
        options: [
          { value: 'open', label: t('status.open') },
          { value: 'onHold', label: t('status.onHold') },
          { value: 'completed', label: t('status.completed') },
          { value: 'parkingLot', label: t('status.parkingLot') },
        ],
      },
      {
        name: 'actionItemCategory.code',
        type: 'select',
        label: t('labels.category'),
        options: actionItemCategories,
        xs: includeApplications ? 6 : 12,
        validate: validators.required,
      },
    ];
    if (includeApplications) {
      fields.push({
        name: 'application',
        type: 'autocomplete',
        options: applications,
        label: t('labels.application'),
        labelKey: 'name',
        xs: 6,
        validate: validators.required,
        isDisabled: hasLinkedEntity,
      });
    }
    fields.push(
      {
        name: 'assignees',
        type: 'users',
        label: t('labels.assignees'),
        multiple: true,
        placeholder: t('labels.assignees'),
        xs: 12,
      },
      {
        name: 'watchers',
        type: 'users',
        label: t('labels.watchers'),
        multiple: true,
        placeholder: t('labels.watchers'),
        xs: 12,
      },
      {
        name: 'description',
        type: 'richTextarea',
        label: t('labels.description'),
        placeholder: t('labels.description'),
        xs: 12,
      },
    );
    if (values?.type !== 'duration') {
      fields.push({
        name: 'endDate',
        type: 'date',
        label: t('labels.dueDate'),
        xs: 12,
      });
    }
    if (values?.type === 'duration') {
      fields.push(
        {
          name: 'startDate',
          type: 'date',
          label: t('labels.startDate'),
        },
        {
          name: 'endDate',
          type: 'date',
          label: t('labels.endDate'),
        },
      );
    }
    if (includeAudit) fields.push({ type: 'audit', name: 'audit' });
    return fields;
  };
  return (
    <FormSectionWFields fields={fields(values)} />
  );
};

export default ActionItemFormSection;
