import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from './forms/Button';

function StyledLinkButton({
  to = '',
  buttonMinWidth = 130,
  children,
  style = null,
  disabled,
}) {
  return (
    <BaseLink
      to={to}
      style={style}
    >
      <BaseButton
        buttonMinWidth={buttonMinWidth}
        disabled={disabled}
      >
        {children}
      </BaseButton>
    </BaseLink>
  );
}

const BaseLink = styled(Link)`
${(props) => { props.style; }}
  &:hover {
    text-decoration: none;
  }
`;

const BaseButton = styled(Button)`
  min-width: ${(props) => props.buttonMinWidth}px;
`;

export default StyledLinkButton;
