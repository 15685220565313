import React from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

export default function FormButtonWDynamicText({
  preceedingText,
  number,
  onClick,
  buttonName,
  disabled,
}) {
  return (
    <Grid
      container
      xs={12}
      alignItems="center"
      justifyContent="space-around"
    >
      <Grid
        item
      >
        <Typography
          variant="body2"
        >
          {`${number} ${preceedingText}`}
        </Typography>
      </Grid>
      <Grid
        item
      >
        <Button
          variant="contained"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonName}
        </Button>
      </Grid>
    </Grid>
  );
}
