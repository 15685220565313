const ANDROID_TITLE_LENGTH = 50;
const IOS_TITLE_LENGTH = 50;

export const Platform = {
  ios: 'ios',
  android: 'android',
};

export const displayPlatformName = (platform) => `${platform === Platform.ios ? 'iOS' : 'Android'} Version`;

export const getTitleLength = (platform) => (platform === Platform.ios ? IOS_TITLE_LENGTH : ANDROID_TITLE_LENGTH);

/**
 * Get enum key by value
 * @param {any} enumInstance instance of certain enum
 * @param {string | number} enumValue value of key
 * @returns {string | undefined} enum key by given value
 */
export const getEnumKeyByValue = (enumInstance, enumValue) => Object.keys(enumInstance).find((key) => enumInstance[key] === enumValue);

export default { displayPlatformName };
