import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CommentIcon from '@mui/icons-material/Comment';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import StatusCircle from './StatusCircle';
import ActionItemMemberAutocomplete from './ActionItemMemberAutocomplete';
import ActionItemDescription from './ActionItemDescription';
import ActionCell from '../ActionCell';
import { MUIGetDateInCurrentTimestamp } from '../../utils/dates';

const typeIconMap = {
  action: <CheckBoxIcon />,
  comment: <CommentIcon />,
  duration: <TimelapseIcon />,
  milestone: <EmojiEventsIcon />,
};

const SingleActionItemCard = ({
  data,
  expandedActionItems,
  setExpandedActionItems,
  clientCode,
  currentUser,
  categoryData,
  showEntityLink,
  setDeleteModalOpen,
  onAddActionItemMember,
  onDeleteActionItemMember,
  onUpdateActionItemMember,
  setCompleteOneOpen,
  setCompleteAllOpen,
  setActionItemOpen,
  isUserSpecific,
}) => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: talt } = useTranslation('ui');
  const { type, name, endDate, status, code, description, members, linkedEntity, client, application } = data;
  const [userAssigneeRecord, setUserAssigneeRecord] = useState(null);
  const [userWatcherRecord, setUserWatcherRecord] = useState(null);
  const categoryColor = useMemo(() => {
    if (isUserSpecific) return 'unset';
    const findCategoryData = categoryData?.actionItemCategories?.find((category) => category?.code === data?.actionItemCategory?.code);
    if (findCategoryData && findCategoryData?.color) return findCategoryData?.color;
    return 'unset';
  }, [categoryData, data]);
  const assignees = useMemo(() => {
    const assigneeArray = [];
    if (members && members?.length !== 0) {
      for (const member of members) {
        if (member.type === 'assignee') assigneeArray.push(member);
      }
    }
    return assigneeArray;
  }, [members]);
  const watchers = useMemo(() => {
    const watchersArray = [];
    if (members && members?.length !== 0) {
      for (const member of members) {
        if (member.type === 'watcher') watchersArray.push(member);
      }
    }
    return watchersArray;
  }, [members]);
  const isExpanded = expandedActionItems?.includes(code);

  const isUserAssignee = useMemo(() => {
    if (assignees && assignees?.length !== 0) {
      const findUserAssignee = assignees.find((assignee) => assignee?.user?.emailAddress === currentUser?.emailAddress);
      if (findUserAssignee) {
        setUserAssigneeRecord(findUserAssignee);
        return true;
      }
    }
    if (userAssigneeRecord) setUserAssigneeRecord(null);
    return false;
  }, [assignees]);

  const isUserWatcher = useMemo(() => {
    if (watchers && watchers?.length !== 0) {
      const findUserWatcher = watchers.find((watcher) => watcher?.user?.emailAddress === currentUser?.emailAddress);
      if (findUserWatcher) {
        setUserWatcherRecord(findUserWatcher);
        return true;
      }
    }
    if (userWatcherRecord) setUserWatcherRecord(null);
    return false;
  }, [assignees]);

  const onExpand = () => {
    if (isExpanded) {
      setExpandedActionItems([]);
      return;
    }
    setExpandedActionItems([code]);
  };

  const handleWatcherChange = () => {
    if (isUserWatcher) {
      const deletePayload = {
        variables: {
          clientCode,
          actionItemCode: code,
          emailAddress: currentUser.emailAddress,
          type: 'watcher',
        },
      };
      onDeleteActionItemMember(deletePayload);
      return;
    }
    const addPayload = {
      variables: {
        clientCode,
        actionItemCode: code,
        member: {
          status: 'open',
          type: 'watcher',
          user: {
            emailAddress: currentUser?.emailAddress,
          },
        },
      },
    };
    onAddActionItemMember(addPayload);
  };

  const handleWatcherNotificationsChange = () => {
    if (userWatcherRecord?.status === 'open') {
      const payload = {
        variables: {
          clientCode,
          actionItemCode: code,
          emailAddress: currentUser?.emailAddress,
          type: 'watcher',
          member: {
            status: 'mute',
          },
        },
      };
      onUpdateActionItemMember(payload);
      return;
    }
    const payload = {
      variables: {
        clientCode,
        actionItemCode: code,
        emailAddress: currentUser?.emailAddress,
        type: 'watcher',
        member: {
          status: 'open',
        },
      },
    };
    onUpdateActionItemMember(payload);
  };

  const statusColorMap = {
    completed: theme.palette.success.main,
    onHold: theme.palette.action.disabled,
    open: theme.palette.warning.main,
    parkingLot: theme.palette.grey[800],
  };

  const renderActions = useMemo(() => {
    const actions = [];
    actions.push({
      muiIcon: <EditIcon />,
      onClick: () => setActionItemOpen({ open: true, data }),
      tooltip: { title: t('tooltips.edit'), placement: 'top-start' },
    });
    if (!isUserAssignee) {
      actions.push({
        muiIcon: userWatcherRecord ? <VisibilityOffIcon /> : <VisibilityIcon />,
        onClick: () => handleWatcherChange(),
        tooltip: { title: isUserWatcher ? t('tooltips.isWatcher') : t('tooltips.watcher'), placement: 'top-start' },
      });
    }
    if (((assignees?.length > 1 && assignees.some((assignee) => assignee.status !== 'completed'))
      || (assignees?.length !== 0 && !isUserAssignee)) && data?.status !== 'parkingLot') {
      actions.push({
        muiIcon: <DoneAllIcon />,
        onClick: () => setCompleteAllOpen({ open: true, assignees, watchers, actionItem: data }),
        tooltip: { title: t('tooltips.completeAll'), placement: 'top-start' },
      });
    }
    if (isUserAssignee && data.status !== 'parkingLot') {
      actions.push({
        muiIcon: userAssigneeRecord?.status === 'completed' ? <CloseIcon /> : <CheckIcon />,
        onClick: () => setCompleteOneOpen({ open: true, userAssigneeRecord, actionItem: data }),
        tooltip: { title: userAssigneeRecord?.status === 'completed' ? t('tooltips.reopen') : t('tooltips.completeSelf'), placement: 'top-start' },
      });
    }
    if (setDeleteModalOpen) {
      actions.push({
        muiIcon: <DeleteIcon />,
        onClick: () => setDeleteModalOpen({ open: true, actionItem: data }),
        tooltip: { title: t('tooltips.delete'), placement: 'top-start' },
      });
    }
    return (
      <ActionCell
        small
        buttons={actions}
      />
    );
  }, [data, isUserAssignee, isUserWatcher, assignees, userAssigneeRecord, userWatcherRecord]);
  return (
    <Card
      sx={{
        width: '100%',
        '&:not(last-of-type)': {
          marginBottom: '8px',
        },
        padding: '8px 8px 8px 8px',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: '.2',
          backgroundColor: categoryColor,
          pointerEvents: 'none',
        },
      }}
    >
      <CardActions
        sx={{
          padding: 0,
        }}
        onClick={() => onExpand()}
      >
        <Grid
          container
          alignItems="center"
          spacing={1}
          flexWrap="nowrap"
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              width={30}
              height={30}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {typeIconMap[type]}
            </Box>
            {isUserWatcher && !isUserAssignee ? (
              <Tooltip
                title={userWatcherRecord?.status === 'open' ? t('tooltips.muteNotifications') : t('tooltips.enableNotifications')}
                placement="top-start"
                disableInteractive
              >
                <Box
                  width={30}
                  height={30}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: userWatcherRecord?.status === 'mute' ? theme.palette.action.disabled : theme.palette.success.main,
                  }}
                  onClick={(e) => { e.stopPropagation(); handleWatcherNotificationsChange(); }}
                >
                  {userWatcherRecord?.status === 'mute' ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
                </Box>
              </Tooltip>
            ) : null}
            <Box
              pl={0.5}
            >
              <Typography>
                {name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardActions>
      <CardActions
        sx={{
          padding: 0,
        }}
        onClick={() => onExpand()}
      >
        <Grid
          container
          alignItems="center"
          spacing={1}
          flexWrap={smDown ? 'wrap' : 'nowrap'}
          justifyContent="space-between"
        >
          <Grid
            item
            xs
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <StatusCircle color={statusColorMap[status]} />
            <Box
              pl={0.5}
            >
              <Typography>
                {t(`status.${status}`)}
              </Typography>
            </Box>
            {isUserAssignee ? (
              <Tooltip
                title={t('tooltips.assigned')}
                placement="top-start"
                disableInteractive
              >
                <Box
                  width={30}
                  height={30}
                  pl={0.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <AccountCircleIcon />
                </Box>
              </Tooltip>
            ) : null}
          </Grid>
          {endDate ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={150}
            >
              <Box>
                <Typography>
                  {endDate && talt('actionItems.due', { date: MUIGetDateInCurrentTimestamp(endDate).format('L') })}
                </Typography>
              </Box>
            </Grid>
          ) : null}
          <Grid
            item
            xs
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {renderActions}
            </Box>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeoout="auto"
      >
        <CardContent
          sx={{
            padding: '8px 0 0 0',
            paddingBottom: '0px !important',
          }}
        >
          <ActionItemMemberAutocomplete
            label={t('labels.assignees')}
            placeholder={t('labels.assignees')}
            type="assignee"
            actionItemCode={code}
            members={assignees}
            currentUser={currentUser}
            isUserSpecific={isUserSpecific}
          />
          <ActionItemDescription
            description={description}
            actionItemCode={code}
            clientCode={clientCode}
            isUserSpecific={isUserSpecific}
          />
          {showEntityLink && linkedEntity ? (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
              >
                <Typography>{`${t('goTo')} ${t(`entityTypes.${linkedEntity?.type}`)}:`}</Typography>
              </Grid>
              <Grid
                item
                zeroMinWidth
                xs={12}
              >
                <Button
                  href={linkedEntity?.url}
                  variant="contained"
                  sx={{
                    maxWidth: '100%',
                    padding: '6px 8px',
                  }}
                >
                  <Typography variant="body2" noWrap>{linkedEntity?.name || linkedEntity?.url}</Typography>
                </Button>
              </Grid>
            </Grid>
          ) : isUserSpecific && application ? (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
              >
                <Typography>{`${t('goTo')} ${t('entityTypes.application')}:`}</Typography>
              </Grid>
              <Grid
                item
                zeroMinWidth
                xs={12}
              >
                <Button
                  component={Link}
                  to={`/${client?.code}/ua/campaign-reports/${application?.code}`}
                  variant="contained"
                  sx={{
                    maxWidth: '100%',
                    padding: '6px 8px',
                  }}
                >
                  <Typography variant="body2" noWrap>{application?.name}</Typography>
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SingleActionItemCard;
