/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import {
  Box,
} from '@mui/material';

const licenseKey = '0566339261-fcs-1707845421';

function Calendar({
  eventSources = [],
  onEventClick = () => {},
  eventTextColor = '#fff',
  initialView = 'dayGridMonth',
  showNonCurrentDates = false,
  fixedWeekCount = false,
  headerToolbar,
  parentContainerSx = {},
  calendarHeight = '100%',
}) {
  return (
    <Box
      sx={{
        '--fc-list-event-hover-bg-color': 'transparent',
        '--fc-page-bg-color': 'transparent',
        '--fc-button-bg-color': '#0c0c26',
        '--fc-button-border-color': '#0c0c26',
        '--fc-button-hover-bg-color': 'rgba(151, 67, 235, 0.5)',
        '--fc-button-hover-border-color': 'rgba(151, 67, 235, 0.5)',
        '--fc-button-active-bg-color': '#9743eb',
        '--fc-button-active-border-color': '#9743eb',
        '--fc-today-bg-color': 'rgba(151, 67, 235, 0.5)',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        a: {
          color: '#fff',
        },
        '& .fc-h-event': {
          '&:hover': {
            cursor: 'pointer',
          },
        },
        '& .fc-h-event .fc-event-title': {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .fc .fc-daygrid-event-harness-abs': {
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: '1',
            backgroundColor: '#23233a',
            pointerEvents: 'none',
          },
        },
        // Left and Right Caret Buttons -- If Mike wants them Purple
        // '& .fc-prev-button': {
        //   backgroundColor: '#9743eb !important',
        //   '&:hover': {
        //     backgroundColor: 'rgba(151, 67, 235, 0.5) !important',
        //   },
        // },
        // '& .fc-next-button': {
        //   backgroundColor: '#9743eb !important',
        //   '&:hover': {
        //     backgroundColor: 'rgba(151, 67, 235, 0.5) !important',
        //   },
        // },
        ...parentContainerSx,
      }}
    >
      <FullCalendar
        schedulerLicenseKey={licenseKey}
        plugins={[dayGridPlugin, listPlugin]}
        initialView={initialView}
        height={calendarHeight}
        eventTextColor={eventTextColor}
        headerToolbar={headerToolbar}
        showNonCurrentDates={showNonCurrentDates}
        fixedWeekCount={fixedWeekCount}
        eventSources={eventSources}
        eventClick={onEventClick ? (eventClickInfo) => onEventClick(eventClickInfo) : undefined}
      />
    </Box>
  );
}

export default Calendar;
