import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Flex } from '@rebass/grid';
import { getDateInCurrentTimestamp } from '../../utils/dates';

export const Indicator = ({ color, subType, value, emptyText, preceedingText }) => {
  const content = () => {
    if (subType === 'date' && value) {
      const noTimeZone = getDateInCurrentTimestamp(value);
      return `${preceedingText} ${moment(noTimeZone).format('L')}`;
    }
    if (subType === 'text') {
      return preceedingText;
    }
    return value;
  };
  return (
    <InnerWrapper>
      <Circle color={color} />
      <ContentWrapper>
        {value ? content() : emptyText}
      </ContentWrapper>
    </InnerWrapper>
  );
};

const InnerWrapper = styled(Flex)`
  height: 38px;
  align-items: center;
  width: 100%;
`;

const Circle = styled.div`
  width: 19px;
  height: 19px;
  background: ${(props) => props.color};
  border-radius: 50%;
  position: relative;
  ${(props) => props.status !== 'inactive' && `
    box-shadow:
      0 0 20px ${props.color},
      0 0 22px ${props.color};
  `}
`;

const ContentWrapper = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
