import React, { useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Stack,
  Button,
  useTheme,
} from '@mui/material';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormSectionWFields from './FormSectionWFields';

export default function EditDialog({
  open,
  onClose,
  title,
  data,
  fields,
  onSubmit,
  buttons,
  maxWidth = 'xl',
  FormHeader,
}) {
  const { t: tg } = useTranslation('general');
  const theme = useTheme();
  const initialValues = useMemo(() => ({
    ...data,
  }), [data]);

  const renderButton = (button, index, submitting, pristine) => {
    if (button?.type === 'submit') {
      return (
        <Button
          variant="contained"
          sx={{ minWidth: '130px' }}
          type="submit"
          disabled={submitting || pristine}
          key={index}
        >
          {tg('save')}
        </Button>
      );
    }
    if (button?.type === 'cancel') {
      return (
        <Button
          variant="contained"
          sx={{ minWidth: '130px' }}
          onClick={() => onClose()}
          key={index}
        >
          {tg('cancel')}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        sx={{ minWidth: '130px' }}
        onClick={button.onClick}
        disabled={button.disabled}
        key={index}
      >
        {button.name}
      </Button>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Card
              sx={{ border: '1px solid' }}
            >
              <DialogTitle backgroundColor={theme.palette.secondary.main}>{title}</DialogTitle>
              <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
                {FormHeader ? <FormHeader /> : null}
                <FormSectionWFields
                  fields={fields}
                />
                <Stack
                  sx={{ width: '100%' }}
                  spacing={2}
                  pt={2}
                  pr="16px"
                  direction="row"
                  justifyContent="flex-end"
                >
                  {buttons && buttons.map((button, index) => renderButton(button, index, submitting, pristine))}
                </Stack>
              </DialogContent>
            </Card>
          </form>
        )}
      </Form>
    </Dialog>
  );
}
