/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React from 'react';
import { Form } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import Button from '../forms/Button';
import Icon, { Icons } from '../Icon';
import DividerHorizontal from '../DividerHorizontal';
import WizardSteps from './WizardSteps';
import themes from '../themes';
// import AppCreationLoader from 'components/AppCreationLoader';

// TODO: Original Wizard from ASO -- need to run through and fix minor things.
// Not being used, but useful to have here for later use possibly.

function Wizard({ initValues, step, maxStep, steps, onStepChange, onSubmit,
  // isLoading
}) {
  const getActivePage = () => steps[step].component;

  const isLastPage = () => step === steps.length - 1;

  const next = () => {
    onStepChange(step + 1);
  };

  const validate = (values) => {
    const activePage = getActivePage();

    if (!activePage) {
      return null;
    }

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = () => {
    if (isLastPage()) {
      return onSubmit();
    } else {
      next();
    }
  };

  const mutateValue = ([name], state, { changeValue }) => {
    // change the value to the same value, thus
    // triggering a revalidation of the same value
    changeValue(state, name, (value) => value);
  };

  const renderStepContent = (form, submitting, valid) => {
    // if (isLoading) {
    //   return <AppCreationLoader />;
    // }

    const activePageContent = getActivePage();

    const handlePreviousClick = () => {
      if (maxStep > step && !valid) {
        return form.submit();
      }

      onStepChange(step - 1);
    };

    const formControlsButtons = (
      <Flex
        justifyContent="flex-end"
        mt={60}
      >
        <Box mr={20}>
          {step > 0 && (
            <ButtonPrevious
              outline
              onClick={handlePreviousClick}
              disabled={submitting}
            >
              <Icon
                verticalCenter
                icon={Icons.faArrowLeft}
                size="1x"
              />
              <span>Prev Step</span>
            </ButtonPrevious>
          )}
        </Box>

        <Box>
          <Button
            disabled={submitting}
            type="submit"
          >
            <span>{isLastPage ? 'Submit' : 'Next Step'}</span>
            {!isLastPage && (
              <Icon
                verticalCenter
                icon={Icons.faArrowRight}
                size="1x"
              />
            )}
          </Button>
        </Box>
      </Flex>
    );

    return (
      <>
        {activePageContent}
        {formControlsButtons}
      </>
    );
  };

  const blockSubmitOnEnter = (event) => {
    const targetTagName = event.target?.tagName;

    if (event.which === 13 && targetTagName !== 'TEXTAREA') {
      event.preventDefault();
    }
  };

  return (
    <Form
      initialValues={initValues}
      mutators={{ mutateValue }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, submitting, valid, form }) => {
        const mutateBeforeSubmit = (event) => {
          // supply the name of the relevant form field
          form.mutators.mutateValue('revalidate');
          // submit handler gets called if revalidation still passes
          handleSubmit(event);
        };
        return (
          <div>
            <WizardSteps
              currentStep={step}
              maxStep={maxStep}
              steps={steps}
              onChange={(newStep) => {
                if (maxStep < newStep) {
                  return;
                }

                if (maxStep > step && !valid) {
                  return form.submit();
                }

                onStepChange(newStep);
              }}
            />

            <DividerHorizontal />

            <form
              style={{ padding: '30px' }}
              onSubmit={mutateBeforeSubmit}
              autoComplete="off"
              onKeyPress={blockSubmitOnEnter}
            >
              {renderStepContent(form, submitting, valid)}
            </form>
          </div>
        );
      }}
    </Form>
  );
}

const ButtonPrevious = styled(Button)`
  border-color: ${themes.colors.purple};

  &:hover {
    background-color: rgba(171, 104, 239, 0.2);
    border-color: #ab68ef;
  }
`;

export default Wizard;
