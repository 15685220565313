import { Grid } from '@mui/material';
import React from 'react';
import WidgetWrapper from './WidgetWrapper';
import WidgetRenderer from './WidgetRenderer';

export default function WidgetGrid({ widgets, appReportingCurrency, paybackWindow }) {
  return (
    <Grid container spacing={2}>
      {widgets.map((widget, index) => (
        <WidgetWrapper widget={widget} key={index}>
          <WidgetRenderer
            widget={widget}
            appReportingCurrency={appReportingCurrency}
            paybackWindow={paybackWindow}
          />
        </WidgetWrapper>
      ))}
    </Grid>
  );
}
