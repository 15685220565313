import React from 'react';
import styled from 'styled-components';
import themes from './themes';

function LifeCycle({ steps, currentStatus }) {
  const stepsCount = steps.length;
  const stepsPercentUsed = stepsCount * 2;
  const dividerPercentAvailable = 100 - stepsPercentUsed;
  const dividerCount = stepsCount - 1;
  const dividerWidthPercent = dividerPercentAvailable / dividerCount;
  const renderStep = (step, index, steps) => {
    const renderClassName = () => {
      if (step.statusArray.includes(currentStatus)) {
        return 'activeLifecycle';
      }
      return '';
    };
    if (index + 1 === steps.length) {
      return (
        <ItemWrapper key={step.name}>
          <Circle className={renderClassName()} />
          <NameWrapper>
            <Name>{step.name}</Name>
          </NameWrapper>
        </ItemWrapper>
      );
    }
    return (
      <React.Fragment key={step.name}>
        <ItemWrapper>
          <Circle className={renderClassName()} />
          <NameWrapper>
            <Name>{step.name}</Name>
          </NameWrapper>
        </ItemWrapper>
        <DividerWrapper dividerWidthPercent={dividerWidthPercent}>
          <Divider />
        </DividerWrapper>
      </React.Fragment>
    );
  };
  return (
    <OutterWrapper>
      <Wrapper>
        {steps && steps.map(renderStep)}
      </Wrapper>
    </OutterWrapper>
  );
}

export default LifeCycle;

const OutterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .activeLifecycle {
    background: ${themes.colors.seaweed};
    box-shadow:
      0 0 20px ${themes.colors.seaweed},
      0 0 22px ${themes.colors.seaweed},
      0 0 24px ${themes.colors.seaweed},
      0 0 30px ${themes.colors.seaweed};
  }
`;

const Wrapper = styled.div`
  width: 90%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 16px 16px 16px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 2%;
  position: relative;
`;

const NameWrapper = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
`;

const Name = styled.span`
  height: 30px;
  color: ${themes.colors.white};
  white-space: nowrap;
`;

const Circle = styled.div`
  width: 15px;
  height: 15px;
  background: rgba(151, 67, 235, 0.5);
  border-radius: 50%;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(87, 159, 245, 0.5);
`;

const DividerWrapper = styled.div`
  width: ${(props) => props.dividerWidthPercent}%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
`;
