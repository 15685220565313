import React, { useMemo } from 'react';
import {
  Autocomplete,
  useTheme,
  TextField,
  Card,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useActionItemColors } from '../utils/hooks';

const ColorAutocomplete = ({
  onChange,
  meta,
  error,
  label,
  value,
  ...rest
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'colors' });
  const theme = useTheme();
  const options = useActionItemColors();

  const fullValue = useMemo(() => {
    if (!value) return null;
    const findValueInOptions = options.find((option) => option.color === value.color);
    if (findValueInOptions) return findValueInOptions;
    return value;
  }, [value]);

  return (
    <Grid
      container
    >
      <Grid
        item
        xs={9}
      >
        <Autocomplete
          options={options}
          value={fullValue}
          getOptionLabel={(option) => (option ? option?.name : '')}
          onChange={(_event, newValue) => onChange(newValue)}
          renderOption={(props, option) => (
            <li {...props}>
              <Card
                sx={{
                  width: '100%',
                  position: 'relative',
                  '::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: '.2',
                    backgroundColor: option.color,
                    pointerEvents: 'none',
                  },
                }}
              >
                {option.name}
              </Card>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              label={label}
              helperText={error}
              variant="filled"
            />
          )}
          sx={{
            '& .MuiInputLabel-root': {
              color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
            },
          }}
          fullWidth
          {...rest}
        />
      </Grid>
      <Grid
        item
        xs={3}
        pl={2}
      >
        <Card
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: '.2',
              backgroundColor: fullValue?.color,
              pointerEvents: 'none',
            },
          }}
        >
          <Typography variant="body2" noWrap>
            {fullValue && fullValue?.color ? (
              fullValue?.name || fullValue?.color
            ) : t('preview')}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ColorAutocomplete;
