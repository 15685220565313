import React, { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MUIGetDateInCurrentTimestamp } from '../utils/dates';

export default function DatePicker({
  label,
  onChange,
  value = null,
  error,
  helperText,
  minDate = moment('01/01/1900'),
  views,
  // meta = {},
  shouldDisableDate,
  isClearable,
  ...rest
}) {
  const { t: tgErr } = useTranslation('general', { keyPrefix: 'errors' });
  const [errorMessage, setErrorMessage] = useState(null);

  const onDateChange = (date, errors) => {
    if (date === null) return onChange(null);
    if (errors.validationError === null) {
      const formattedDate = moment.utc(date.toISOString(true).split('T')[0]);
      return onChange(formattedDate);
    }
    return errors;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        views={views}
        slotProps={{
          textField: {
            fullWidth: true,
            helperText: errorMessage || undefined,
            clearable: !!isClearable,
          },
        }}
        fullWidth
        onChange={onDateChange}
        value={MUIGetDateInCurrentTimestamp(value)}
        minDate={minDate}
        onError={(err) => (err
          ? setErrorMessage(tgErr(err, { minDate: moment(minDate).format('L') }))
          : setErrorMessage(null))}
        shouldDisableDate={(date) => (shouldDisableDate ? shouldDisableDate(date) : false)}
        {...rest}
      />
    </LocalizationProvider>
  );
}
