import React from 'react';
import {
  styled,
  IconButton,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export const ExpandMore = styled((props) => {
  const { expand, active, ...other } = props;
  return <IconButton {...other} color={active ? 'primary' : 'default'} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const OpenSettings = styled((props) => {
  const { settingOpen, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, settingOpen }) => ({
  transform: !settingOpen ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));
