import React from 'react';
import styled from 'styled-components';
import Icon, { Icons } from 'components/Icon';
import themes from './themes';
import Tooltip from './Tooltip';

function Section({
  title,
  description,
  children,
  unified = false,
  transparent = false,
  transparentHeader = false,
  error = false,
  style,
  titleTooltip = '',
  ...props
}) {
  const renderToolip = () => (
    <>
      <Icon
        icon={Icons.faQuestionCircle}
        color={themes.colors.white}
        data-for={`section-${title}`}
        data-tip={titleTooltip}
      />
      <Tooltip
        id={`section-${title}`}
        place="left"
      />
    </>
  );
  return (
    <Container
      error={!transparentHeader && error}
      style={style}
      {...props}
    >
      {!!title && (
      <Header
        transparent={transparentHeader || transparent}
        unified={unified}
      >
        {title}
        {titleTooltip && renderToolip()}
        {!!description && <Description>{description}</Description>}
      </Header>
      )}
      <Content
        transparent={transparent}
        transparenHeader={transparentHeader}
        error={error}
        unified={unified}
        headerTitle={title}
      >
        {children}
      </Content>
    </Container>
  );
}
// -- STYLED
const Container = styled.div`
  border-radius: 8px;

  ${(props) => props.error
    && `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;
const Description = styled.span`
  float: right;
  font-size: 16px;
  font-weight: 300;
`;
const Header = styled.div`
  font-size: 18px;
  font-weight: bold;

  color: ${themes.colors.white};

  min-height: 66px;
  padding: 20px 0 30px 20px;

  ${(props) => !props.transparent
    && `
    border-bottom: 1px solid ${themes.colors.backgroundDark};
  `}
  border-radius: 8px 8px 0 0;

  background-color: ${(props) => {
    if (props.transparent) {
      return 'transparent';
    }
    if (props.unified) {
      return themes.colors.card;
    }
    return '#1e1e36';
  }};

  svg {
    vertical-align: middle;
    margin-left: 10px;
  }
`;
const Content = styled.div`
  font-size: 14px;
  font-weight: 300;

  padding: ${(props) => (props.transparent ? null : '40px')};

  color: ${themes.colors.white};

  background-color: ${(props) => {
    if (props.transparent) {
      return 'transparent';
    }
    if (props.unified) {
      return themes.colors.card;
    }
    return '#1e1e36';
  }};

  border-radius: ${(props) => (props.transparenHeader || !props.headerTitle ? '8px' : '0 0 8px 8px')};

  ${(props) => props.error
    && props.transparenHeader
    && `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;
export default Section;
