import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import UnStyledIframe from 'react-iframe';

import frameImage from '../assets/images/phone-frame.png';
import themes from './themes';

// TODO: Add in react-iframe new library to Module Vendors/Webpack Etc where needed.

function ApplicationPreview({ url, title, smallerVersion }) {
  return (
    <Wrapper>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Container smallerVersion={smallerVersion}>
          <Iframe
            url={url}
            frameBorder={0}
            smallerVersion={smallerVersion}
            display="block"
          />
          <IframeOverlay smallerVersion={smallerVersion} />
        </Container>
      </a>
      {title ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          mt={20}
        >
          <Box>
            <Title
              href={url}
              target="_blank"
            >
              {title}
            </Title>
          </Box>
        </Flex>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-right: 20px;
`;

const Container = styled.div`
  width: ${(props) => (props.smallerVersion ? '270px' : '360px')};
  height: ${(props) => (props.smallerVersion ? '555px' : '740px')};
  padding: ${(props) => (props.smallerVersion ? '60px 12px 0' : '80px 16px 0')};
  background-image: url(${frameImage});
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;

  position: relative;
`;

const Title = styled.a`
  font-size: 16px;
  text-decoration: underline !important;
  color: ${themes.colors.white};
  cursor: pointer;

  :hover {
    color: inherit;
  }
`;

const Iframe = styled(UnStyledIframe)`
  width: ${(props) => (props.smallerVersion ? '243.75px' : '325px')};
  height: ${(props) => (props.smallerVersion ? '427.5px' : '570px')};
  position: absolute;
  border-radius: 2px;
`;

const IframeOverlay = styled.div`
  position: absolute;
  width: ${(props) => (props.smallerVersion ? '238.5px' : '318px')};
  height: ${(props) => (props.smallerVersion ? '427.5px' : '570px')};
  border-radius: 2px;
`;

export default ApplicationPreview;
