import React from 'react';
import { Grid } from '@mui/material';
import TableElement from './ScoreCardElements/TableElement';
import BigNumberElement from './ScoreCardElements/BigNumberElement';
import PieChartElement from './ScoreCardElements/PieChartElement';
import HorizontalNumberGrid from './ScoreCardElements/HorizontalNumberGrid';
import BarChartElement from './ScoreCardElements/BarChartElement';
import ChartElement from './ScoreCardElements/ChartElement';
import HorizontalDivider from './ScoreCardElements/HorizontalDivider';
import { DateRangeElement } from './ScoreCardElements/DateRangeElement';

export default function ScoreCard({ widget, appReportingCurrency, paybackWindow, modelType }) {
  const renderElements = (element) => {
    switch (element.type) {
      case 'table':
        return (<TableElement element={element} appReportingCurrency={appReportingCurrency} modelType={modelType} />);
      case 'bigNumber':
        return (<BigNumberElement element={element} appReportingCurrency={appReportingCurrency} modelType={modelType} />);
      case 'pieChart':
        return (<PieChartElement element={element} modelType={modelType} />);
      case 'barChart':
        return (<BarChartElement element={element} appReportingCurrency={appReportingCurrency} modelType={modelType} />);
      case 'chart':
        return (<ChartElement element={element} appReportingCurrency={appReportingCurrency} modelType={modelType} />);
      case 'horizontalNumberGrid':
        return (<HorizontalNumberGrid element={element} appReportingCurrency={appReportingCurrency} modelType={modelType} />);
      case 'horizontalDivider':
        return (<HorizontalDivider element={element} />);
      case 'dateRange':
        return (<DateRangeElement element={element} paybackWindow={paybackWindow} />);
      default:
        return (<p>{element.type}</p>);
    }
  };

  return (
    <Grid container>
      {widget.params.elements?.map((element, index) => {
        const sx = { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } };
        if (element.widthXs === 0) sx.display.xs = 'none';
        if (element.widthSm === 0) sx.display.sm = 'none';
        if (element.widthMd === 0) sx.display.md = 'none';
        if (element.widthLg === 0) sx.display.lg = 'none';
        if (element.widthXl === 0) sx.display.xl = 'none';
        return (
          <Grid
            key={`${element.type}-${index}`}
            item
            xs={element.widthXs || undefined}
            sm={element.widthSm || undefined}
            md={element.widthMd || undefined}
            lg={element.widthLg || undefined}
            xl={element.widthXl || undefined}
            sx={sx}
          >
            {renderElements(element)}
          </Grid>
        );
      })}
    </Grid>
  );
}
