import React from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import SelectInput from '../forms/SelectInput/SelectInput';
import UserSelectInput from '../forms/UserSelectInput';
import FormLabel from '../forms/FormLabel';
import { TdComponent } from './components/TdComponent';

/**
 * @param {Object} param0
 * @param {Array} param0.filterDefinitions
 * @param {string} param0.filterDefinitions[].name
 * @param {string} param0.filterDefinitions[].label
 * @param {string} param0.filterDefinitions[].type
 * @param {Array} [param0.filterDefinitions[].options]
 * @param {string} [param0.filterDefinitions[].placeholder]
 * @param {Boolean} [param0.filterDefinitions[].isMulti]
 * @param {Boolean} [param0.filterDefinitions[].includeUppticUser]
 * @returns
 */
const TableFilter = ({ filterDefinitions, clientCode, filter, onChange = () => {}, handleRenderSearch }) => {
  const formFields = filterDefinitions;
  return (
    <FilterTdComponent>
      <FormWrapper>
        <Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="center"
        >
          {handleRenderSearch(formFields.length)}
          {formFields.map((field, index) => (
            <Container
              width={handleRenderSearch ? 1 / (formFields.length + 1) : 1 / formFields.length}
              px={7.5}
              key={index}
            >
              <FormLabel
                name={field.name}
                spacing={false}
                style={{ width: '100%' }}
              >
                {field.label}
              </FormLabel>
              {((field.type === 'user') ? (
                <UserSelectInput
                  key={field.name}
                  name={field.name}
                  includeUppticUser={field.includeUppticUser}
                  clientCode={clientCode}
                  isMulti={field.isMulti}
                  light
                  additionalOptions={field.options}
                  onChange={({ emailAddress }) => onChange({
                    ...filter,
                    [field.name]: (field.isMulti)
                      ? emailAddress.length ? { $in: emailAddress } : undefined
                      : emailAddress,
                  })}
                  value={{ emailAddress: field.isMulti ? filter?.[field.name]?.$in : filter?.[field.name] }}
                  placeholder={field.placeholder}
                />
              ) : (
                <SelectInput
                  name={field.name}
                  options={field.options}
                  labelKey={field.type === 'tags' ? 'name' : 'label'}
                  valueKey={field.type === 'tags' ? 'code' : 'value'}
                  placeholder={field.placeholder}
                  isSearchable
                  isMulti={field.isMulti}
                  light
                  onChange={(val) => onChange({
                    ...filter,
                    [field.name]: (field.isMulti)
                      ? val.length ? { $in: val } : undefined
                      : val,
                  })}
                  value={field.isMulti ? filter?.[field.name]?.$in : filter?.[field.name]}
                />
              ))}
            </Container>
          ))}
        </Flex>
      </FormWrapper>
    </FilterTdComponent>
  );
};

const FormWrapper = styled.form`
  width: 100%;
`;

const FilterTdComponent = styled(TdComponent)`
  padding: 5px 10px 5px 10px;
`;

const Container = styled(Box)`
  min-width: 200px;
`;

export default TableFilter;
