import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../utils/hooks';

export const Search = (params) => {
  const { t } = useTranslation('conceptsGrid');
  const { onSearchTermEntered, searchTerm, label = t('search'), placeholder = t('search'), width, noPadding, onClear, inputSize } = params;
  const [searchValue, setSearchValue] = useState(searchTerm);
  const debouncedValue = useDebounce(searchValue, 200);
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    if (searchTerm === '' && searchValue !== '') {
      setSearchValue('');
    }
  }, [searchTerm]);
  useEffect(() => {
    if (!debouncedValue) return;
    if (searchValue === '') {
      onSearchTermEntered && onSearchTermEntered('');
      return;
    }
    const searchTerm = debouncedValue;
    onSearchTermEntered && onSearchTermEntered(searchTerm);
  }, [searchValue, debouncedValue, onSearchTermEntered]);
  return (
    <Box
      px={noPadding ? 0 : 7.5}
      key="search"
      sx={{
        minWidth: 200,
        width,
      }}
    >
      <TextField
        placeholder={placeholder}
        label={label}
        onChange={handleInputChange}
        value={searchValue}
        variant="filled"
        fullWidth
        size={inputSize}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
            >
              <IconButton
                onClick={() => (onClear ? onClear() : setSearchValue(''))}
                disabled={!searchTerm}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
