import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { Form } from 'react-final-form';
import FormSectionWFields from '../../Forms/FormSectionWFields';

export const CalendarDialog = ({
  data,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('ui', { keyPrefix: 'calendar' });
  const { t: talt } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: tg } = useTranslation('general');
  const onSubmit = async () => {};
  const fields = [
    {
      name: 'name',
      label: t('fields.name'),
      xs: 12,
      isDisabled: true,
    },
    {
      name: 'status',
      label: tg('status.name'),
      type: 'select',
      options: [
        { label: talt('status.open'), value: 'open' },
        { label: talt('status.onHold'), value: 'onHold' },
        { label: talt('status.completed'), value: 'completed' },
        { label: talt('status.parkingLot'), value: 'parkingLot' },
      ],
      xs: 12,
      isDisabled: true,
    },
    {
      name: 'startDate',
      label: t('fields.startDate'),
      type: 'date',
      isDisabled: true,
      xs: 6,
    },
    {
      name: 'endDate',
      label: t('fields.endDate'),
      type: 'date',
      isDisabled: true,
      xs: 6,
    },
    {
      name: 'description',
      type: 'textField',
      label: t('fields.description'),
      xs: 12,
      isDisabled: true,
    },
  ];
  const initialValues = {
    status: 'active',
    ...data,
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Card
              sx={{ border: '1px solid' }}
            >
              <DialogTitle backgroundColor={theme.palette.secondary.main}>{t('event')}</DialogTitle>
              <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
                <FormSectionWFields fields={fields} />
                <Stack
                  sx={{ width: '100%' }}
                  spacing={2}
                  pt={2}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    sx={{ minWidth: '130px' }}
                    onClick={() => onClose()}
                  >
                    {tg('close')}
                  </Button>
                </Stack>
              </DialogContent>
            </Card>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
