import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ClipboardTooltip({ value }) {
  const { t } = useTranslation('integrations');
  const [copied, setCopied] = useState(false);
  return (
    <InputAdornment
      position="end"
    >
      <Tooltip
        title={t('copied')}
        open={copied === true}
        onClose={() => setCopied(false)}
        placement="right"
        arrow
        leaveDelay={500}
      >
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopied(true);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
}
