import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const SingleNotificationCard = ({
  notification,
  onClick,
  onDismissClick,
}) => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { subject, status, content } = notification;
  const createDate = notification?.audit?.createDate;
  const [expanded, setExpanded] = useState(false);
  const [isRead, setIsRead] = useState(false);
  useEffect(() => {
    if (status === 'read') setIsRead(true);
  }, [status]);
  return (
    <Card
      sx={{
        '&:not(last-of-type)': {
          marginBottom: '8px',
        },
        padding: '8px 8px 8px 8px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CardActions
        sx={{
          padding: 0,
        }}
        onClick={() => {
          if (!isRead) onClick();
          setExpanded((prevState) => !prevState);
          if (!isRead) setIsRead(true);
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={1}
          flexWrap="nowrap"
        >
          <Grid
            item
            xs
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              width={30}
              height={30}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircleIcon color={!isRead ? 'primary' : 'disabled'} />
            </Box>
            <Box
              pl={0.5}
              sx={smDown ? { width: '90px' } : {}}
            >
              <Typography>{subject}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            width={70}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box mr={1}>
              <Typography>
                {createDate && moment(createDate).format('L')}
              </Typography>
            </Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDismissClick(isRead);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto">
        <CardContent
          sx={{
            padding: '8px 0 0 0',
            paddingBottom: '0px !important',
          }}
        >
          <Typography>{content}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SingleNotificationCard;
