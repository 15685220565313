import React from 'react';
import styled from 'styled-components';
import { ReportIframe } from './ReportIframe';

function AssetReport(props) {
  const { item } = props;

  const maxWidthCalc = (item.reportHeight * 1.78) - 38;
  const screenHeight = window.screen.height;
  const fullscreenMaxWidth = (screenHeight * 1.78 - 38);

  return (
    <ReportWrapper key={item.name}>
      <Headline>{item.name}</Headline>
      <CreativeReportWrapper reportHeight={item.reportHeight}>
        <SingleReportWrapper
          maxWidth={maxWidthCalc}
          fullscreenMaxWidth={fullscreenMaxWidth}
        >
          <ReportIframe url={item.url} />
        </SingleReportWrapper>
      </CreativeReportWrapper>
    </ReportWrapper>
  );
}

const ReportWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SingleReportWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => (props.maxWidth)}px;
  @media (display-mode: fullscreen) {
    max-width: ${(props) => (props.fullscreenMaxWidth)}px;
  }
`;

const CreativeReportWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.reportHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Headline = styled.h4`
  margin-bottom: 10px;
`;
export default AssetReport;
