import { Button, Grid, IconButton, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const FilterGroup = ({
  availableFields,
  availableCombinators,
  combinator = 'and',
  filters = [],
  level,
  maxLevels,
  onFilterAdd,
  onGroupAdd,
  onNodeChange,
  onNodeRemove,
  onGroupRemove,
  onCombinatorChange,
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'queryBuilder' });
  const onFilterInFilterGroupAdd = (index) => {
    const firstField = availableFields[0];
    const filterGroup = filters[index];
    filterGroup.filters.push({ type: 'field', field: firstField, operator: firstField?.operators?.[0], value: null });
    onNodeChange(filterGroup, index);
  };

  const onFilterInFilterGroupChange = (groupIndex, newFilter, index) => {
    filters[groupIndex].filters[index] = { ...filters[groupIndex].filters[index], ...newFilter };
    onNodeChange(filters[groupIndex], groupIndex);
  };

  const onCombinatorGroupChange = (groupIndex, combinator) => {
    filters[groupIndex].combinator = combinator;
    onNodeChange(filters[groupIndex], groupIndex);
  };

  const onGroupInFilterGroupAdd = (index) => {
    const firstField = availableFields[0];
    const filterGroup = filters[index];
    filterGroup.filters.push({
      type: 'group',
      combinator: 'and',
      filters: [{ type: 'field', field: firstField, operator: firstField?.operators?.[0], value: null }],
      isValid: false,
    });
    onNodeChange(filterGroup, index);
  };

  const onNodeInFilterGroupRemove = (groupIndex, nodeIndex) => {
    const filterGroup = filters[groupIndex];
    filterGroup.filters.splice(nodeIndex, 1);
    onNodeChange(filterGroup, groupIndex);
  };

  return level <= maxLevels ? (
    <Grid
      container
      sx={{
        borderLeft: (theme) => (level > 0 ? `2px solid ${theme.palette.divider}` : 'none'),
        paddingLeft: (theme) => theme.spacing(1.5),
        marginBottom: (theme) => theme.spacing(0.5),
        marginTop: (theme) => (level > 0 ? theme.spacing(0.5) : 'none'),
      }}
      direction="column"
      spacing={1}
    >
      <Grid item>
        <Grid
          container
          spacing={2}
        >
          {level !== 0 ? (
            <Grid item>
              <IconButton
                sx={{
                  marginRight: (theme) => theme.spacing(-1),
                  marginTop: (theme) => theme.spacing(0.75),
                }}
                disabled={level === 0}
                size="small"
                onClick={onGroupRemove}
              >
                <RemoveIcon sx={{ fill: 'rgba(255, 0, 0, 0.9)' }} />
              </IconButton>
            </Grid>
          ) : null}

          <Grid item>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={combinator}
              onChange={(event, value) => {
                if (value && onCombinatorChange) onCombinatorChange(value);
              }}
            >
              {availableCombinators.map((combinator) => (
                <ToggleButton
                  key={combinator}
                  sx={{
                    height: 36,
                    padding: (theme) => theme.spacing(0, 1.5),
                  }}
                  value={combinator}
                >
                  <Typography variant="body2">{t(`combinators.${combinator}`)}</Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Button
              sx={{
                '& svg': {
                  marginRight: (theme) => theme.spacing(0.5),
                  marginTop: (theme) => theme.spacing(0.25),
                },
                textTransform: 'none',
              }}
              color="primary"
              onClick={onFilterAdd}
            >
              <AddIcon />
              {t('rule')}
            </Button>
          </Grid>
          {level < maxLevels && (
            <Grid item>
              <Button
                sx={{
                  '& svg': {
                    marginRight: (theme) => theme.spacing(0.5),
                    marginTop: (theme) => theme.spacing(0.25),
                  },
                  textTransform: 'none',
                }}
                color="primary"
                onClick={onGroupAdd}
              >
                <AddIcon />
                {t('group')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {filters?.length > 0 && (
        <Grid item>
          {filters.map((filter, index) => {
            if (filter.type === 'group') {
              return (
                <FilterGroup
                  level={level + 1}
                  key={index}
                  maxLevels={maxLevels}
                  availableFields={availableFields}
                  availableCombinators={availableCombinators}
                  filters={filter?.filters || []}
                  combinator={filter.combinator}
                  onFilterAdd={() => onFilterInFilterGroupAdd(index)}
                  onGroupAdd={() => onGroupInFilterGroupAdd(index)}
                  onNodeChange={(newFilter, nodeIndex) => onFilterInFilterGroupChange(index, newFilter, nodeIndex)}
                  onNodeRemove={(nodeIndex) => onNodeInFilterGroupRemove(index, nodeIndex)}
                  onGroupRemove={() => onNodeRemove(index)}
                  onCombinatorChange={(combinator) => onCombinatorGroupChange(index, combinator)}
                />
              );
            }
            return (
              <Filter
                key={index}
                availableFields={availableFields}
                filter={filter}
                onChange={(value) => onNodeChange(value, index)}
                onRemove={() => onNodeRemove(index)}
              />
            );
          })}
        </Grid>
      )}
    </Grid>
  ) : (
    <span />
  );
};

export default FilterGroup;
