/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ActionItemPanel from '../../ActionItems/ActionItemPanel';

const ActionItemElement = ({ widget }) => {
  const platformStore = useContext(PlatformContext);
  const { t } = useTranslation('miniHub');
  const { type } = widget;
  return (
    <ActionItemPanel
      title={type === 'myActionItem' ? t('myAssignedActionItems') : t('allClientActionItems')}
      isCollapse={widget?.params?.collapsable}
      showEntityLink
      contentSx={{
        minHeight: 64,
        height: 'auto',
        maxHeight: 436,
      }}
      externalFilter={type === 'myActionItem' ? [
        {
          'members.user.type': {
            $eq: 'assignee',
          },
        },
        {
          'members.user.emailAddress': {
            $eq: platformStore.currentUser?.emailAddress,
          },
        },
        {
          'application.code': {
            $eq: platformStore.selectedApplication?.code,
          },
        },
      ] : [
        {
          $or: [
            {
              $and: [
                {
                  'members.user.type': {
                    $eq: 'watcher',
                  },
                },
                {
                  'members.user.emailAddress': {
                    $eq: platformStore.currentUser?.emailAddress,
                  },
                },
                {
                  'members.user.notAssignedAsAssignee': {
                    $eq: platformStore.currentUser?.emailAddress,
                  },
                },
              ],
            },
            {
              'members.user.notAssigned': {
                $eq: platformStore.currentUser?.emailAddress,
              },
            },
          ],
        },
        {
          'application.code': {
            $eq: platformStore.selectedApplication?.code,
          },
        },
      ]}
    />
  );
};

export default observer(ActionItemElement);
