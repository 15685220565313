import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { FormHeadline } from './forms/FormHeader';
import Button from './forms/Button';
import Icon, { Icons } from './Icon';

const modalElement = document.getElementById('modal-root');

export function Modal({ children, fade = false, title = '', open = false, onClose, buttons, withClose }) {
  const close = useCallback(() => {
    onClose && onClose();
  }, []);

  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      onClose && onClose();
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscape, false);
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
      document.documentElement.style.overflow = 'initial';
      document.body.scroll = 'yes';
    };
  }, [handleEscape, open]);

  const renderButton = (button, index) => (
    <ModalButton
      key={index}
      onClick={button.onClick}
      disabled={button.disabled || false}
    >
      {button.name}
    </ModalButton>
  );

  return createPortal(
    open
      ? (
        <ModalWrapper fade={fade}>
          <ModalOverlay
            onClick={close}
          />
          <ModalBody>
            {title ? (
              <TitleWrapper>
                <Title>
                  {title}
                </Title>
                {withClose ? (
                  <IconWrapper onClick={onClose}>
                    <Icon
                      icon={Icons.faTimes}
                      size="1x"
                    />
                  </IconWrapper>
                ) : null}
              </TitleWrapper>
            ) : null}
            {children}
            {buttons ? (
              <ActionsWrapper>
                <ButtonWrapper>
                  {buttons.map(renderButton)}
                </ButtonWrapper>
              </ActionsWrapper>
            ) : null}
          </ModalBody>
        </ModalWrapper>
      ) : null,
    modalElement,
  );
}

const ModalWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.80);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 1304;
  box-sizing: border-box;
  opacity: 1

  
`;

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const ModalBody = styled.div`
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: #13132b;
  border: 1px solid;
  border-radius: 3px;
  color: #c3c0c0;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0px 30px;
`;

const Title = styled(FormHeadline)`
  margin: 0;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 30px 0
`;

const ButtonWrapper = styled.div`
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled(Button)`
  min-width: 100px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Modal;
