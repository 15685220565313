import React, { useRef, useEffect, useState } from 'react';
import {
  Tooltip,
} from '@mui/material';

export default function OverflowTooltip(props) {
  const textElementRef = useRef();
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip
      title={props.children}
      disableHoverListener={!hoverStatus}
      placement={props?.placement || 'top'}
    >
      <div
        key="tooltip-wrapper"
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
}
