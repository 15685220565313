/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import {
  Box,
  Autocomplete,
  Checkbox,
  TextField,
  Chip,
  useTheme,
} from '@mui/material';
import { PlatformContext } from '@upptic/module-directory';
import CheckIcon from '@mui/icons-material/Check';
import { sortBy, uniqBy } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useQuery, useMutation } from '@apollo/client';
import { addActionItemMemberGql, addUserActionItemMemberGql, clientUsersGql, deleteActionItemMemberGql, deleteUserActionItemMemberGql } from '../gql';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ActionItemMemberAutocomplete = ({
  members,
  label,
  placeholder,
  type,
  actionItemCode,
  sx = { marginTop: '8px' },
  currentUser,
  isUserSpecific,
}) => {
  const theme = useTheme();
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const isUppticUser = platformStore?.currentUser?.isUppticUser;
  const [originalMembers, setOriginalMembers] = useState(members);
  const [selectedMembers, setSelectedMembers] = useState(members);

  useEffect(() => {
    if (members) {
      setOriginalMembers(members);
      setSelectedMembers(members);
    }
  }, [members]);

  const generateQueryParam = (clientCode, skip) => ({
    skip: !clientCode || skip,
    onError: () => {},
    context: {
      headers: { 'client-code': clientCode },
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: { clientCode },
  });

  const { data: clientUsers, loading: clientUsersLoading } = useQuery(clientUsersGql, generateQueryParam(clientCode));
  const { data: uppticUsers, loading: uppticUsersLoading } = useQuery(clientUsersGql, generateQueryParam('upptic', !isUppticUser));

  const joinedUsersData = useMemo(() => {
    const options = [];
    if (isUppticUser && !uppticUsers) return options;
    if (!clientUsers) return options;
    const allOptions = [...(uppticUsers?.clientUsers || []), ...clientUsers.clientUsers];
    if (allOptions && allOptions?.length !== 0) {
      for (const user of allOptions) {
        options.push({ user });
      }
    }
    const unique = uniqBy(options, 'user.emailAddress');
    return sortBy(unique, ['user.lastName', 'user.firstName']);
  }, [clientUsers, uppticUsers, isUppticUser]);

  const [addMember] = useMutation(addActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });
  const [deleteMember] = useMutation(deleteActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [addUserMember] = useMutation(addUserActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['currentUserActionItems'],
    awaitRefetchQueries: true,
  });
  const [deleteUserMember] = useMutation(deleteUserActionItemMemberGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['currentUserActionItems'],
    awaitRefetchQueries: true,
  });

  const onSelectChange = async (newValues) => {
    setSelectedMembers(newValues);
    async function handleDelete(member) {
      const payload = {
        variables: {
          actionItemCode,
          clientCode,
          emailAddress: member?.user?.emailAddress,
          type,
        },
      };
      if (isUserSpecific) {
        deleteUserMember(payload);
        return;
      }
      await deleteMember(payload);
    }
    async function handleAdd(member) {
      const payload = {
        variables: {
          actionItemCode,
          clientCode,
          member: {
            status: 'open',
            type,
            user: {
              emailAddress: member?.user?.emailAddress,
            },
          },
        },
      };
      if (isUserSpecific) {
        addUserMember(payload);
        return;
      }
      await addMember(payload);
    }
    async function handleChanges(oldMembers, newMembers) {
      const memberToAdd = [];
      const memberToDelete = [];
      for await (const member of oldMembers) {
        const memberInNewArray = newMembers.some((item) => item.user.emailAddress === member.user.emailAddress);
        if (!memberInNewArray) memberToDelete.push(member);
      }
      for await (const member of newMembers) {
        const memberInOldArray = oldMembers.some((item) => item.user.emailAddress === member.user.emailAddress);
        if (!memberInOldArray) memberToAdd.push(member);
      }
      if (!newMembers || newMembers.length === 0) {
        if (oldMembers && oldMembers.length !== 0) {
          memberToDelete.push(...oldMembers);
        }
      }
      if (memberToAdd.length !== 0) {
        await handleAdd(memberToAdd[0]);
      }
      if (memberToDelete.length !== 0) {
        await handleDelete(memberToDelete[0]);
      }
    }
    await handleChanges(originalMembers, newValues);
  };

  const handleRenderTags = (value, getTagProps) => value.map((item, index) => {
    const isUser = item?.user?.emailAddress === currentUser?.emailAddress;
    const memberStatus = item?.status;
    return (
      <Chip
        {...getTagProps({ index })}
        key={index}
        color={isUser ? 'primary' : 'default'}
        label={`${item?.user?.firstName} ${item?.user?.lastName} | ${item?.user?.emailAddress}`}
        icon={memberStatus === 'completed' ? <CheckIcon /> : undefined}
        sx={{
          '& .MuiChip-icon': {
            color: theme.palette.success.main,
          },
        }}
      />
    );
  });

  const isLoading = clientUsersLoading || uppticUsersLoading;

  return (
    <Box
      sx={sx}
    >
      <Autocomplete
        value={selectedMembers}
        onChange={(event, newValue) => onSelectChange(newValue)}
        multiple
        disableCloseOnSelect
        options={joinedUsersData}
        loading={isLoading}
        isOptionEqualToValue={(option, value) => option?.user?.emailAddress === value?.user?.emailAddress}
        getOptionLabel={(option) => `${option?.user?.firstName} ${option?.user?.lastName} | ${option?.user?.emailAddress}`}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option?.user?.firstName} ${option?.user?.lastName} | ${option?.user?.emailAddress}`}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
          />
        )}
        disableClearable
        renderTags={handleRenderTags}
      />
    </Box>
  );
};

export default ActionItemMemberAutocomplete;
