import React from 'react';
import SelectInput from './SelectInput/SelectInput';
// eslint-disable-next-line import/extensions
import languageCodes from './data/languageCodes.json';

export default function LocaleSelectInput(props) {
  const options = [
    { value: 'en-us', label: 'English (United States)' },
    ...languageCodes.map(({ code, language }) => ({ label: language, value: code })),
  ];
  return (
    <SelectInput
      {...props}
      isSearchable
      options={options}
    />
  );
}
