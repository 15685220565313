import React from 'react';
import styled from 'styled-components';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import themes from './themes';

function Tabs({ id, activeKey, onChange, items }) {
  return (
    <TabContainer
      id={id}
      activeKey={activeKey}
      onSelect={onChange}
    >
      <Navigation variant="pills">
        {items.map((item) => (
          <Nav.Item key={`tab-item-${item.id}`}>
            <NavigationItem eventKey={item.id}>{item.title}</NavigationItem>
          </Nav.Item>
        ))}
      </Navigation>
      <TabContent>
        {items.map((item) => (
          <Content
            key={`tab-pane-${item.id}`}
            eventKey={item.id}
          >
            {item.children}
          </Content>
        ))}
      </TabContent>
    </TabContainer>
  );
}
const Navigation = styled(Nav)`
  border-bottom: 1px solid ${themes.colors.background};
`;
const NavigationItem = styled(Nav.Link)`
  min-width: 160px;
  text-align: center;
  background-color: transparent !important;
  border-radius: 6px 6px 0 0 !important;
  margin-right: 5px;
  color: #fff;
  font-size: 14px;

  &:hover {
    color: #fff;

    :not([aria-selected="true"]) {
      text-decoration: underline;
    }
  }

  &[aria-selected="true"] {
    background-color: ${themes.colors.purple} !important;
  }
`;
const Content = styled(TabPane)`
  margin-top: 20px;
`;
export default Tabs;
