import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import AssetInput from './forms/AssetInput';

// -- COMPONENT
function UploadInput({
  placeholder = 'Choose file',
  onUpload,
  disabled = false,
  style = {},
  accept,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredAndFormatedFiles = acceptedFiles
        .map((file) => ({
          preview: URL.createObjectURL(file),
          file,
        }));
      if (filteredAndFormatedFiles.length) onUpload(filteredAndFormatedFiles);
    },
    [onUpload],
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
    disabled,
  });

  const inputStyle = useMemo(() => ({
    ...style,
    ...(isDragAccept ? { borderColor: '#00e676' } : {}),
    ...(isDragReject ? { borderColor: 'red' } : {}),

  }), [isDragAccept, isDragReject, style]);

  return (
    <AssetInput
      title={placeholder}
      rootProps={getRootProps()}
      disabled={disabled}
      style={inputStyle}
    >
      {!disabled && <input {...getInputProps()} />}
    </AssetInput>
  );
}

export default UploadInput;
