export const isFilterValid = (filter) => {
  if (
    !filter.field
    || !filter.operator
    || (
      (filter.value === undefined || filter.value === null)
      && filter.operator !== '$has'
    )) return false;
  if (filter.field.type === 'array' && filter.value.length === 0) return false;
  if (filter.field.type === 'select' && filter.field.operators[0] === '$in' && filter.value.length === 0) return false;
  if (filter.field.type === 'text' && !filter.value) return false;
  return true;
};

export const isGroupValid = (filters) => {
  let isValid = true;
  for (const filter of filters) {
    if (filter.type === 'field') {
      if (!isFilterValid(filter)) isValid = false;
      filter.field.error = isValid ? undefined : 'Required';
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!isGroupValid(filter.filters)) isValid = false;
  }
  return isValid;
};

const formatFilterObjGroupToArray = (filterGroupFilters, availableFields) => (
  filterGroupFilters.map((filter) => {
    const [key, value] = Object.entries(filter)[0];
    if (['$and', '$or'].includes(key)) {
      return { type: 'group', combinator: key.replace('$', ''), filters: formatFilterObjGroupToArray(value, availableFields) };
    }
    const [operator, filterValue] = Object.entries(value)[0];
    return {
      type: 'field',
      field: availableFields.find((field) => field.key === key),
      operator,
      value: filterValue,
    };
  })
);

export const formatFilterObjToArray = (orgFilterObj, availableFields) => {
  const filterObj = { ...orgFilterObj };
  if (!Object.keys(filterObj).length) return { filters: [], combinator: 'and' };
  if (filterObj.$and) {
    return {
      filters: formatFilterObjGroupToArray(filterObj.$and, availableFields),
      combinator: 'and',
    };
  }
  if (filterObj.$or) {
    return {
      filters: formatFilterObjGroupToArray(filterObj.$or, availableFields),
      combinator: 'or',
    };
  }
  return { filters: [], combinator: 'and' };
};

const formatFilterGroup = (filters) => {
  const filterArray = [];
  for (const filter of filters) {
    if (filter.type === 'field') {
      filterArray.push(filter.field.formatFilter
        ? filter.field.formatFilter(filter.value, filter.operator)
        : { [filter.field.key]: { [filter.operator]: filter.value } });
    }
    if (filter.type === 'group') {
      filterArray.push(
        { [`$${filter.combinator.toLowerCase()}`]: formatFilterGroup(filter.filters) },
      );
    }
  }
  return filterArray;
};

export const formatFilterArrayToObj = (filterArray, combinator) => {
  const filterGroupArray = formatFilterGroup(filterArray);
  if (!filterGroupArray.length) {
    return {};
  }

  return { [`$${combinator.toLowerCase()}`]: filterGroupArray };
};
