/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';
import { Gauge as MUIGauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { formatData } from '../utils/formatters';

export default function Gauge({
  value,
  valueMin,
  valueMax,
  innerRadius = '80%',
  outerRadius = '100%',
  startAngle = -110,
  endAngle = 110,
  fontSize,
  dataType = 'number',
  formatParams = {},
}) {
  const externalMaxValue = valueMax;
  const calculatedMaxValue = useMemo(() => {
    if (value > valueMax) return value;
    return valueMax;
  }, [value, valueMax]);
  return (
    <MUIGauge
      value={value}
      valueMin={valueMin}
      valueMax={calculatedMaxValue}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      sx={{
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: fontSize || '1rem',
          transform: 'translate(0px, 38px)',
        },
      }}
      margin={{ bottom: 25 }}
      text={
        ({ value, valueMax }) => {
          if (value > externalMaxValue) return `${formatData(value, dataType, formatParams)} / ${formatData(externalMaxValue, dataType, formatParams)}`;
          return `${formatData(value, dataType, formatParams)} / ${formatData(valueMax, dataType, formatParams)}`;
        }
      }
    />
  );
}
