/* eslint-disable import/no-cycle */
import * as React from 'react';
import styled from 'styled-components';
import { TableHeader } from './TableHeader';
import { TableContent } from './TableContent';
import { TableFooter } from './TableFooter';
// -- COMPONENT
function TableComponent({
  displayHeader = true,
  highlightFirstCell = false,
  footerProps,
  trElement,
  columnsNumber,
  rows,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  scrollable,
  maxHeight,
  fullHeight,
  layoutFixed,
  loading,
  noStickyHeaderFooter,
  filters,
  renderRowSubComponent,
  childrenSubTableColumns,
}) {
  const isBrowserFirefox = navigator.userAgent.indexOf('Firefox') > -1;
  const content = React.useMemo(() => (
    <TableContent
      columnsNumber={columnsNumber}
      highlightFirstCell={highlightFirstCell}
      prepareRow={prepareRow}
      rows={rows}
      trElement={trElement}
      loading={loading}
      renderRowSubComponent={renderRowSubComponent}
      childrenSubTableColumns={childrenSubTableColumns}
    />
  ), [rows, columnsNumber, highlightFirstCell, loading, trElement]);

  return (
    <StyledTable
      {...getTableProps()}
      scrollable={scrollable}
      layoutFixed={layoutFixed}
      fullHeight={fullHeight}
    >
      {displayHeader && (
        <TableHeader
          headerGroups={headerGroups}
          isBrowserFirefox={isBrowserFirefox}
          noStickyHeaderFooter={noStickyHeaderFooter}
          filters={filters}
        />
      )}
      <Tbody
        {...getTableBodyProps()}
        displayHeader={displayHeader}
        footerProps={footerProps}
        scrollable={scrollable}
        maxHeight={maxHeight}
        fullHeight={fullHeight}
      >
        {content}
      </Tbody>
      {footerProps && <TableFooter {...footerProps} isBrowserFirefox={isBrowserFirefox} />}
    </StyledTable>
  );
}
export const Table = React.memo(TableComponent);

const StyledTable = styled.table`
  width: 100%;
  ${(props) => props.fullHeight
  && `
  height: 100%;
  `}
  border-collapse: collapse;
  border-radius: 8px;
  ${(props) => props.layoutFixed
  && `
  table-layout: fixed;
  `}

  ${(props) => props.scrollable
  && `
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  `}
  
`;
const Tbody = styled.tbody`
  ${(props) => props.scrollable && props.fullHeight
  && `
    height: 100%;
    overflow-y: auto;
    display: block;
  `}
  ${(props) => props.scrollable && !props.fullHeight
  && `
    max-height: ${props.maxHeight};
    overflow-y: auto;
    display: block;
  `}
  ${(props) => !props.displayHeader
    && `
    & > tr:first-of-type {
      td:first-child {
        border-top-left-radius: 10px;
      }

      td:last-child {
        border-top-right-radius: 10px;
      }
    }
  `}

  ${(props) => !props.footerProps
    && `
    & > tr:last-of-type {
      td:first-child {
        border-bottom-left-radius: 10px;
      }

      td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  `}
`;
