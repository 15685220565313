import React from 'react';
import {
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Link as MUILink,
  Stack,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreActionsMenu from './MoreActionsMenu';
import { ExpandMore } from '../../MUIStyledComponents/Generic/generic';

/**
 * @param {object} param
 * @param {string} param.title
 * @param {object[]} param.buttons
 * @param {string} param.buttons[].link
 * @param {string} param.buttons[].name
 * @param {Boolean} param.buttons[].disabled
 * @param {Function} param.buttons[].onClick
 */
const FormHeader = ({ title, buttons, infoTitle, breadcrumbs, subtitle, sx }) => {
  const renderButton = (button, index) => {
    if (button.expand) {
      return (
        <ExpandMore
          expand={button.expanded}
          onClick={() => button.setExpanded()}
          aria-expanded={button.expanded}
          aria-label="show more"
          sx={button.sx}
        >
          <ExpandMoreIcon sx={{ width: '25px', height: '25px' }} />
        </ExpandMore>
      );
    }
    if (button.switch) {
      return (
        <FormGroup
          sx={{
            '& .MuiFormControlLabel-root': {
              margin: 0,
            },
          }}
        >
          <FormControlLabel
            control={<Switch size="small" />}
            label={button.label}
            checked={button.checked}
            onChange={(event) => button.onChange(event.target.checked)}
          />
        </FormGroup>
      );
    }
    if (button.icon) {
      if (button.tooltip) {
        return (
          <Tooltip
            title={button?.tooltip?.title}
            placement={button?.tooltip?.placement || 'top'}
            disableInteractive
          >
            {button.link
              ? (
                <IconButton
                  key={index}
                  component={MUILink}
                  disabled={button.disabled}
                  href={button.link}
                  target={button.target || '_blank'}
                  sx={button?.sx || {}}
                >
                  {button.icon}
                </IconButton>
              )
              : button.to
                ? (
                  <IconButton
                    key={index}
                    component={Link}
                    disabled={button.disabled}
                    to={button.to}
                    sx={button?.sx || {}}
                  >
                    {button.icon}
                  </IconButton>
                )
                : (
                  <IconButton
                    key={index}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    sx={button?.sx || {}}
                  >
                    {button.icon}
                  </IconButton>
                )}
          </Tooltip>
        );
      }
      if (button.link) {
        return (
          <IconButton
            key={index}
            component={MUILink}
            disabled={button.disabled}
            href={button.link}
            target={button.target || '_blank'}
            sx={button?.sx || {}}
          >
            {button.icon}
          </IconButton>
        );
      }
      return (
        <IconButton
          key={index}
          onClick={button.onClick}
          disabled={button.disabled}
          sx={button?.sx || {}}
        >
          {button.icon}
        </IconButton>
      );
    }
    if (button.externalLink) {
      return (
        <Button
          key={index}
          component={MUILink}
          href={button.externalLink}
          variant="contained"
          sx={{ minWidth: '130px' }}
          disabled={button.disabled}
        >
          {button.name}
        </Button>
      );
    }
    if (button.link) {
      return (
        <Button
          key={index}
          component={Link}
          to={button.link}
          variant="contained"
          sx={{ minWidth: '130px' }}
          disabled={button.disabled}
        >
          {button.name}
        </Button>
      );
    }
    if (button.moreInfoMenu) {
      if (!button?.menuItems?.length) return null;
      return (
        <MoreActionsMenu
          key={index}
          menuItems={button.menuItems}
        />
      );
    }
    return (
      <Button
        key={index}
        disabled={button.disabled}
        type={!button.onClick ? 'submit' : undefined}
        onClick={button.onClick}
        variant="contained"
        sx={button?.sx ? {
          minWidth: '130px',
          ...button.sx,
        }
          : {
            minWidth: '130px',
          }}
      >
        {button.name}
      </Button>
    );
  };

  const renderBreadcrumbs = (breadcrumb, index) => (
    <Breadcrumbs
      key={index}
      seperator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <MUILink
        component={Link}
        to={breadcrumb.link}
      >
        {breadcrumb.name}
      </MUILink>
    </Breadcrumbs>
  );
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ minHeight: '37px', ...sx }}
    >
      <Grid
        item
        xs
      >
        <Grid
          container
          direction="column"
        >
          <Grid
            item
          >
            <Typography
              variant="h6"
              noWrap
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
          >
            {breadcrumbs ? breadcrumbs.map(renderBreadcrumbs) : null}
            {subtitle ? (
              <Typography
                variant="body2"
                fontSize="1.1rem"
              >
                {subtitle}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {infoTitle ? (
        <Grid
          item
        >
          <Typography
            variant="body2"
            fontSize="1.4rem"
          >
            {infoTitle}
          </Typography>
        </Grid>
      ) : null}
      <Grid
        item
        xs
      >
        <Grid
          container
          justifyContent="flex-end"
        >
          <Grid
            item
          >
            {buttons ? (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
              >
                {buttons.map(renderButton)}
              </Stack>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
