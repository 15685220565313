import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { useTranslation } from 'react-i18next';

import Step from '../Step';
import Icon, { Icons } from '../Icon';

function WizardStep({
  step,
  title,
  current = false,
  finished = false,
  inProgress = false,
  width,
  onClick,
}) {
  const disabled = !current && !finished && !inProgress;
  const { t: tw } = useTranslation('wizard');

  return (
    <Flex
      flexDirection="column"
      width={width ? '100%' : width}
      onClick={onClick}
    >
      <Box
        mx="auto"
        my={3}
      >
        <Step
          glowing={current}
          bordered={inProgress}
          muted={disabled}
        >
          {finished && !current ? <Tick icon={Icons.faCheck} /> : step}
        </Step>
      </Box>
      <Box mx="auto">
        <Subtitle>
          {tw('step')}
          {step}
        </Subtitle>
        <Title opacity={disabled ? 0.6 : 1}>{title}</Title>
      </Box>
    </Flex>
  );
}

const Tick = styled(Icon)`
  width: 0.6em !important;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  opacity: ${(props) => props.opacity};
  text-align: center;
  user-select: none;
`;

const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  opacity: 0.4;
  margin: auto 2px;
  color: #fff;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
`;

export default WizardStep;
