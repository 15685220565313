import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import {
  DateRangePicker as MUIDateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { MUIGetDateInCurrentTimestamp } from '../utils/dates';

export default function DateRangePicker({
  value,
  onChange,
  error,
  helperText,
  startLabel = 'Start',
  endLabel = 'End',
  minDate = moment('01/01/1900'),
  disableFuture,
  startInputProps,
  endInputProps,
  // meta = {},
  ...rest
}) {
  if (!Array.isArray(value)) value = [null, null];
  const { t } = useTranslation('general');
  const { t: tgErr } = useTranslation('general', { keyPrefix: 'errors' });
  const [startErrorMessage, setStartErrorMessage] = useState(null);
  const [endErrorMessage, setEndErrorMessage] = useState(null);

  const handleError = ([startErr, endErr]) => {
    if (startErr) {
      setStartErrorMessage(tgErr(startErr, { minDate: moment(minDate).format('L') }));
    } else {
      setStartErrorMessage(null);
    }
    if (endErr) {
      setEndErrorMessage(tgErr(endErr, { minDate: moment(minDate).format('L') }));
    } else {
      setEndErrorMessage(null);
    }
  };

  const handleChange = (value, errors) => {
    if (errors.validationError.every((item) => item === null)) {
      onChange(value.map((val) => val && moment.utc(val.toString().split(' GMT')[0])));
    }
  };

  const handleHelperText = useMemo(() => {
    if (startErrorMessage && endErrorMessage) return `${startErrorMessage} - ${endErrorMessage}`;
    if (startErrorMessage) return startErrorMessage;
    if (endErrorMessage) return endErrorMessage;
    return undefined;
  }, [startErrorMessage, endErrorMessage]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ start: startLabel, end: endLabel }}
    >
      <MUIDateRangePicker
        value={value.map((val) => MUIGetDateInCurrentTimestamp(val))}
        onChange={handleChange}
        onError={handleError}
        minDate={minDate}
        disableFuture={disableFuture}
        slotProps={{
          textField: {
            helperText: handleHelperText,
          },
          fieldSeparator: { children: ` ${t('dateRangeTo')} ` },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
