import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import SelectInput from './SelectInput/SelectInput';

const clientUsersGql = gql`
  query clientUsers($clientCode: String!, $filter: Filter){
    clientUsers(clientCode: $clientCode, filter: $filter){
      emailAddress
      firstName
      lastLoginDate
      lastName
      roles {
        name
      }
      status
    }
  }
`;

export const createClientUsersOptionsArray = (clientUsers) => {
  const optionsArray = [];
  for (const user of clientUsers) {
    optionsArray.push({ value: user.emailAddress, label: `${user.firstName} ${user.lastName}` });
  }
  const sortedArray = optionsArray.sort((a, b) => {
    const aName = a.label?.toLowerCase();
    const bName = b.label?.toLowerCase();
    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }
    return 0;
  });
  return sortedArray;
};

/**
 * @param {object} props.includeUppticUser
 * @param {object} props.clientCode
 * @returns
 */
export default function UserSelectInput(props) {
  const [options, setOptions] = useState([]);
  const generateQueryParam = (clientCode, skip) => ({
    skip: !clientCode || skip,
    onError: () => {},
    context: {
      headers: { 'client-code': clientCode },
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      filter: props?.activeOnly
        ? { status: { $eq: 'active' } }
        : {},
    },
  });

  const { data: clientUsers } = useQuery(clientUsersGql, generateQueryParam(props.clientCode));
  const { data: uppticUsers } = useQuery(clientUsersGql, generateQueryParam('upptic', !props.includeUppticUser));

  useEffect(() => {
    if (props.includeUppticUser && !uppticUsers) return;
    if (!clientUsers) return;
    const options = [...(uppticUsers?.clientUsers || []), ...clientUsers.clientUsers, ...(props.additionalOptions || [])];
    if (props.value?.firstName && !options.find(({ emailAddress }) => emailAddress === props.value?.emailAddress)) {
      options.push(props.value);
    }
    setOptions(createClientUsersOptionsArray(options));
  }, [clientUsers, uppticUsers, props.includeUppticUser, props.value]);

  return (
    <SelectInput
      {...props}
      options={options}
      isSearchable
      value={props.value?.emailAddress}
      onChange={(value) => props.onChange({ emailAddress: value })}
    />
  );
}
