import React from 'react';
import {
  Typography,
} from '@mui/material';

export default function Title({
  title,
  ml,
}) {
  return (
    <Typography
      component="div"
      noWrap
      ml={ml}
      variant="h6"
      fontSize="1.125rem"
    >
      {title}
    </Typography>
  );
}
