import gql from 'graphql-tag';

export const currentUserNotificationHistoryGql = gql`
  query currentUserNotificationHistory(
    $clientCode: String!
    $applicationCode: String
    $limit: Int
    $offset: Int
  ) {
    currentUserNotificationHistory(
      clientCode: $clientCode
      applicationCode: $applicationCode
      limit: $limit
      offset: $offset
    ) {
      items {
        code
        event {
          name
          displayName
          group
        }
        affectedEntity {
          type
          code
          name
        }
        subject
        content
        status
        application {
          code
          name
        }
        audit {
          createDate
        }
      }
      totalItemCount
      unreadCount
    }
  }
`;

export const updateCurrentUserHistoryEntryGql = gql`
  mutation updateCurrentUserHistoryEntry(
    $clientCode: String!
    $historyEntryCode: String!
    $status: UserHistoryEntryStaus!
  ) {
    updateCurrentUserHistoryEntry(
      clientCode: $clientCode
      code: $historyEntryCode
      status: $status
    )
  }
`;

export const dismissCurrentUserHistoryEntryGql = gql`
  mutation dismissCurrentUserHistoryEntry(
    $clientCode: String!
    $code: String!
  ) {
    dismissCurrentUserHistoryEntry(
      clientCode: $clientCode
      code: $code
    )
  }
`;

export const dismissAllCurrentUserHistoryEntriesGql = gql`
  mutation dismissAllCurrentUserHistoryEntries(
    $applicationCode: String
    $clientCode: String!
  ) {
    dismissAllCurrentUserHistoryEntries(
      applicationCode: $applicationCode
      clientCode: $clientCode
    )
  }
`;
