import React from 'react';
import styled from 'styled-components';
import { WithContext as ReactTags } from 'react-tag-input';
import theme from '../themes';

function Tags({ tags, handleDelete, handleAddition, suggestions, disabled, placeholder, useMuiTheming }) {
  const KeyCodes = {
    enter: 13,
  };
  const delimiters = [KeyCodes.enter];

  // eslint-disable-next-line prefer-template
  const tagsArray = tags ? tags?.map((tag) => ({ ...tag, id: tag.code, className: 'tag-color-' + tag.color.replace('#', '') })) : [];

  const suggestionsArray = suggestions?.map((tag) => ({ ...tag, id: tag.code }));

  const uniqueColors = tags ? [...new Set(tags?.map(({ color }) => color))] : [];

  return (
    <TagWrapper
      disabled={disabled}
      uniqueColors={uniqueColors}
      useMuiTheming={useMuiTheming}
    >
      <ReactTags
        tags={tagsArray || []}
        suggestions={suggestionsArray || []}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        placeholder={placeholder}
        inputFieldPosition="inline"
        autocomplete
        labelField="name"
        allowDragDrop={false}
        allowDeleteFromEmptyInput={false}
        readOnly={disabled}
        autofocus={false}
      />
    </TagWrapper>
  );
}

const TagWrapper = styled.div`
  ${(props) => props.uniqueColors.map((color) => `.tag-color-${color.replace('#', '')} { background-color: ${color}; }`).join('\n')}

  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    outline: none;
    height: 39px;
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid ${theme.colors.background};
    border-radius: 2px;
    background-color: ${theme.colors.inputs};
    color: ${theme.colors.white};
    width: 150px;
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
    /* margin: 3px; */

    ::placeholder {
      opacity: 0.3;
      font-size: 16px;
      font-weight: 300;
      color: ${theme.colors.white};
    }
  }

  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    background-color: ${(props) => (props?.useMuiTheming ? 'transparent' : theme.colors.card)};
    // width: 100%;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    padding: 0px 15px 10px 0;
    color: ${theme.colors.white};
  }

  .ReactTags__tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${theme.colors.white};
    padding: 2px 3px 3px 6px;
    border-radius: 2px;
    height: 26px;
    margin: 6px;
  }
  
  button {
    border: none;
    background-color: transparent;
    color: ${theme.colors.white};
    padding: 0 4px 0 7px;
  }

  .ReactTags__activeSuggestion {
    background-color: ${theme.colors.purple}
  }

  .ReactTags__suggestions {
    position: absolute;
    width: 150px;
    margin-left: 2px;
    font-size: 16px;
    z-index: 2;
    ul {
      width: 150px;
      background-color: ${theme.colors.inputs};
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    li {
      &:hover {
        background-color: ${theme.colors.purple}
      }
    }
    li span {
      padding: 3px 3px 3px 6px;
    }
    li mark {
      background: transparent;
      color: ${theme.colors.white};
      padding: 0;
    }
  }
`;

export default Tags;
