import React from 'react';
import {
  Grid,
  Typography,
  styled,
  CardActions,
  Card,
  Chip,
  Box,
  Link,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';

import SingleReport from '../../components/Reports/iframe/SingleReport';

const StyledCard = styled(Card)(({ theme, scheme, active }) => ({
  backgroundColor: scheme === 'secondary' ? theme.palette.secondary.main : theme.palette.background.paper,
  ...theme.typography.body2,
  padding: active ? `calc(${theme.spacing(0.3)} - 3px)` : theme.spacing(0.3),
  textAlign: 'center',
  color: theme.palette.text.primary,
  border: active ? `3px solid ${theme.palette.primary.dark}` : 'none',
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(0.3),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  minWidth: '120px',
  color: theme.palette.text.primary,
}));

const ReportFirstClassCard = ({
  selectedReport,
}) => {
  const { t } = useTranslation('campaigns');
  return (
    <StyledCard>
      <StyledCardActions>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="subtitle1"
              >
                {selectedReport?.name}
              </Typography>
            </Box>
            {selectedReport?.documentationUrl ? (
              <Tooltip
                title={t('needHelp')}
                placement="top"
              >
                <Box
                  component={Link}
                  href={selectedReport?.documentationUrl}
                  target="_blank"
                  sx={{
                    marginLeft: '4px',
                    textDecoration: 'none',
                    '&:hover': {
                      cursor: 'pointer',
                      color: '#ac68ee',
                    },
                  }}
                >
                  <InfoIcon />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      </StyledCardActions>
      <Grid
        container
        justifyContent="center"
        width="100%"
      >
        <Grid
          item
          xs={12}
          justifyContent="center"
        >
          {selectedReport ? (
            <SingleReport
              url={selectedReport.url}
              selectedReport={{}}
            />
          ) : (
            <StyledChip
              label={t('noData.report')}
            />
          )}
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default ReportFirstClassCard;
