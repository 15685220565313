import moment from 'moment';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// This function only cares about the Day/Date and Drops the Time
export const MUIGetDateInCurrentTimestamp = (val) => {
  if (!val) return null;
  if (!(val instanceof Date) && !(typeof val === 'string') && !(val instanceof moment)) return null;
  if (val instanceof Date && Number.isNaN(val.getTime())) return val;
  if (typeof val === 'string') {
    return moment.tz(val.split('T')[0], timezone);
  }
  return moment.tz(val.toISOString().split('T')[0], timezone);
};

export const getDateInCurrentTimestamp = (val) => {
  if (!val) return null;
  if (!(val instanceof Date) && !(typeof val === 'string') && !(val instanceof moment)) return null;
  if (val instanceof Date && Number.isNaN(val.getTime())) return val;
  if (typeof val === 'string') {
    return moment.tz(val.split('T')[0], timezone).toDate();
  }
  return moment.tz(val.toISOString().split('T')[0], timezone).toDate();
};

export default {
  getDateInCurrentTimestamp,
  MUIGetDateInCurrentTimestamp,
};
