import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
  GridRowModes,
} from '@mui/x-data-grid-pro';
import { get } from 'lodash';
import ActionCell from '../ActionCell';

export const generateActionColumn = ({
  setRowModesModel,
  rowModesModel,
  setGridRows,
  gridRows,
  setConfirmationOpen,
  rowActions,
  columnArray,
  tg,
  requiredFields,
  editableFields,
  calculateActionColumnWidth,
  onPostRowSave,
}) => {
  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = gridRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setGridRows(gridRows.filter((row) => row.id !== id));
    }
  };

  const handleEditClick = async (id) => {
    const findFieldToFocus = columnArray?.filter((column) => column.editable)?.map((column) => column.field);
    const resetGridRowModes = new Promise((resolve) => {
      const newRowModesModel = {};
      const idsArray = Object.keys(rowModesModel);
      if (idsArray?.length === 0) resolve(newRowModesModel);
      for (const id of idsArray) {
        newRowModesModel[id] = { mode: GridRowModes.View, ignoreModifications: true };
        const editedRow = gridRows.find((row) => row.id === id);
        if (editedRow.isNew) {
          setGridRows(gridRows.filter((row) => row.id !== id));
        }
      }
      resolve(newRowModesModel);
    });
    await resetGridRowModes
      .then((res) => {
        setRowModesModel({
          ...res,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: findFieldToFocus.length !== 0 ? findFieldToFocus[0] : '',
          },
        });
      });
  };

  const handleSaveClick = async (row) => {
    const { id } = row;
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    if (onPostRowSave) onPostRowSave();
  };

  return ({
    headerName: tg('actions'),
    align: 'center',
    headerAlign: 'center',
    field: 'actions',
    width: calculateActionColumnWidth,
    sortable: false,
    filterable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const hasError = () => {
        let error = false;
        if (requiredFields && requiredFields.length !== 0) {
          for (const field of requiredFields) {
            if (params?.otherFieldsProps[field]?.value === '') error = true;
          }
        }
        return error;
      };

      const hasDataChanged = () => {
        let isDataChanged = false;
        if (editableFields && editableFields.length !== 0) {
          for (const field of editableFields) {
            if (params?.otherFieldsProps?.[field]?.value !== undefined
              && params?.row?.[field] !== params?.otherFieldsProps?.[field]?.value) {
              isDataChanged = true;
            }
          }
        }
        return isDataChanged;
      };

      return { ...params.props, error: hasError(), hasDataChanged: hasDataChanged() };
    },
    renderEditCell: (params) => {
      const { id, row, hasDataChanged, error } = params;
      const buttons = [];
      buttons.push(
        {
          muiIcon: <CheckIcon />,
          onClick: () => handleSaveClick(row),
          tooltip: !hasDataChanged ? undefined : { title: tg('save') },
          disabled: error || !hasDataChanged,
        },
        {
          muiIcon: <CancelIcon />,
          tooltip: { title: tg('cancel') },
          onClick: () => handleCancelClick(id),
        },
      );
      return (
        <ActionCell buttons={buttons} />
      );
    },
    renderCell: (props) => {
      const { id, row } = props;
      const buttons = [];
      const createButtonObject = (action) => {
        if (action.type === 'inlineEdit') {
          buttons.push(
            {
              muiIcon: <EditIcon />,
              tooltip: { title: tg('edit') },
              onClick: () => handleEditClick(id),
              disabled: action.disabled,
            },
          );
          return;
        }
        if (action.type === 'delete') {
          buttons.push({
            muiIcon: <DeleteIcon />,
            tooltip: { title: tg('delete') },
            onClick: () => setConfirmationOpen({ open: true, row }),
            disabled: action.disabled,
          });
          return;
        }
        if (action.type === 'clipboard') {
          buttons.push({
            muiIcon: action.IconComponent ? action.IconComponent : false,
            value: action.valueKey ? get(row, action.valueKey, '') : action.valueText(row),
            tooltip: action.tooltip,
            disabled: action.disabled,
          });
          return;
        }
        buttons.push(
          {
            muiIcon: action.IconComponent,
            tooltip: action.tooltip,
            onClick: () => action.onClick(row),
            disabled: action.disabled,
            to: action.to,
          },
        );
      };
      if (rowActions.length !== 0) {
        for (const action of rowActions) {
          if (action?.renderCondition && action.renderCondition(row)) {
            createButtonObject(action);
          }
          if (!action?.renderCondition) {
            createButtonObject(action);
          }
        }
      }
      return (
        <ActionCell buttons={buttons} />
      );
    },
  });
};
