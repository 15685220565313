import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Droppable } from 'react-beautiful-dnd';
import themes from '../themes';
import Icon, { Icons } from '../Icon';
import { OptionRow } from './OptionRow';

export const OptionColumn = ({
  rows,
  column,
  containerSize,
  valid,
  myRef,
  boxSize,
  rowFields,
  updateValue,
  onChecked,
  deleteRow,
  addRow,
  data,
  onRowClick,
  marked,
}) => (
  <Droppable droppableId={column.id}>
    {(provided) => (
      <Wrapper
        height={containerSize}
        flexDirection="column"
        alignItems="center"
        backgroundColor={themes.colors.inputs}
        style={{ overflowY: 'scroll' }}
        py={15}
        pl={10}
        valid={valid}
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {rows.length !== 0 ? (
          rows.map((row, index) => (
            <OptionRow
              key={row.id}
              row={row}
              column={column}
              index={index}
              rows={rows}
              myRef={myRef}
              boxSize={boxSize}
              rowFields={rowFields}
              updateValue={updateValue}
              onChecked={onChecked}
              deleteRow={deleteRow}
              data={data}
              onRowClick={onRowClick}
              marked={marked}
            />
          ))
        ) : null}
        {provided.placeholder}
        <InnerButtonWrapper
          rows={rows}
        >
          <IconWrapper onClick={() => addRow()}>
            <Icon icon={Icons.faPlus} />
          </IconWrapper>
        </InnerButtonWrapper>
      </Wrapper>
    )}
  </Droppable>
);

const Wrapper = styled(Flex)`
  ${(props) => !props.valid
    && `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;

const InnerButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.rows.length !== 0
  && `
    display: none;
  `}
`;

const IconWrapper = styled.span`
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(151, 67, 235, 0.7);
  &:hover {
    cursor: pointer;
    color: rgba(151, 67, 235, 1);
  }
`;
