import React from 'react';
import { Box, Typography } from '@mui/material';

export default function TextPlaceholderCard({ widget }) {
  return (
    <Box
      mb={7}
    >
      <Typography variant="h5" align="center">{widget?.params?.text}</Typography>
    </Box>
  );
}
