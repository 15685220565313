import React, { useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import { writeText } from 'clipboard-polyfill';
import Icon, { Icons } from 'components/Icon';
import Popover from 'components/Popover';
import ExternalLink from 'components/ExternalLink';
import themes from './themes';
import Tooltip from './Tooltip';

function HoverLink({ value, title, clickable = false, copyable = false, tooltipContent }) {
  const [isHovered, setHovered] = useState(false);
  const renderToolip = () => (
    <>
      <Icon
        icon={Icons.faQuestionCircle}
        color={themes.colors.white}
        style={{ marginLeft: '10px' }}
        data-for={`hover-link-${value}`}
        data-tip={tooltipContent}
      />
      <Tooltip
        id={`hover-link-${value}`}
        place="right"
      />
    </>
  );
  return (
    <Flex
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box>
        {title && (
        <span>
          {title}
          {' '}
        </span>
        )}
        {clickable ? <ExternalLink value={value} /> : <span>{value}</span>}
        {tooltipContent && renderToolip()}
      </Box>

      {isHovered && (
      <Box>
        {copyable && (
        <Popover content="Copied">
          <Action onClick={() => {
            writeText(value);
          }}
          >
            <ActionIcon icon={Icons.faCopy} />
            {' '}
            Copy
          </Action>
        </Popover>
        )}
      </Box>
      )}
    </Flex>
  );
}
const Action = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.45px;
  margin-right: 15px;
  cursor: pointer;
  color: #ffffff;

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }
`;
const ActionIcon = styled(Icon)`
  margin-right: 5px;
`;
export default HoverLink;
