import validator from 'validator';
import _, { isEmpty } from 'lodash';
import { getTitleLength } from '../enums';

export const urlOptions = {
  require_valid_protocol: true,
  require_protocol: true,
  protocols: ['http', 'https'],
};
const PASSWORD_LENGTH = 8;
const MIN_PASSWORD_DIFFERENTIAL = 3;
const AGE_LIMIT_REGEXP = /^[0-9]{1,2}\+?$/;

export const isPasswordDifferential = (value) => {
  const chars = value.split('');
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const digits = '0123456789'.split('');
  const special = "!\"#$%&\\'()*+,-./:;<=>?@[\\\\]^_`{|}~".split('');
  const validGroups = [uppercase, lowercase, digits, special].filter((group) => chars.some((ch) => group.includes(ch)));
  return validGroups.length >= MIN_PASSWORD_DIFFERENTIAL;
};

export const required = (value) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'Required';
  }
  return value || value === 0 ? undefined : 'Required';
};

export const requiredDependOnOtherFields = (requiredFields) => (value, allValues) => {
  if (!Array.isArray(requiredFields)) {
    return undefined;
  }
  const areOtherFieldsHaveValue = requiredFields.some((fieldName) => _.get(allValues, fieldName));
  return areOtherFieldsHaveValue && !value ? 'Required' : undefined;
};
export const requiredOneOf = (fieldsNames) => (value, allValues) => {
  if (!Array.isArray(fieldsNames)) {
    return undefined;
  }
  const anyFieldHasValue = fieldsNames.some((fieldName) => _.get(allValues, fieldName));
  return !anyFieldHasValue ? 'One of these fields are required' : undefined;
};

export const url = (value) => {
  if (value === null || value === undefined || value === '') return undefined;
  return (typeof value === 'string' && validator.isURL(value, urlOptions))
    ? undefined
    : 'Invalid URL';
};

export const email = (value) => (validator.isEmail(value) ? undefined : 'Invalid email address');

export const passwordLength = (value) => (value.length >= PASSWORD_LENGTH
  ? undefined
  : 'At least 8 characters required');

export const passwordStrength = (value) => (isPasswordDifferential(value)
  ? undefined
  : 'At least 1 uppercase letter, 1 lowercase letter, and 1 number required');

export const passwordMatch = (value1, value2) => (value1 === value2 ? undefined : 'Passwords do not match');

export const ageLimitChars = (value) => (AGE_LIMIT_REGEXP.test(value)
  ? undefined
  : "Only 2 digits and an optional '+' are allowed");

export const minValue = (min) => (value) => (parseInt(value, 10) >= min
  ? undefined
  : `Value is too low. Should be greater than or equal to ${min}`);

export const maxValue = (max) => (value) => (parseInt(value, 10) <= max
  ? undefined
  : `Value is too high. Should be less than or equal to ${max}`);

export const minFloatValue = (min) => (value) => (parseFloat(value) >= min
  ? undefined
  : `Value is too low. Should be greater than or equal to ${min}`);

export const maxFloatValue = (max) => (value) => (parseFloat(value) <= max
  ? undefined
  : `Value is too high. Should be less than or equal to ${max}`);

export const maxStringLength = (maxLength) => (text) => (text && text.length > maxLength
  ? `The field exceeds the maximum ${maxLength} characters limit`
  : undefined);

export const applicationName = async (name, platform, userId, query) => {
  if (!name || !platform) {
    return undefined;
  }
  const { data } = await query({
    name,
    platform,
    userId,
  });
  if (!data || !data.checkApplicationName) {
    return undefined;
  }
  return 'Application with given name already exists';
};

export const applicationTitle = (title, maxLength) => (title && title.length > maxLength
  ? 'App title exceeds the maximum 30 characters limit for iOS Version'
  : undefined);

export const applicationTitles = (titleList, platform) => {
  const maxLength = getTitleLength(platform);
  for (const title of titleList) {
    const titleValidation = applicationTitle(title, maxLength);
    if (titleValidation) {
      return titleValidation;
    }
  }
  return undefined;
};

export const composeValidators = (valueCanBeEmpty, ...validators) => (value, allValues) => {
  if (valueCanBeEmpty) {
    if (typeof value !== 'object' && !value) {
      return undefined;
    }
    if (typeof value === 'object' && isEmpty(value)) {
      return undefined;
    }
  }
  return validators.reduce((error, validate) => error || validate(value, allValues), undefined);
};

export const applicationAssetsCount = (assets, min, max) => {
  const assetsLength = assets.length;
  if (assetsLength < min) {
    const msg = min === 1
      ? 'Required'
      : `At least ${min} asset${min > 1 ? 's are' : ' is'} required`;
    return msg;
  }
  if (assetsLength > max) {
    return `The number of assets allowed is ${max}`;
  }
  return undefined;
};

export const checkForSpecialCharacters = (text) => {
  if (typeof text !== 'string') {
    return undefined;
  }
  const reg = new RegExp('^[a-zA-Z0-9]*$');
  const isStringContainsSpecials = !reg.test(text);
  if (isStringContainsSpecials) {
    return 'The field should not contain special characters';
  }
  return undefined;
};

export const isEqual = (currentValue, previousValue, fieldType) => {
  if (fieldType === 'tags') {
    if (currentValue?.length === 0 && (previousValue === undefined || previousValue?.length === 0)) return true;
  }
  if (fieldType === 'dualList' && previousValue && currentValue) {
    const sort = (value) => value.sort((a, b) => {
      const aCode = a.code.toLowerCase();
      const bCode = b.code.toLowerCase();
      if (aCode > bCode) {
        return 1;
      }
      if (aCode < bCode) {
        return -1;
      }
      return 0;
    });
    return _.isEqual(sort(previousValue), sort(currentValue));
  }
  return currentValue === previousValue;
};

export const iosAppId = (value) => {
  if (value === '' || value === undefined || value === null) return undefined;
  const regex = new RegExp(/^\d+$/);
  return regex.test(value) ? undefined : 'Invalid Ios App Store ID';
};

export const androidAppId = (value) => {
  if (value === '' || value === undefined || value === null) return undefined;
  const regex = new RegExp(/^(\w+\.)+\w+$/);
  return regex.test(value) ? undefined : 'Invalid Android App Store ID';
};
