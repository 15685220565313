/* eslint-disable import/no-cycle */
import * as React from 'react';
import styled from 'styled-components';
import { TrComponent } from './TrComponent';
import { TdComponent } from './TdComponent';
import Spinner from '../../Spinner';
import { StaticTable } from '../StaticTable';
// -- COMPONENT
export function TableContent({
  rows,
  prepareRow,
  columnsNumber,
  highlightFirstCell,
  trElement,
  loading,
  renderRowSubComponent,
  childrenSubTableColumns,
}) {
  const renderRow = (row) => {
    prepareRow(row);
    const rowChildrens = row.cells.map((cell, index) => {
      const TdComponentToRender = cell.column
        .tdElement || TdComponent;
      return (
        <TdComponentToRender
          {...cell.getCellProps()}
          key={`row-${row.id}-cell-${index}`}
          cellAlignment={cell.column.cellAlignment}
        >
          {cell.render('Cell')}
        </TdComponentToRender>
      );
    });
    const rowProps = {
      highlightFirstCell,
      rowObject: row,
      ...row.getRowProps(),
      children: rowChildrens,
    };
    const TrComponenToRender = trElement || TrComponent;
    if (renderRowSubComponent && childrenSubTableColumns && row?.original?.children) {
      const depth = row?.original?.depth + 1;
      return (
        <React.Fragment key={`${row.id}_frag`}>
          <TrComponenToRender {...rowProps} />
          {row.isExpanded ? (
            <SubTableTr>
              <SubTableTh depth={depth}>
                <StaticTable
                  key={`row-${row.id}`}
                  data={row?.original?.children}
                  columns={childrenSubTableColumns[depth]}
                  expanded
                  renderRowSubComponent
                  childrenSubTableColumns={childrenSubTableColumns}
                />
              </SubTableTh>
            </SubTableTr>
          ) : null}
        </React.Fragment>
      );
    }
    return <TrComponenToRender {...rowProps} />;
  };

  if (loading) {
    return (
      <TrComponent>
        <TdComponent colSpan={columnsNumber}>
          <Spinner style={{ marginTop: 0 }} />
        </TdComponent>
      </TrComponent>
    );
  }

  if (!rows.length) {
    return (
      <TrComponent>
        <TdComponent colSpan={columnsNumber}>No data available</TdComponent>
      </TrComponent>
    );
  }
  return <>{rows.map(renderRow)}</>;
}

const SubTableTr = styled.tr`
  background: #1a1a32;
`;

const SubTableTh = styled.th`
  display: block;
`;
