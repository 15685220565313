import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { formatters, validators } from '../utils';
import FormSectionWFields from './forms/FormSectionWFields';

export default function Contact({ data, closeModal, isNew = false, onSubmit }) {
  const { t: tg } = useTranslation('general');

  const initialValues = {
    city: data?.city || '',
    company: data?.company || '',
    country: data?.country || '',
    emailAddress: data?.emailAddress || '',
    firstName: data?.firstName || '',
    isDefaultBilling: data?.isDefaultBilling || false,
    lastName: data?.lastName || '',
    middleName: data?.middleName || '',
    phoneNumber: data?.phoneNumber || '',
    postalcode: data?.postalcode || '',
    region: data?.region || '',
    status: data?.status || 'active',
    street1: data?.street1 || '',
    street2: data?.street2 || '',
  };

  const fields = [
    {
      name: 'firstName',
      label: tg('firstName'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('firstName'),
    },
    {
      name: 'middleName',
      label: tg('middleName'),
      width: 0.5,
      formatter: formatters.trim,
      placeholder: tg('middleName'),
    },
    {
      name: 'lastName',
      label: tg('lastName'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('lastName'),
    },
    {
      name: 'status',
      type: 'select',
      label: tg('status.name'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      options: [{ label: tg('status.active'), value: 'active' }, { label: tg('status.inactive'), value: 'inactive' }],
    },
    {
      name: 'company',
      label: tg('company'),
      width: 1,
      formatter: formatters.trim,
      placeholder: tg('company'),
    },
    {
      name: 'street1',
      label: tg('street1'),
      width: 1,
      formatter: formatters.trim,
      placeholder: tg('street1'),
    },
    {
      name: 'street2',
      label: tg('street2'),
      width: 1,
      formatter: formatters.trim,
      placeholder: tg('street2'),
    },
    {
      name: 'city',
      label: tg('city'),
      width: 0.5,
      formatter: formatters.trim,
      placeholder: tg('city'),
    },
    {
      name: 'region',
      label: tg('region'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('region'),
    },
    {
      name: 'postalcode',
      label: tg('postalCode'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('postalCode'),
    },
    {
      name: 'country',
      label: tg('country'),
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('country'),
    },
    {
      name: 'phoneNumber',
      label: tg('phoneNumber'),
      type: 'tel',
      width: 0.5,
      formatter: formatters.trim,
      placeholder: tg('phoneNumber'),
    },
    {
      name: 'emailAddress',
      label: tg('email'),
      type: 'email',
      width: 0.5,
      formatter: formatters.trim,
      validate: validators.required,
      placeholder: tg('email'),
    },
    {
      name: 'isDefaultBilling',
      label: tg('billing'),
      type: 'checkbox',
      width: 1,
      noMargin: true,
      style: {
        margin: 0,
      },
    },
  ];

  return (
    <>
      <Form
        onSubmit={(values) => onSubmit(values, isNew, data)}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Wrapper>
              <FormSectionWFields
                fields={fields}
                onCancel={closeModal}
                submitting={submitting}
                pristine={pristine}
                withButtons
              />
            </Wrapper>
          </form>
        )}
      </Form>
    </>
  );
}

const Wrapper = styled.div`
  width: 800px;
`;
