import React from 'react';
import styled from 'styled-components';
import {
  Tooltip,
} from '@mui/material';
import { ReactComponent as ASOLogo } from 'assets/images/aso_icon_platform.svg';
import { ReactComponent as GrowthLogo } from 'assets/images/ua_icon_platform.svg';
import { ReactComponent as CreativeLogo } from 'assets/images/creative_icon_platform.svg';
import { ReactComponent as SocialLogo } from 'assets/images/social_icon_platform.svg';

export const UppticAppIcon = ({ tooltip, svg, isActive, onClick, product }) => {
  const IconComponent = svg;
  const className = isActive ? `product-icon-${product} active` : `product-icon-${product}`;
  return (
    <Tooltip
      title={tooltip}
      placement="top"
    >
      <IconWrapper
        fill={isActive ? 'rgba(151, 67, 235, 0.8)' : 'rgba(255, 255, 255, 0.2)'}
        onClick={onClick}
        isActive={isActive}
        className={className}
        data-tip={tooltip}
        data-for="product"
      >
        <IconComponent />
      </IconWrapper>
    </Tooltip>
  );
};

export const FullUppticAppIconSet = ({ activeProductFamilies, uaOnClick, asoOnClick, creativeOnClick, socialOnClick }) => (
  <AppsWrapper>
    <UppticAppIcon
      svg={GrowthLogo}
      tooltip="Growth"
      isActive={activeProductFamilies?.includes('ua')}
      onClick={activeProductFamilies?.includes('ua') ? uaOnClick : null}
      product="ua"
    />
    <UppticAppIcon
      svg={CreativeLogo}
      tooltip="Creative"
      isActive={activeProductFamilies?.includes('creative')}
      onClick={activeProductFamilies?.includes('creative') ? creativeOnClick : null}
      product="creative"
    />
    <UppticAppIcon
      svg={SocialLogo}
      tooltip="Social"
      isActive={activeProductFamilies?.includes('social')}
      onClick={activeProductFamilies?.includes('social') ? socialOnClick : null}
      product="social"
    />
    <UppticAppIcon
      svg={ASOLogo}
      tooltip="ASO"
      isActive={activeProductFamilies?.includes('aso')}
      onClick={activeProductFamilies?.includes('aso') ? asoOnClick : null}
      product="aso"
    />
  </AppsWrapper>
);

const AppsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`;

const IconWrapper = styled.div`
  min-width: 25px;
  height: 25px;
  svg {
    fill: ${(props) => props.fill};
    height: 100%;
  }
  ${(props) => props.isActive
  && `
    &:hover svg {
      fill: rgba(151, 67, 235, 1);
      cursor: pointer;
    }
  `}
  ${(props) => !props.isActive
  && `
  cursor: default;
  `}
`;
