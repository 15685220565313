import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { styled } from '@mui/system';
import FormError from 'components/forms/FormError';
import { DualListOptionComponent } from './DualListOptionComponent';

const DualList = ({ options, selected, valueField = 'value', labelField = 'label', onChange, error }) => {
  const valid = !error;
  const getValueField = (option) => option[valueField];
  const getLabelField = (option) => option[labelField];
  const compare = (a, b) => {
    const labelA = getLabelField(a).toUpperCase();
    const labelB = getLabelField(b).toUpperCase();
    let comparison = 0;
    if (labelA > labelB) {
      comparison = 1;
    } else if (labelA < labelB) {
      comparison = -1;
    }
    return comparison;
  };
  const sort = (array) => {
    array.sort(compare);
    return array;
  };
  const findDiff = (oriArray, diffArray) => oriArray.filter((obj) => !diffArray.some((obj2) => getValueField(obj) === getValueField(obj2)));
  const [markedOptions, setMarkedOptions] = useState([]);
  const [possibleOptions, setPossibleOptions] = useState([]);
  const [optionsSelected, setOptionSelected] = useState([]);
  const isMarked = (option) => markedOptions.find((element) => element === option) !== undefined;
  const addToMarked = (option) => {
    if (isMarked(option)) {
      setMarkedOptions(markedOptions.filter((element) => element !== option));
    } else {
      markedOptions.push(option);
      setMarkedOptions(markedOptions);
    }
  };
  const onUnSelectAllOptions = () => {
    setOptionSelected([]);
    setPossibleOptions(sort(options));
    setMarkedOptions([]);
    onChange([]);
  };
  const onUnSelectOptions = () => {
    const diff = findDiff(optionsSelected, markedOptions);
    const leftInMarked = findDiff(markedOptions, optionsSelected);
    setOptionSelected(sort(uniqBy(diff, valueField)));
    setPossibleOptions(sort(uniqBy(possibleOptions.concat(markedOptions), valueField)));
    setMarkedOptions(leftInMarked);
    onChange(sort(uniqBy(diff, valueField)));
  };
  const onSelectOptions = () => {
    const diff = findDiff(possibleOptions, markedOptions);
    const leftInMarked = findDiff(markedOptions, possibleOptions);
    setOptionSelected(sort(uniqBy(optionsSelected.concat(markedOptions), valueField)));
    setPossibleOptions(sort(uniqBy(diff, valueField)));
    setMarkedOptions(leftInMarked);
    onChange(sort(uniqBy(optionsSelected.concat(markedOptions), valueField)));
  };
  const onSelectAllOptions = () => {
    setOptionSelected(sort([...options]));
    setPossibleOptions([]);
    setMarkedOptions([]);
    onChange(sort([...options]));
  };
  useEffect(() => {
    if (options) {
      setPossibleOptions(sort([...findDiff(options, selected)]));
    }
    if (selected) {
      setOptionSelected(sort([...selected]));
    }
  }, [options, selected]);
  return (
    <>
      <DualListContent>
        <OptionsBox>
          {possibleOptions.map((option) => (
            <DualListOptionComponent
              key={getValueField(option)}
              option={option}
              onOptionClick={addToMarked}
              getLabel={getLabelField}
            />
          ))}
        </OptionsBox>
        <ActionBox>
          <DoubleArrowLeft onClick={onUnSelectAllOptions} />
          <ArrowLeft onClick={onUnSelectOptions} />
          <ArrowRight onClick={onSelectOptions} />
          <DoubleArrowRight onClick={onSelectAllOptions} />
        </ActionBox>
        <SelectedBox valid={valid}>
          {optionsSelected.map((option) => (
            <DualListOptionComponent
              key={getValueField(option)}
              option={option}
              onOptionClick={addToMarked}
              getLabel={getLabelField}
            />
          ))}
        </SelectedBox>
      </DualListContent>
      {!valid ? <FormError>{error}</FormError> : null}
    </>
  );
};
const DualListContent = styled('div')`
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const OptionsBox = styled('div')`
  width: 45%;
  height: 100%;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.inputsLight};
  overflow-y: auto;
`;
const ActionBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 10%;
  justify-content: space-around;
  align-items: center;
`;
const SelectedBox = styled('div')`
  width: 45%;
  height: 100%;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.inputsLight};
  overflow-y: auto;
  ${(props) => !props.valid && `
    box-shadow: 0 0 15px 0 ${props.theme.colors.notificationRedBoxShadow};
    border: 1px solid ${props.theme.colors.notificationRed};
  `};
`;
const ArrowBox = styled('div')`
  font-size: 20px;
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.lighterPurple};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  vertical-align: center;
  cursor: pointer;
  border-radius: 4px;
  height: 35px;
  width: 70px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightishPurple};
  }
`;
const ArrowLeft = styled(ArrowBox)`
  &:before {
    content: "‹";
  }
`;
const DoubleArrowLeft = styled(ArrowBox)`
  &:before {
    content: "‹‹";
  }
`;
const ArrowRight = styled(ArrowBox)`
  &:before {
    content: "›";
  }
`;
const DoubleArrowRight = styled(ArrowBox)`
  &:before {
    content: "››";
  }
`;

export default DualList;
