import React from 'react';
import { styled } from '@mui/system';
import { Box, Flex } from '@rebass/grid';
// -- COMPONENT
function Title({ children, button, subtitle, ...rest }) {
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        {...rest}
      >
        <Box>
          <Text subtitle={subtitle}>{children}</Text>
        </Box>
        {!!button && <Box>{button}</Box>}
      </Flex>
      {subtitle && (
      <Box>
        <Subtitle>{subtitle}</Subtitle>
      </Box>
      )}
    </>
  );
}
const Text = styled('h1')`
  font-size: 36px;

  color: ${(props) => props.theme.colors.white};

  margin: 35px 0;

  ${(props) => props.subtitle
    && `
    margin-bottom: 10px;
  `}
`;
const Subtitle = styled('p')`
  color: ${(props) => props.theme.colors.lightGrey};

  font-size: 21px;
  font-weight: bold;

  margin: 0 0 35px 0;
`;
export default Title;
