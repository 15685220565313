import React from 'react';
import styled from 'styled-components';
import themes from 'components/themes';

function Card({ children, center = false, style = {} }) {
  const defaultBoxStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: themes.colors.backgroundDark,
  };
  const defaultCenterBoxStyles = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  };

  const combinedBoxStyles = { ...defaultBoxStyles, ...style };
  const combinedCenterBoxStyles = { ...defaultCenterBoxStyles, ...style };

  return (center
    ? (<CenterBox style={combinedCenterBoxStyles}>{children}</CenterBox>) : (<Box style={combinedBoxStyles}>{children}</Box>)
  );
}

const Box = styled.div`
  ${(props) => { props.style; }}
`;
const CenterBox = styled(Box)`
  ${(props) => { props.style; }}
`;
export default Card;
