/* eslint-disable no-continue */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { PlatformContext } from '@upptic/module-directory';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { paramCase } from 'param-case';
import moment from 'moment';

import ChartGenerator, { colorPalette } from '../../ChartGenerator/ChartGenerator';
import BarChartCustomTooltip from '../../ChartGenerator/components/BarChartCustomTooltip';
import { formatChartDate } from '../../../utils/formatters';
import ElementTitle from '../GenericElements/ElementTitle';
import { discordMetricsGql } from '../gql';

function DiscordOverviewChartElement({
  element,
}) {
  const platformStore = useContext(PlatformContext);
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const { t } = useTranslation('community');
  const { t: talt } = useTranslation('integrations', { keyPrefix: 'audit' });

  const elementHeight = element.heightFactor ? (250 * element.heightFactor) : 250;
  const chartHeight = (elementHeight - 40);
  const last14daysDates = useMemo(() => ({
    startDate: moment.tz('UTC').startOf('day').subtract(13, 'days').toISOString(),
    endDate: moment.tz('UTC').endOf('day').toISOString(),
  }), []);

  const { data, loading } = useQuery(discordMetricsGql, {
    onError: () => {},
    context: { handleError: true },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode: platformStore.currentClient.code,
      applicationCode: selectedApplicationCode,
      period: 'day',
      startDate: last14daysDates.startDate,
      endDate: last14daysDates.endDate,
    },
  });

  const customPayloadFormat = (payload) => {
    const dataArray = [];
    for (const item of payload) {
      if (item?.name === 'Exits') {
        if (item.payload?.exits === 0 || item.payload?.joins === 0) {
          dataArray.push({
            ...item,
            label: item.name,
          });
          continue;
        }
        const exits = Math.abs(item.payload?.exits);
        const joins = item.payload?.joins;
        const percentageExits = (exits / joins) * 100;
        dataArray.push({
          ...item,
          label: item.name,
          secondaryValue: !Number.isNaN(percentageExits) ? `${percentageExits.toFixed(1)}%` : undefined,
        });
        continue;
      }
      dataArray.push({
        ...item,
        label: item.name,
      });
    }
    return dataArray;
  };

  const definition = {
    elements: [
      {
        type: 'sparklineChart',
        headline: { label: t('discord.headlines.discordServerOverview'), align: 'center' },
        dataKey: 'discordMetrics.metrics',
        height: chartHeight,
        layout: 'horizontal',
        hideLegend: true,
        hideChartActions: true,
        hideHeadline: true,
        tooltip: { CustomTooltipComponent: (props) => BarChartCustomTooltip(
          props,
          'value',
          {
            customPayloadFormat: (payload) => customPayloadFormat(payload),
            labelFormatter: (date) => formatChartDate(date),
          },
        ),
        },
        xAxis: {
          dataKey: 'metricDate',
          tickFormatter: (date) => formatChartDate(date, '', 'short'),
        },
        yAxis: [
          {
            yAxisId: 1,
            tickLine: false,
            axisLine: false,
            label: {
              value: t('discord.diagrams.totalDiscordUsers'),
              angle: -90,
              fill: '#666',
              dx: -30,
            },
            domain: [0, (dataMax) => Math.ceil(dataMax * 1.15)],
          },
          {
            yAxisId: 2,
            tickLine: false,
            axisLine: false,
            orientation: 'right',
            label: {
              value: t('discord.diagrams.joinAndExits'),
              angle: -90,
              fill: '#666',
              dx: 30,
            },
            domain: ['auto', (dataMax) => Math.ceil(dataMax * 1.15)],
          },
        ],
        elements: [
          {
            type: 'line',
            name: t('discord.discordUsers'),
            dataKey: 'totalUserCount',
            params: { type: 'monotoneX', stroke: colorPalette[3] },
            yAxisId: 1,
          },
          {
            type: 'bar',
            dataKey: 'joins',
            name: t('discord.joins'),
            params: { type: 'monotoneX', barColor: colorPalette[4] },
            yAxisId: 2,
          },
          {
            type: 'bar',
            dataKey: 'exits',
            name: t('discord.exits'),
            params: { type: 'monotoneX', barColor: colorPalette[2] },
            yAxisId: 2,
          },
        ],
      },
    ],
  };

  const csvData = useMemo(() => {
    if (!data || data?.discordMetrics?.metrics?.length === 0) return [];
    return data?.discordMetrics?.metrics?.map((metric) => ({
      ...metric,
      metricDate: formatChartDate(metric.metricDate),
    }));
  }, [data]);

  return (
    <Box sx={{ height: elementHeight, textAlign: 'center' }}>
      <Grid
        container
      >
        <Grid
          item
          xs
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={40}
        >
          <ElementTitle title={element.title} ml={5} />
        </Grid>
        <Grid
          item
          width={40}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CSVLink
            data={csvData}
            headers={[
              {
                key: 'metricDate',
                label: talt('fieldLabels.date'),
              },
              {
                key: 'totalUserCount',
                label: t('discord.discordUsers'),
              },
              {
                key: 'joins',
                label: t('discord.joins'),
              },
              {
                key: 'exits',
                label: t('discord.exits'),
              },
            ]}
            filename={`${paramCase(element.title || '') || 'export'
            }-${new Date().getTime()}.csv`}
          >
            <IconButton>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </CSVLink>
        </Grid>
        <ChartGenerator
          data={data}
          definition={definition}
          spacing={0}
          loading={loading}
        />
      </Grid>
    </Box>
  );
}

export default DiscordOverviewChartElement;
