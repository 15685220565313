import React from 'react';
import styled from 'styled-components';
import themes from 'components/themes';
// -- COMPONENT
const Toggle = (props) => {
  function setValue(toggleValue, onChange) {
    return (e) => {
      e.preventDefault();
      onChange(toggleValue);
    };
  }
  return (
    <Wrapper>
      {props.options
        ? props.options.map((singleOption) => (
          <Button
            key={singleOption.value}
            onClick={setValue(singleOption.value, props.onChange)}
            active={props.value === singleOption.value}
          >
            {singleOption.label}
          </Button>
        ))
        : null}
    </Wrapper>
  );
};
// -- STYLES
const Wrapper = styled.div``;
const Button = styled.button`
  color: ${themes.colors.white};
  background-color: ${themes.colors.backgroundDark};
  border: 1px solid ${themes.colors.purple};
  font-size: 12px;
  width: 130px;
  padding: 10px 0;
  letter-spacing: 0.5px;
  font-weight: 600;
  &:focus {
    outline: none;
  }

  &:not(:first-of-type) {
    border-radius: 0 2px 2px 0;
  }

  &:first-of-type {
    border-radius: 2px 0 0 2px;
  }

  ${(props) => props.active
    && `
    background-color: ${themes.colors.purple}
  `}
`;
export default Toggle;
