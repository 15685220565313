import React from 'react';
import styled from 'styled-components';

function CircleProgress({ size, progress, strokeWidth, circleOneStroke, circleTwoStroke }) {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <>
      <SVG
        width={size}
        height={size}
      >
        <SVGCircleBG
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <SVGCircle
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
        />
      </SVG>
    </>
  );
}

export default CircleProgress;

const SVG = styled.svg`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  transform: rotate(-90deg);
`;

const SVGCircleBG = styled.circle`
  fill: none;
`;

const SVGCircle = styled.circle`
  fill: none;
  transition: stroke-dashoffset 2000ms ease;
`;
