import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import theme from 'components/themes';
import FormError from './FormError';

// -- STYLES
const DatepickerWrapper = styled.div`
  .SingleDatePickerInput {
    ${(props) => props.error
    && `
          box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
          border: 1px solid ${theme.colors.notificationRed};
    `};
  }
  input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
const Arrow = styled.div`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  top: 31px;
  cursor: pointer;
`;
const LeftArrow = styled(Arrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 35px;
`;
const RightArrow = styled(Arrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 35px;
`;

const RangePicker = ({ initialStartDate, initialEndDate, onChange, externalError, displayFormat = 'DD-MM-YYYY', minDay, maxDay }) => {
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [pickerConfig, setPickerConfig] = useState({
    error: false,
    focusedOn: null,
  });

  const getInitialDates = (initialFrom, initialTo) => {
    const [startDate, endDate] = [initialFrom, initialTo].map((singleDate) => {
      let date = moment(singleDate || '');
      if (!date.isValid()) {
        date = null;
      }
      return date;
    });
    return {
      startDate,
      endDate,
    };
  };

  useEffect(() => {
    setDates(getInitialDates(initialStartDate, initialEndDate));
  }, [initialEndDate, initialStartDate]);

  const renderCustomButton = (buttonDirection) => (buttonProps) => {
    const buttonsType = {
      left: LeftArrow,
      right: RightArrow,
    };
    const Component = buttonsType[buttonDirection];
    return <Component {...buttonProps} />;
  };

  const onDateChange = (selectedDates) => setDates({ ...selectedDates });
  const changeVisibility = (focusInput) => setPickerConfig({ ...pickerConfig, focusedOn: focusInput });
  const onClose = (selectedDates) => {
    const { startDate, endDate } = selectedDates;
    if (startDate && endDate) {
      onChange(startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY'));
    }
  };

  const isDayBlocked = React.useCallback((day) => {
    const isBefore = minDay && day.isBefore(minDay, 'day');
    const isAfter = maxDay && day.isAfter(maxDay, 'day');
    return Boolean(isBefore || isAfter);
  }, [minDay, maxDay]);

  const defaultInputProps = {
    onDatesChange: onDateChange,
    onFocusChange: changeVisibility,
    hideKeyboardShortcutsPanel: true,
    displayFormat,
    numberOfMonths: 2,
    renderNavPrevButton: renderCustomButton('left'),
    renderNavNextButton: renderCustomButton('right'),
    keepOpenOnDateSelect: false,
    isOutsideRange: () => false,
    readOnly: true,
    customArrowIcon: '-',
    onClose,
    isDayBlocked: minDay || maxDay ? isDayBlocked : undefined,
  };

  const renderError = () => {
    const { error } = pickerConfig;
    const errorList = [];
    if (externalError) {
      errorList.push(externalError);
    }
    if (error) {
      errorList.push('Invalid date');
    }
    const errorMessage = errorList.filter(Boolean).join('. ');
    if (error || externalError) {
      return <FormError>{`${errorMessage}.`}</FormError>;
    }
    return null;
  };

  return (
    <>
      <DatepickerWrapper error={pickerConfig.error || !!externalError}>
        <DateRangePicker
          {...defaultInputProps}
          startDate={dates.startDate}
          endDate={dates.endDate}
          focusedInput={pickerConfig.focusedOn}
          startDateId="start-id"
          endDateId="end-id"
        />
      </DatepickerWrapper>
      {renderError()}
    </>
  );
};

export default memo(RangePicker);
