import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Box,
  Typography,
} from '@mui/material';
import Icon, { Icons } from '../Icon';
import FormError from '../forms/FormError';
import { OptionColumn } from './OptionColumn';

export default function OptionBuilder({
  rowFields,
  onChange,
  containerSize = '250px',
  boxSize = 52.5,
  value,
  error,
  data,
  label,
  onRowSelect,
  marked,
  customAddRowFields,
  customDelete,
}) {
  const valid = !error;
  const [dragData, setDragData] = useState(null);
  const [rows, setRows] = useState(value || []);
  const myRef = useRef(null);
  const [newRowCount, setNewRowCount] = useState(0);
  const onRowClick = (row) => {
    if (onRowSelect) {
      onRowSelect(row);
    }
  };
  useEffect(() => {
    if (onChange && dragData) {
      const rowsData = [];
      const rowData = Object.values(dragData.rows);
      const orderedRows = dragData.columns['column-1'].rowIds;
      for (const item of orderedRows) {
        const findItem = rowData.filter((row) => row.id === item);
        if (findItem.length !== 0) {
          rowsData.push(findItem[0]);
        }
      }
      onChange(rowsData);
    }
  }, [dragData]);

  useEffect(() => {
    const dataObj = {
      rows: {},
      columns: {
        'column-1': {
          id: 'column-1',
          rowIds: [],
        },
      },
      columnOrder: ['column-1'],
    };
    if (rows) {
      for (const [index, row] of rows.entries()) {
        const { id } = row;
        // existing data
        if (id === undefined) {
          dataObj.rows[`row-${index}`] = { id: `row-${index}`, ...row };
          dataObj.columns['column-1'].rowIds.push(`row-${index}`);
        }
        if (id !== undefined) {
          // if ID !== index + 1, reset ID
          if (id !== `row-${index}`) {
            delete dataObj[id];
            dataObj.rows[`row-${index}`] = { ...row, id: `row-${index}` };
            dataObj.columns['column-1'].rowIds.push(`row-${index}`);
            // eslint-disable-next-line no-continue
            continue;
          }
          dataObj.rows[id] = { ...row };
          dataObj.columns['column-1'].rowIds.push(id);
        }
      }
    }
    setDragData(dataObj);
  }, [rows]);

  const scrollNewRowIntoView = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const updateValue = (e, rowId, columnId) => {
    const rowsData = [];
    const rowData = Object.values(dragData.rows);
    const orderedRows = dragData.columns[columnId].rowIds;
    for (const item of orderedRows) {
      const findItem = rowData.filter((row) => row.id === item);
      rowsData.push(findItem[0]);
    }
    const currentRow = rowsData.filter((row) => row.id === rowId)[0];
    currentRow.value = e.target.value;
    setRows(rowsData);
  };
  const onChecked = (rowId, columnId) => {
    const rowsData = [];
    const rowData = Object.values(dragData.rows);
    const orderedRows = dragData.columns[columnId].rowIds;
    for (const item of orderedRows) {
      const findItem = rowData.filter((row) => row.id === item);
      rowsData.push(findItem[0]);
    }
    const currentRow = rowsData.filter((row) => row.id === rowId)[0];
    const checkedRows = rowsData.filter((row) => row.isDefault === true && row.id !== rowId);
    if (checkedRows) {
      for (const row of checkedRows) {
        row.isDefault = false;
      }
    }
    currentRow.isDefault = !currentRow.isDefault;
    setRows(rowsData);
  };

  const addRow = async () => {
    const rowsData = [];
    const rowData = Object.values(dragData.rows);
    const orderedRows = dragData.columns['column-1'].rowIds;
    for (const item of orderedRows) {
      const findItem = rowData.filter((row) => row.id === item);
      rowsData.push(findItem[0]);
    }
    let valuesObjectBuilder = { id: `row-${rowData.length + 1}` };
    if (rowFields.some((item) => !item.type)) valuesObjectBuilder.value = '';
    if (rowFields.some((item) => item.type === 'checkbox')) valuesObjectBuilder.isDefault = false;
    if (customAddRowFields) valuesObjectBuilder = { ...valuesObjectBuilder, ...customAddRowFields };
    if (onRowSelect) valuesObjectBuilder.rowCode = `newRow-${newRowCount + 1}`;
    rowsData.push(valuesObjectBuilder);
    setRows(rowsData);
    setNewRowCount(newRowCount + 1);
    await new Promise((resolve) => setTimeout(resolve, 100));
    scrollNewRowIntoView();
  };

  const deleteRow = (rowId, columnId, row) => {
    const rowsData = [];
    const rowData = Object.values(dragData.rows);
    const filteredRowData = rowData.filter((row) => row.id !== rowId);
    const orderedRows = dragData.columns[columnId].rowIds;
    for (const item of orderedRows) {
      const findItem = filteredRowData.filter((row) => row.id === item);
      if (findItem.length !== 0) {
        rowsData.push(findItem[0]);
      }
    }
    if (customDelete) customDelete(row);
    setRows(rowsData);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const dataObj = {
      ...dragData,
      rows: {},
      columns: {
        ...dragData.columns,
        [source.droppableId]: {
          id: source.droppableId,
          rowIds: [],
        },
      },
    };

    const column = dragData.columns[source.droppableId];
    const newRowIds = Array.from(column.rowIds);
    newRowIds.splice(source.index, 1);
    newRowIds.splice(destination.index, 0, draggableId);

    const rowsData = [];
    const rowData = Object.values(dragData.rows);
    const orderedRows = newRowIds;
    for (const item of orderedRows) {
      const findItem = rowData.find((row) => row.id === item);
      rowsData.push(findItem);
    }
    if (rowsData.length !== 0) {
      for (const [index, row] of rowsData.entries()) {
        const { id } = row;
        // existing data
        if (id === undefined) {
          dataObj.rows[`row-${index}`] = { id: `row-${index}`, ...row };
          dataObj.columns[source.droppableId].rowIds.push(`row-${index}`);
        }
        if (id !== undefined) {
          // if ID !== index + 1, reset ID
          if (id !== `row-${index}`) {
            delete dataObj[id];
            dataObj.rows[`row-${index}`] = { ...row, id: `row-${index}` };
            dataObj.columns[source.droppableId].rowIds.push(`row-${index}`);
            // eslint-disable-next-line no-continue
            continue;
          }
          dataObj.rows[id] = { ...row };
          dataObj.columns[source.droppableId].rowIds.push(id);
        }
      }
    }

    setDragData(dataObj);
  };

  return (
    <Flex
      flexDirection="column"
      position="relative"
    >
      {label ? (
        <Box
          height={30}
        >
          <Typography>{label}</Typography>
        </Box>
      ) : null}
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        {dragData && dragData.columnOrder.map((columnId) => {
          const column = dragData.columns[columnId];
          const mappedRows = column.rowIds.map((rowId) => dragData.rows[rowId]);
          return (
            <OptionColumn
              key={column.id}
              column={column}
              rows={mappedRows}
              containerSize={label ? `calc(${containerSize} - 30px)` : containerSize}
              valid={valid}
              myRef={myRef}
              boxSize={boxSize}
              rowFields={rowFields}
              updateValue={updateValue}
              onChecked={onChecked}
              deleteRow={deleteRow}
              addRow={addRow}
              data={data}
              onRowClick={onRowSelect ? onRowClick : undefined}
              marked={marked}
            />
          );
        })}
      </DragDropContext>
      {!valid ? <FormError>{error}</FormError> : null}
      <OutterButtonWrapper
        rows={rows}
      >
        <IconWrapper onClick={() => addRow()}>
          <Icon icon={Icons.faPlus} />
        </IconWrapper>
      </OutterButtonWrapper>
    </Flex>
  );
}

const IconWrapper = styled.span`
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(151, 67, 235, 0.7);
  &:hover {
    cursor: pointer;
    color: rgba(151, 67, 235, 1);
  }
`;

const OutterButtonWrapper = styled.div`
  display: flex;
  visibility: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${(props) => props.rows.length !== 0
  && `
    visibility: visible;
    position: sticky;
    bottom: 0;
  `}
`;
