/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';

import { formatData } from '../../../utils/formatters/formatters';
import { getWidgetQueryInfo, useGetMetricsFilter } from '../helper';
import HorizontalNumberWSource from './HorizontalNumberWSource';
import ElementTitle from '../GenericElements/ElementTitle';

function HorizontalNumberGrid({ element, appReportingCurrency, modelType }) {
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;
  const elementHeight = element.heightFactor ? (100 * element.heightFactor) : 100;

  const queryInfo = getWidgetQueryInfo(element?.dataSource?.queryType);

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);

  const { data } = useQuery(queryInfo.gql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource?.metrics,
      filter: metricsFilter,
      dateRange: element.dataSource?.dateRange,
      dimensions: element.dataSource?.dimensions,
    },
  });

  const values = useMemo(() => {
    const values = _.get(data, queryInfo.dataKey, null);
    if (!values?.length) return null;
    return values[0];
  }, [_.get(data, queryInfo.dataKey)]);

  const renderElement = (element) => {
    const dataPointFormat = () => {
      if (element.format?.type === 'currency' && appReportingCurrency) {
        return ({
          ...element.format,
          currency: appReportingCurrency,
        });
      }
      return element?.format;
    };
    if (element.dataSource) return <HorizontalNumberWSource element={element} appReportingCurrency={appReportingCurrency} />;
    return (
      <>
        {element.title && <ElementTitle title={element.title} />}
        <Typography
          variant="h5"
          component="div"
          mt={0.5}
          noWrap
        >
          {formatData(values?.[element.dataKey], element.format?.type || 'number', dataPointFormat())}
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        height: elementHeight,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        {element.numbers.map((number) => renderElement(number))}
      </Stack>
    </Box>
  );
}

export default observer(HorizontalNumberGrid);
