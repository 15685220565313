import * as React from 'react';
import styled from 'styled-components';
import ReactSwitch from 'react-switch';
import themes from 'components/themes';
// -- COMPONENT
export function Switch(props) {
  const {
    value = false,
    onChange,
    disabled = false,
    labelOn = 'Yes',
    labelOff = 'No',
    displayLabels = false,
  } = props;
  const checkedIcon = labelOn && displayLabels ? (<LabelWrapper ml>{labelOn}</LabelWrapper>) : (false);
  const uncheckedIcon = labelOff && displayLabels ? <LabelWrapper>{labelOff}</LabelWrapper> : false;
  return (
    <ReactSwitch
      onChange={onChange}
      disabled={disabled}
      onColor={themes.colors.purple}
      offColor="#5f5f6f"
      handleDiameter={22}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      {...props}
      checked={value}
    />
  );
}
// -- STYLED
const LabelWrapper = styled.div`
  color: ${themes.colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  font-size: 12px;
  font-weight: bold;

  ${(props) => props.ml && 'margin-left: 3px;'};
`;
