import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as BrandIcons from '@fortawesome/free-brands-svg-icons';

function Icon(props) {
  const { verticalCenter, ...base } = props;
  return verticalCenter ? (
    <FontAwesomeIcon
      style={{ alignSelf: 'center' }}
      {...base}
    />
  ) : (<FontAwesomeIcon {...base} />);
}
export const Icons = { ...SolidIcons, ...BrandIcons };
export default Icon;
