import React, { useMemo } from 'react';
import {
  TextField,
  Autocomplete,
  useTheme,
} from '@mui/material';
// eslint-disable-next-line import/extensions
import languageCodes from '../../components/forms/data/languageCodes.json';

export default function LocaleSelect({
  error,
  helperText,
  meta,
  ...rest
}) {
  const theme = useTheme();
  const options = [
    { label: 'English (United States)', value: 'en-us' },
    ...languageCodes.map(({ code, language }) => ({ label: language, value: code })),
  ];
  const findValue = useMemo(() => {
    const incomingValue = rest.value;
    const findOption = options.find((option) => option.value === incomingValue);
    if (findOption) return findOption;
    return null;
  }, [rest.value]);
  return (
    <Autocomplete
      {...rest}
      value={findValue}
      options={options}
      fullWidth
      onChange={(_event, newValue) => {
        rest.onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={rest.label}
          variant="filled"
        />
      )}
      sx={{
        '& .MuiInputLabel-root': {
          color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
        },
      }}
    />
  );
}
