export const timezones = [
  {
    value: 'America/Denver',
    name: 'America/Denver',
  },
  {
    value: 'America/Chicago',
    name: 'America/Chicago',
  },
  {
    value: 'America/Indiana/Indianapolis',
    name: 'America/Indiana/Indianapolis',
  },
  {
    value: 'America/Kentucky/Louisville',
    name: 'America/Kentucky/Louisville',
  },
  {
    value: 'America/Los_Angeles',
    name: 'America/Los Angeles',
  },
  {
    value: 'America/Mexico_City',
    name: 'America/Mexico City',
  },
  {
    value: 'America/New_York',
    name: 'America/New York',
  },
  {
    value: 'America/Phoenix',
    name: 'America/Phoenix',
  },
  {
    value: 'Asia/Hong_Kong',
    name: 'Asia/Hong Kong',
  },
  {
    value: 'Asia/Seoul',
    name: 'Asia/Seoul',
  },
  {
    value: 'Asia/Singapore',
    name: 'Asia/Singapore',
  },
  {
    value: 'Europe/Amsterdam',
    name: 'Europe/Amsterdam',
  },
  {
    value: 'Europe/Berlin',
    name: 'Europe/Berlin',
  },
  {
    value: 'Europe/London',
    name: 'Europe/London',
  },
  {
    value: 'Europe/Madrid',
    name: 'Europe/Madrid',
  },
  {
    value: 'Europe/Paris',
    name: 'Europe/Paris',
  },
  {
    value: 'Africa/Abidjan',
    name: 'Africa/Abidjan',
  },
  {
    value: 'Africa/Accra',
    name: 'Africa/Accra',
  },
  {
    value: 'Africa/Algiers',
    name: 'Africa/Algiers',
  },
  {
    value: 'Africa/Bissau',
    name: 'Africa/Bissau',
  },
  {
    value: 'Africa/Cairo',
    name: 'Africa/Cairo',
  },
  {
    value: 'Africa/Casablanca',
    name: 'Africa/Casablanca',
  },
  {
    value: 'Africa/Ceuta',
    name: 'Africa/Ceuta',
  },
  {
    value: 'Africa/El_Aaiun',
    name: 'Africa/El Aaiun',
  },
  {
    value: 'Africa/Johannesburg',
    name: 'Africa/Johannesburg',
  },
  {
    value: 'Africa/Juba',
    name: 'Africa/Juba',
  },
  {
    value: 'Africa/Khartoum',
    name: 'Africa/Khartoum',
  },
  {
    value: 'Africa/Lagos',
    name: 'Africa/Lagos',
  },
  {
    value: 'Africa/Maputo',
    name: 'Africa/Maputo',
  },
  {
    value: 'Africa/Monrovia',
    name: 'Africa/Monrovia',
  },
  {
    value: 'Africa/Nairobi',
    name: 'Africa/Nairobi',
  },
  {
    value: 'Africa/Ndjamena',
    name: 'Africa/Ndjamena',
  },
  {
    value: 'Africa/Sao_Tome',
    name: 'Africa/Sao Tome',
  },
  {
    value: 'Africa/Tripoli',
    name: 'Africa/Tripoli',
  },
  {
    value: 'Africa/Tunis',
    name: 'Africa/Tunis',
  },
  {
    value: 'Africa/Windhoek',
    name: 'Africa/Windhoek',
  },
  {
    value: 'America/Adak',
    name: 'America/Adak',
  },
  {
    value: 'America/Anchorage',
    name: 'America/Anchorage',
  },
  {
    value: 'America/Araguaina',
    name: 'America/Araguaina',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    name: 'America/Argentina/Buenos Aires',
  },
  {
    value: 'America/Argentina/Catamarca',
    name: 'America/Argentina/Catamarca',
  },
  {
    value: 'America/Argentina/Cordoba',
    name: 'America/Argentina/Cordoba',
  },
  {
    value: 'America/Argentina/Jujuy',
    name: 'America/Argentina/Jujuy',
  },
  {
    value: 'America/Argentina/La_Rioja',
    name: 'America/Argentina/La Rioja',
  },
  {
    value: 'America/Argentina/Mendoza',
    name: 'America/Argentina/Mendoza',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    name: 'America/Argentina/Rio Gallegos',
  },
  {
    value: 'America/Argentina/Salta',
    name: 'America/Argentina/Salta',
  },
  {
    value: 'America/Argentina/San_Juan',
    name: 'America/Argentina/San Juan',
  },
  {
    value: 'America/Argentina/San_Luis',
    name: 'America/Argentina/San Luis',
  },
  {
    value: 'America/Argentina/Tucuman',
    name: 'America/Argentina/Tucuman',
  },
  {
    value: 'America/Argentina/Ushuaia',
    name: 'America/Argentina/Ushuaia',
  },
  {
    value: 'America/Asuncion',
    name: 'America/Asuncion',
  },
  {
    value: 'America/Atikokan',
    name: 'America/Atikokan',
  },
  {
    value: 'America/Bahia',
    name: 'America/Bahia',
  },
  {
    value: 'America/Bahia_Banderas',
    name: 'America/Bahia Banderas',
  },
  {
    value: 'America/Barbados',
    name: 'America/Barbados',
  },
  {
    value: 'America/Belem',
    name: 'America/Belem',
  },
  {
    value: 'America/Belize',
    name: 'America/Belize',
  },
  {
    value: 'America/Blanc-Sablon',
    name: 'America/Blanc-Sablon',
  },
  {
    value: 'America/Boa_Vista',
    name: 'America/Boa Vista',
  },
  {
    value: 'America/Bogota',
    name: 'America/Bogota',
  },
  {
    value: 'America/Boise',
    name: 'America/Boise',
  },
  {
    value: 'America/Cambridge_Bay',
    name: 'America/Cambridge Bay',
  },
  {
    value: 'America/Campo_Grande',
    name: 'America/Campo Grande',
  },
  {
    value: 'America/Cancun',
    name: 'America/Cancun',
  },
  {
    value: 'America/Caracas',
    name: 'America/Caracas',
  },
  {
    value: 'America/Cayenne',
    name: 'America/Cayenne',
  },
  {
    value: 'America/Chihuahua',
    name: 'America/Chihuahua',
  },
  {
    value: 'America/Costa_Rica',
    name: 'America/Costa Rica',
  },
  {
    value: 'America/Creston',
    name: 'America/Creston',
  },
  {
    value: 'America/Cuiaba',
    name: 'America/Cuiaba',
  },
  {
    value: 'America/Curacao',
    name: 'America/Curacao',
  },
  {
    value: 'America/Danmarkshavn',
    name: 'America/Danmarkshavn',
  },
  {
    value: 'America/Dawson',
    name: 'America/Dawson',
  },
  {
    value: 'America/Dawson_Creek',
    name: 'America/Dawson Creek',
  },
  {
    value: 'America/Detroit',
    name: 'America/Detroit',
  },
  {
    value: 'America/Edmonton',
    name: 'America/Edmonton',
  },
  {
    value: 'America/Eirunepe',
    name: 'America/Eirunepe',
  },
  {
    value: 'America/El_Salvador',
    name: 'America/El Salvador',
  },
  {
    value: 'America/Fort_Nelson',
    name: 'America/Fort Nelson',
  },
  {
    value: 'America/Fortaleza',
    name: 'America/Fortaleza',
  },
  {
    value: 'America/Glace_Bay',
    name: 'America/Glace Bay',
  },
  {
    value: 'America/Goose_Bay',
    name: 'America/Goose Bay',
  },
  {
    value: 'America/Grand_Turk',
    name: 'America/Grand Turk',
  },
  {
    value: 'America/Guatemala',
    name: 'America/Guatemala',
  },
  {
    value: 'America/Guayaquil',
    name: 'America/Guayaquil',
  },
  {
    value: 'America/Guyana',
    name: 'America/Guyana',
  },
  {
    value: 'America/Halifax',
    name: 'America/Halifax',
  },
  {
    value: 'America/Havana',
    name: 'America/Havana',
  },
  {
    value: 'America/Hermosillo',
    name: 'America/Hermosillo',
  },
  {
    value: 'America/Indiana/Knox',
    name: 'America/Indiana/Knox',
  },
  {
    value: 'America/Indiana/Marengo',
    name: 'America/Indiana/Marengo',
  },
  {
    value: 'America/Indiana/Petersburg',
    name: 'America/Indiana/Petersburg',
  },
  {
    value: 'America/Indiana/Tell_City',
    name: 'America/Indiana/Tell City',
  },
  {
    value: 'America/Indiana/Vevay',
    name: 'America/Indiana/Vevay',
  },
  {
    value: 'America/Indiana/Vincennes',
    name: 'America/Indiana/Vincennes',
  },
  {
    value: 'America/Indiana/Winamac',
    name: 'America/Indiana/Winamac',
  },
  {
    value: 'America/Inuvik',
    name: 'America/Inuvik',
  },
  {
    value: 'America/Iqaluit',
    name: 'America/Iqaluit',
  },
  {
    value: 'America/Jamaica',
    name: 'America/Jamaica',
  },
  {
    value: 'America/Juneau',
    name: 'America/Juneau',
  },
  {
    value: 'America/Kentucky/Monticello',
    name: 'America/Kentucky/Monticello',
  },
  {
    value: 'America/La_Paz',
    name: 'America/La Paz',
  },
  {
    value: 'America/Lima',
    name: 'America/Lima',
  },
  {
    value: 'America/Maceio',
    name: 'America/Maceio',
  },
  {
    value: 'America/Managua',
    name: 'America/Managua',
  },
  {
    value: 'America/Manaus',
    name: 'America/Manaus',
  },
  {
    value: 'America/Martinique',
    name: 'America/Martinique',
  },
  {
    value: 'America/Matamoros',
    name: 'America/Matamoros',
  },
  {
    value: 'America/Mazatlan',
    name: 'America/Mazatlan',
  },
  {
    value: 'America/Menominee',
    name: 'America/Menominee',
  },
  {
    value: 'America/Merida',
    name: 'America/Merida',
  },
  {
    value: 'America/Metlakatla',
    name: 'America/Metlakatla',
  },
  {
    value: 'America/Miquelon',
    name: 'America/Miquelon',
  },
  {
    value: 'America/Moncton',
    name: 'America/Moncton',
  },
  {
    value: 'America/Monterrey',
    name: 'America/Monterrey',
  },
  {
    value: 'America/Montevideo',
    name: 'America/Montevideo',
  },
  {
    value: 'America/Nassau',
    name: 'America/Nassau',
  },
  {
    value: 'America/Nipigon',
    name: 'America/Nipigon',
  },
  {
    value: 'America/Nome',
    name: 'America/Nome',
  },
  {
    value: 'America/Noronha',
    name: 'America/Noronha',
  },
  {
    value: 'America/North_Dakota/Beulah',
    name: 'America/North Dakota/Beulah',
  },
  {
    value: 'America/North_Dakota/Center',
    name: 'America/North Dakota/Center',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    name: 'America/North Dakota/New Salem',
  },
  {
    value: 'America/Nuuk',
    name: 'America/Nuuk',
  },
  {
    value: 'America/Ojinaga',
    name: 'America/Ojinaga',
  },
  {
    value: 'America/Panama',
    name: 'America/Panama',
  },
  {
    value: 'America/Pangnirtung',
    name: 'America/Pangnirtung',
  },
  {
    value: 'America/Paramaribo',
    name: 'America/Paramaribo',
  },
  {
    value: 'America/Port_of_Spain',
    name: 'America/Port of Spain',
  },
  {
    value: 'America/Port-au-Prince',
    name: 'America/Port-au-Prince',
  },
  {
    value: 'America/Porto_Velho',
    name: 'America/Porto Velho',
  },
  {
    value: 'America/Puerto_Rico',
    name: 'America/Puerto Rico',
  },
  {
    value: 'America/Punta_Arenas',
    name: 'America/Punta Arenas',
  },
  {
    value: 'America/Rainy_River',
    name: 'America/Rainy River',
  },
  {
    value: 'America/Rankin_Inlet',
    name: 'America/Rankin Inlet',
  },
  {
    value: 'America/Recife',
    name: 'America/Recife',
  },
  {
    value: 'America/Regina',
    name: 'America/Regina',
  },
  {
    value: 'America/Resolute',
    name: 'America/Resolute',
  },
  {
    value: 'America/Rio_Branco',
    name: 'America/Rio Branco',
  },
  {
    value: 'America/Santarem',
    name: 'America/Santarem',
  },
  {
    value: 'America/Santiago',
    name: 'America/Santiago',
  },
  {
    value: 'America/Santo_Domingo',
    name: 'America/Santo Domingo',
  },
  {
    value: 'America/Sao_Paulo',
    name: 'America/Sao Paulo',
  },
  {
    value: 'America/Scoresbysund',
    name: 'America/Scoresbysund',
  },
  {
    value: 'America/Sitka',
    name: 'America/Sitka',
  },
  {
    value: 'America/St_Johns',
    name: 'America/St Johns',
  },
  {
    value: 'America/Swift_Current',
    name: 'America/Swift Current',
  },
  {
    value: 'America/Tegucigalpa',
    name: 'America/Tegucigalpa',
  },
  {
    value: 'America/Thule',
    name: 'America/Thule',
  },
  {
    value: 'America/Thunder_Bay',
    name: 'America/Thunder Bay',
  },
  {
    value: 'America/Tijuana',
    name: 'America/Tijuana',
  },
  {
    value: 'America/Toronto',
    name: 'America/Toronto',
  },
  {
    value: 'America/Vancouver',
    name: 'America/Vancouver',
  },
  {
    value: 'America/Whitehorse',
    name: 'America/Whitehorse',
  },
  {
    value: 'America/Winnipeg',
    name: 'America/Winnipeg',
  },
  {
    value: 'America/Yakutat',
    name: 'America/Yakutat',
  },
  {
    value: 'America/Yellowknife',
    name: 'America/Yellowknife',
  },
  {
    value: 'Antarctica/Casey',
    name: 'Antarctica/Casey',
  },
  {
    value: 'Antarctica/Davis',
    name: 'Antarctica/Davis',
  },
  {
    value: 'Antarctica/DumontDUrville',
    name: 'Antarctica/DumontDUrville',
  },
  {
    value: 'Antarctica/Macquarie',
    name: 'Antarctica/Macquarie',
  },
  {
    value: 'Antarctica/Mawson',
    name: 'Antarctica/Mawson',
  },
  {
    value: 'Antarctica/Palmer',
    name: 'Antarctica/Palmer',
  },
  {
    value: 'Antarctica/Rothera',
    name: 'Antarctica/Rothera',
  },
  {
    value: 'Antarctica/Syowa',
    name: 'Antarctica/Syowa',
  },
  {
    value: 'Antarctica/Troll',
    name: 'Antarctica/Troll',
  },
  {
    value: 'Antarctica/Vostok',
    name: 'Antarctica/Vostok',
  },
  {
    value: 'Asia/Almaty',
    name: 'Asia/Almaty',
  },
  {
    value: 'Asia/Amman',
    name: 'Asia/Amman',
  },
  {
    value: 'Asia/Anadyr',
    name: 'Asia/Anadyr',
  },
  {
    value: 'Asia/Aqtau',
    name: 'Asia/Aqtau',
  },
  {
    value: 'Asia/Aqtobe',
    name: 'Asia/Aqtobe',
  },
  {
    value: 'Asia/Ashgabat',
    name: 'Asia/Ashgabat',
  },
  {
    value: 'Asia/Atyrau',
    name: 'Asia/Atyrau',
  },
  {
    value: 'Asia/Baghdad',
    name: 'Asia/Baghdad',
  },
  {
    value: 'Asia/Baku',
    name: 'Asia/Baku',
  },
  {
    value: 'Asia/Bangkok',
    name: 'Asia/Bangkok',
  },
  {
    value: 'Asia/Barnaul',
    name: 'Asia/Barnaul',
  },
  {
    value: 'Asia/Beirut',
    name: 'Asia/Beirut',
  },
  {
    value: 'Asia/Bishkek',
    name: 'Asia/Bishkek',
  },
  {
    value: 'Asia/Brunei',
    name: 'Asia/Brunei',
  },
  {
    value: 'Asia/Chita',
    name: 'Asia/Chita',
  },
  {
    value: 'Asia/Choibalsan',
    name: 'Asia/Choibalsan',
  },
  {
    value: 'Asia/Colombo',
    name: 'Asia/Colombo',
  },
  {
    value: 'Asia/Damascus',
    name: 'Asia/Damascus',
  },
  {
    value: 'Asia/Dhaka',
    name: 'Asia/Dhaka',
  },
  {
    value: 'Asia/Dili',
    name: 'Asia/Dili',
  },
  {
    value: 'Asia/Dubai',
    name: 'Asia/Dubai',
  },
  {
    value: 'Asia/Dushanbe',
    name: 'Asia/Dushanbe',
  },
  {
    value: 'Asia/Famagusta',
    name: 'Asia/Famagusta',
  },
  {
    value: 'Asia/Gaza',
    name: 'Asia/Gaza',
  },
  {
    value: 'Asia/Hebron',
    name: 'Asia/Hebron',
  },
  {
    value: 'Asia/Ho_Chi_Minh',
    name: 'Asia/Ho Chi Minh',
  },
  {
    value: 'Asia/Hovd',
    name: 'Asia/Hovd',
  },
  {
    value: 'Asia/Irkutsk',
    name: 'Asia/Irkutsk',
  },
  {
    value: 'Asia/Jakarta',
    name: 'Asia/Jakarta',
  },
  {
    value: 'Asia/Jayapura',
    name: 'Asia/Jayapura',
  },
  {
    value: 'Asia/Jerusalem',
    name: 'Asia/Jerusalem',
  },
  {
    value: 'Asia/Kabul',
    name: 'Asia/Kabul',
  },
  {
    value: 'Asia/Kamchatka',
    name: 'Asia/Kamchatka',
  },
  {
    value: 'Asia/Karachi',
    name: 'Asia/Karachi',
  },
  {
    value: 'Asia/Kathmandu',
    name: 'Asia/Kathmandu',
  },
  {
    value: 'Asia/Khandyga',
    name: 'Asia/Khandyga',
  },
  {
    value: 'Asia/Kolkata',
    name: 'Asia/Kolkata',
  },
  {
    value: 'Asia/Krasnoyarsk',
    name: 'Asia/Krasnoyarsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    name: 'Asia/Kuala Lumpur',
  },
  {
    value: 'Asia/Kuching',
    name: 'Asia/Kuching',
  },
  {
    value: 'Asia/Macau',
    name: 'Asia/Macau',
  },
  {
    value: 'Asia/Magadan',
    name: 'Asia/Magadan',
  },
  {
    value: 'Asia/Makassar',
    name: 'Asia/Makassar',
  },
  {
    value: 'Asia/Manila',
    name: 'Asia/Manila',
  },
  {
    value: 'Asia/Nicosia',
    name: 'Asia/Nicosia',
  },
  {
    value: 'Asia/Novokuznetsk',
    name: 'Asia/Novokuznetsk',
  },
  {
    value: 'Asia/Novosibirsk',
    name: 'Asia/Novosibirsk',
  },
  {
    value: 'Asia/Omsk',
    name: 'Asia/Omsk',
  },
  {
    value: 'Asia/Oral',
    name: 'Asia/Oral',
  },
  {
    value: 'Asia/Pontianak',
    name: 'Asia/Pontianak',
  },
  {
    value: 'Asia/Pyongyang',
    name: 'Asia/Pyongyang',
  },
  {
    value: 'Asia/Qatar',
    name: 'Asia/Qatar',
  },
  {
    value: 'Asia/Qostanay',
    name: 'Asia/Qostanay',
  },
  {
    value: 'Asia/Qyzylorda',
    name: 'Asia/Qyzylorda',
  },
  {
    value: 'Asia/Riyadh',
    name: 'Asia/Riyadh',
  },
  {
    value: 'Asia/Sakhalin',
    name: 'Asia/Sakhalin',
  },
  {
    value: 'Asia/Samarkand',
    name: 'Asia/Samarkand',
  },
  {
    value: 'Asia/Shanghai',
    name: 'Asia/Shanghai',
  },
  {
    value: 'Asia/Srednekolymsk',
    name: 'Asia/Srednekolymsk',
  },
  {
    value: 'Asia/Taipei',
    name: 'Asia/Taipei',
  },
  {
    value: 'Asia/Tashkent',
    name: 'Asia/Tashkent',
  },
  {
    value: 'Asia/Tbilisi',
    name: 'Asia/Tbilisi',
  },
  {
    value: 'Asia/Tehran',
    name: 'Asia/Tehran',
  },
  {
    value: 'Asia/Thimphu',
    name: 'Asia/Thimphu',
  },
  {
    value: 'Asia/Tokyo',
    name: 'Asia/Tokyo',
  },
  {
    value: 'Asia/Tomsk',
    name: 'Asia/Tomsk',
  },
  {
    value: 'Asia/Ulaanbaatar',
    name: 'Asia/Ulaanbaatar',
  },
  {
    value: 'Asia/Urumqi',
    name: 'Asia/Urumqi',
  },
  {
    value: 'Asia/Ust-Nera',
    name: 'Asia/Ust-Nera',
  },
  {
    value: 'Asia/Vladivostok',
    name: 'Asia/Vladivostok',
  },
  {
    value: 'Asia/Yakutsk',
    name: 'Asia/Yakutsk',
  },
  {
    value: 'Asia/Yangon',
    name: 'Asia/Yangon',
  },
  {
    value: 'Asia/Yekaterinburg',
    name: 'Asia/Yekaterinburg',
  },
  {
    value: 'Asia/Yerevan',
    name: 'Asia/Yerevan',
  },
  {
    value: 'Atlantic/Azores',
    name: 'Atlantic/Azores',
  },
  {
    value: 'Atlantic/Bermuda',
    name: 'Atlantic/Bermuda',
  },
  {
    value: 'Atlantic/Canary',
    name: 'Atlantic/Canary',
  },
  {
    value: 'Atlantic/Cape_Verde',
    name: 'Atlantic/Cape Verde',
  },
  {
    value: 'Atlantic/Faroe',
    name: 'Atlantic/Faroe',
  },
  {
    value: 'Atlantic/Madeira',
    name: 'Atlantic/Madeira',
  },
  {
    value: 'Atlantic/Reykjavik',
    name: 'Atlantic/Reykjavik',
  },
  {
    value: 'Atlantic/South_Georgia',
    name: 'Atlantic/South Georgia',
  },
  {
    value: 'Atlantic/Stanley',
    name: 'Atlantic/Stanley',
  },
  {
    value: 'Australia/Adelaide',
    name: 'Australia/Adelaide',
  },
  {
    value: 'Australia/Brisbane',
    name: 'Australia/Brisbane',
  },
  {
    value: 'Australia/Broken_Hill',
    name: 'Australia/Broken Hill',
  },
  {
    value: 'Australia/Darwin',
    name: 'Australia/Darwin',
  },
  {
    value: 'Australia/Eucla',
    name: 'Australia/Eucla',
  },
  {
    value: 'Australia/Hobart',
    name: 'Australia/Hobart',
  },
  {
    value: 'Australia/Lindeman',
    name: 'Australia/Lindeman',
  },
  {
    value: 'Australia/Lord_Howe',
    name: 'Australia/Lord Howe',
  },
  {
    value: 'Australia/Melbourne',
    name: 'Australia/Melbourne',
  },
  {
    value: 'Australia/Perth',
    name: 'Australia/Perth',
  },
  {
    value: 'Australia/Sydney',
    name: 'Australia/Sydney',
  },
  {
    value: 'Europe/Andorra',
    name: 'Europe/Andorra',
  },
  {
    value: 'Europe/Astrakhan',
    name: 'Europe/Astrakhan',
  },
  {
    value: 'Europe/Athens',
    name: 'Europe/Athens',
  },
  {
    value: 'Europe/Belgrade',
    name: 'Europe/Belgrade',
  },
  {
    value: 'Europe/Brussels',
    name: 'Europe/Brussels',
  },
  {
    value: 'Europe/Bucharest',
    name: 'Europe/Bucharest',
  },
  {
    value: 'Europe/Budapest',
    name: 'Europe/Budapest',
  },
  {
    value: 'Europe/Chisinau',
    name: 'Europe/Chisinau',
  },
  {
    value: 'Europe/Copenhagen',
    name: 'Europe/Copenhagen',
  },
  {
    value: 'Europe/Dublin',
    name: 'Europe/Dublin',
  },
  {
    value: 'Europe/Gibraltar',
    name: 'Europe/Gibraltar',
  },
  {
    value: 'Europe/Helsinki',
    name: 'Europe/Helsinki',
  },
  {
    value: 'Europe/Istanbul',
    name: 'Europe/Istanbul',
  },
  {
    value: 'Europe/Kaliningrad',
    name: 'Europe/Kaliningrad',
  },
  {
    value: 'Europe/Kiev',
    name: 'Europe/Kiev',
  },
  {
    value: 'Europe/Kirov',
    name: 'Europe/Kirov',
  },
  {
    value: 'Europe/Lisbon',
    name: 'Europe/Lisbon',
  },
  {
    value: 'Europe/Luxembourg',
    name: 'Europe/Luxembourg',
  },
  {
    value: 'Europe/Malta',
    name: 'Europe/Malta',
  },
  {
    value: 'Europe/Minsk',
    name: 'Europe/Minsk',
  },
  {
    value: 'Europe/Monaco',
    name: 'Europe/Monaco',
  },
  {
    value: 'Europe/Moscow',
    name: 'Europe/Moscow',
  },
  {
    value: 'Europe/Oslo',
    name: 'Europe/Oslo',
  },
  {
    value: 'Europe/Prague',
    name: 'Europe/Prague',
  },
  {
    value: 'Europe/Riga',
    name: 'Europe/Riga',
  },
  {
    value: 'Europe/Rome',
    name: 'Europe/Rome',
  },
  {
    value: 'Europe/Samara',
    name: 'Europe/Samara',
  },
  {
    value: 'Europe/Saratov',
    name: 'Europe/Saratov',
  },
  {
    value: 'Europe/Simferopol',
    name: 'Europe/Simferopol',
  },
  {
    value: 'Europe/Sofia',
    name: 'Europe/Sofia',
  },
  {
    value: 'Europe/Stockholm',
    name: 'Europe/Stockholm',
  },
  {
    value: 'Europe/Tallinn',
    name: 'Europe/Tallinn',
  },
  {
    value: 'Europe/Tirane',
    name: 'Europe/Tirane',
  },
  {
    value: 'Europe/Ulyanovsk',
    name: 'Europe/Ulyanovsk',
  },
  {
    value: 'Europe/Uzhgorod',
    name: 'Europe/Uzhgorod',
  },
  {
    value: 'Europe/Vienna',
    name: 'Europe/Vienna',
  },
  {
    value: 'Europe/Vilnius',
    name: 'Europe/Vilnius',
  },
  {
    value: 'Europe/Volgograd',
    name: 'Europe/Volgograd',
  },
  {
    value: 'Europe/Warsaw',
    name: 'Europe/Warsaw',
  },
  {
    value: 'Europe/Zaporozhye',
    name: 'Europe/Zaporozhye',
  },
  {
    value: 'Europe/Zurich',
    name: 'Europe/Zurich',
  },
  {
    value: 'Indian/Chagos',
    name: 'Indian/Chagos',
  },
  {
    value: 'Indian/Christmas',
    name: 'Indian/Christmas',
  },
  {
    value: 'Indian/Cocos',
    name: 'Indian/Cocos',
  },
  {
    value: 'Indian/Kerguelen',
    name: 'Indian/Kerguelen',
  },
  {
    value: 'Indian/Mahe',
    name: 'Indian/Mahe',
  },
  {
    value: 'Indian/Maldives',
    name: 'Indian/Maldives',
  },
  {
    value: 'Indian/Mauritius',
    name: 'Indian/Mauritius',
  },
  {
    value: 'Indian/Reunion',
    name: 'Indian/Reunion',
  },
  {
    value: 'Pacific/Apia',
    name: 'Pacific/Apia',
  },
  {
    value: 'Pacific/Auckland',
    name: 'Pacific/Auckland',
  },
  {
    value: 'Pacific/Bougainville',
    name: 'Pacific/Bougainville',
  },
  {
    value: 'Pacific/Chatham',
    name: 'Pacific/Chatham',
  },
  {
    value: 'Pacific/Chuuk',
    name: 'Pacific/Chuuk',
  },
  {
    value: 'Pacific/Easter',
    name: 'Pacific/Easter',
  },
  {
    value: 'Pacific/Efate',
    name: 'Pacific/Efate',
  },
  {
    value: 'Pacific/Enderbury',
    name: 'Pacific/Enderbury',
  },
  {
    value: 'Pacific/Fakaofo',
    name: 'Pacific/Fakaofo',
  },
  {
    value: 'Pacific/Fiji',
    name: 'Pacific/Fiji',
  },
  {
    value: 'Pacific/Funafuti',
    name: 'Pacific/Funafuti',
  },
  {
    value: 'Pacific/Galapagos',
    name: 'Pacific/Galapagos',
  },
  {
    value: 'Pacific/Gambier',
    name: 'Pacific/Gambier',
  },
  {
    value: 'Pacific/Guadalcanal',
    name: 'Pacific/Guadalcanal',
  },
  {
    value: 'Pacific/Guam',
    name: 'Pacific/Guam',
  },
  {
    value: 'Pacific/Honolulu',
    name: 'Pacific/Honolulu',
  },
  {
    value: 'Pacific/Kiritimati',
    name: 'Pacific/Kiritimati',
  },
  {
    value: 'Pacific/Kosrae',
    name: 'Pacific/Kosrae',
  },
  {
    value: 'Pacific/Kwajalein',
    name: 'Pacific/Kwajalein',
  },
  {
    value: 'Pacific/Majuro',
    name: 'Pacific/Majuro',
  },
  {
    value: 'Pacific/Marquesas',
    name: 'Pacific/Marquesas',
  },
  {
    value: 'Pacific/Nauru',
    name: 'Pacific/Nauru',
  },
  {
    value: 'Pacific/Niue',
    name: 'Pacific/Niue',
  },
  {
    value: 'Pacific/Norfolk',
    name: 'Pacific/Norfolk',
  },
  {
    value: 'Pacific/Noumea',
    name: 'Pacific/Noumea',
  },
  {
    value: 'Pacific/Pago_Pago',
    name: 'Pacific/Pago Pago',
  },
  {
    value: 'Pacific/Palau',
    name: 'Pacific/Palau',
  },
  {
    value: 'Pacific/Pitcairn',
    name: 'Pacific/Pitcairn',
  },
  {
    value: 'Pacific/Pohnpei',
    name: 'Pacific/Pohnpei',
  },
  {
    value: 'Pacific/Port_Moresby',
    name: 'Pacific/Port Moresby',
  },
  {
    value: 'Pacific/Rarotonga',
    name: 'Pacific/Rarotonga',
  },
  {
    value: 'Pacific/Tahiti',
    name: 'Pacific/Tahiti',
  },
  {
    value: 'Pacific/Tarawa',
    name: 'Pacific/Tarawa',
  },
  {
    value: 'Pacific/Tongatapu',
    name: 'Pacific/Tongatapu',
  },
  {
    value: 'Pacific/Wake',
    name: 'Pacific/Wake',
  },
  {
    value: 'Pacific/Wallis',
    name: 'Pacific/Wallis',
  },
  {
    value: 'PST8PDT',
    name: 'PST8PDT',
  },
];
