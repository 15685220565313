import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import Button from './Button';
import SelectInput from './SelectInput/SelectInput';

export const FormSelectWButton = ({
  options,
  name,
  buttonName,
  value,
  error,
  valid,
  onClick,
  isDisabled,
  ...rest
}) => (
  <Box
    height={38}
    sx={{
      width: '100%',
      display: 'flex',
    }}
  >
    <SelectInput
      key={name}
      options={options}
      valid={valid}
      error={error}
      isDisabled={isDisabled}
      light
      value={value}
      {...rest}
    />
    <FieldButton
      onClick={onClick}
    >
      {buttonName}
    </FieldButton>
  </Box>
);

const FieldButton = styled(Button)`
  min-width: 190px;
  height: 36px;
  bottom: 17px;
  margin-left: 20px;
`;
