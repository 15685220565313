import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';

export const MultiCheckbox = ({
  name,
  groupLabel,
  input,
  meta,
  checkboxes,
  onChange,
  orientation,
  labelPlacement,
}) => {
  const [checkboxesState, setCheckboxesState] = useState({});
  useEffect(() => {
    const checkboxesObj = {};
    for (const checkbox of checkboxes) {
      // set initial value
      const checkboxInValue = input.value?.find((val) => val === checkbox.value);
      if (checkboxInValue) {
        checkboxesObj[checkbox.value] = ({
          ...checkbox,
          checked: true,
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      checkboxesObj[checkbox.value] = ({
        ...checkbox,
        checked: false,
      });
    }
    setCheckboxesState(checkboxesObj);
  }, [input.value]);

  const handleOnCheckboxChange = (checkboxValue) => {
    const newCheckboxesState = { ...checkboxesState };
    newCheckboxesState[checkboxValue].checked = !newCheckboxesState[checkboxValue].checked;
    const keys = Object.keys(newCheckboxesState);
    const onChangeArray = [];
    for (const key of keys) {
      if (newCheckboxesState[key].checked) onChangeArray.push(newCheckboxesState[key].value);
    }
    onChange(onChangeArray);
  };

  const checkboxComponents = useMemo(() => {
    const renderCheckbox = (checkbox, index) => (
      <FormControlLabel
        key={checkbox.value + index}
        {...input}
        control={<Checkbox />}
        label={checkbox.label}
        checked={checkboxesState[checkbox.value]?.checked}
        value={checkboxesState[checkbox.value]?.checked}
        disabled={checkbox.isDisabled}
        readOnly={checkbox.readOnly}
        onChange={() => handleOnCheckboxChange(checkbox.value)}
        labelPlacement={labelPlacement}
        sx={orientation !== 'row' ? {
          height: '56px',
          marginLeft: '0px',
          marginBottom: '0px',
          '&:not(:first-of-type)': {
            marginTop: '16px',
          },
        } : {}}
      />
    );
    return checkboxes?.map(renderCheckbox);
  }, [checkboxesState]);

  return (
    <Box key={name}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={meta.error && meta.touched}
        fullWidth
      >
        {groupLabel ? (
          <FormLabel component="legend">{groupLabel}</FormLabel>
        ) : null}
        <FormGroup row={orientation === 'row'}>
          {Object.entries(checkboxesState)?.length !== 0 ? (
            checkboxComponents
          ) : null}
        </FormGroup>
        {meta.error && meta.touched ? (
          <FormHelperText>{meta.error}</FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};
