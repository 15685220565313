export const truncate = (value, length) => {
  const dots = value.length > length ? '...' : '';
  return `${value.substring(0, length - dots.length)}${dots}`;
};

export const trimExtension = (value) => value.replace(/\.[^/.]+$/, '');

export const getExtension = (value) => value.substring(value.lastIndexOf('.'), value.length) || value;

export const camelToSnakeCase = (stringToConvert) => (stringToConvert[0].toLowerCase()
        + stringToConvert
          .slice(1, stringToConvert.length)
          .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`));

export default { truncate, trimExtension, getExtension };
