/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext } from 'react';
import { Card, CardActions, CardContent, Collapse, Grid, Typography, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { ExpandMore } from '../../MUIStyledComponents/Generic/generic';
import { HeaderElements } from './GenericElements/HeaderElements';
import { trackCurrentUserGql } from './gql';

function WidgetWrapper({ widget, children }) {
  const platformStore = useContext(PlatformContext);
  const selectedClientCode = platformStore.currentClient?.code;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const appPlatforms = platformStore?.selectedApplication?.appPlatform;
  const isIos = appPlatforms?.some((platform) => platform?.platform === 'ios');
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [trackCurrentUser] = useMutation(trackCurrentUserGql);
  const location = useLocation();

  const collapsableStorage = widget?.params?.collapsableStorageKey
    ? JSON.parse(localStorage.getItem(widget?.params?.collapsableStorageKey) || 'true') : null;
  const [isExpanded, setIsExpanded] = useState(widget?.params?.collapsableStorageKey
    ? collapsableStorage : true);
  useEffect(() => {
    if (!widget?.params?.collapsableStorageKey) return;
    localStorage.setItem(widget?.params?.collapsableStorageKey, JSON.stringify(isExpanded));
  }, [isExpanded]);

  const [modelType, setModelType] = useState(isIos ? 'SKAN' : 'TotalRev');

  const formattedTitle = widget?.title?.replace(/\s+/g, '-');

  const loadValuesFromStorage = () => {
    const key = `hubWidgetModel_${formattedTitle}_${selectedClientCode}_${selectedApplicationCode}`;
    const val = window.sessionStorage.getItem(key) || null;
    if (val === null) {
      setModelType(isIos ? 'SKAN' : 'TotalRev');
      return;
    }
    setModelType(JSON.parse(val));
  };

  useEffect(loadValuesFromStorage, [selectedApplicationCode]);

  useEffect(() => {
    const key = `hubWidgetModel_${formattedTitle}_${selectedClientCode}_${selectedApplicationCode}`;
    window.sessionStorage.setItem(key, JSON.stringify(modelType));
  }, [modelType]);

  const onModelTypeChanged = (modelType) => {
    setModelType(modelType);
    trackCurrentUser({
      variables: {
        clientCode: selectedClientCode,
        applicationCode: selectedApplicationCode,
        trackingInfo: {
          action: 'modelTypeSwitch',
          url: location.pathname,
          product: 'hub',
          featureSet: 'widget',
          feature: widget.title,
          entityCode: modelType,
        },
      },
    });
  };

  const sx = { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } };
  if (widget.widthXs === 0) sx.display.xs = 'none';
  if (widget.widthSm === 0) sx.display.sm = 'none';
  if (widget.widthMd === 0) sx.display.md = 'none';
  if (widget.widthLg === 0) sx.display.lg = 'none';
  if (widget.widthXl === 0) sx.display.xl = 'none';

  return (
    <Grid
      item
      xs={widget.widthXs || undefined}
      sm={widget.widthSm || undefined}
      md={widget.widthMd || undefined}
      lg={widget.widthLg || undefined}
      xl={widget.widthXl || undefined}
      sx={sx}
    >
      <Card
        sx={{ width: '100%' }}
      >

        <CardActions
          onClick={widget?.params?.collapsable && (() => setIsExpanded((isExpanded) => !isExpanded))}
          sx={widget?.params?.collapsable ? {
            padding: '8px 8px 0px 8px',
            '&:hover': {
              cursor: 'pointer',
            },
          }
            : {
              padding: '8px 8px 0px 8px',
            }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexWrap="nowrap"
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                alignItems="center"
                spacing={1}
                flexWrap={!smDown ? 'nowrap' : 'wrap'}
              >
                <Grid
                  item
                  xs={widget?.params?.headerElements && !smDown ? 6 : 12}
                  height={56}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    align="left"
                  >
                    {widget.title}
                  </Typography>
                </Grid>
                {widget?.params?.headerElements ? (
                  <HeaderElements
                    widget={widget}
                    modelType={modelType}
                    setModelType={onModelTypeChanged}
                    isIos={isIos}
                  />
                ) : null}
              </Grid>
            </Grid>
            {widget?.params?.collapsable && (
              <Grid
                item
                xs
              >
                <Grid
                  container
                  justifyContent="flex-end"
                  flexWrap="nowrap"
                >
                  <Grid
                    item
                  >
                    <ExpandMore
                      expand={isExpanded}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded((isExpanded) => !isExpanded);
                      }}
                      aria-expanded={isExpanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardActions>
        {widget?.params?.collapsable ? (
          <Collapse
            in={isExpanded}
            timeout="auto"
          >
            <CardContent
              sx={{
                position: 'relative',
                height: '100%',
                padding: '0px 8px 8px 8px',
                ':last-child': {
                  paddingBottom: '8px',
                },
              }}
            >
              {React.cloneElement(children, { modelType })}
            </CardContent>
          </Collapse>
        ) : (
          <CardContent
            sx={{
              position: 'relative',
              height: '100%',
              padding: '0px 8px 8px 8px',
              ':last-child': {
                paddingBottom: '8px',
              },
            }}
          >
            {React.cloneElement(children, { modelType })}
          </CardContent>
        )}
      </Card>
    </Grid>
  );
}

export default observer(WidgetWrapper);
