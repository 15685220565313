import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FilterGroup from './FilterGroup';
import QueryBuilderContext from './QueryBuilderContext';
import {
  formatFilterArrayToObj,
  formatFilterObjToArray,
  isGroupValid,
} from './utils';

const QueryBuilder = (props, ref) => {
  const {
    availableCombinators = ['and', 'or'],
    availableFields = [],
    initialFilters,
    filters,
    maxLevels = 5,
    onChange = () => {},
    onSearch = () => {},
    onReset = () => {},
    onValidStateChange = () => {},
    hideSearchButton = false,
    showError = false,
    enableKeyboardSearch = false,
  } = props;

  const { t } = useTranslation('ui', { keyPrefix: 'queryBuilder' });
  const [currentFilters, setCurrentFilters] = useState([]);
  const [combinator, setCombinator] = useState('and');
  const [isValid, setIsValid] = useState(true);

  const triggerReset = () => {
    const { combinator, filters } = formatFilterObjToArray(initialFilters, availableFields);
    setCurrentFilters(filters);
    setCombinator(combinator);
    const isValid = isGroupValid(filters);
    setIsValid(isValid);
    if (isValid) onChange(formatFilterArrayToObj(filters, combinator));
    onReset();
  };

  useEffect(() => onValidStateChange(isValid), [isValid]);

  useImperativeHandle(ref, () => ({
    reset: triggerReset,
    isValid: () => isValid,
  }));

  useEffect(() => {
    if (!initialFilters || !Object.keys(initialFilters).length) return;
    const { combinator, filters } = formatFilterObjToArray(initialFilters, availableFields);
    setCurrentFilters(filters);
    setCombinator(combinator);
  }, [initialFilters, availableFields]);

  useEffect(() => {
    if (!filters || !Object.keys(initialFilters).length) return;
    const { combinator, filters: formattedFilters } = formatFilterObjToArray(filters, availableFields);
    setCurrentFilters(formattedFilters);
    setCombinator(combinator);
  }, [filters, availableFields]);

  const onNodeChange = (newFilter, index) => {
    setCurrentFilters((prevValue) => {
      const filters = [...prevValue];
      filters[index] = { ...filters[index], ...newFilter };

      const isValid = isGroupValid(filters);

      setIsValid(isValid);
      if (isValid) onChange(formatFilterArrayToObj(filters, combinator));

      return filters;
    });
  };

  const onFilterAdd = () => {
    const firstField = availableFields[0];
    setCurrentFilters((prevValue) => {
      const newValue = [
        ...prevValue,
        { type: 'field', field: { ...firstField }, operator: firstField?.operators?.[0], value: null },
      ];

      const isValid = isGroupValid(newValue);
      setIsValid(isValid);
      if (isValid) onChange(formatFilterArrayToObj(newValue, combinator));

      return newValue;
    });
  };

  const onGroupAdd = () => {
    const firstField = availableFields[0];
    setCurrentFilters((prevValue) => {
      const newValue = [
        ...prevValue,
        {
          type: 'group',
          combinator: 'and',
          filters: [{ type: 'field', field: { ...firstField }, operator: firstField?.operators?.[0], value: null }],
          isValid: false,
        },
      ];

      const isValid = isGroupValid(newValue);
      setIsValid(isValid);
      if (isValid) onChange(formatFilterArrayToObj(newValue, combinator));
      return newValue;
    });
  };

  const onNodeRemove = (index) => {
    setCurrentFilters((prevValue) => {
      const filter = [...prevValue];
      filter.splice(index, 1);

      const isValid = isGroupValid(filter);
      setIsValid(isValid);
      if (isValid) onChange(formatFilterArrayToObj(filter, combinator));

      return filter;
    });
  };

  const onCombinatorChange = (newCombinator) => {
    const isValid = isGroupValid(currentFilters);
    setIsValid(isValid);
    if (isValid) onChange(formatFilterArrayToObj(currentFilters, newCombinator));

    setCombinator(newCombinator);
  };

  useEffect(() => {
    const listener = (event) => {
      // Block Unless Section is Expanded, and Button is Enabled
      if ((event.code === 'Enter' || event.code === 'NumpadEnter') && enableKeyboardSearch && isValid) {
        event.preventDefault();
        onSearch(formatFilterArrayToObj(currentFilters, combinator));
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // Must have state in dependancy array or function will execute with version of state on load of component
  }, [combinator, currentFilters, enableKeyboardSearch, isValid]);

  return (
    <QueryBuilderContext.Provider value={{ showError }}>
      <FilterGroup
        level={0}
        combinator={combinator}
        maxLevels={maxLevels}
        availableFields={availableFields}
        availableCombinators={availableCombinators}
        filters={currentFilters}
        onFilterAdd={onFilterAdd}
        onGroupAdd={onGroupAdd}
        onNodeChange={onNodeChange}
        onNodeRemove={onNodeRemove}
        onCombinatorChange={onCombinatorChange}
      />
      <Grid
        item
        justifyContent="flex-end"
        xs={12}
        marginTop={2}
      >
        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              onClick={triggerReset}
              variant="contained"
            >
              {t('reset')}
            </Button>
          </Grid>
          {!hideSearchButton && (
          <Grid item>
            <Button
              onClick={() => onSearch(formatFilterArrayToObj(currentFilters, combinator))}
              disabled={!isValid}
              variant="contained"
              type="submit"
            >
              {t('search')}
            </Button>
          </Grid>
          )}

        </Grid>
      </Grid>
    </QueryBuilderContext.Provider>
  );
};

export default forwardRef(QueryBuilder);
