import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { styled } from '@mui/system';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import FormError from 'components/forms/FormError';
import { DualListOptionComponent } from './DualListOptionComponent';
import { DraggableDLOptionComponent } from './DraggableDLOptionComponent';

const DualList = ({
  options,
  selected,
  valueField = 'value',
  labelField = 'label',
  onChange,
  error,
  reversed,
  optionsLabel,
  selectedLabel,
  selectedReorderable = false,
  height = 200,
  sortSelected = true,
  disabled,
  CustomOptionComponent,
}) => {
  const [dragData, setDragData] = useState(null);
  const [selectedRows, setSelectedRows] = useState(selected || []);
  const valid = !error;
  const getValueField = (option) => option[valueField];
  const getLabelField = (option) => option[labelField];
  const sort = (array) => {
    const compare = (a, b) => {
      const labelA = getLabelField(a).toUpperCase();
      const labelB = getLabelField(b).toUpperCase();
      let comparison = 0;
      if (labelA > labelB) {
        comparison = 1;
      } else if (labelA < labelB) {
        comparison = -1;
      }
      return comparison;
    };
    array.sort(compare);
    return array;
  };
  const findDiff = (oriArray, diffArray) => oriArray?.filter((obj) => !diffArray?.some((obj2) => getValueField(obj) === getValueField(obj2)));
  const [selectedMarkedOptions, setSelectedMarkedOptions] = useState([]);
  const [possibleOptionsMarkedOptions, setPossibleOptionsMarkedOptions] = useState([]);
  const [possibleOptions, setPossibleOptions] = useState([]);
  const [optionsSelected, setOptionSelected] = useState([]);
  const addToSelectedMarked = (option) => {
    const isSelectedMarked = (option) => selectedMarkedOptions.find((element) => element === option) !== undefined;
    if (isSelectedMarked(option)) {
      setSelectedMarkedOptions(selectedMarkedOptions.filter((element) => element !== option));
    } else {
      setSelectedMarkedOptions([...selectedMarkedOptions, option]);
    }
  };
  const addToPossibleOptionsMarked = (option) => {
    const isPossibleOptionMarked = (option) => possibleOptionsMarkedOptions.find((element) => element === option) !== undefined;
    if (isPossibleOptionMarked(option)) {
      setPossibleOptionsMarkedOptions(possibleOptionsMarkedOptions.filter((element) => element !== option));
    } else {
      setPossibleOptionsMarkedOptions([...possibleOptionsMarkedOptions, option]);
    }
  };
  const onUnSelectAllOptions = () => {
    setOptionSelected([]);
    setPossibleOptions(sort(options));
    setSelectedMarkedOptions([]);
    if (selectedReorderable) {
      setSelectedRows([]);
      return;
    }
    onChange([]);
  };
  const onUnSelectOptions = () => {
    const idArrayItemsToDelete = selectedMarkedOptions.map((item) => item.id);
    const diff = findDiff(optionsSelected, selectedMarkedOptions);
    setPossibleOptions(sort(uniqBy(possibleOptions.concat(selectedMarkedOptions), valueField)));
    setSelectedMarkedOptions([]);
    if (selectedReorderable) {
      const rowsData = [];
      const rowData = Object.values(dragData.rows);
      const filteredRowData = rowData.filter((row) => !idArrayItemsToDelete.includes(row.id));
      const orderedRows = dragData.rowIds;
      for (const item of orderedRows) {
        const findItem = filteredRowData.filter((row) => row.id === item);
        if (findItem.length !== 0) {
          rowsData.push(findItem[0]);
        }
      }
      setSelectedRows(rowsData);
    }
    if (sortSelected) {
      onChange(sort(uniqBy(diff, valueField)));
      return;
    }
    onChange(uniqBy(diff, valueField));
  };
  const onSelectOptions = () => {
    const diff = findDiff(possibleOptions, possibleOptionsMarkedOptions);
    setPossibleOptions(sort(uniqBy(diff, valueField)));
    setPossibleOptionsMarkedOptions([]);
    if (selectedReorderable) {
      const newRows = uniqBy(optionsSelected.concat(possibleOptionsMarkedOptions), valueField);
      const rowsData = [];
      const rowData = Object.values(dragData.rows);
      const orderedRows = dragData.rowIds;
      for (const item of orderedRows) {
        const findItem = rowData.filter((row) => row.id === item);
        rowsData.push(findItem[0]);
      }
      for (const item of newRows) {
        const valuesObjectBuilder = { id: `row-${rowData.length + 1}`, ...item };
        rowsData.push(valuesObjectBuilder);
      }
      setSelectedRows(rowsData);
      return;
    }
    if (sortSelected) {
      onChange(sort(uniqBy(optionsSelected.concat(possibleOptionsMarkedOptions), valueField)));
      return;
    }
    onChange(uniqBy(optionsSelected.concat(possibleOptionsMarkedOptions), valueField));
  };
  const onSelectAllOptions = () => {
    setPossibleOptions([]);
    setPossibleOptionsMarkedOptions([]);
    if (selectedReorderable) {
      const diff = findDiff(possibleOptions, selectedRows);
      const rowsData = [];
      const rowData = Object.values(dragData.rows);
      const orderedRows = dragData.rowIds;
      for (const item of orderedRows) {
        const findItem = rowData.filter((row) => row.id === item);
        rowsData.push(findItem[0]);
      }
      for (const item of diff) {
        const valuesObjectBuilder = { id: `row-${rowData.length + 1}`, ...item };
        rowsData.push(valuesObjectBuilder);
      }
      setSelectedRows(rowsData);
      return;
    }
    onChange(sort([...options]));
  };

  // onChange for Reorderable
  useEffect(() => {
    if (onChange && dragData) {
      const rowsData = [];
      const rowData = Object.values(dragData.rows);
      const orderedRows = dragData.rowIds;
      for (const item of orderedRows) {
        const findItem = rowData.filter((row) => row.id === item);
        if (findItem.length !== 0) {
          rowsData.push(findItem[0]);
        }
      }
      onChange(rowsData);
    }
  }, [dragData]);

  useEffect(() => {
    if (options) {
      setPossibleOptions(sort([...findDiff(options, selected)]));
    }
  }, [options, selected]);

  useEffect(() => {
    if (selected && !selectedReorderable) {
      if (sortSelected) {
        setOptionSelected(sort([...selected]));
        return;
      }
      setOptionSelected([...selected]);
      return;
    }
    setSelectedRows(selected);
  }, [selected]);

  useEffect(() => {
    const dataObj = {
      rows: {},
      rowIds: [],
    };
    if (selectedRows && selectedReorderable) {
      for (const [index, row] of selectedRows.entries()) {
        const { id } = row;
        if (id === undefined) {
          dataObj.rows[`row-${index}`] = { id: `row-${index}`, ...row };
          dataObj.rowIds.push(`row-${index}`);
        }
        if (id !== undefined) {
          // if ID !== index + 1, reset ID
          if (id !== `row-${index + 1}`) {
            delete dataObj[id];
            dataObj.rows[`row-${index + 1}`] = { ...row, id: `row-${index + 1}` };
            dataObj.rowIds.push(`row-${index + 1}`);
            // eslint-disable-next-line no-continue
            continue;
          }
          dataObj.rows[id] = { ...row };
          dataObj.rowIds.push(id);
        }
      }
      setDragData(dataObj);
    }
  }, [selectedRows]);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const newRowIds = Array.from(dragData.rowIds);
    newRowIds.splice(source.index, 1);
    newRowIds.splice(destination.index, 0, draggableId);

    const newState = {
      ...dragData,
      rowIds: newRowIds,
    };
    setDragData(newState);
  };

  return (
    // Left to Right
    <>
      {!reversed ? (
        <DualListContent height={height}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '45%',
            }}
          >
            {optionsLabel ? (
              <Box
                height={30}
              >
                <Typography>{optionsLabel}</Typography>
              </Box>
            ) : null}
            <OptionsBox
              height={height}
            >
              {possibleOptions.map((option) => (
                <DualListOptionComponent
                  key={getValueField(option)}
                  option={option}
                  onOptionClick={addToPossibleOptionsMarked}
                  getLabel={getLabelField}
                  disabled={disabled}
                  valueField={valueField}
                  CustomOptionComponent={CustomOptionComponent}
                />
              ))}
            </OptionsBox>
          </Box>
          <ActionBox
            selectedLabel={selectedLabel}
            optionsLabel={optionsLabel}
          >
            <ActionButton
              variant="contained"
              onClick={onUnSelectAllOptions}
              disabled={disabled}
            >
              <KeyboardDoubleArrowLeftIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onUnSelectOptions}
              disabled={selectedMarkedOptions.length === 0 || disabled}
            >
              <KeyboardArrowLeftIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onSelectOptions}
              disabled={possibleOptionsMarkedOptions.length === 0 || disabled}
            >
              <KeyboardArrowRightIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onSelectAllOptions}
              disabled={disabled}
            >
              <KeyboardDoubleArrowRightIcon />
            </ActionButton>
          </ActionBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '45%',
            }}
          >
            {selectedLabel ? (
              <Box
                height={30}
              >
                <Typography>{selectedLabel}</Typography>
              </Box>
            ) : null}
            {optionsLabel && !selectedLabel ? <Box height={30} /> : null}
            {selectedReorderable ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="selected">
                  {(provided) => (
                    <SelectedBox
                      valid={valid}
                      height={height}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {dragData?.rowIds?.map((rowId, index) => {
                        const option = dragData.rows[rowId];
                        return (
                          <DraggableDLOptionComponent
                            key={getValueField(option)}
                            option={option}
                            onOptionClick={addToSelectedMarked}
                            getLabel={getLabelField}
                            index={index}
                            CustomOptionComponent={CustomOptionComponent}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </SelectedBox>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <SelectedBox
                valid={valid}
                height={height}
              >
                {optionsSelected.map((option) => (
                  <DualListOptionComponent
                    key={getValueField(option)}
                    option={option}
                    onOptionClick={addToSelectedMarked}
                    getLabel={getLabelField}
                    CustomOptionComponent={CustomOptionComponent}
                  />
                ))}
              </SelectedBox>
            )}
          </Box>
        </DualListContent>
      ) : (
        // Right to Left
        <DualListContent height={height}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '45%',
            }}
          >
            {selectedLabel ? (
              <Box
                height={30}
              >
                <Typography>{selectedLabel}</Typography>
              </Box>
            ) : null}
            {selectedReorderable ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="selected">
                  {(provided) => (
                    <SelectedBox
                      valid={valid}
                      height={height}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {dragData?.rowIds?.map((rowId, index) => {
                        const option = dragData.rows[rowId];
                        return (
                          <DraggableDLOptionComponent
                            key={getValueField(option)}
                            option={option}
                            onOptionClick={addToSelectedMarked}
                            getLabel={getLabelField}
                            index={index}
                            CustomOptionComponent={CustomOptionComponent}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </SelectedBox>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <SelectedBox
                valid={valid}
                height={height}
              >
                {optionsSelected.map((option) => (
                  <DualListOptionComponent
                    key={getValueField(option)}
                    option={option}
                    onOptionClick={addToSelectedMarked}
                    getLabel={getLabelField}
                    CustomOptionComponent={CustomOptionComponent}
                  />
                ))}
              </SelectedBox>
            )}
          </Box>
          <ActionBox
            selectedLabel={selectedLabel}
            optionsLabel={optionsLabel}
          >
            <ActionButton
              variant="contained"
              onClick={onSelectAllOptions}
              disabled={disabled}
            >
              <KeyboardDoubleArrowLeftIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onSelectOptions}
              disabled={possibleOptionsMarkedOptions.length === 0 || disabled}
            >
              <KeyboardArrowLeftIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onUnSelectOptions}
              disabled={selectedMarkedOptions.length === 0 || disabled}
            >
              <KeyboardArrowRightIcon />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={onUnSelectAllOptions}
              disabled={disabled}
            >
              <KeyboardDoubleArrowRightIcon />
            </ActionButton>
          </ActionBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '45%',
            }}
          >
            {optionsLabel ? (
              <Box
                height={30}
              >
                <Typography>{optionsLabel}</Typography>
              </Box>
            ) : null}
            <OptionsBox
              height={height}
            >
              {possibleOptions.map((option) => (
                <DualListOptionComponent
                  key={getValueField(option)}
                  option={option}
                  onOptionClick={addToPossibleOptionsMarked}
                  getLabel={getLabelField}
                  valueField={valueField}
                  disabled={disabled}
                  CustomOptionComponent={CustomOptionComponent}
                />
              ))}
            </OptionsBox>
          </Box>
        </DualListContent>
      )}
      {!valid ? <FormError>{error}</FormError> : null}
    </>
  );
};
const DualListContent = styled('div')`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const OptionsBox = styled('div')`
  width: 100%;
  height: calc(${(props) => props.height}px - 30px);
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.inputsLight};
  overflow-y: auto;
  padding: 15px 10px 15px 10px;
`;
const ActionBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 10%;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.selectedLabel && props.optionsLabel ? '30px 0 0 0' : undefined)};
  button {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;
const SelectedBox = styled('div')`
  width: 100%;
  height: calc(${(props) => props.height}px - 30px);
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.inputsLight};
  overflow-y: auto;
  padding: 15px 10px 15px 10px;
  ${(props) => !props.valid && `
    box-shadow: 0 0 15px 0 ${props.theme.colors.notificationRedBoxShadow};
    border: 1px solid ${props.theme.colors.notificationRed};
  `};
`;
const ActionButton = styled(Button)`
  height: 35px;
  width: 70px;
`;

export default DualList;
