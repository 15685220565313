import { ErrorMap } from './constants/errorMap';

export const errorMessageHandler = (err) => {
  if (typeof err === 'string') {
    return err;
  }
  const { graphQLErrors } = err;
  if (!graphQLErrors || !graphQLErrors.length) {
    return 'Something went wrong';
  }
  const errorsMessagesList = graphQLErrors.map((singleGraphqlError) => ErrorMap[singleGraphqlError.message]
        || ErrorMap.UNKNOW_ERROR);
  return Array.from(new Set(errorsMessagesList)).join('. ');
};
