/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

export const ModelSwitcherElement = ({
  element,
  index,
  modelType,
  setModelType,
  isIos,
}) => {
  const { t } = useTranslation('ui');
  const renderOptions = useMemo(() => {
    const result = [<MenuItem value="TotalRev">{t('privacyModel.mmp')}</MenuItem>];
    if (isIos) result.push(<MenuItem value="SKAN">{t('privacyModel.skan')}</MenuItem>);
    return result;
  }, [isIos]);

  return (
    <Grid
      item
      xs={element.xs || 12}
      key={index}
      display="flex"
      alignItems="center"
    >
      <FormControl size="small" fullWidth>
        <InputLabel id="modelSwitcher">{t('privacyModel.label')}</InputLabel>
        <Select
          labelId="modelSwitcher"
          id="modelSwitcher"
          value={modelType}
          label={t('privacyModel.label')}
          onChange={(e) => setModelType(e.target.value)}
        >
          {renderOptions}
        </Select>
      </FormControl>
    </Grid>
  );
};
