import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  TableCell,
  styled,
} from '@mui/material';

const classes = {
  flexContainer: 'ReactVirtualizedDemo-flexContainer',
  tableCell: 'ReactVirtualizedDemo-tableCell',
};

const styles = () => ({
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  [`& .${classes.tableCell}`]: {
    flex: 1,
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 80,
  };

  cellRenderer = ({ cellData, columnIndex, rowData }) => {
    const { columns, rowHeight } = this.props;
    const ComponentToRender = columns[columnIndex]?.Component;
    return (
      <TableCell
        component="div"
        variant="body"
        style={{
          height: rowHeight,
        }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
        className={[classes.tableCell, classes.flexContainer]}
      >
        {ComponentToRender ? (
          <ComponentToRender cellData={cellData} rowData={rowData} />
        ) : cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, columnFlexBasis }) => {
    const { headerHeight, columns } = this.props;

    return (
      <TableCell
        component="div"
        variant="head"
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        style={{
          height: headerHeight,
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          flexBasis: columnFlexBasis,
        }}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight}
            {...tableProps}
            rowClassName={[classes.flexContainer]}
          >
            {columns.map(({ dataKey, columnFlexBasis, ...other }, index) => (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) => this.headerRenderer({
                  ...headerProps,
                  columnIndex: index,
                  columnFlexBasis,
                })}
                cellRenderer={this.cellRenderer}
                dataKey={dataKey}
                {...other}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  flexBasis: columnFlexBasis,
                }}
                headerStyle={{
                  flexBasis: columnFlexBasis,
                }}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const StyledVirtualizedTable = styled(MuiVirtualizedTable)(styles);

export default function VirtualizedTable({ rows, columns }) {
  return (
    <StyledVirtualizedTable
      rowCount={rows.length}
      rowGetter={({ index }) => rows[index]}
      columns={columns}
    />
  );
}
