import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import FormSectionWFields from './FormSectionWFields';
import { required, url } from '../../utils/validators';

export default function DynamicFields({ dynamicFields = [], values, disabled }) {
  const { t: tcg } = useTranslation('creativeGeneral');
  const currentSections = [];
  const getFieldWidth = (width) => {
    switch (width) {
      case 0.25:
        return 3;
      case 0.5:
        return 6;
      case 1:
        return 12;
      default:
        return 6;
    }
  };
  const fields = dynamicFields.reduce((acc, field) => {
    if (!currentSections.includes(field.section) && (field.section !== null && field.section !== '')) {
      currentSections.push(field.section);
      acc.push({ name: field.section, type: 'headline', label: field.section, xs: 12 });
    }
    acc.push({
      name: `settings.${field.code}.value`,
      label: field.name,
      type: field.type === 'boolean' ? 'checkbox' : field.type,
      fieldKey: `settings.${field.code}.value.${field.isRequired}`,
      validate: (value) => {
        if (field.isRequired) {
          const error = required(value);
          if (error) return error;
        }
        if (field.type === 'link' && value) {
          const error = url(value);
          if (error) return error;
        }
        return null;
      },
      options: field.selectValues ? field.selectValues.map(({ name, value }) => ({ label: name, value })) : null,
      style: field.type === 'boolean' ? ({
        margin: 0,
      }) : undefined,
      noMargin: field.type === 'boolean' ? true : undefined,
      isClearable: field.type === 'select' && !field.isRequired ? true : undefined,
      isSearchable: field.type === 'select' ? true : undefined,
      isDisabled: disabled || field.isDisabled || false,
      minRows: field?.minRows || undefined,
      maxRows: field?.maxRows || undefined,
      xs: getFieldWidth(field.width),
      icon: field.type === 'link'
        ? {
          icon: <OpenInNewIcon />,
          url: values.settings[field.code]?.value || undefined,
          target: '_blank',
        }
        : undefined,
    });
    return acc;
  }, []);

  return (
    dynamicFields.length !== 0 ? (
      <FormSectionWFields fields={fields} />
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>{tcg('noInfo')}</Typography>
      </Box>
    )
  );
}
