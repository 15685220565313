import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'components/themes';
import { ItemsCountSelector } from './components/ItemsCountSelector';
// -- COMPONENT
export function Pagination({
  currentPageNumber,
  pagesCount,
  onPaginationChange,
  align = 'flex-end',
  displayItemsCountSelector,
  initialItemsCount = 10,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsCount);

  const handlePageChange = (page) => () => {
    onPaginationChange(page, itemsPerPage);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPage(perPage);
    onPaginationChange(1, perPage);
  };

  const getAvailablePages = () => {
    const currentPage = currentPageNumber > pagesCount ? pagesCount : currentPageNumber;
    const isFirst = currentPage === 1;
    const isLast = currentPage === pagesCount;
    if (isFirst || isLast) {
      let availablePages = isFirst
        ? [1, 2, 3]
        : [pagesCount - 2, pagesCount - 1, pagesCount];
      if (pagesCount < 3) {
        availablePages = isFirst
          ? availablePages.slice(0, pagesCount)
          : availablePages.slice(3 - pagesCount);
      }
      return availablePages;
    }
    return [currentPage - 1, currentPage, currentPage + 1];
  };

  const renderPagesItems = () => {
    const currentPage = currentPageNumber > pagesCount ? pagesCount : currentPageNumber;
    const pages = getAvailablePages();
    return pages.map((singleItem) => {
      const active = Number(singleItem)
                === (currentPage > pagesCount ? pagesCount : currentPage);
      return (
        <PaginationItem
          key={`page-${singleItem}`}
          active={active}
          onClick={!active ? handlePageChange(singleItem) : undefined}
        >
          {singleItem}
        </PaginationItem>
      );
    });
  };

  const renderControlsItems = (direction) => {
    const currentPage = currentPageNumber > pagesCount ? pagesCount : currentPageNumber;
    const disabled = (direction === 'next' && currentPage === pagesCount)
            || (direction === 'previous' && currentPage === 1);
    const isRight = direction === 'next';
    const items = [
      {
        key: direction === 'next' ? 'last' : 'first',
        component: <Arrow
          double
          right={isRight}
        />,
        refereToPage: direction === 'next' ? pagesCount : 1,
      },
      {
        key: direction,
        component: <Arrow right={isRight} />,
        refereToPage: direction === 'next' ? currentPage + 1 : currentPage - 1,
      },
    ];
    direction === 'next' && items.reverse();
    return items.map((singleControlItem) => (
      <PaginationItem
        key={singleControlItem.key}
        disabled={disabled}
        onClick={!disabled
          ? handlePageChange(singleControlItem.refereToPage)
          : undefined}
      >
        {singleControlItem.component}
      </PaginationItem>
    ));
  };

  return (
    <PaginationWrapper align={align}>
      {renderControlsItems('previous')}
      {renderPagesItems()}
      {renderControlsItems('next')}
      {displayItemsCountSelector && (
      <ItemsCountSelector
        initialItemsCount={initialItemsCount}
        currentItemsPerPage={itemsPerPage}
        onCountSelect={handleItemsPerPageChange}
      />
      )}
    </PaginationWrapper>
  );
}
// -- STYLES
const PaginationWrapper = styled.ul`
  display: flex;
  justify-content: ${(props) => props.align};
  list-style: none;
  align-items: center;
  margin-bottom: 0;
`;
const PaginationItem = styled.li`
  font-size: 16px;
  padding: 0 4px;
  color: ${theme.colors.white};
  cursor: pointer;
  ${(props) => props.active
    && `
    font-weight: bold;
  `}
  ${(props) => props.disabled
    && `
    opacity: 0.3;
  `}
`;
const BasicArrowCSS = css`
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  width: 10px;
  height: 10px;
`;
const Arrow = styled.i`
  position: relative;
  ${BasicArrowCSS}
  transform: rotate(${(props) => (props.right ? '-45' : '135')}deg);
  -webkit-transform: rotate(${(props) => (props.right ? '-45' : '135')}deg);
  border-color: ${theme.colors.white};
  ${(props) => props.double
    && `
    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      ${BasicArrowCSS}
      border-color: ${theme.colors.white};
    }
  `}
`;
