import { TextField, Autocomplete } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Field = (props) => {
  const { t } = useTranslation('ui', { keyPrefix: 'queryBuilder' });
  const options = useMemo(() => props.options.map((option) => ({
    ...option,
    id: option.key,
  })), [props.options]);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          key={params.id}
          label={t('field')}
          size="small"
          variant="outlined"
        />
      )}
      style={{ minWidth: 250 }}
      value={props.value}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        props.onChange(newValue);
      }}
    />
  );
};

export default Field;
