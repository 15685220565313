/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo, useState } from 'react';
import { PlatformContext } from '@upptic/module-directory';
import { useQuery } from '@apollo/client';
import tinycolor from 'tinycolor2';
import { actionItemCategoriesGql, actionItemsGql } from '../../gql';
import { CalendarDialog } from './CalendarDialog';
import Calendar from '../../Calendar';

function CalendarElement({
  element,
}) {
  const elementHeight = element.heightFactor ? (250 * element.heightFactor) : 250;
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const [modalOpen, setModalOpen] = useState({ open: false });
  const { data } = useQuery(actionItemsGql, {
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      filter: {
        $and: [
          {
            type: {
              $in: ['duration', 'milestone'],
            },
          },
          {
            'application.code': {
              $eq: selectedApplicationCode,
            },
          },
        ],
      },
    },
  });

  const { data: categoryData } = useQuery(actionItemCategoriesGql, {
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
    },
  });

  const events = useMemo(() => {
    if (!data || data?.actionItems?.length === 0 || !categoryData) return [];
    return data.actionItems
      ?.filter((item) => (item.startDate !== null || item.endDate !== null))
      ?.map((event) => {
        const categoryColor = () => {
          const findCategoryData = categoryData?.actionItemCategories?.find((category) => category?.code === event?.actionItemCategory?.code);
          if (findCategoryData && findCategoryData?.color) {
            // eslint-disable-next-line no-floating-decimal
            return tinycolor(findCategoryData?.color).setAlpha(.2).toRgbString();
          }
          return undefined;
        };
        if (!event.startDate) {
          return ({
            ...event,
            title: event.name,
            start: event.endDate,
            allDay: true,
            backgroundColor: categoryColor(),
            borderColor: categoryColor(),
          });
        }
        return ({
          ...event,
          title: event.name,
          start: event.startDate,
          end: event.endDate,
          allDay: true,
          backgroundColor: categoryColor(),
          borderColor: categoryColor(),
        });
      });
  }, [data, categoryData]);

  const onEventClick = (eventInfo) => {
    setModalOpen({ open: true, data: eventInfo?.event?.extendedProps });
  };
  return (
    <>
      <Calendar
        parentContainerSx={{ height: elementHeight }}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,listMonth',
        }}
        eventSources={[
          {
            events,
          },
        ]}
        onEventClick={onEventClick}
      />
      <CalendarDialog
        open={modalOpen?.open}
        onClose={() => setModalOpen({ open: false })}
        data={modalOpen?.data}
      />
    </>
  );
}

export default CalendarElement;
