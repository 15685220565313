/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import { Box, Typography, IconButton, Grid, Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import { PlatformContext } from '@upptic/module-directory';
import { useTranslation } from 'react-i18next';
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { CSVLink } from 'react-csv';
import { paramCase } from 'param-case';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { observer } from 'mobx-react';

import { reportingAppMetricsGql } from '../gql';
import CustomTooltip from '../../ChartGenerator/components/CustomTooltip';
import PieChartCustomTooltip from '../../ChartGenerator/components/PieChartCustomTooltip';
import { colorPalette } from '../../ChartGenerator/ChartGenerator';
import ElementTitle from '../GenericElements/ElementTitle';
import { useGetMetricsFilter } from '../helper';

function PieChartElement({ element, modelType }) {
  const { t: tg } = useTranslation('general');
  const { t: tgp } = useTranslation('general', { keyPrefix: 'platforms' });
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;
  const isCsvEnabled = element.csv?.enabled;
  const isTitle = element.title;
  const elementHeight = element.heightFactor ? (200 * element.heightFactor) : 200;
  const chartHeight = !isTitle && !isCsvEnabled
    ? elementHeight
    : !isCsvEnabled
      ? (elementHeight - 24)
      : (elementHeight - 64);

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);

  const { data, loading } = useQuery(reportingAppMetricsGql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource.metrics,
      filter: metricsFilter,
      dimensions: element.dataSource.dimensions,
      dateRange: element.dataSource?.dateRange,
    },
  });

  const values = useMemo(() => {
    if (!data?.reportingAppMetrics?.items.length) return [];
    // eslint-disable-next-line react/jsx-indent
    return data.reportingAppMetrics.items
      .map((group) => ({
        name: element.dataSource.dimensions[0] === 'platform'
          ? tgp(group[element.dataSource.dimensions[0]])
          : group[element.dataSource.dimensions[0]],
        value: group[element.dataSource.metrics[0]],
      }))
      .sort((a, b) => {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
      });
  }, [data?.reportingAppMetrics?.items]);

  const CustomLabelComponent = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, index, midAngle, innerRadius, outerRadius, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = (cy - 25) + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={13}
      >
        <tspan x={x} dy="1.2em">
          {values[index].name}
        </tspan>
        <tspan x={x} dy="1.2em">
          {`${(percent * 100).toFixed(0)}%`}
        </tspan>
      </text>
    );
  };

  return (
    <Box sx={{ height: elementHeight, textAlign: 'center' }}>
      <Grid
        container
      >
        {isTitle && (
          <Grid
            item
            xs={12}
          >
            <ElementTitle title={element.title} />
          </Grid>
        )}
        {isCsvEnabled && (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CSVLink
              data={values}
              headers={element.csv.headers}
              filename={`${element?.csv?.filename || paramCase(element.title || '') || 'export'
              }-${new Date().getTime()}.csv`}
            >
              <IconButton>
                <CloudDownloadIcon color="primary" />
              </IconButton>
            </CSVLink>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            '.recharts-wrapper': {
              position: 'absolute !important',
            },
            '.recharts-tooltip-wrapper': {
              zIndex: 1500,
            },
          }}
        >
          <Box
            position="relative"
            height={chartHeight}
            sx={{
              '.MuiBackdrop-root': {
                zIndex: 1099,
              },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  animationDuration={2000}
                  animationEasing="ease-in-out"
                  data={values}
                  labelLine={false}
                  label={CustomLabelComponent}
                  outerRadius="100%"
                >
                  {values.map((el, index) => (
                    <Cell key={`cell-${index}`} fill={colorPalette[index]} />
                  ))}
                </Pie>
                <Tooltip
                  content={(
                    <CustomTooltip
                      CustomTooltipComponent={(props) => PieChartCustomTooltip(props, 'name')}
                      colorPalette={colorPalette}
                      chartData={values}
                    />
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
            <Backdrop
              open={(data && values.length === 0) || loading}
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 5,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: chartHeight,
                width: '100%',
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography>{tg('noData')}</Typography>
              )}
            </Backdrop>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(PieChartElement);
