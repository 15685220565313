import React, { useMemo } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from '@mui/material';
import { get } from 'lodash';
// refactor all instances to use colorGetter after initial release

const splitIntoChunks = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

export default function BarChartCustomTooltip(props, labelKey, configParams) {
  const { label, renderIcon, valueFormatter, chartData, payload, colorPalette } = props;
  const widthMultiplier = configParams?.widthMultiplier ? configParams?.widthMultiplier : 250;
  const findIndex = useMemo(() => (
    chartData.findIndex((item) => get(item, labelKey, '') === label)
  ), [chartData, label, labelKey]);
  const formatPayload = useMemo(() => {
    const dataArray = [];
    if (configParams?.customPayloadFormat) return configParams?.customPayloadFormat(payload);
    for (const item of payload) {
      dataArray.push({
        ...item,
        fill: configParams?.colorGetter ? configParams?.colorGetter(payload, item) : colorPalette[findIndex],
      });
    }
    return dataArray;
  }, [payload, colorPalette, findIndex]);

  const metricChunks = splitIntoChunks(formatPayload, 15);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      p={0.5}
      sx={{
        backgroundColor: '#fff',
        opacity: '0.9',
        width: metricChunks.length * widthMultiplier,
      }}
    >
      {configParams?.CustomTooltipTitle ? (
        <configParams.CustomTooltipTitle payload={formatPayload} />
      ) : null}
      {configParams?.labelFormatter ? (
        <Typography
          variant="body2"
          ml={0.5}
          color="secondary"
        >
          {configParams?.labelFormatter(label)}
        </Typography>
      ) : null}
      <Grid container>
        {
          metricChunks.map((chunk) => (
            <Grid
              item
              xs={parseInt(12 / metricChunks.length)}
              sx={{
                '& .MuiListItem-divider': {
                  '&:not(:last-of-type)': {
                    borderBottom: '1px solid black',
                  },
                },
              }}
            >
              <List
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                {chunk.map((item) => (
                  <ListItem
                    dense
                    divider
                    secondaryAction={(
                      <Typography
                        color="secondary"
                        variant="body2"
                      >
                        <span style={{ fontWeight: 'bolder' }}>
                          {valueFormatter(item.value)}
                        </span>
                      </Typography>
                    )}
                  >
                    <ListItemIcon
                      sx={{ minWidth: '24px' }}
                    >
                      {renderIcon(item)}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      sx={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        maxWidth: '130px',
                      }}
                      primary={(
                        <Typography
                          component="div"
                          color="secondary"
                          variant="body2"
                          noWrap
                        >
                          {`${item?.label ? item?.label : label}: `}
                        </Typography>
                      )}
                      secondary={item.secondaryValue ? (
                        <Typography
                          color="secondary"
                          variant="body2"
                        >
                          <span style={{ fontWeight: 'bolder' }}>
                            {item.secondaryValue}
                          </span>
                        </Typography>
                      ) : undefined}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}
