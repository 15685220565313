import React, { useState, useMemo } from 'react';
import ImageGalleryReact from 'react-image-gallery';
import styled from 'styled-components';
import themes from '../themes';
import 'react-image-gallery/styles/css/image-gallery.css';
import SingleReport from './iframe/SingleReport';

function ReportGallery({
  data,
  mediaCode,
  children,
  modal = true,
  showThumbnails = true,
  showFullscreenButton = true,
  showBullets = true,
  showIndex = true,
  lazyLoad = true,
  showNav = true,
  thumbnailPosition = 'left',
}) {
  const [index, setIndex] = useState(0);
  const renderMedia = (item) => {
    if (item.type === 'custom') {
      return <item.component item={item} />;
    }
    return (
      <ReportWrapper>
        <SingleReport
          selectedReport={{}}
          url={item.url}
          modal
        />
      </ReportWrapper>
    );
  };

  const createMediaArray = useMemo(() => {
    const mediaArray = [];
    data.map((media, index) => {
      mediaArray.push({ ...media, index, thumbnail: media.previewImage || '', renderItem: renderMedia });
      // eslint-disable-next-line no-useless-return
      return;
    });
    return mediaArray;
  }, [data]);

  const startIndex = useMemo(() => {
    if (mediaCode) {
      const findItem = createMediaArray.filter((item) => item.code === mediaCode);
      setIndex(findItem[0].index);
      return findItem[0].index;
    }
    return 0;
  }, [mediaCode, createMediaArray]);

  const onSlide = (index) => {
    setIndex(index);
  };
  return (
    <>
      <Wrapper
        modal={modal}
        showThumbnails={showThumbnails}
      >
        <ImageGalleryReact
          items={createMediaArray || []}
          showThumbnails={createMediaArray.length > 1 ? showThumbnails : false}
          showFullscreenButton={showFullscreenButton}
          showNav={showNav}
          thumbnailPosition={thumbnailPosition}
          showBullets={createMediaArray.length > 1 ? showBullets : false}
          showIndex={createMediaArray.length > 1 ? showIndex : false}
          onSlide={onSlide}
          startIndex={startIndex}
          showPlayButton={false}
          lazyLoad={lazyLoad}
        />
        {children ? React.cloneElement(children, { createMediaArray, index }) : null }
      </Wrapper>
    </>
  );
}

export default ReportGallery;

const Wrapper = styled.div`
  background-color: #13132b;
  padding: ${(props) => (!props.modal ? '0' : '0 30px 30px 30px')};
  display: flex;
  width: ${(props) => (!props.modal ? '100%' : '90vw')};
  height: 100%;
  flex-direction: column;
  align-items: center;

  * {
    &:focus {
      outline: 1px solid ${themes.colors.purple};
    }
  }

  .image-gallery {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;

  }

  .image-gallery-content {
    height: 100%;
    max-width: 100%;
    ${(props) => !props.modal && `
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    ${(props) => props.modal && !props.showThumbnails && `
      display: flex;
      justify-content: center;
    `};
  }
  
  .image-gallery-slide-wrapper {
    height: 100%;
    max-width: 100%;
    width: 100%;
    ${(props) => props.modal && !props.showThumbnails && `
      width: 100%;
      @media (display-mode: fullscreen) {
        padding: 0 50px;
      }
    `};
  }

  .image-gallery-swipe {
    height: 100%;
    max-width: 100%;
  }

  .image-gallery-slides {
    height: 100%;
    max-width: 100%;
  }

  .image-gallery-slide {
    height: 100%;
    max-width: 100%;
  }

  button {
    &:hover {
      color: ${themes.colors.purple};
    }
  }

  .image-gallery-bullet {
    &:hover {
      background: ${themes.colors.purple};
      border: 1px solid ${themes.colors.purple};
    }
    &:focus {
      background: ${themes.colors.purple};
      border: 1px solid ${themes.colors.purple};
    }
  }

  .image-gallery-bullet.active {
    background: ${themes.colors.purple};
    border: 1px solid ${themes.colors.purple};
    &:hover {
      background: ${themes.colors.purple};
    }
  }

  .image-gallery-thumbnails {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .image-gallery-thumbnail {
    &:hover {
      border: 4px solid ${themes.colors.purple};
    }
  }

  .image-gallery-thumbnail.active {
    border: 4px solid ${themes.colors.purple}; 
  }

`;

const ReportWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
