/* eslint-disable import/no-cycle */
import * as React from 'react';
import { useSortBy, usePagination, useTable, useExpanded } from 'react-table';
import { Table } from './components/TableComponent';
// -- COMPONENT
function StaticTableComponent({
  columns,
  data,
  paginate,
  scrollable,
  layoutFixed,
  maxHeight = '500px',
  fullHeight,
  defaultItemsPerPage = 10,
  initialSortBy,
  expanded,
  renderRowSubComponent,
  childrenSubTableColumns,
  ...rest
}) {
  const tableConfig = React.useMemo(() => {
    const usedHooks = [useSortBy];
    const params = {
      disableMultiSort: false,
      initialState: {},
    };
    if (paginate) {
      usedHooks.push(usePagination);
      params.initialState = {
        pageSize: defaultItemsPerPage,
      };
    }
    if (expanded) {
      usedHooks.push(useExpanded);
      params.getSubRows = (row) => row[expanded] || [];
    }
    if (initialSortBy) {
      params.initialState = {
        ...params.initialState,
        sortBy: initialSortBy, // puts initialSortBy element in array because react-table requires this
      };
    }
    return {
      usedHooks,
      params,
    };
  }, [paginate, defaultItemsPerPage, initialSortBy, expanded]);

  const tableData = React.useMemo(() => {
    if (data !== undefined) return data;
    return [];
  }, [data]);

  const getRowId = React.useCallback((row, relativeIndex, parent) => {
    if (row.code) return row.code;
    return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
  }, []);

  const { page, rows, state, pageCount, setPageSize, gotoPage, getTableProps, getTableBodyProps, headerGroups, prepareRow } = useTable({
    columns,
    data: tableData,
    ...tableConfig.params,
    getRowId,
  }, ...tableConfig.usedHooks);
  const footerProps = React.useMemo(() => {
    if (!paginate) {
      return undefined;
    }
    return {
      displayItemsCountSelector: true,
      initialItemsCount: defaultItemsPerPage,
      columnsNumber: columns.length,
      pagesCount: pageCount,
      currentPage: state.pageIndex + 1,
      onPaginationChange: (pageNumber, itemsPerPage) => {
        if (pageNumber !== state.pageIndex - 1) {
          gotoPage(pageNumber - 1);
        }
        if (itemsPerPage !== state.pageSize) {
          setPageSize(itemsPerPage);
        }
      },
    };
  }, [
    paginate,
    pageCount,
    state,
    setPageSize,
    gotoPage,
    columns.length,
    defaultItemsPerPage,
  ]);

  const rowsToDisplay = React.useMemo(() => page || rows, [page, rows]);

  return (
    <Table
      rows={rowsToDisplay}
      columnsNumber={columns.length}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      footerProps={footerProps}
      scrollable={scrollable}
      maxHeight={maxHeight}
      fullHeight={fullHeight}
      layoutFixed={layoutFixed}
      renderRowSubComponent={renderRowSubComponent}
      childrenSubTableColumns={childrenSubTableColumns}
      {...rest}
    />
  );
}
export const StaticTable = React.memo(StaticTableComponent);
