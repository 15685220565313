/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Checkbox, TextField, useTheme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const UsersAutocomplete = (params) => {
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const theme = useTheme();
  const {
    name,
    placeholder = t('users'),
    label = t('users'),
    multiple = true,
    disableClearable = false,
    error,
    loading,
    onChange,
    options,
    meta,
    ...rest
  } = params;
  const isOptionEqualToValue = (option, value) => {
    if (option?.user?.emailAddress === value?.user?.emailAddress) return true;
    return false;
  };

  return (
    <Autocomplete
      key={name}
      options={options}
      getOptionLabel={(option) => (option ? `${option?.user?.firstName} ${option?.user?.lastName} | ${option?.user?.emailAddress}` : '')}
      isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
      placeholder={placeholder}
      multiple={multiple}
      onChange={(_event, newValue) => onChange(newValue)}
      loading={loading}
      noOptionsText={t('noOptions')}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option?.user?.firstName} ${option?.user?.lastName} | ${option?.user?.emailAddress}`}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          helperText={error}
          variant="filled"
        />
      )}
      sx={{
        '& .MuiInputLabel-root': {
          color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
        },
      }}
      disableClearable={disableClearable}
      fullWidth
      {...rest}
    />
  );
};
