import React from 'react';
import styled from 'styled-components';

function ExternalLink({ value, children, ...rest }) {
  return (
    <Link
      href={value}
      target="_blank"
      {...rest}
    >
      {children || value}
    </Link>
  );
}
const Link = styled.a`
  color: inherit;

  cursor: pointer;

  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;
export default ExternalLink;
