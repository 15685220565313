/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import {
  DataGridPro,
  GridColumnMenuContainer,
  GridColumnMenuSortItem,
  GridColumnMenuFilterItem,
  GridColumnMenuHideItem,
  GridColumnMenuColumnsItem,
} from '@mui/x-data-grid-pro';
import {
  Box,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { paramCase } from 'param-case';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlatformContext } from '@upptic/module-directory';
import { reportingAppMetricsGql } from '../gql';
import { formatData } from '../../../utils/formatters/formatters';
import { useGetMetricsFilter } from '../helper';

function CustomCSVDownloadItem(props) {
  const { element, csvHeaders, csvData } = props;
  return (
    <CSVLink
      data={csvData}
      headers={csvHeaders}
      filename={`${element?.csv?.filename || paramCase(element.title || '') || 'export'
      }-${new Date().getTime()}.csv`}
    >
      <MenuItem>
        <ListItemIcon>
          <CloudDownloadIcon color="primary" />
        </ListItemIcon>
      </MenuItem>
    </CSVLink>
  );
}

function CustomColumnMenu(props) {
  const { hideMenu, currentColumn, isCsvEnabled, element, csvHeaders, csvData, ...other } = props;
  return (
    <GridColumnMenuContainer
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      {...other}
    >
      {isCsvEnabled ? (
        <CustomCSVDownloadItem
          element={element}
          csvHeaders={csvHeaders}
          csvData={csvData}
        />
      ) : null}
      <GridColumnMenuSortItem onClick={hideMenu} colDef={currentColumn && currentColumn} />
      <GridColumnMenuFilterItem onClick={hideMenu} colDef={currentColumn && currentColumn} />
      <GridColumnMenuHideItem onClick={hideMenu} colDef={currentColumn && currentColumn} />
      <GridColumnMenuColumnsItem onClick={hideMenu} colDef={currentColumn && currentColumn} />
    </GridColumnMenuContainer>
  );
}

function TableElement({ element, appReportingCurrency, modelType }) {
  const { t: tgp } = useTranslation('general', { keyPrefix: 'platforms' });
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;

  const elementHeight = element.heightFactor ? (200 * element.heightFactor) : 200;
  const isCsvEnabled = element.csv?.enabled;

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);

  const { data, loading } = useQuery(reportingAppMetricsGql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource.metrics,
      filter: metricsFilter,
      dimensions: element.dataSource.dimensions,
      sort: element.dataSource.sort,
      dateRange: element.dataSource?.dateRange,
    },
  });
  const columns = useMemo(() => {
    if (!element?.columns) return [];
    return element.columns.map((column) => {
      const dataPointFormat = () => {
        if (column?.type === 'currency' && appReportingCurrency) {
          return ({
            type: column?.type,
            currency: appReportingCurrency,
          });
        }
        return ({});
      };
      return ({
        field: column.dataKey,
        headerName: column.name,
        flex: 1,
        valueFormatter: (value) => (column.dataKey === 'platform'
          ? tgp(value)
          : formatData(value, column.type, dataPointFormat())),
      });
    });
  }, [element?.columns, appReportingCurrency]);

  const csvHeaders = useMemo(() => {
    if (!element?.columns) return [];
    return element.columns.map((column) => ({
      key: column.dataKey,
      label: column.name,
    }));
  }, [element?.columns]);

  const csvData = useMemo(() => {
    if (!data?.reportingAppMetrics?.items || !isCsvEnabled) return [];
    const columnsByKey = element.columns
      .reduce((acc, column) => {
        acc[column.dataKey] = column;
        return acc;
      }, {});
    return data.reportingAppMetrics.items.map((orgRow) => {
      const row = { ...orgRow };
      for (const [key, val] of Object.entries(row)) {
        // eslint-disable-next-line no-continue
        if (!columnsByKey[key]) continue;
        // eslint-disable-next-line no-continue
        if (val === null || val === undefined) continue;
        row[key] = key === 'platform'
          ? tgp(val)
          : formatData(val, columnsByKey[key]?.type);
      }
      return row;
    });
  }, [data?.reportingAppMetrics?.items]);

  return (
    <Box sx={{ height: elementHeight, paddingTop: '8px' }}>
      <DataGridPro
        rows={data?.reportingAppMetrics?.items || []}
        getRowId={(row) => (element.dataSource.dimensions || ['week']).map((dim) => row[dim]).join('-')}
        columns={columns}
        hideFooter
        density="compact"
        loading={loading}
        slots={{
          columnMenu: CustomColumnMenu,
        }}
        slotProps={{
          columnMenu: { isCsvEnabled, element, csvHeaders, csvData },
        }}
      />
    </Box>
  );
}

export default observer(TableElement);
