/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from './forms/Button';
import { default as Modal } from './Modal';

function ConfirmationDialog({
  open, onClose, title, text, buttons,
}) {
  const { t: tcg } = useTranslation('general');

  const renderButton = (button, index) => (
    <ModalButton
      key={index}
      onClick={button.onClick}
      disabled={button.disabled || false}
    >
      {button.name}
    </ModalButton>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
    >
      <Wrapper>
        <DefaultText>
          <span dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br />') }} />
        </DefaultText>
        <ActionsWrapper>
          <ButtonWrapper>
            {buttons ? buttons.map(renderButton)
              : (
                <>
                  <ModalButton
                    onClick={onClose}
                  >
                    {tcg('close')}
                  </ModalButton>
                </>
              )}
          </ButtonWrapper>
        </ActionsWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 700px;
  background-color: #13132b;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DefaultText = styled.div`
  width: 100%;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled(Button)`
  min-width: 100px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

export default ConfirmationDialog;
