import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.align || 'space-between'};
  align-items: center;
  padding-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 21px;
  font-weight: 300;
  color: #fff;
`;
