import React from 'react';
import styled from 'styled-components';
import { ReportIframe } from './ReportIframe';

const SingleReport = (props) => {
  const { selectedReport, url, maxWidth = '2034px', modal } = props;

  const screenHeight = window.screen.height;
  const fullscreenMaxWidth = (screenHeight * 1.78 - 38);

  return (
    <SingleReportWrapper
      maxWidth={maxWidth}
      fullscreenMaxWidth={fullscreenMaxWidth}
      modal={modal}
    >
      {selectedReport ? (
        <>
          {selectedReport?.name ? (
            <ReportTitleWrapper>
              <Title>{selectedReport?.name}</Title>
            </ReportTitleWrapper>
          ) : null}
          <ReportIframe url={url} />
        </>
      ) : (
        <TitleWrapper>
          <Title>Please Select a Report to View</Title>
        </TitleWrapper>
      )}
    </SingleReportWrapper>
  );
};

const Title = styled.h1`
  font-size: 30px;
  color: #ffffff;
  margin: 2px 0;
`;

const SingleReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  ${(props) => props.modal && `
    max-width: 1000px;
    @media (min-height: 1000px) {
      max-width: 1200px;
    }
    @media (min-height: 1100px) {
      max-width: 1400px;
    }
    @media (min-height: 1200px) {
      max-width: 1500px;
    }
    @media (min-height: 1300px) {
      max-width: 1700px;
    }
    @media (min-height: 1400px) {
      max-width: 1800px;
    }
    @media (min-height: 1530px) {
      max-width: ${(props) => props.maxWidth};
    }
  `}
  @media (display-mode: fullscreen) {
    max-width: ${(props) => (props.fullscreenMaxWidth)}px;
  }
`;

const ReportTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default SingleReport;
