import React, { useState, useMemo, useCallback, useRef } from 'react';
import ImageGalleryReact from 'react-image-gallery';
import styled from 'styled-components';
import themes from './themes';
import 'react-image-gallery/styles/css/image-gallery.css';
import { MediaGalleryThumbnail } from './MediaGalleryThumbnail';

function MediaGallery({
  data,
  mediaCode,
  children,
  modal = true,
  showThumbnails = false,
  showFullscreenButton = true,
  showBullets = true,
  showIndex = true,
  lazyLoad = true,
  showNav = true,
  thumbnailPosition = 'left',
  renderItem,
  childrenHeight,
  useMuiTheming,
  compactVersion,
}) {
  const isIOS = /iPad|iPhone/.test(navigator.userAgent);
  const [index, setIndex] = useState(null);

  const refImg = useRef(null);

  const findFileToDisplay = (media) => {
    if (typeof media.file === 'string') return media.file;
    if (media?.file?.preview) return media.file.preview;
    if (media.conceptImage) return media.conceptImage;
    return '';
  };

  const findPreviewToDisplay = (media) => {
    if (media.previewImage) return media.previewImage;
    if (media.conceptImage) return media.conceptImage;
    return '';
  };

  const createMediaArray = useMemo(() => {
    const mediaArray = [];
    data.map((media, index) => {
      mediaArray.push({
        ...media,
        index,
        original: findFileToDisplay(media),
        thumbnail: findPreviewToDisplay(media),
      });
      // eslint-disable-next-line no-useless-return
      return;
    });
    return mediaArray;
  }, [data]);
  const videoList = document.getElementsByTagName('video');
  const startIndex = useMemo(() => {
    if (mediaCode && data.length !== 0) {
      const findItem = createMediaArray.filter((item) => item.code === mediaCode);
      setIndex(findItem[0].index);
      return findItem[0].index;
    }
    return 0;
  }, [mediaCode, createMediaArray, data]);

  const onSlide = (index) => {
    if (videoList?.length !== 0) {
      for (const player of videoList) {
        player.pause();
      }
    }
    setIndex(index);
  };

  const handleOnClickThumbnail = useCallback((index) => {
    refImg.current.slideToIndex(index);
  }, [refImg.current]);
  const renderThumbnail = useCallback((item, i) => (
    <MediaGalleryThumbnail
      item={item}
      onClick={handleOnClickThumbnail}
      activeMedia={item.index === index}
      key={`${item.code}${i}`}
    />
  ), [createMediaArray, index]);
  const hasThumbnails = createMediaArray?.length > 1 && !compactVersion;
  return (
    <>
      <Wrapper
        modal={modal}
        showThumbnails={showThumbnails}
        useMuiTheming={useMuiTheming}
      >
        <InnerWrapper wrapperChildren={children} childrenHeight={childrenHeight}>
          <ThumbnailsWrapper hasThumbnails={hasThumbnails}>
            {createMediaArray.length !== 0 && createMediaArray.map(renderThumbnail)}
          </ThumbnailsWrapper>
          <GalleryWrapper hasThumbnails={hasThumbnails}>
            <ImageGalleryReact
              ref={refImg}
              items={createMediaArray || []}
              showThumbnails={false}
              showFullscreenButton={isIOS ? false : showFullscreenButton}
              showNav={showNav}
              thumbnailPosition={thumbnailPosition}
              showBullets={hasThumbnails || compactVersion ? showBullets : false}
              showIndex={hasThumbnails || compactVersion ? showIndex : false}
              onSlide={onSlide}
              startIndex={startIndex}
              showPlayButton={false}
              lazyLoad={lazyLoad}
              renderItem={renderItem}
            />
          </GalleryWrapper>
        </InnerWrapper>
        {children ? React.cloneElement(children, { createMediaArray, index }) : null }
      </Wrapper>
    </>
  );
}

export default MediaGallery;

const GalleryWrapper = styled.div`
  width: ${(props) => (props.hasThumbnails ? '87%' : '100%')};
  height: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  height: ${(props) => (!props.wrapperChildren ? '100%' : `calc(100% - ${props.childrenHeight}px)`)};
`;

const ThumbnailsWrapper = styled.div`
  width: 13%;
  height: 100%;
  padding: 5px;
  display: ${(props) => (props.hasThumbnails ? 'flex' : 'none')};
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Wrapper = styled.div`
  background-color: #13132b;
  padding: ${(props) => (!props.modal || props.useMuiTheming ? '0' : '0 30px 30px 30px')};
  display: flex;
  width: ${(props) => (!props.modal || props.useMuiTheming ? '100%' : '90vw')};
  height: ${(props) => (!props.modal ? '100%' : '80vh')};
  flex-direction: column;
  align-items: center;

  * {
    &:focus {
      outline: 1px solid ${themes.colors.purple};
    }
  }

  .image-gallery {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;

  }

  .image-gallery-content {
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-gallery-slide-wrapper {
    height: 100%;
    max-width: 100%;
    ${(props) => props.modal && props?.children[0]?.props?.items?.length < 2 && `
      width: 100%;
    `};
  }

  .image-gallery-slide-wrapper.left {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .image-gallery-swipe {
    height: 98%;
    max-width: 100%;
  }

  .image-gallery-slides {
    height: 98%;
    max-width: 100%;
  }

  .image-gallery-slide {
    height: 100%;
    max-width: 100%;
  }

  .image-gallery-icon {
    &:hover {
      color: ${themes.colors.purple};
    }
  }

  .image-gallery-bullets {
    position: unset;
    margin: 5px auto 5px auto;
  }

  .image-gallery-bullet {
    &:hover {
      background: ${themes.colors.purple};
      border: 1px solid ${themes.colors.purple};
    }
    &:focus {
      background: ${themes.colors.purple};
      border: 1px solid ${themes.colors.purple};
    }
  }

  .image-gallery-bullet.active {
    background: ${themes.colors.purple};
    border: 1px solid ${themes.colors.purple};
    &:hover {
      background: ${themes.colors.purple};
    }
  }

  .image-gallery-thumbnails {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .image-gallery-thumbnail {
    &:hover {
      border: 4px solid ${themes.colors.purple};
    }
  }

  .image-gallery-thumbnail.active {
    border: 4px solid ${themes.colors.purple}; 
  }

`;
