import React from 'react';
import styled from 'styled-components';
import { errorMessageHandler } from 'utils/errorHandlers/errorMessageHandler';
import logoImage from 'assets/images/logo.svg';
// -- COMPONENT
export function Error({ errorElement, size = 'normal', customErrorFirstRow = 'An error occured.' }) {
  return (
    <Wrapper>
      <Logo size={size} />
      <Message>
        {customErrorFirstRow}
        <br />
        {errorElement && (
        <>
          {errorMessageHandler(errorElement)}
          .
          <br />
        </>
        )}
        Please try again or contact your administrator.
      </Message>
    </Wrapper>
  );
}
// -- STYLED
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
`;
const Logo = styled.div`
  height: 150px;
  width: 173px;

  ${(props) => props.size === 'small'
    && `
    height: 36px;
    width: 43px;
  `}

  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-size: cover;
`;
const Message = styled.p`
  margin-top: 20px;
  color: #fff;
  text-align: center;
`;
