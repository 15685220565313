import gql from 'graphql-tag';

export const gdsReportsGql = gql`
query gdsReports($additionalParams: Params, $applicationCode: String!, $clientCode: String!, $product: String) {
  gdsReports(additionalParams: $additionalParams, applicationCode: $applicationCode, clientCode: $clientCode, product: $product) {
    code
    name
    product
    status
    url
  }
}
`;
