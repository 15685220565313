/* eslint-disable prefer-arrow-callback */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Slide,
  Stack,
  Typography,
  Button,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  text,
  secondaryText,
  buttons,
}) {
  const renderButton = (button, index) => (
    <Button
      key={`confirmation-button-${index}`}
      variant="contained"
      onClick={button.onClick}
      sx={{
        minWidth: '110px',
      }}
    >
      {button.text}
    </Button>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableEnforceFocus
    >
      <Card
        sx={{ border: '1px solid' }}
      >
        {title ? (
          <DialogTitle>{title}</DialogTitle>
        ) : null}
        <DialogContent>
          <Typography
            variant="body1"
            textAlign="center"
          >
            {text}
          </Typography>
          {secondaryText ? (
            <Typography
              variant="body1"
              textAlign="center"
            >
              {secondaryText}
            </Typography>
          ) : null}
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            mt={1}
            direction="row"
            justifyContent="flex-end"
          >
            {buttons && buttons.map(renderButton)}
          </Stack>
        </DialogContent>
      </Card>
    </Dialog>
  );
}
