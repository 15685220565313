import React, { useState } from 'react';
import IframeResizer from 'iframe-resizer-react';
import styled from 'styled-components';
import Spinner from '../../Spinner';

export const ReportIframe = (props) => {
  const { url } = props;

  const [loading, setLoading] = useState(true);

  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <>
      <IframeOutterWrapper>
        <IframeInnerWrapper>
          {loading ? <Spinner /> : null}
          <IframeResizer
            key={url}
            src={url}
            style={
              !loading ? { width: '100%', border: 0 } : { display: 'none' }
            }
            frameBorder="0"
            allowFullScreen
            onLoad={hideSpinner}
          />
        </IframeInnerWrapper>
      </IframeOutterWrapper>
      <BarBlocker />
    </>
  );
};

const BarBlocker = styled.div`
  width: 100%;
  height: 25px;
  position: relative;
  background: #0b0c26;
  left: 0;
  right: 0;
  top: -25px;
  z-index: 20;
`;

const IframeOutterWrapper = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
`;

const IframeInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
