/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React from 'react';
import { Form } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '../forms/Button';
import Icon, { Icons } from '../Icon';
import DividerHorizontal from '../DividerHorizontal';
import WizardSteps from './WizardSteps';
import themes from '../themes';
import FormHeader from '../forms/FormHeader';

function AppPlatformImportWizard({ initValues, step, maxStep, steps, onStepChange, onSubmit, onImport, formTitle, buttonLink }) {
  const { t: tg } = useTranslation('general');
  const { t: tw } = useTranslation('wizard');
  const getActivePage = () => steps[step].component;

  const isLastPage = () => step === steps.length - 1;

  const next = () => {
    onStepChange(step + 1);
  };

  // Remnant of old Wizard -- Probably not needed but didnt have time to verify
  const validate = (values) => {
    const activePage = getActivePage();

    if (!activePage) {
      return null;
    }

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = (values) => {
    if (isLastPage()) {
      return onSubmit();
    } else {
      onImport(values);
      next();
    }
  };

  const renderStepContent = (form, submitting, valid) => {
    const activePageContent = getActivePage();

    const handlePreviousClick = () => {
      if (maxStep > step && !valid) {
        return form.submit();
      }

      onStepChange(step - 1);
    };

    const formControlsButtons = (
      !isLastPage() && step !== 1 ? (
        <Flex
          justifyContent="flex-end"
          mt={60}
        >
          <Box mr={20}>
            {step > 0 && (
              <ButtonPrevious
                outline
                onClick={handlePreviousClick}
                disabled={submitting}
              >
                <Icon
                  verticalCenter
                  icon={Icons.faArrowLeft}
                  size="1x"
                />
                <span>{tw('prevStep')}</span>
              </ButtonPrevious>
            )}
          </Box>
          <Box>
            <Button
              disabled={submitting}
              type="submit"
            >
              <span>{tw('nextStep')}</span>
              <Icon
                verticalCenter
                icon={Icons.faArrowRight}
                size="1x"
              />
            </Button>
          </Box>
        </Flex>
      ) : null
    );

    return (
      <>
        {activePageContent}
        {formControlsButtons}
      </>
    );
  };

  const blockSubmitOnEnter = (event) => {
    const targetTagName = event.target?.tagName;

    if (event.which === 13 && targetTagName !== 'TEXTAREA') {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Form
        initialValues={initValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, submitting, valid, form }) => {
          return (
            <div>
              <form
                style={{ padding: '30px' }}
                onSubmit={handleSubmit}
                autoComplete="off"
                onKeyPress={blockSubmitOnEnter}
              >
                <FormHeader
                  title={formTitle}
                  buttons={step === 1 ? null : !isLastPage() ? [
                    // TODO: Add general page translations to the names
                    {
                      name: tg('discard'),
                      link: buttonLink,
                    },
                  ] : [
                    // {
                    //   name: tg('cancel'),
                    //   link: buttonLink,
                    // },
                    {
                      name: tg('done'),
                      onClick: () => handleSubmit(),
                    },
                  ]}
                />
                <WizardSteps
                  currentStep={step}
                  maxStep={maxStep}
                  steps={steps}
                  onChange={(newStep) => {
                    if (maxStep < newStep) {
                      return;
                    }

                    onStepChange(newStep);
                  }}
                />

                <DividerHorizontal />
                {renderStepContent(form, submitting, valid)}
              </form>
            </div>
          );
        }}
      </Form>
    </Wrapper>
  );
}

const ButtonPrevious = styled(Button)`
  border-color: ${themes.colors.purple};

  &:hover {
    background-color: rgba(171, 104, 239, 0.2);
    border-color: #ab68ef;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

export default AppPlatformImportWizard;
