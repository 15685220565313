import React from 'react';
import moment from 'moment';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

const formatName = (user) => {
  if (!user) return '-';
  if (user.emailAddress === 'system@upptic.com') return 'System';
  return `${user.firstName} ${user.lastName}`;
};

const AuditFields = ({ audit }) => (
  <AuditFieldsWrapper>
    <Flex flexWrap="wrap">
      <Box
        key="created"
        width={1 / 2}
      >
        <p>{`Created On: ${(audit?.createDate) ? moment(audit?.createDate).format('LLL') : '-'}`}</p>
        <p>{`Created By: ${formatName(audit?.createBy)}`}</p>
      </Box>
      <Box
        key="updated"
        width={1 / 2}
      >
        <p>{`Updated On: ${(audit?.updateDate) ? moment(audit?.updateDate).format('LLL') : '-'}`}</p>
        <p>{`Updated By: ${formatName(audit?.updateBy)}`}</p>
      </Box>
    </Flex>
  </AuditFieldsWrapper>
);

export default AuditFields;

const AuditFieldsWrapper = styled.div`
  p {
    margin-bottom: 5px;
  }
`;
