import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import JsxParser from 'react-jsx-parser';

// eslint-disable-next-line arrow-body-style
export const HTMLInclude = ({ string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
      }}
      height={272}
    >
      {string ? (
        <JsxParser jsx={string} />
      ) : (
        <>
          <Typography
            variant="h5"
          >
            Integration Instructions:
          </Typography>
          <Typography
            variant="h6"
          >
            Please select a connector
          </Typography>
        </>
      )}
    </Box>
  );
};
