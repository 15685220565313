import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@rebass/grid';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import { ReactComponent as ASOLogo } from 'assets/images/aso_icon_platform.svg';
import { ReactComponent as GrowthLogo } from 'assets/images/ua_icon_platform.svg';
import { ReactComponent as CreativeLogo } from 'assets/images/creative_icon_platform.svg';
import { ReactComponent as SocialLogo } from 'assets/images/social_icon_platform.svg';
import { entitlementsAll } from './gql';
import FormHeader from '../forms/FormHeader';
import FormSection from '../forms/FormSection';
import { requiredDependOnOtherFields } from '../../utils/validators';
import Entitlement from './Entitlement';

export default function ClientEntitlements({
  entitlements,
  setEntitlements,
  isDisabled,
  handleIconClick,
  canAccessAso,
  canAccessGrowth,
  canAccessCreative,
  canAccessSocial,
}) {
  const { t } = useTranslation('clientEntitlements');
  const [asoBillingOptions, setASOBillingOptions] = useState([]);
  const [uaBillingOptions, setUABillingOptions] = useState([]);
  const [creativeBillingOptions, setCreativeBillingOptions] = useState([]);
  const [socialBillingOptions, setSocialBillingOptions] = useState([]);
  const form = useForm();

  const isEntitlementActive = (entitlement) => {
    const singleEntitlement = entitlements.filter((item) => item.productFamily === entitlement);
    if (singleEntitlement.length !== 0 && (singleEntitlement[0].status === 'active' || singleEntitlement[0].status === true)) {
      return true;
    }
    return false;
  };

  useQuery(entitlementsAll, {
    onError: () => {},
    context: {
      context: { handleError: true },
    },
    onCompleted: ({ entitlements }) => {
      const asoBillingArray = [];
      const uaBillingArray = [];
      const creativeBillingArray = [];
      const socialBillingArray = [];
      for (const item of entitlements) {
        if (item.productFamily === 'aso') {
          asoBillingArray.push({ label: item.name, value: item.code });
        }
        if (item.productFamily === 'ua') {
          uaBillingArray.push({ label: item.name, value: item.code });
        }
        if (item.productFamily === 'creative') {
          creativeBillingArray.push({ label: item.name, value: item.code });
        }
        if (item.productFamily === 'social') {
          socialBillingArray.push({ label: item.name, value: item.code });
        }
      }
      setASOBillingOptions(asoBillingArray);
      setUABillingOptions(uaBillingArray);
      setCreativeBillingOptions(creativeBillingArray);
      setSocialBillingOptions(socialBillingArray);
    },
  });

  const asoFields = [
    {
      name: 'entitlements.aso.code',
      type: 'select',
      label: t('billingTitle'),
      options: asoBillingOptions,
      width: 1,
      isDisabled: !isEntitlementActive('aso') || isDisabled,
      validate: requiredDependOnOtherFields(['entitlements.aso.status']),
    },
  ];

  const uaFields = [
    {
      name: 'entitlements.ua.code',
      type: 'select',
      label: t('billingTitle'),
      options: uaBillingOptions,
      width: 1,
      isDisabled: !isEntitlementActive('ua') || isDisabled,
      validate: requiredDependOnOtherFields(['entitlements.ua.status']),
    },
  ];

  const creativeFields = [
    {
      name: 'entitlements.creative.code',
      type: 'select',
      label: t('billingTitle'),
      options: creativeBillingOptions,
      width: 1,
      isDisabled: !isEntitlementActive('creative') || isDisabled,
      validate: requiredDependOnOtherFields(['entitlements.creative.status']),
    },
  ];

  const socialFields = [
    {
      name: 'entitlements.social.code',
      type: 'select',
      label: t('billingTitle'),
      options: socialBillingOptions,
      width: 1,
      isDisabled: !isEntitlementActive('social') || isDisabled,
      validate: requiredDependOnOtherFields(['entitlements.social.status']),
    },
  ];
  return (
    <>
      <FormHeader title={t('title')} />
      <FormSection>
        <Flex
          justifyContent="center"
        >
          <Entitlement
            fieldsArray={uaFields}
            entitlements={entitlements}
            setEntitlements={setEntitlements}
            entitlementTag="ua"
            name="entitlements.ua.status"
            iconTitle={t('uaIconTitle')}
            svg={GrowthLogo}
            isEntitlementActive={isEntitlementActive}
            style={{ borderRight: '0.1px solid rgba(151, 67, 235, 0.4)' }}
            onClick={() => handleIconClick('ua')}
            form={form}
            isDisabled={isDisabled}
            canAccessModule={canAccessGrowth}
          />
          <Entitlement
            fieldsArray={creativeFields}
            entitlements={entitlements}
            setEntitlements={setEntitlements}
            entitlementTag="creative"
            name="entitlements.creative.status"
            iconTitle={t('creativeIconTitle')}
            svg={CreativeLogo}
            isEntitlementActive={isEntitlementActive}
            style={{ borderRight: '0.1px solid rgba(151, 67, 235, 0.4)' }}
            onClick={() => handleIconClick('creative')}
            form={form}
            isDisabled={isDisabled}
            canAccessModule={canAccessCreative}
          />
          <Entitlement
            fieldsArray={socialFields}
            entitlements={entitlements}
            setEntitlements={setEntitlements}
            entitlementTag="social"
            name="entitlements.social.status"
            iconTitle={t('socialIconTitle')}
            svg={SocialLogo}
            isEntitlementActive={isEntitlementActive}
            style={{ borderRight: '0.1px solid rgba(151, 67, 235, 0.4)' }}
            onClick={() => handleIconClick('social')}
            form={form}
            isDisabled={isDisabled}
            canAccessModule={canAccessSocial}
          />
          <Entitlement
            fieldsArray={asoFields}
            entitlements={entitlements}
            setEntitlements={setEntitlements}
            entitlementTag="aso"
            name="entitlements.aso.status"
            iconTitle={t('asoIconTitle')}
            svg={ASOLogo}
            isEntitlementActive={isEntitlementActive}
            onClick={() => handleIconClick('aso')}
            form={form}
            isDisabled={isDisabled}
            canAccessModule={canAccessAso}
          />
        </Flex>
      </FormSection>
    </>
  );
}
