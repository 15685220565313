import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReportMenuCategory } from './ReportMenuCategory';

const ReportMenu = (props) => {
  const { data, onReportChange } = props;
  const [categoryWidth, setCategoryWidth] = useState(null);

  useEffect(() => {
    if (data) {
      if (data.gdsReportCategories.length <= 6) {
        setCategoryWidth('16.67%');
      } else {
        const calc = Math.round((100 / data.gdsReportCategories.length) * 100) / 100;
        setCategoryWidth(`${calc}%`);
      }
    }
  }, [data]);

  return (
    <MenuWrapper>
      {data !== undefined
        ? data.gdsReportCategories.map((category, index) => (
          <ReportMenuCategory
            categoryWidth={categoryWidth}
            onReportChange={onReportChange}
            category={category}
            key={index}
          />
        ))
        : null}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 2034px;
`;

export default ReportMenu;
