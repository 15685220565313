import React, { useMemo, useState } from 'react';
import { styled } from '@mui/system';

export const DualListOptionComponent = ({ option, onOptionClick, getLabel }) => {
  const [marked, setMarked] = useState(false);
  const onClick = () => {
    setMarked(!marked);
    onOptionClick(option);
  };
  const label = useMemo(() => getLabel(option), [getLabel, option]);
  return (
    <OptionElement
      onClick={onClick}
      isActive={marked}
    >
      {label}
    </OptionElement>
  );
};
const OptionElement = styled('div')`
  cursor: pointer;
  padding: 3px 15px;
  background-color: ${(props) => (props.isActive ? props.theme.colors.purple : 'None')};
  &:hover {
    background-color: ${(props) => props.theme.colors.lightishPurple};
  }
`;
