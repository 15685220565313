import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );

  return debouncedValue;
}

export const useActionItemColors = () => {
  const { t } = useTranslation('ui', { keyPrefix: 'colors' });
  const options = [
    { color: '#17806D', name: t('#17806D') }, // Dark Green
    { color: '#1CAC78', name: t('#1CAC78') }, // Green
    { color: '#71BC78', name: t('#71BC78') }, // Light Green
    { color: '#2B6CC4', name: t('#2B6CC4') }, // Dark Blue
    { color: '#1DACD6', name: t('#1DACD6') }, // Blue
    { color: '#77DDE7', name: t('#77DDE7') }, // Light Blue
    { color: '#ADD8E6', name: t('#ADD8E6') }, // Grey Blue
    { color: '#00FFFF', name: t('#00FFFF') }, // Aqua
    { color: '#B22222', name: t('#B22222') }, // Dark Red
    { color: '#FF0000', name: t('#FF0000') }, // Red
    { color: '#FF6347', name: t('#FF6347') }, // Light Red
    { color: '#FF4500', name: t('#FF4500') }, // Orange Red
    { color: '#FF8C00', name: t('#FF8C00') }, // Dark Orange
    { color: '#FFA500', name: t('#FFA500') }, // Orange
    { color: '#B8860B', name: t('#B8860B') }, // Dark Gold
    { color: '#DAA520', name: t('#DAA520') }, // Gold
    { color: '#FFD700', name: t('#FFD700') }, // Light Gold
    { color: '#FFFF00', name: t('#FFFF00') }, // Yellow
    { color: '#F0E68C', name: t('#F0E68C') }, // Beige
    { color: '#808000', name: t('#808000') }, // Olive
    { color: '#FFC0CB', name: t('#FFC0CB') }, // Pink
    { color: '#A0522D', name: t('#A0522D') }, // Brown
    { color: '#D2691E', name: t('#D2691E') }, // Orange Brown
    { color: '#CD853F', name: t('#CD853F') }, // Light Brown
    { color: '#D2B48C', name: t('#D2B48C') }, // Tan
    { color: '#C0C0C0', name: t('#C0C0C0') }, // Silver
    { color: '#F5F5F5', name: t('#F5F5F5') }, // Smoke
  ];
  return options;
};
