/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState, useContext, useEffect } from 'react';
import { Box, Card, Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { PlatformContext } from '@upptic/module-directory';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {
  currentUserNotificationHistoryGql,
  dismissAllCurrentUserHistoryEntriesGql,
  dismissCurrentUserHistoryEntryGql,
  updateCurrentUserHistoryEntryGql,
} from './gql';
import SingleNotificationCard from './components/SingleNotificationCard';
import FormHeader from '../Forms/FormHeader';
import ConfirmationDialog from '../ConfirmationDialog';

const NotificationPanel = ({
  isCollapse,
  contentSx,
}) => {
  const { t } = useTranslation('miniHub');
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);
  const [dismissModalOpen, setDismissModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [offset, setOffset] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const limit = 10;

  const emitNotificationStatusChangedEvent = (type, status) => {
    document.dispatchEvent(new CustomEvent('notificationStatusChanged', {
      detail: {
        type,
        status,
        origin: 'hub',
      },
    }));
  };

  const { loading, refetch } = useQuery(currentUserNotificationHistoryGql, {
    skip: !platformStore.currentClient?.code,
    fetchPolicy: 'no-cache',
    onError: () => {},
    context: { handleNetworkError: true },
    onCompleted: ({ currentUserNotificationHistory }) => {
      if (offset === 0) {
        setNotifications(currentUserNotificationHistory.items);
        setTotalNotifications(currentUserNotificationHistory.totalItemCount);
        return;
      }
      setNotifications((prevState) => [
        ...prevState,
        ...currentUserNotificationHistory.items,
      ]);
      setTotalNotifications(currentUserNotificationHistory.totalItemCount);
    },
    variables: {
      clientCode: platformStore.currentClient?.code,
      applicationCode: platformStore.selectedApplication?.code,
      limit,
      offset,
    },
  });

  const [updateHistoryEntry] = useMutation(updateCurrentUserHistoryEntryGql, {
    onError: () => {},
    context: { handleError: true },
  });
  const [dismissHistoryEntry] = useMutation(dismissCurrentUserHistoryEntryGql, {
    onError: () => {},
    context: { handleError: true },
  });
  const [dismissAllHistoryEntries] = useMutation(dismissAllCurrentUserHistoryEntriesGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['currentUserNotificationHistory'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const listener = (e) => {
      setNotifications((notificationsList) => [e.detail, ...notificationsList]);
    };
    document.addEventListener('userNotificationReceived', listener);
    return () => document.removeEventListener('userNotificationReceived', listener);
  }, []);

  useEffect(() => {
    const listener = (e) => {
      if (e?.detail?.origin !== 'hub') {
        refetch();
      }
    };
    document.addEventListener('notificationStatusChanged', listener);
    return () => document.removeEventListener('notificationStatusChanged', listener);
  }, []);

  const onNotificationClick = (code) => {
    updateHistoryEntry({
      variables: {
        clientCode: platformStore.currentClient?.code,
        historyEntryCode: code,
        status: 'read',
      },
    });
    emitNotificationStatusChangedEvent('read', 'unread');
  };

  const onDismissClick = (code, isRead) => {
    const newNotificationArray = notifications;
    const findIndexOfCode = newNotificationArray.findIndex(
      (notification) => notification.code === code,
    );
    newNotificationArray.splice(findIndexOfCode, 1);
    setNotifications([...newNotificationArray]);
    dismissHistoryEntry({
      variables: {
        clientCode: platformStore.currentClient?.code,
        code,
      },
    });
    if (!isRead) emitNotificationStatusChangedEvent('dismiss', 'unread');
  };

  const onDismissAll = () => {
    dismissAllHistoryEntries({
      variables: {
        clientCode: platformStore.currentClient?.code,
        applicationCode:
          platformStore.selectedApplication?.code,
      },
    });
  };

  const renderNotificationCards = useMemo(() => {
    if (notifications && notifications?.length !== 0) {
      return notifications.map((notification) => (
        <SingleNotificationCard
          key={notification.code}
          notification={notification}
          onClick={() => onNotificationClick(notification.code)}
          onDismissClick={(isRead) => onDismissClick(notification.code, isRead)}
        />
      ));
    }
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>{t('notifications.none')}</Typography>
      </Box>
    );
  }, [notifications]);

  const handleScroll = (e) => {
    const isMoreNotifications = offset + limit < totalNotifications;
    const bottom = Math.abs(
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight),
    ) <= 1;
    if (bottom && isMoreNotifications) {
      setOffset((prevState) => prevState + limit);
    }
  };

  const handleDismissAllConfirm = () => {
    setDismissModalOpen(false);
    if (offset !== 0) setOffset(0);
    onDismissAll();
  };

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        border: '1px solid rgba(255, 255, 255, 0.23)',
      }}
    >
      {
        isCollapse ? (
          <Box
            width="100%"
            height={50}
          >
            <FormHeader
              title={t('notifications.title')}
              buttons={[
                {
                  icon: <DoneAllIcon />,
                  onClick: () => setDismissModalOpen(true),
                  tooltip: {
                    title: t('notifications.dismissAll'),
                  },
                },
                {
                  expand: true,
                  setExpanded: () => setExpanded((prevState) => !prevState),
                  expanded,
                  sx: { height: '30px', width: '30px' },
                },
              ]}
              sx={{
                border: '1px solid rgba(255, 255, 255, 0.23)',
                padding: '8px',
                minHeight: '45px',
              }}
            />
            <Collapse
              in={expanded}
              timeout="auto"
            >
              <Box
                onScroll={(e) => handleScroll(e)}
                height={600}
                width="100%"
                p={1}
                sx={{
                  overflowY: 'auto',
                  ...contentSx,
                }}
              >
                {renderNotificationCards}
                {loading ? (
                  <Card
                    sx={{
                      height: '56px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '&:not(last-of-type)': {
                        marginBottom: '8px',
                      },
                      padding: '8px 8px 8px 8px',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Typography>{t('notifications.loading')}</Typography>
                  </Card>
                ) : null}
              </Box>
            </Collapse>
          </Box>
        ) : (
          <>
            <Box
              width="100%"
              height={50}
            >
              <FormHeader
                title={t('notifications.title')}
                buttons={[
                  {
                    icon: <DoneAllIcon />,
                    onClick: () => setDismissModalOpen(true),
                    tooltip: {
                      title: t('notifications.dismissAll'),
                    },
                    sx: { height: 32, width: 32, padding: '0px' },
                  },
                ]}
                sx={{
                  border: '1px solid rgba(255, 255, 255, 0.23)',
                  padding: '8px',
                  minHeight: '45px',
                  marginBottom: '8px',
                }}
              />
            </Box>
            <Box
              onScroll={(e) => handleScroll(e)}
              width="100%"
              height="calc(100% - 59px)"
              p={1}
              sx={{
                overflowY: 'auto',
                ...contentSx,
              }}
            >
              {renderNotificationCards}
              {loading ? (
                <Card
                  sx={{
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:not(last-of-type)': {
                      marginBottom: '8px',
                    },
                    padding: '8px 8px 8px 8px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography>{t('notifications.loading')}</Typography>
                </Card>
              ) : null}
            </Box>
          </>
        )
      }
      <ConfirmationDialog
        open={dismissModalOpen}
        onClose={() => setDismissModalOpen(false)}
        title={t('notifications.dismissAll')}
        text={t('notifications.dismissText')}
        secondaryText={t('notifications.effect')}
        buttons={[
          {
            text: tg('cancel'),
            onClick: () => setDismissModalOpen(false),
          },
          {
            text: tg('confirm'),
            onClick: () => handleDismissAllConfirm(),
          },
        ]}
      />
    </Box>
  );
};

export default observer(NotificationPanel);
