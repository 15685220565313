import * as React from 'react';
import { styled } from '@mui/system';
import Icon, { Icons } from 'components/Icon';
import { ThComponent } from './ThComponent';
import { TrComponent } from './TrComponent';
// -- COMPONENT
export function TableHeader({ headerGroups, isBrowserFirefox, noStickyHeaderFooter, filters }) {
  const headerProps = (column) => {
    let headerProperties = {};
    if (column.sortAvailable
            && typeof column.getSortByToggleProps === 'function') {
      headerProperties = {
        ...headerProperties,
        ...column.getSortByToggleProps(),
      };
    }
    return column.getHeaderProps(headerProperties);
  };
  const renderFilterControls = (column) => {
    if (!column.sortAvailable) {
      return null;
    }
    let sortIcon = Icons.faSort;
    if (column.isSorted) {
      sortIcon = column.isSortedDesc ? Icons.faSortDown : Icons.faSortUp;
    }
    return <SortIcon icon={sortIcon} />;
  };
  return (
    <Thead
      isBrowserFirefox={isBrowserFirefox}
      noStickyHeaderFooter={noStickyHeaderFooter}
    >
      {headerGroups.map((headerGroup, index) => (
        <React.Fragment key={`header-group-${index}`}>
          {filters && (
            <TrComponent
              {...headerGroup.getHeaderGroupProps()}
              key={`header-filters-${index}`}
            >
              {filters()}
            </TrComponent>
          )}
          <TrComponent
            {...headerGroup.getHeaderGroupProps()}
            key={`header-group-${index}`}
          >
            {headerGroup.headers.map((column) => {
              const ThComponentToRender = column.thElement || ThComponent;
              return (
                <ThComponentToRender
                  {...headerProps(column)}
                  key={column.id}
                  headerAlignment={column.headerAlignment}
                  depth={column?.tableDepth ? column.tableDepth : undefined}
                >
                  {column.render('Header')}
                  {renderFilterControls(column)}
                </ThComponentToRender>
              );
            })}
          </TrComponent>
        </React.Fragment>
      ))}
    </Thead>
  );
}
// -- STYLED
const Thead = styled('thead')`
  background-color: ${(props) => props.theme.table.colors.tHeadBackgroundColor};
  ${(props) => props.isBrowserFirefox && !props.noStickyHeaderFooter
  && `
    position: sticky;
    display: block;
    top: 0;
    z-index: 2;
  `}
  tr {
    border-width: 0px;
  }

  & > tr:first-of-type {
    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  & > tr:first-of-type {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }
`;
const SortIcon = styled(Icon)`
  margin-left: 10px;
`;
