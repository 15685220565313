import React from 'react';
import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

function Link(props) {
  return <StyledLink {...props} />;
}
const StyledLink = styled(BaseLink)`
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;
export default Link;
