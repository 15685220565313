/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { PlatformContext } from '@upptic/module-directory';
import { Link } from 'react-router-dom';
import { Button, Grid, Box } from '@mui/material';
import { useResolveLink } from './helper';

function PlaceholderCard({ widget }) {
  const platformStore = useContext(PlatformContext);
  const selectedClientCode = platformStore.currentClient?.code;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const buttonText = widget?.params?.text;
  const resolvedLink = useResolveLink(
    widget?.params?.link,
    { clientCode: selectedClientCode, applicationCode: selectedApplicationCode },
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Box
          height={478}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            component={Link}
            variant="contained"
            to={resolvedLink}
          >
            {buttonText}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PlaceholderCard;
