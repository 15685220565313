/* eslint-disable prefer-template */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from './forms/Button';
import { default as Modal } from './Modal';
import { formatBytes } from '../utils/formatters/index';

function ErrorConfirmationDialog({ onConfirm, open, onClose, title, text, buttons, errorItems }) {
  const { t } = useTranslation('general');

  const renderButton = (button, index) => (
    <ModalButton
      key={index}
      onClick={button.onClick}
      disabled={button.disabled || false}
    >
      {button.name}
    </ModalButton>
  );

  const renderErrorItem = (item, index) => (
    <li key={index}>{item.name + (item.size ? ' -- ' + formatBytes(item.size) : '')}</li>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
    >
      <Wrapper>
        <DefaultText>
          {text
            // eslint-disable-next-line react/no-danger
            ? <span dangerouslySetInnerHTML={{ __html: text.replace('\n', '<br />') }} />
            // eslint-disable-next-line react/no-danger
            : <span dangerouslySetInnerHTML={{ __html: t('errorText').replace('\n', '<br />') }} /> }
        </DefaultText>
        {errorItems && errorItems.items.length !== 0 && errorItems.title ? (
          <ErrorItemsWrapper>
            {errorItems.title}
            <ul>
              {errorItems.items.map(renderErrorItem)}
            </ul>
          </ErrorItemsWrapper>
        ) : null}
        <ActionsWrapper>
          <ButtonWrapper>
            {buttons ? buttons.map(renderButton)
              : (
                <>
                  <ModalButton
                    onClick={onConfirm}
                  >
                    {t('confirm')}
                  </ModalButton>
                </>
              )}
          </ButtonWrapper>
        </ActionsWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 700px;
  background-color: #13132b;
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const DefaultText = styled.div`
  text-align:center;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  justify-content: center;
  margin: 15px 0px 30px 0px;

`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
`;

const ErrorItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalButton = styled(Button)`
  min-width: 100px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

export default ErrorConfirmationDialog;
