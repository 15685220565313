import React from 'react';
import {
  Box,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const StatusCircle = ({
  color,
  glow,
}) => (
  <Box
    width={30}
    height={30}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Box
      width={20}
      height={20}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: '50%',
        boxShadow: glow ? `0 0 20px ${color}, 0 0 22px ${color}` : 'unset',
        color,
      }}
    >
      <CircleIcon />
    </Box>
  </Box>
);

export default StatusCircle;
