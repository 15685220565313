/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import { Box, Typography, IconButton, Grid, Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CSVLink } from 'react-csv';
import { paramCase } from 'param-case';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { reportingAppMetricsGql } from '../gql';
import { colorPalette } from '../../ChartGenerator/ChartGenerator';
import CustomTooltip from '../../ChartGenerator/components/CustomTooltip';
import BarChartCustomTooltip from '../../ChartGenerator/components/BarChartCustomTooltip';
import { formatters } from '../../../utils';
import ElementTitle from '../GenericElements/ElementTitle';
import { formatData } from '../../../utils/formatters/formatters';
import { useGetMetricsFilter } from '../helper';

function BarChartCustomLabel(props) {
  const { x, y, value, width, position, format } = props;
  const formatValue = formatData(value, format?.type || 'number', format);
  return (
    <text
      x={x + width / 2}
      y={y}
      fill="#fff"
      fontSize={16}
      textAnchor="middle"
      dy={position === 'below' ? 18 : -6}
    >
      {formatValue}
    </text>
  );
}

function BarChartElement({ element, appReportingCurrency, modelType }) {
  const { t } = useTranslation('ui');
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;
  const isCsvEnabled = element.csv?.enabled;
  const isTitle = element.title;
  const elementHeight = element.heightFactor ? (250 * element.heightFactor) : 250;
  const chartHeight = !isTitle && !isCsvEnabled
    ? elementHeight
    : (elementHeight - 40);

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);

  const { data, loading } = useQuery(reportingAppMetricsGql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource.metrics,
      filter: metricsFilter,
      dimensions: element.dataSource.dimensions,
      dateRange: element.dataSource?.dateRange,
    },
  });

  const values = useMemo(() => {
    if (!data?.reportingAppMetrics?.items.length) return [];

    if (!element.dataSource.dimensions.length) {
      return element.dataSource.metrics.map((metric) => ({
        name: element.labelMapping?.[metric] || metric,
        value: data.reportingAppMetrics.items[0][metric],
      }));
    }
    return data.reportingAppMetrics.items;
  }, [data?.reportingAppMetrics?.items]);

  const bars = useMemo(() => {
    if (!element.dataSource.dimensions?.length) return ['value'];
    return element.dataSource.metrics;
  }, [element.dataSource.metrics, element.dataSource.dimensions]);

  const legendPayload = useMemo(() => values.map((value, index) => ({
    value: value[element.dataSource?.dimensions?.[0]] || value.name,
    color: colorPalette[index],
    type: 'rect',
  })), [element, values]);

  const dataPointFormat = useMemo(() => {
    if (element.format?.type === 'currency' && appReportingCurrency) {
      return ({
        ...element.format,
        currency: appReportingCurrency,
      });
    }
    return element?.format;
  }, [element?.format, appReportingCurrency]);

  return (
    <Box sx={{ height: elementHeight, textAlign: 'center' }}>
      <Grid
        container
      >
        {isTitle && (
          <Grid
            item
            xs
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={40}
          >
            <ElementTitle title={element.title} ml={isCsvEnabled ? 5 : 0.5} />
          </Grid>
        )}
        {isCsvEnabled && (
          <Grid
            item
            width={40}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CSVLink
              data={values}
              headers={element.csv.headers}
              filename={`${element?.csv?.filename || paramCase(element?.title || '') || 'export'
              }-${new Date().getTime()}.csv`}
            >
              <IconButton>
                <CloudDownloadIcon color="primary" />
              </IconButton>
            </CSVLink>
          </Grid>
        )}
        <Grid
          item xs={12}
          sx={{
            '.recharts-wrapper': {
              position: 'absolute !important',
            },
            '.recharts-tooltip-wrapper': {
              zIndex: 1500,
            },
          }}
        >
          <Box
            position="relative"
            height={chartHeight}
            sx={{
              '.MuiBackdrop-root': {
                zIndex: 1099,
              },
              '.recharts-legend-item': {
                fontSize: '11px',
              },
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={values}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                <XAxis
                  dataKey={element.dataSource.dimensions[0] || 'name'}
                  tick={false}
                  hide
                />
                <YAxis
                  tickline={false}
                  axisLine={false}
                  domain={[0, (dataMax) => Math.ceil(dataMax * 1.15)]}
                  label={element?.yAxis?.label ? {
                    value: element?.yAxis?.currency && appReportingCurrency
                      ? `${element.yAxis.label} (${t(`currency.${appReportingCurrency}`)})`
                      : element.yAxis.label,
                    angle: -90,
                    fill: '#666',
                    dx: -30,
                  } : undefined}
                  tickFormatter={(value) => formatters.formatData(
                    value,
                    'number',
                    { decimalPlaces: element?.yAxis?.decimalPlaces || 0,
                      compactDecimals: element?.yAxis?.compactDecimals,
                      currency: appReportingCurrency,
                    },
                  )}
                />
                <Tooltip
                  {...element.tooltip?.params}
                  content={(
                    <CustomTooltip
                      valueFormatter={(value) => formatData(value, element.format?.type || 'number', dataPointFormat)}
                      CustomTooltipComponent={(props) => BarChartCustomTooltip(props, (element.dataSource?.dimensions?.[0] || 'name'))}
                      colorPalette={colorPalette}
                      chartData={values}
                    />
                  )}
                />
                <Legend
                  payload={legendPayload}
                  iconSize={11}
                />
                {bars.map((metric) => (
                  <Bar
                    dataKey={metric}
                    label={values?.length < 7 || element.showDataLabels
                      ? <BarChartCustomLabel format={dataPointFormat} />
                      : false}
                    animationDuration={2000}
                    animationEasing="ease-in-out"
                  >
                    {
                      colorPalette.map((color, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                      ))
                      }
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
            <Backdrop
              open={(data && values.length === 0) || loading}
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 5,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: chartHeight,
                width: '100%',
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography>{tg('noData')}</Typography>
              )}
            </Backdrop>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(BarChartElement);
