import { get } from 'lodash';

export const handleError = (meta, formState, customErrorField = null) => {
  // !meta.dirtySinceLastSubmit makes the error clear once you change the input field with error
  if (meta.submitError && !meta.dirtySinceLastSubmit) return meta.submitError;
  if (!meta.dirtySinceLastSubmit && customErrorField && formState.submitErrors) {
    const fieldError = get(formState.submitErrors, customErrorField);
    if (fieldError) return fieldError;
  }
  if (meta.submitError && meta.error && meta.touched) return meta.submitError;
  if (meta.touched && meta.error) return meta.error;
  if (formState?.touched && Object.values(formState?.touched).some((state) => state)) {
    return meta.submitError || meta.error;
  }
  return null;
};
