import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import Icon, { Icons } from 'components/Icon';
import themes from './themes';
import Tooltip from './Tooltip';
// -- COMPONENT
export const TextWithTooltip = ({ value, tooltipContent, onIconClick }) => (
  <Flex alignItems="center">
    <Box>{value}</Box>
    <IconBox onClick={onIconClick}>
      <Icon
        icon={Icons.faQuestionCircle}
        color={themes.colors.white}
        data-for={`text-tooltip-${value}`}
        data-tip={tooltipContent}
      />
      <Tooltip
        id={`text-tooltip-${value}`}
        place="left"
      />
    </IconBox>
  </Flex>
);
// -- STYLED
const IconBox = styled(Box)`
  padding: 0 10px;
  cursor: pointer;
`;
