import React, { useState } from 'react';
import styled from 'styled-components';
// -- COMPONENT
const Popover = ({ children, content, trigger = 'onClick', hideDelay = 3 }) => {
  const [isVisible, setIsVisible] = useState(false);
  let hideTimeout = 0;
  const setVisibility = () => {
    clearTimeout(hideTimeout);
    setIsVisible(true);
    hideTimeout = setTimeout(() => {
      setIsVisible(false);
    }, hideDelay * 1000);
  };
  const triggerProps = {
    [trigger]: setVisibility,
  };
  return (
    <Wrapper {...triggerProps}>
      {isVisible && <PopoverContent>{content}</PopoverContent>}
      {children}
    </Wrapper>
  );
};
// -- STYLES
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
const PopoverContent = styled.div`
  border: 2px solid #5d3393;
  border-radius: 4px;
  background-color: #3c3c51;
  color: #fff;
  padding: 4px 8px;
  top: -45px;
  position: absolute;
  word-break: normal;
`;
export default Popover;
