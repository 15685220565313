import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';

export const DualListOptionComponent = ({
  option,
  onOptionClick,
  getLabel,
  valueField,
  boxSize = 40,
  disabled,
  CustomOptionComponent,
}) => {
  const theme = useTheme();
  const [marked, setMarked] = useState(false);
  const onClick = () => {
    if (disabled) return;
    setMarked(!marked);
    onOptionClick(option);
  };
  const label = useMemo(() => getLabel(option), [getLabel, option]);
  return (
    <RowWrapper
      onClick={onClick}
      key={`row-${option[valueField]}`}
      disabled={disabled}
    >
      <Row
        boxSize={boxSize}
        isActive={marked}
        theme={theme}
        disabled={disabled}
      >
        {CustomOptionComponent ? (
          <CustomOptionComponent
            option={option}
            onOptionClick={onOptionClick}
            label={label}
            marked={marked}
            setMarked={setMarked}
          />
        ) : (
          <Box mx={1}>
            <Typography>
              {label}
            </Typography>
          </Box>
        )}
      </Row>
    </RowWrapper>
  );
};

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin 300ms ease;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
`;

const Row = styled.div`
  width: 95%;
  height: ${(props) => props.boxSize}px;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 6px;
  background-color: ${(props) => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.background.default)};
  &:hover {
    background-color: ${(props) => (props.isActive
    ? props.theme.palette.primary.main
    : !props.disabled
      ? props.theme.palette.primary.dark
      : props.theme.palette.background.default)};
  }
`;
