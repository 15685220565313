import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function StyledLink({
  children,
  to = '',
  style = null,
}) {
  return (
    <BaseLink
      to={to}
      style={style}
    >
      {children}
    </BaseLink>
  );
}

const BaseLink = styled(Link)`
  ${(props) => { props.style; }}
  &:hover {
    text-decoration: none;
  }
`;

export default StyledLink;
