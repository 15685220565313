import React, { useEffect, useState } from 'react';
import { TextInput } from '.';
import { useDebounce } from '../../utils/hooks';

export const GridSearch = ({ placeholder = 'Search', items, valueKey, onChange }) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 200);

  useEffect(() => {
    if (!items || !debouncedValue) {
      onChange(null);
      return;
    }
    const searchTerm = debouncedValue.toLowerCase();
    onChange(items.filter((item) => item[valueKey].toLowerCase().includes(searchTerm)));
  }, [items, debouncedValue, onChange, valueKey]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <TextInput
      placeholder={placeholder}
      value={searchValue}
      onChange={handleInputChange}
    />
  );
};
