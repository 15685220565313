/* eslint-disable max-len */
import gql from 'graphql-tag';

export const reportingAppMetricsGql = gql`
query reportingAppMetrics(
  $clientCode: String!, $applicationCode: String!, $filter: Filter,
  $metrics: [Metric]!, $dimensions: [Dimension], $dateRange: AppReportingDateRange, $sort: [String!]
) {
  reportingAppMetrics(
    clientCode: $clientCode
    applicationCode: $applicationCode
    metrics: $metrics
    dimensions: $dimensions
    filter: $filter
    dateRange: $dateRange
    sort: $sort
  ) {
    items {
      day
      week
      month
      channel
      channelType
      platform
      installs
      budget
      budgetUsage
      spend
      additiveSpend
      totalRev
      roasD1
      roasD3
      roasD5
      roasD7
      roasD14
      roasD30
      roasD60
      roasD90
      roasD120
      roasD180
      roasD365
      roasD540
      arpuD1
      arpuD3
      arpuD5
      arpuD7
      arpuD14
      arpuD30
      arpuD60
      arpuD90
      w2WInstalls
      w2WSpend
      m2MInstalls
      m2MSpend
      mtdInstalls
      mtdSpend
      eomForecast1D
      eomForecast7D
      cpi
      totalPurchaseRev
      totalAdRev
    }
    totalItemCount
  }
}
`;

export const clientAppBudgetsGql = gql`
query clientAppBudgets($clientCode: String!, $applicationCode: String!) {
  clientAppBudgets(clientCode: $clientCode, applicationCode: $applicationCode) {
    month
    budget
  }
}
`;

export const discordMetricsGql = gql`
query discordMetrics (
  $clientCode: String!, $applicationCode: String!,
  $period: Period, $startDate: Date, $endDate: Date
){
  discordMetrics(
    clientCode: $clientCode
    applicationCode: $applicationCode
    period: $period
    startDate: $startDate
    endDate: $endDate
  ) {
    metrics {
      metricDate
      totalUserCount
      totalUserDiff
      totalInviteCodeCount
      joins
      exits
      rejoins
      userAvgLifespan
    }
  }
}
`;

export const trackCurrentUserGql = gql`
  mutation trackCurrentUser(
    $clientCode: String!
    $applicationCode: String!
    $trackingInfo: TrackingInfo!
  ) {
    trackCurrentUser(
      clientCode: $clientCode
      applicationCode: $applicationCode
      trackingInfo: $trackingInfo
    )
  }
`;
