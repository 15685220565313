import React from 'react';
import {
  Stack,
  Button,
  Box,
  Backdrop,
  Typography,
  CircularProgress,
} from '@mui/material';

/**
 * @param {Boolean} param.open
 * @param {Boolean} param.loading
 * @param {string} param.text
 * @param {object[]} param.buttons
 * @param {string} param.buttons[].name
 * @param {Boolean} param.buttons[].disabled
 * @param {Function} param.buttons[].onClick
 */

export default function DataGridConfirmationOverlay({
  open,
  loading,
  text,
  buttons,
}) {
  const renderButton = (button, index) => (
    <Button
      key={index}
      disabled={button.disabled}
      type={!button.onClick ? 'submit' : undefined}
      onClick={button.onClick}
      variant="contained"
      sx={{ minWidth: '130px' }}
    >
      {button.name}
    </Button>
  );

  return (
    <Backdrop
      open={open}
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 5,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <CircularProgress size="6rem" />
        ) : (
          <>
            <Typography>
              {text}
            </Typography>
            <Stack
              sx={{ width: '100%' }}
              spacing={2}
              pt={2}
              direction="row"
              justifyContent="center"
            >
              {buttons && buttons.map(renderButton)}
            </Stack>
          </>
        )}
      </Box>
    </Backdrop>
  );
}
