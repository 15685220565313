import gql from 'graphql-tag';

export const entitlementsAll = gql`
  query{
    entitlements{
      code
      name
      params
      productFamily
      status
    }
}
`;
