/* eslint-disable no-continue */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import {
  DataGridPro,
  useGridApiRef,
  GridEditInputCell,
} from '@mui/x-data-grid-pro';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import moment from 'moment';
import DatePicker from '../DatePicker';
import DataGridConfirmationOverlay from './DataGridConfirmationOverlay';
import { generateActionColumn } from './generateActionColumn';
import Toolbar from './Toolbar';
import { NoRows } from './NoRows';
import { getDateInCurrentTimestamp } from '../../utils/dates';
import { LoadingOverlay } from './LoadingOverlay';

// To make the Data Grid in Row Editable the following params are used:
/**
 * @param {boolean} editable
 * @param {function} onRowSave - row data is passed through; can return error data
 * @returns {object} { errors: graphQl errors object, message: translated string, field: string, value }
 * @param {function} onRowDelete - row data is passed through
 * @param {object[]} rowActions - action column buttons
 */
/* const rowActions = [
  {
    type: 'edit,
    disabled: !condition,
  }
  {
    type: 'delete,
    renderCondition: (row) => renderCheck(),
  },
  {
    type: 'clipboard,
    tooltip: {
      title: 'Copy to Clipboard',
      clipboardTitle: 'Copied to Clipboard!',
    },
    valueKey: 'externalCode',
  },
  {
    type: 'clipboard,
    tooltip: {
      title: t('tooltip'),
      clipboardTitle: t('clipboardTooltip'),
    },
    valueText: (row) => `https://discord.gg/${row?.externalCode}`,
  },
  {
    IconComponent: <AddIcon />,
    tooltip: {
      title: t('addEvent'),
      placement: 'left',
    },
    onClick: (row) => handleClick(),
    disabled: !condition,
  },
  {
    IconComponent: <AddIcon />,
    to: `${clientCode}/creative`,
  }
];
*/
// Toolbar params
/**
 * @param {object[]} toolbarButtons - Toolbar only renders if this is passed
*/
/* const toolbarButtons = [
  {
    type: 'add',
    label: t('addEvent'),
    disabled: !condition,
  },
  {
    IconComponent: <AddIcon />,
    label: t('addEvent'),
    onClick: (props) => handleOnClick();
    disabled: !condition,
  },
];
*/
// Generic custom params
/**
 * @param {object} confirmationOverlayProps
*/
/*
const confirmationOverlayProps = {
  deleteText: (row) => t('eventDeleteConfirmation', { name: findName() }),
}
const confirmationOverlayProps = {
  nameKey: 'name',
}
*/

const DataGrid = ({
  parentContainerProps,
  rows,
  columns,
  sx,
  sort = [{ field: 'name', sort: 'asc' }],
  toolbarButtons,
  rowActions,
  editable,
  loading,
  confirmationOverlayProps,
  onRowSave,
  onPostRowSave,
  onRowDelete,
  idKey,
  checkboxSelection,
  slots,
  idToScrollTo,
  height = 450,
  ...rest
}) => {
  const theme = useTheme();
  const { t: tg } = useTranslation('general');
  const { t } = useTranslation('ui');
  const [rowModesModel, setRowModesModel] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState({ open: false });
  const [tableErrors, setTableErrors] = useState({});
  const [gridRows, setGridRows] = useState([]);
  const apiRef = useGridApiRef();

  const getRowIndexRelativeToVisibleRows = useCallback((id) => apiRef.current.getRowIndexRelativeToVisibleRows(id), [apiRef]);
  useEffect(() => {
    if (idToScrollTo) {
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: getRowIndexRelativeToVisibleRows(idToScrollTo),
        });
      }, 400);
    }
  }, [idToScrollTo]);

  const formatRowData = useMemo(() => {
    const formattedRows = [];
    if (rows && rows.length !== 0) {
      for (const row of rows) {
        formattedRows.push({
          id: row?.[idKey] || row?.code || randomId(),
          ...row,
        });
      }
    }
    return formattedRows;
  }, [rows]);

  useEffect(() => {
    setGridRows(formatRowData);
  }, [formatRowData]);

  const hasDeleteAction = useMemo(() => rowActions?.find((action) => action?.type === 'delete'), [rowActions]);

  const calculateActionColumnWidth = useMemo(() => {
    if (rowActions && rowActions.length > 2) {
      return (((rowActions.length - 2) * 50) + 150);
    }
    return 150;
  }, [rowActions]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow) => {
    const { id } = newRow;
    const { errors, message, value, field } = await onRowSave(newRow);
    const promise = new Promise((resolve, reject) => {
      if (errors) {
        setTableErrors({
          ...tableErrors,
          [id]: { id, message, value, field },
        });
        apiRef.current.setCellFocus(id, field);
        reject(new Error(errors));
      }
      if (!errors) {
        if (Object.keys(tableErrors).length !== 0) setTableErrors({});
      }
      const updatedRow = { ...newRow, isNew: false };
      resolve(updatedRow);
    });
    const response = await promise;
    return response;
  };

  const formattedColumns = useMemo(() => {
    const columnArray = [];
    const requiredFields = [];
    const editableFields = [];
    const renderEditTextInput = (params, col) => {
      const { value } = params;
      const findTableError = tableErrors[params?.id];
      const hasError = () => {
        if (findTableError && findTableError?.field === col?.field && findTableError?.value === value) {
          return true;
        }
        return params?.error;
      };

      if (col.type === 'autocomplete') {
        return (
          <Autocomplete
            placeholder={col.placeholder}
            onChange={(_event, newValue) => {
              if (col.onChange) return col.onChange(newValue, params);
              return params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
            }}
            onInputChange={col.onInputChange}
            loading={col.loading}
            options={col.valueOptions}
            value={params.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                sx={{
                  '& .MuiFilledInput-root': {
                    paddingY: '10px',
                  },
                }}
              />
            )}
            fullWidth
          />
        );
      }

      if (col.type === 'date') {
        return (
          <DatePicker
            value={params.value}
            error={hasError()}
            onChange={(item) => {
              if (item !== null) {
                const createDate = new Date(item);
                // eslint-disable-next-line no-restricted-globals
                const isValid = createDate instanceof Date && !isNaN(createDate);
                if (isValid) {
                  params.api.setEditCellValue({ id: params.id, field: params.field, value: createDate.toISOString() });
                  return;
                }
                params.api.setEditCellValue({ id: params.id, field: params.field, value: createDate });
                return;
              }
              params.api.setEditCellValue({ id: params.id, field: params.field, value: item });
            }}
          />
        );
      }

      if (hasError()) {
        return (
          <Tooltip
            title={findTableError?.message}
            open
            arrow
            placement="left"
          >
            <GridEditInputCell
              {...params}
              disabled={col.disabled}
              error={hasError()}
            />
          </Tooltip>
        );
      }

      return (
        <GridEditInputCell
          {...params}
          disabled={col.disabled}
          error={hasError()}
        />
      );
    };
    if (columns) {
      for (const col of columns) {
        if (col?.required && editable) requiredFields.push(col?.field);
        if (col?.editable && editable) editableFields.push(col?.field);

        if (col.type === 'date' && col?.editable && editable) {
          columnArray.push({
            display: 'flex',
            ...col,
            valueFormatter: (value) => moment(getDateInCurrentTimestamp(value)).format('L'),
            preProcessEditCellProps: (params) => {
              const { value } = params?.props;
              const findTableError = tableErrors[params?.id];
              const hasError = () => {
                if (findTableError && findTableError?.field === col?.field) {
                  if (findTableError?.value !== value) {
                    return false;
                  }
                  return true;
                }
                if (col?.required) {
                  return !params.props.value;
                }
                return false;
              };
              return {
                ...params.props,
                error: hasError(),
              };
            },
            renderEditCell: (params) => renderEditTextInput(params, col),
          });
          continue;
        }

        if ((!col.type || col.type === 'autocomplete' || col.type === 'number') && col?.editable && editable) {
          columnArray.push({
            display: 'flex',
            ...col,
            preProcessEditCellProps: (params) => {
              const { value } = params?.props;
              const findTableError = tableErrors[params?.id];
              const hasError = () => {
                if (findTableError && findTableError?.field === col?.field) {
                  if (findTableError?.value !== value) {
                    if (col?.required) {
                      setTableErrors({
                        ...tableErrors,
                        [params.id]: {
                          message: t('errors.required'),
                        },
                      });
                      return params.props.value === null || params.props.value === '';
                    }
                    const newErrors = { ...tableErrors };
                    delete newErrors[params.id];
                    setTableErrors(newErrors);
                    return false;
                  }
                  return true;
                }
                if (col?.required) {
                  setTableErrors({
                    ...tableErrors,
                    [params.id]: {
                      message: t('errors.required'),
                    },
                  });
                  return params.props.value === null || params.props.value === '';
                }
                return false;
              };
              return {
                ...params.props,
                error: hasError(),
              };
            },
            renderEditCell: (params) => renderEditTextInput(params, col),
          });
          continue;
        }
        columnArray.push({ display: 'flex', ...col });
      }
      if (rowActions) {
        columnArray.push(
          generateActionColumn({
            setRowModesModel,
            rowModesModel,
            setGridRows,
            gridRows,
            setConfirmationOpen,
            rowActions,
            columnArray,
            tg,
            requiredFields,
            editableFields,
            calculateActionColumnWidth,
            onPostRowSave,
          }),
        );
      }
    }
    return columnArray;
  }, [columns, tableErrors, rowActions, editable, rowModesModel, apiRef]);

  return (
    <Box
      py={2}
      height={height}
      sx={editable ? {
        display: 'grid',
        position: 'relative',
        '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-cell--editing:focus-within': {
          outline: 'none !important',
          boxShadow: 'inset 0 0 0 1px #9743eb',
        },
        '& .Mui-error': {
          border: `1px solid ${theme.palette.error.main}`,
        },
        '& .MuiDataGrid-cell--editing': {
          '& .MuiInputBase-root': {
            height: '100%',
          },
        },
        '& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
          marginTop: '1rem',
          marginBottom: '1rem',
        },
        '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
          color: theme.palette.primary.main,
        },
        '.MuiDataGrid-overlayWrapper': {
          height: 'auto !important',
        },
        '.MuiDataGrid-overlayWrapperInner': {
          height: 'auto !important',
        },
      } : {
        display: 'grid',
        '& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
          marginTop: '1rem',
          marginBottom: '1rem',
        },
        '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
          color: theme.palette.primary.main,
        },
        '.MuiDataGrid-overlayWrapper': {
          height: 'auto !important',
        },
        '.MuiDataGrid-overlayWrapperInner': {
          height: 'auto !important',
        },
      }}
      {...parentContainerProps}
    >
      <DataGridPro
        apiRef={apiRef}
        rows={gridRows}
        columns={formattedColumns}
        getRowId={(row) => row.id}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.default,
          },
          ...sx,
        }}
        initialState={{
          sorting: {
            sortModel: sort,
          },
        }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        editMode={editable ? 'row' : undefined}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={() => {}}
        checkboxSelection={checkboxSelection}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: NoRows,
          loadingOverlay: LoadingOverlay,
          ...slots,
        }}
        slotProps={{
          toolbar: {
            setGridRows,
            gridRows,
            setRowModesModel,
            apiRef,
            toolbarButtons,
            formattedColumns,
            rowModesModel,
          },
        }}
        loading={loading}
        {...rest}
      />
      {hasDeleteAction && (
        <DataGridConfirmationOverlay
          open={confirmationOpen?.open}
          loading={loading}
          text={confirmationOverlayProps?.nameKey
            ? tg('deleteText', { item: get(confirmationOpen?.row, confirmationOverlayProps?.nameKey, '') })
            : confirmationOverlayProps?.deleteText(confirmationOpen?.row)}
          buttons={[
            {
              name: tg('cancel'),
              onClick: () => setConfirmationOpen({ ...confirmationOpen, open: false }),
            },
            {
              name: tg('confirm'),
              onClick: () => {
                setConfirmationOpen({ ...confirmationOpen, open: false });
                onRowDelete(confirmationOpen?.row);
                setConfirmationOpen({ open: false });
              },
            },
          ]}
        />
      )}
    </Box>
  );
};

export default DataGrid;
