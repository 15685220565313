/* eslint-disable no-continue */
class DynamicFieldsHelper {
  /**
   * creates the initial values for Dynamic Fields settings
   * based on the field definition and the current values.
   * @param {*} fields
   * @param {*} entitySettings
   * @returns
   */
  static mapInitValues(fields = [], creativeSettings = {}) {
    const entitySettings = Object.entries(creativeSettings).map((item) => ({ code: item[0], value: item[1] }));
    const settings = entitySettings.reduce((acc, setting) => {
      acc[setting.code] = setting;
      return acc;
    }, {}) || {};
    return fields.reduce((acc, { type, code, defaultValue }) => {
      if (type === 'date' && defaultValue === 'NOW' && (!settings[code]?.value || settings[code]?.value === 'NOW')) {
        defaultValue = new Date().toISOString();
        if (settings[code]) settings[code].value = new Date().toISOString();
      }
      acc[code] = {
        value: settings[code]?.value !== null && settings[code]?.value !== undefined ? settings[code]?.value : defaultValue,
      };
      return acc;
    }, {});
  }

  static mapValuesForSetMutation(values) {
    const mutationValues = {};
    for (const [key, { value, isInherited }] of Object.entries(values)) {
      if (value === '' || value === undefined || value === null || isInherited) {
        mutationValues[key] = null;
        continue;
      }
      mutationValues[key] = value;
    }
    return mutationValues;
  }
}

export default DynamicFieldsHelper;
