import React from 'react';
import styled from 'styled-components';

function DividerText({ children }) {
  return (
    <Separator>
      <SeparatorLine />
      <SeparatorText>{children}</SeparatorText>
      <SeparatorLine />
    </Separator>
  );
}
const Separator = styled.div`
  padding: 0 20px;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SeparatorText = styled.span`
  padding: 0 10px;
  color: #9d9da8;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
`;
const SeparatorLine = styled.div`
  height: 1px;
  width: 20px;
  background: #9d9da8;
  display: inline-block;
`;
export default DividerText;
