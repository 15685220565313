import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { RemoveCircleOutline as RemoveIcon } from '@mui/icons-material';
import Field from './Field';
import Operator from './Operator';
import Value from './Value';

const Filter = ({
  availableFields,
  filter,
  onChange,
  onRemove,
}) => (
  <Grid
    container
    sx={{
      '& > div': {
        marginBottom: (theme) => theme.spacing(0.5),
        marginTop: (theme) => theme.spacing(0.5),
      },
    }}
    spacing={2}
  >
    <Grid item>
      <IconButton
        sx={{
          marginRight: (theme) => theme.spacing(-1),
          marginTop: (theme) => theme.spacing(0.75),
        }}
        size="small"
        onClick={onRemove}
      >
        <RemoveIcon sx={{ fill: 'rgba(255, 0, 0, 0.9)' }} />
      </IconButton>
    </Grid>
    <Grid item>
      <Field
        value={filter.field}
        options={availableFields}
        onChange={(newValue) => {
          if (!filter.operator) {
            onChange({ operator: newValue.operators[0], field: newValue, value: null });
            return;
          }
          onChange({ field: newValue, value: null });
        }}
      />
    </Grid>
    <Grid item>
      <Operator
        field={filter.field}
        value={filter.operator}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </Grid>
    <Grid
      item
      sx={{ flex: 'auto' }}
    >
      <Value
        field={filter.field}
        operator={filter.operator}
        value={filter.value}
        error={filter.field.error}
        onChange={(newValue) => {
          onChange({ value: newValue });
        }}
      />
    </Grid>
  </Grid>
);

export default Filter;
