import React, { useState } from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../../Icon';

export const ReportMenuCategory = (props) => {
  const { category, onReportChange, categoryWidth } = props;
  const [subNav, setSubNav] = useState(false);

  const showSubNav = () => setSubNav(!subNav);

  const renderReport = (report) => {
    showSubNav();
    onReportChange(report);
  };

  return (
    <>
      <CategoryMenuWrapper
        categoryWidth={categoryWidth}
        onMouseEnter={category.gdsReports.length > 0 ? showSubNav : null}
        onMouseLeave={showSubNav}
      >
        <MenuItem>
          <MenuNameWrapper>{category.name}</MenuNameWrapper>
          {category.gdsReports.length > 0 ? (
            <IconWrapper>
              <Icon
                icon={Icons.faCaretDown}
                size="sm"
              />
            </IconWrapper>
          ) : (
            <IconWrapper />
          )}
          <SubMenuWrapper>
            {subNav
            && category.gdsReports.map((report, index) => (
              <SubMenuItem key={index}>
                <SubMenuNameWrapper
                  key={index + report.name}
                  onClick={() => renderReport(report)}
                >
                  {report.name}
                </SubMenuNameWrapper>
              </SubMenuItem>
            ))}
          </SubMenuWrapper>
        </MenuItem>
      </CategoryMenuWrapper>
    </>
  );
};

// -- STYLES

const CategoryMenuWrapper = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.categoryWidth};
  height: 40px;
  color: #ffffff;
  background-color: #07071e;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-right: 5px;
  box-shadow: inset 1px -1px 0px 0px rgba(35, 35, 58, 1),
    inset -1px 1px 0px 0px rgba(35, 35, 58, 1);
  &:hover {
    box-shadow: inset 1px -1px 0px 0px rgba(87, 159, 245, 1),
      inset -1px 1px 0px 0px rgba(87, 159, 245, 1);
  }

  @media (max-width: 1540px) {
    font-size: 11px;
  }
`;

const SubMenuWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #07071e;
  box-shadow: inset 0.5px -0.5px 0px 0px rgba(151, 67, 235, 1),
    inset -0.5px -0.5px 0px 0px rgba(151, 67, 235, 1);
`;

const MenuItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    box-shadow: 0 0 15px 0 rgba(87, 159, 245, 0.5);
    // border: 1px solid #579ff5;
  }
`;

const SubMenuItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  transition: height 2s;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: rgba(151, 67, 235, 0.5);
  }
`;

const MenuNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
  width: 80%;
  height: 100%;
`;

const SubMenuNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
`;
