import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import MediaGallery from '../components/MediaGallery';

const GalleryModal = ({
  open,
  onClose,
  title,
  data,
  loading,
  mediaCode,
  RenderItem,
  childrenHeight,
  children,
}) => {
  const { t: tg } = useTranslation('general');
  const theme = useTheme();
  const galleryData = data?.filter((media) => media.previewImage !== null) || [];
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <Card
        sx={{ border: '1px solid' }}
      >
        <DialogTitle backgroundColor={theme.palette.secondary.main}>{title}</DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
          {loading || !data ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '80vh',
              }}
            >
              <CircularProgress size="6rem" />
            </Box>
          ) : (
            <MediaGallery
              useMuiTheming
              mediaCode={mediaCode}
              data={galleryData}
              renderItem={(item) => (
                <RenderItem
                  item={item}
                />
              )}
              children={children}
              childrenHeight={childrenHeight}
            />
          )}
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            mt={2}
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              sx={{ minWidth: '130px' }}
              onClick={() => onClose()}
            >
              {tg('close')}
            </Button>
          </Stack>
        </DialogContent>
      </Card>
    </Dialog>
  );
};

export default GalleryModal;
