import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import theme from 'components/themes';
import FormError from './FormError';

const NumberInput = ({ decimalSpaces = 0, error, light = false, name, onChange, value, inputSufix, ...rest }) => {
  const [savedValue, setSavedValue] = useState(value);
  useEffect(() => {
    setSavedValue(value);
  }, [value]);
  const isMatchingFormat = (typedValue, numberOfDecimalSpaces) => {
    // ^\d+\.?\d{0,2}?$
    const integerPart = '\\d+';
    const decimalPart = `\\.?\\d{0,${numberOfDecimalSpaces}}?`;
    const regexp = new RegExp(`^${integerPart}${numberOfDecimalSpaces > 0 ? decimalPart : ''}$`);
    return regexp.test(typedValue);
  };
  const handleChange = (event) => {
    const typedValue = event.target.value;
    const formatError = !isMatchingFormat(typedValue, decimalSpaces);
    if (typedValue === '' || typedValue === undefined || savedValue === undefined) {
      setSavedValue('');
      onChange(null);
    }
    const numericValue = Number(typedValue);
    if (Number.isNaN(numericValue) || formatError) {
      return;
    }
    setSavedValue(typedValue);
    onChange(numericValue);
  };
  const reformatOnBlur = () => {
    if (savedValue === '' || savedValue === undefined) {
      setSavedValue('');
      onChange(null);
      return;
    }
    const numberedValue = Number(savedValue);
    onChange(numberedValue);
    setSavedValue(numberedValue);
  };
  return (
    <>
      <Wrapper onBlur={reformatOnBlur} valid={!error}>
        <InputWrapper>
          <StyledInput
            {...rest}
            light={light}
            id={name}
            valid={!error}
            onChange={handleChange}
            value={savedValue}
            inputSufix={inputSufix}
          />
          {inputSufix && <Suffix {...rest} valid={!error}>{inputSufix}</Suffix>}
        </InputWrapper>
      </Wrapper>
      {error ? <FormError>{error}</FormError> : null}
    </>
  );
};
// eslint-disable-next-line
const Wrapper = styled('div') `
  ${(props) => !props.valid
    && `
    box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
  `};
`;
const Input = styled('input')`
  outline: none;
  width: 100%;
  padding: 10px 15px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.background};
  background-color: ${(props) => (props.light ? theme.colors.inputsLight : theme.colors.inputs)};
  color: ${theme.colors.white};
  font-size: 16px;
  opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};

  ${(props) => !props.valid
    && `
    border: 1px solid ${theme.colors.notificationRed};
  `};

  ::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
const StyledInput = styled(Input)`
  ${(props) => props.inputSufix
    && `
      padding-right: 0px;
      border-right-width: 0px;
  `};
`;
const Suffix = styled('div')`
  display: flex;

  border: 1px solid ${(props) => props.theme.colors.background};
  border-left-width: 0px;

  background-color: ${(props) => props.theme.colors.inputs};

  opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};

  margin-left: -1px;
  padding-right: 15px;

  align-items: center;
  color: ${(props) => props.theme.colors.white};
  ${(props) => !props.valid
    && `
    border-top: 1px solid ${theme.colors.notificationRed};
    border-right: 1px solid ${theme.colors.notificationRed};
    border-bottom: 1px solid ${theme.colors.notificationRed};
  `};
`;
const InputWrapper = styled('div')`
  display: flex;
`;
export default NumberInput;
