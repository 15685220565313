import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SelectInput from 'components/forms/SelectInput/SelectInput';
import { SelectDropdownOptions } from 'components/forms/SelectInput/constants/selectDropdownOptions';
import { itemsCountOptions } from '../constants/itemsCountOptions';
// -- COMPONENT
export function ItemsCountSelector({ initialItemsCount, onCountSelect, currentItemsPerPage }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const deduplicatedSet = new Set([...itemsCountOptions, initialItemsCount]);
    const optionList = Array.from(deduplicatedSet)
      .sort((a, b) => a - b)
      .map((singleOption) => {
        const stringifiedValue = String(singleOption);
        return {
          label: stringifiedValue,
          value: stringifiedValue,
        };
      });
    setOptions(optionList);
  }, [setOptions, initialItemsCount]);
  const handleChange = (value) => {
    onCountSelect(Number(value));
  };
  return (
    <Wrapper>
      Rows per page
      <SelectInput
        value={String(currentItemsPerPage)}
        options={options}
        onChange={handleChange}
        menuPosition={SelectDropdownOptions.top}
      />
    </Wrapper>
  );
}
// -- STYLED
const Wrapper = styled.div`
  margin-left: 30px;

  font-size: 14px;

  color: rgba(255, 255, 255, 0.4);

  display: flex;
  align-items: center;

  > div {
    margin-left: 10px;
    width: 75px;
  }
`;
