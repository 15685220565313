import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoRows = () => {
  const { t: tg } = useTranslation('general');
  return (
    <Box
      sx={{
        height: 72,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="noRows"
    >
      <Typography variant="body2">{tg('noRows')}</Typography>
    </Box>
  );
};
