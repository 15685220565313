/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo } from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
  PieChart,
  Pie,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import {
  Grid,
  Typography,
  IconButton,
  Backdrop,
  Box,
  CircularProgress,
  useTheme,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import get from 'lodash.get';
import { CSVLink } from 'react-csv';
import { paramCase } from 'param-case';
import moment from 'moment';
import FormSectionWFields from '../Forms/FormSectionWFields';
import { formatters } from '../../utils';

import CustomTooltip from './components/CustomTooltip';
import PieChartCustomTooltip from './components/PieChartCustomTooltip';

export default function ChartGenerator({
  data,
  definition,
  onControlChange,
  spacing = 4,
  initialValues,
  loading,
  appReportingCurrency,
  parentContainerSx,
  controlsSpacing,
  controlsColumnSpacing,
}) {
  const { t: tg } = useTranslation('general');
  const themes = useTheme();

  const renderCsvButton = (element, isDateBasedDiagram = true) => {
    let isDisabled = false;
    if (element.type === 'radarChart') {
      const currentElementData = get(data, element.dataKey, []);
      if (!currentElementData.length) isDisabled = true;
    }
    if (element?.elements?.length === 0) isDisabled = true;
    const csvHeaders = element.csv?.headers || [];
    if (!element.csv?.headers) {
      if (isDateBasedDiagram) csvHeaders.push({ label: 'Date', key: 'metricDate' });
      csvHeaders.push(...element.elements.map((el) => ({ label: el.name, key: el.dataKey })));
    }

    const csvData = get(data, element.dataKey, [])
      ?.map((el) => ({
        ...el,
        metricDate: ('metricDate' in el) ? moment(el.metricDate).format('YYYY-MM-DD') : undefined,
      }));

    return !isDisabled ? (
      <CSVLink
        data={element?.csv?.dataGetter ? element.csv.dataGetter({ data: csvData, element }) : csvData}
        headers={csvHeaders}
        filename={`${element?.csv?.filename || paramCase(element.headline?.label) || 'export'
        }-${new Date().getTime()}.csv`}
      >
        <IconButton>
          <CloudDownloadIcon color="primary" />
        </IconButton>
      </CSVLink>
    ) : (
      <Box>
        <IconButton disabled>
          <CloudDownloadIcon />
        </IconButton>
      </Box>
    );
  };

  const renderChartActions = (element, isDateBasedDiagram = true) => {
    if (element.hideChartActions) return null;
    return (
      <Grid
        container
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid
          item
          xs={6}
        >
          {element.headline && element.combinedHeader && !element.hideHeadline ? (
            <Typography
              variant="body1"
              ml={0.5}
              align={element.headline.align || 'left'}
            >
              {element.headline.label}
            </Typography>
          ) : null}
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="center"
          justifyContent="right"
        >
          {element.actions?.map((action) => {
            if (action.CustomComponent) {
              return <action.CustomComponent />;
            }
            return (
              <IconButton onClick={action.onClick}>
                <action.IconComponent />
              </IconButton>
            );
          })}
          {renderCsvButton(element, isDateBasedDiagram)}
        </Grid>
      </Grid>
    );
  };

  const defaultYAxisFormatter = (value) => formatters.formatData(value, 'number', { decimals: 0, currency: appReportingCurrency });

  const renderElements = (element) => {
    const result = [];

    if (element.headline && !element.combinedHeader && !element.hideHeadline) {
      result.push(
        <Typography
          variant="body1"
          ml={0.5}
          align={element.headline.align || 'left'}
        >
          {element.headline.label}
        </Typography>,
      );
    }

    if (element.type === 'tile') {
      if (element.subType === 'scorecard') {
        result.push(
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minWidth={element.compact ? 100 : 150}
            width="100%"
            height={element.height || 105}
            borderRadius={1}
            backgroundColor={themes.palette.primary.dark}
            p={1}
          >
            {element.items?.map((item) => (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  height={element.compact ? 45 : 60}
                  mr={0.5}
                  width="50%"
                >
                  <Box>
                    <Typography
                      fontSize="15px"
                      variant="subtitle1"
                      marginTop={element?.compact ? '5px' : '10px'}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  height={element.compact ? 45 : 60}
                  ml={0.5}
                  width="50%"
                >
                  <Box>
                    <Typography
                      fontSize={element.compact ? '30px' : '40px'}
                      noWrap
                      variant="subtitle1"
                    >
                      {formatters.formatData(
                        get(data, item.dataKey, 0),
                        item?.format,
                        {
                          decimalPlaces: item?.decimalPlaces || 0,
                          compactDecimals: item?.compactDecimals,
                          precision: element?.precision || 2,
                          currency: appReportingCurrency,
                        },
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>,
        );
        return result;
      }
      result.push(
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minWidth={element.compact ? 100 : 150}
          width="100%"
          height={element.height || 105}
          borderRadius={1}
          backgroundColor={themes.palette.primary.dark}
          p={1}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={30}
          >
            <Typography
              fontSize="15px"
            >
              {element.label}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={45}
          >
            <Typography fontSize={element.compact ? '30px' : '40px'}>
              {formatters.formatData(
                get(data, element.dataKey, 0),
                element?.format,
                {
                  decimalPlaces: element?.decimalPlaces || 0,
                  compactDecimals: element?.compactDecimals,
                  precision: element?.precision || 2,
                  currency: appReportingCurrency,
                },
              )}
            </Typography>
          </Box>
        </Box>,
      );
      return result;
    }

    if (element.type === 'pie') {
      const chartData = useMemo(() => get(data, element.dataKey, []), [data, element.dataKey]);
      const CustomLabelComponent = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, index, midAngle, innerRadius, outerRadius, percent } = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = (cy - 25) + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={13}
          >
            <tspan x={x} dy="1.2em">
              {chartData[index].name}
            </tspan>
            <tspan x={x} dy="1.2em">
              {`${(percent * 100).toFixed(0)}%`}
            </tspan>
          </text>
        );
      };
      return (
        <Grid
          item
          xs={12}
          sx={{
            '.recharts-wrapper': {
              position: 'absolute !important',
            },
            '.recharts-tooltip-wrapper': {
              zIndex: 1500,
            },
          }}
        >
          <Box
            position="relative"
            height={element.height}
            sx={{
              '.MuiBackdrop-root': {
                zIndex: 1099,
              },
            }}
          >
            <ResponsiveContainer height={element.height || 300}>
              <PieChart>
                <Pie
                  dataKey="value"
                  animationDuration={2000}
                  animationEasing="ease-in-out"
                  data={chartData}
                  labelLine={false}
                  label={CustomLabelComponent}
                  outerRadius="100%"
                >
                  {chartData.map((el, index) => {
                    if (chartData?.length <= 2) {
                      if (index === 0) return <Cell key={`cell-${index}`} fill={colorPalette[4]} />;
                      return <Cell key={`cell-${index}`} fill={colorPalette[2]} />;
                    }
                    return (
                      <Cell key={`cell-${index}`} fill={colorPalette[index]} />
                    );
                  })}
                </Pie>
                <Tooltip
                  content={(
                    <CustomTooltip
                      CustomTooltipComponent={(props) => PieChartCustomTooltip(props, 'name')}
                      colorPalette={colorPalette}
                      chartData={chartData}
                    />
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
            <Backdrop
              open={(chartData && chartData.length === 0) || loading}
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 5,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: element.height,
                width: '100%',
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography>{tg('noData')}</Typography>
              )}
            </Backdrop>
          </Box>
        </Grid>
      );
    }

    if (element.type === 'sparklineChart') {
      if (!element.yAxis) element.yAxis = [];
      const leftYAxis = element.yAxis?.find(({ orientation }) => orientation !== 'right');
      const rightYAxis = element.yAxis?.find(({ orientation }) => orientation === 'right');
      if (!leftYAxis) {
        element.yAxis.push({ tickLine: false, axisLine: false, orientation: 'left' });
      }
      if (!rightYAxis && element.layout !== 'vertical') {
        element.yAxis.push({ tickLine: false, axisLine: false, orientation: 'right' });
      }
      const leftYAxisTickFormatter = leftYAxis?.formatter || defaultYAxisFormatter;

      const hasBarElements = element.elements.some((el) => el.type === 'bar');
      const chartData = useMemo(() => get(data, element.dataKey, []), [data, element.dataKey]);
      const renderLegend = () => {
        if (element.hideLegend) return null;
        if (element.customLegendPayload) {
          return (
            <Legend
              payload={element.customLegendPayload}
            />
          );
        }
        return <Legend />;
      };
      result.push(
        renderChartActions(element),
        <Box
          position="relative"
          sx={{
            '.MuiBackdrop-root': {
              zIndex: 1099,
            },
          }}
        >
          <ResponsiveContainer height={element.height || 300}>
            <ComposedChart
              data={chartData}
              stackOffset={element.stackOffset}
              margin={{
                top: 0,
                right: 10,
                bottom: element?.layout !== 'vertical' ? 0 : 10,
                left: element?.layout !== 'vertical' ? 10 : 60,
              }}
              layout={element?.layout}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#555" />
              <XAxis
                hide={element.xAxis?.hide}
                dataKey={element.xAxis.dataKey}
                tickFormatter={element.xAxis.tickFormatter}
                padding={{
                  top: 0,
                  left: hasBarElements ? 0 : 40,
                  bottom: 0,
                  right: hasBarElements ? 0 : 40,
                }}
                type={element?.xAxis?.type}
                domain={element?.xAxis?.domain}
                label={element?.xAxis?.label}
              />
              {element.secondXAxis ? (
                <XAxis
                  dataKey={element.secondXAxis.dataKey}
                  xAxisId={element.secondXAxis.xAxisId}
                  padding={{
                    top: 0,
                    left: hasBarElements ? 0 : 40,
                    bottom: 0,
                    right: hasBarElements ? 0 : 40,
                  }}
                  allowDuplicatedCategory={false}
                />
              ) : null}
              {element.yAxis.map((yAxis) => (
                <YAxis
                  {...yAxis}
                  tickFormatter={yAxis.formatter || defaultYAxisFormatter}
                />
              )) }
              {renderLegend()}
              <Tooltip
                {...element.tooltip?.params}
                content={(
                  <CustomTooltip
                    mainAxisTickFormatter={element?.layout === 'vertical'
                      ? undefined
                      : element?.tooltip?.labelFormatter
                        ? element.tooltip.labelFormatter
                        : element.xAxis.tickFormatter}
                    valueFormatter={element.tooltip?.formatter || leftYAxisTickFormatter}
                    CustomTooltipComponent={element.tooltip?.CustomTooltipComponent}
                    // eslint-disable-next-line no-use-before-define
                    colorPalette={colorPalette}
                    chartData={chartData}
                    payloadFormatter={element?.tooltip?.payloadFormatter}
                  />
                )}
              />
              {element.zAxis ? (
                <ZAxis range={[200, 31]} />
              ) : null}
              {element.referenceLine
                ? <ReferenceLine {...element.referenceLine} />
                : null}
              {element.elements.map((el) => {
                if (el.type === 'area') {
                  return (
                    <Area
                      name={el.name}
                      dataKey={el.dataKey}
                      type={el.params.type || 'monotoneX'}
                      unit={el.params.unit}
                      animationDuration={2000}
                      animationEasing="ease-in-out"
                      fill={el.params.areaColor}
                      stroke={el.params.areaStrokeColor}
                      yAxisId={el.yAxisId}
                    />
                  );
                }
                if (el.type === 'bar') {
                  return (
                    <Bar
                      name={el.name}
                      dataKey={el.dataKey}
                      animationDuration={2000}
                      animationEasing="ease-in-out"
                      fill={el.params.barColor}
                      yAxisId={el.yAxisId}
                      unit={el.params.unit}
                      label={el.label}
                      stackId={el.stackId}
                      onClick={(data, index) => {
                        if (element.activeIndex === index) {
                          el.onClick(null, null);
                          return;
                        }
                        el.onClick(data, index);
                      }}
                    >
                      {
                      // eslint-disable-next-line no-use-before-define
                      el.params.useColorPalette ? (el.params.colorPalette || colorPalette).map((color, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={color}
                          cursor={el.onClick ? 'pointer' : 'default'}
                        />
                      )) : undefined
                      }
                      {el.onClick ? (
                        chartData?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={index === element.activeIndex ? 'cyan' : undefined}
                            strokeWidth={index === element.activeIndex ? 4 : 0}
                            cursor={el.onClick ? 'pointer' : 'default'}
                          />
                        ))
                      ) : undefined}
                    </Bar>
                  );
                }

                if (el.type === 'scatter') {
                  return (
                    <Scatter
                      name={el.name}
                      data={el.data}
                      dataKey={el.dataKey}
                      yAxisId={el.yAxisId}
                      unit={el.params.unit}
                      shape="square"
                      fill={el?.params?.fill}
                      animationDuration={2000}
                      animationEasing="ease-in-out"
                    />
                  );
                }

                return (
                  <Line
                    name={el.name}
                    data={el.data}
                    dataKey={el.dataKey}
                    type={el.params.type || 'monotoneX'}
                    animationDuration={2000}
                    animationEasing="ease-in-out"
                    unit={el.params.unit}
                    dot={{ strokeWidth: 2, r: 1 }}
                    yAxisId={el.yAxisId}
                    stroke={el.params.stroke}
                    strokeDasharray={el.params.strokeDasharray}
                    strokeWidth={4}
                  />
                );
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <Backdrop
            open={(data && (element?.elements?.length === 0 || get(data, element.dataKey, [])?.length === 0)) || loading}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 5,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              height: element?.height || '300px',
              width: '100%',
            }}
          >
            {loading ? (
              <CircularProgress size="3rem" />
            ) : (
              <Typography>{tg('noData')}</Typography>
            )}
          </Backdrop>
        </Box>,
      );
      return result;
    }

    if (element.type === 'radarChart') {
      const currentElementData = get(data, element.dataKey, []);
      result.push(
        renderChartActions(element, false),
        <Box
          position="relative"
          sx={{
            '.MuiBackdrop-root': {
              zIndex: 1099,
            },
          }}
        >
          <ResponsiveContainer height={element.height || 300}>
            <RadarChart
              data={currentElementData}
            >
              {currentElementData.length ? (
                <>
                  <Tooltip content={<CustomTooltip />} />
                  <PolarGrid />
                  <PolarAngleAxis tick={{ stroke: '#DDD' }} dataKey={element.angleAxis.dataKey} />
                  <PolarRadiusAxis tick={{ stroke: '#999' }} />
                  {element.elements.map((el) => (
                    <Radar
                      name={el.name}
                      dataKey={el.dataKey}
                      fill={el.params.fill}
                      fillOpacity={el.params.fillOpacity}
                    />
                  ))}
                  <Legend />
                </>
              ) : null}
            </RadarChart>
          </ResponsiveContainer>
          <Backdrop
            open={(data && !currentElementData.length) || loading}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 5,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              height: element?.height || '300px',
              width: '100%',
            }}
          >
            {loading ? (
              <CircularProgress size="3rem" />
            ) : (
              <Typography>{tg('noData')}</Typography>
            )}
          </Backdrop>
        </Box>,
      );
      return result;
    }

    return null;
  };

  return (
    <>
      {definition?.controls ? (
        <Form onSubmit={() => {}} initialValues={initialValues}>
          {({ handleSubmit, values }) => {
            useEffect(() => {
              onControlChange && onControlChange(values);
            }, [values]);
            return (
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                style={{ textAlign: 'left' }}
              >
                <FormSectionWFields
                  fields={definition.controls}
                  spacing={controlsSpacing || 4}
                  columnJustifyContent="flex-end"
                  columnSpacing={controlsColumnSpacing || 6}
                  disablePadding
                />
              </form>
            );
          }}
        </Form>
      ) : null}
      <Grid
        container
        spacing={spacing}
        pt={definition?.controls ? 4 : 0}
        sx={parentContainerSx || {}}
      >
        {definition.elements.map((element) => (
          <Grid
            item
            xs={element.xs || 12}
            sm={element.sm || undefined}
            md={element.md || undefined}
            lg={element.lg || undefined}
            xl={element.xl || undefined}
            minWidth={element.minWidth || undefined}
            m={element.margin || undefined}
            sx={{
              '.recharts-wrapper': {
                position: 'absolute !important',
              },
              '.recharts-tooltip-wrapper': {
                zIndex: 1500,
              },
            }}
          >
            {renderElements(element)}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export const colorPalette = [
  '#9742eb',
  '#1976D2',
  '#5C6BC0',
  '#FFB300',
  '#388E3C',
  '#00838F',
  '#F4511E',
  '#9E9D24',
  '#8E24AA',
  '#AD1457',
  '#004D40',
  '#880E4F',
  '#4527A0',
  '#0277BD',
  '#558B2F',
  '#F9A825',
  '#B71C1C',
  '#EF6C00',
  '#6D4C41',
  '#424242',
  '#455A64',
];

export const contrastColorPalette = [
  '#EBEB42',
];

export const secondaryColorPalette = [
  '#C6A0F5',
  '#94B0DA',
  '#BFC2D6',
  '#FBEEC2',
  '#B8CFB4',
  '#B5CAD1',
  '#EDB6A4',
  '#DDDCA6',
  '#E2C3EE',
  '#DF99B5',
  '#9DB0AB',
  '#E5A3C5',
  '#B4AAD7',
  '#BDD8F3',
  '#C3D3B4',
  '#FFF0D0',
  '#CDA19E',
  '#EEC9B0',
  '#A4BEB5',
  '#B5ACAC',
  '#C4C4C4',
];
