/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState } from 'react';
import {
  Grid,
  Collapse,
  Typography,
  IconButton,
  Button,
  styled,
  CardActions,
  Card,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PlatformContext } from '@upptic/module-directory';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { gdsReportsGql } from './gql';
import { ExpandMore } from '../../MUIStyledComponents/Generic/generic';
import SingleReport from '../../components/Reports/iframe/SingleReport';

const StyledCard = styled(Card)(({ theme, scheme, active }) => ({
  backgroundColor: scheme === 'secondary' ? theme.palette.secondary.main : theme.palette.background.paper,
  ...theme.typography.body2,
  padding: active ? `calc(${theme.spacing(0.3)} - 3px)` : theme.spacing(0.3),
  textAlign: 'center',
  color: theme.palette.text.primary,
  border: active ? `3px solid ${theme.palette.primary.dark}` : 'none',
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(0.3),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  minWidth: '120px',
  color: theme.palette.text.primary,
}));

const ResizeableIcon = styled((props) => {
  const { IconComponent, ...other } = props;
  return <IconComponent {...other} />;
})(() => ({
  width: '25px',
  height: '25px',
}));

const ReportCard = ({
  load,
  product,
  additionalParams = {},
  expandOnInit = false,
  selectedReport,
  expansionParams = { type: 'collapse' },
}) => {
  const { t } = useTranslation('campaigns');
  const { clientCode } = useParams();
  const platformStore = useContext(PlatformContext);
  const applicationCode = platformStore.selectedApplication?.code;
  const [expanded, setExpanded] = useState(!!expandOnInit);

  const { data: gdsReportsData } = useQuery(gdsReportsGql, {
    onError: () => {},
    context: {
      handleError: true,
    },
    skip: !load,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      product,
      additionalParams,
    },
  });

  return (
    <StyledCard>
      <StyledCardActions>
        <Grid
          container
        >
          <Grid
            item
            xs={12}
          >
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid
                item
              >
                {expansionParams?.type === 'collapseAndClose' || expansionParams?.type === 'analytics' ? (
                  <IconButton
                    onClick={() => expansionParams?.onClick()}
                  >
                    <ResizeableIcon
                      IconComponent={(props) => <CloseIcon {...props} />}
                    />
                  </IconButton>
                ) : null}
              </Grid>
              <Grid
                item
                sx={{ padding: '0px', display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  variant="subtitle1"
                >
                  {selectedReport ? selectedReport.name : gdsReportsData?.gdsReports[0]?.name}
                </Typography>
              </Grid>
              <Grid
                item
              >
                {selectedReport?.documentationUrl ? (
                  <Grid
                    container
                  >
                    <Grid
                      item
                      sx={{ paddingRight: '8px' }}
                    >
                      <Button
                        variant="contained"
                        sx={{ minWidth: '130px' }}
                        href={selectedReport?.documentationUrl}
                        target="_blank"
                      >
                        {t('needHelp')}
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
                {expansionParams?.type === 'collapse' || expansionParams?.type === 'collapseAndClose' ? (
                  <Grid
                    container
                  >
                    <Grid
                      item
                    >
                      <ExpandMore
                        expand={expanded}
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ResizeableIcon
                          IconComponent={(props) => <ExpandMoreIcon {...props} />}
                        />
                      </ExpandMore>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledCardActions>
      <Collapse
        in={expanded}
        timeout={300}
      >
        <Grid
          container
          justifyContent="center"
          width="100%"
        >
          <Grid
            item
            xs={12}
            justifyContent="center"
          >
            {selectedReport ? (
              <SingleReport
                url={selectedReport.url}
                selectedReport={{}}
              />
            )
              : gdsReportsData && gdsReportsData?.gdsReports?.length !== 0 ? (
                <SingleReport
                  url={gdsReportsData?.gdsReports[0].url}
                  selectedReport={{}}
                />
              ) : (
                <StyledChip
                  label={t('noData.report')}
                />
              )}
          </Grid>
        </Grid>
      </Collapse>
    </StyledCard>
  );
};

export default ReportCard;
