import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from './forms/Button';
import TextArea from './forms/TextArea';
import { default as Modal } from './Modal';

function DeleteConfirmationDialog({
  onConfirm, itemIdentifier, disabledButton, open, onClose, title, text, buttons, withComment = false,
}) {
  const { t } = useTranslation('general');
  const [comment, setComment] = useState('');

  const handleCommentChange = (event) => {
    setComment(event.target.value.trim());
  };

  const renderButton = (button, index) => (
    <ModalButton
      key={index}
      onClick={button.onClick}
      disabled={button.disabled || false}
    >
      {button.name}
    </ModalButton>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
    >
      <Wrapper>
        <DefaultText>
          {text
            // eslint-disable-next-line react/no-danger
            ? <span dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br />') }} />
            // eslint-disable-next-line react/no-danger
            : <span dangerouslySetInnerHTML={{ __html: t('deleteText', { item: itemIdentifier || '' }).replace('\n', '<br />') }} /> }
        </DefaultText>
        {withComment && (
          <CommentWrapper>
            <TextArea
              style={{ height: '205px' }}
              onChange={handleCommentChange}
              placeholder={t('comment')}
              autoFocus
            />
          </CommentWrapper>
        )}
        <ActionsWrapper>
          <ButtonWrapper>
            {buttons ? buttons.map(renderButton)
              : (
                <>
                  <ModalButton onClick={onClose}>
                    {t('cancel')}
                  </ModalButton>
                  <ModalButton
                    onClick={() => onConfirm(comment || null)}
                    disabled={disabledButton}
                  >
                    {t('confirm')}
                  </ModalButton>
                </>
              )}
          </ButtonWrapper>
        </ActionsWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 700px;
  background-color: #13132b;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DefaultText = styled.div`
  width: 100%;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  justify-content: center;
`;

const CommentWrapper = styled.div`
  width: 100%;
  margin: 30px 0 0 0;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled(Button)`
  min-width: 100px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

export default DeleteConfirmationDialog;
