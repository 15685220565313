import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  styled,
  Paper,
} from '@mui/material';

export const PrimaryPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(0.3),
  border: `3px solid ${theme.palette.primary.dark}`,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function StickyHeaderTable({
  maxHeight,
  minHeight,
  columns,
  rows,
  disableHeader,
}) {
  const theme = useTheme();
  return (
    <PrimaryPaper>
      <TableContainer
        sx={{
          maxHeight,
          minHeight,
          '::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Table
          stickyHeader
        >
          {!disableHeader ? (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      minWidth: column.minWidth,
                      padding: '8px',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody
            sx={{
              'tr:not(:last-of-type)': {
                borderBottom: '1px solid rgba(81, 81, 81, 1)',
              },
              'td:not(:last-of-type)': {
                borderRight: '1px solid rgba(81, 81, 81, 1)',
              },
            }}
          >
            {rows.map((row) => (
              <TableRow
                hover
                key={row.code}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: theme.palette.background.paper,
                        padding: '8px',
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.component ? (
                        column.component(row)
                      ) : (
                        column.format && typeof value === 'number'
                          ? column.format(value)
                          : value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PrimaryPaper>
  );
}
