/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState, useEffect } from 'react';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
  FormControlLabel,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { Search } from './Search';
import OverflowTooltip from '../OverflowTooltip';

export default function SelectionList({
  onChange,
  options,
  value = [],
  height = 300,
  labelField = 'label',
  valueField = 'value',
  groupingField,
}) {
  const { t } = useTranslation('ui');
  const checkForAllOptions = (baseArray, targetArray) => targetArray.every((item) => baseArray.includes(item));
  const availableOptionsArray = useMemo(() => options?.map((option) => option?.[valueField]), [options]);
  const [selectedItems, setSelectedItems] = useState(
    value.includes('all')
      ? options?.map((option) => option[valueField])
      : value,
  );
  const [selectAllChecked, setSelectAllChecked] = useState(value.includes('all') || checkForAllOptions(value, availableOptionsArray));
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (checkForAllOptions(selectedItems, availableOptionsArray)) {
      onChange(['all']);
      return;
    }
    onChange(selectedItems);
  }, [selectedItems]);

  const onSearchTermEntered = (searchItem) => {
    if (searchTerm !== searchItem) setSearchTerm(searchItem);
  };

  const optionsDisplay = useMemo(() => {
    if (groupingField) {
      const groupedOptionsArray = [];
      const groupedOptionsObject = {};
      for (const option of options) {
        if (!groupedOptionsObject[option[groupingField]]) groupedOptionsObject[option[groupingField]] = [];
        groupedOptionsObject[option[groupingField]].push(option);
      }
      // eslint-disable-next-line guard-for-in
      for (const key in groupedOptionsObject) {
        groupedOptionsArray.push(
          { [labelField]: key, type: 'section' },
          ...groupedOptionsObject[key],
        );
      }
      if (searchTerm) {
        const formattedSearchTerm = searchTerm.toLowerCase();
        return groupedOptionsArray
          ?.filter((option) => option?.[valueField]?.toLowerCase()?.includes(formattedSearchTerm)
              || option?.[labelField]?.toLowerCase()?.includes(formattedSearchTerm)
              || option?.[groupingField]?.toLowerCase()?.includes(formattedSearchTerm));
      }
      return groupedOptionsArray;
    }
    if (searchTerm) {
      const formattedSearchTerm = searchTerm.toLowerCase();
      return options?.filter(
        (option) => option[valueField].toLowerCase().includes(formattedSearchTerm) || option[labelField].toLowerCase().includes(formattedSearchTerm),
      );
    }
    return options;
  }, [options, searchTerm]);

  const handleSelectAllClick = (checked) => {
    if (checked) {
      setSelectAllChecked(true);
      setSelectedItems([...availableOptionsArray]);
      return;
    }
    setSelectAllChecked(false);
    setSelectedItems([]);
  };
  const handleSelectItem = (optionValue) => {
    const itemIndex = selectedItems.findIndex((item) => item === optionValue);
    if (itemIndex !== -1) {
      const newArray = [...selectedItems];
      newArray.splice(itemIndex, 1);
      setSelectedItems(newArray);
      if (selectAllChecked) setSelectAllChecked(false);
    } else {
      if (checkForAllOptions([...selectedItems, optionValue], availableOptionsArray)) {
        setSelectedItems([...availableOptionsArray]);
        setSelectAllChecked(true);
        return;
      }
      const newArray = [...selectedItems, optionValue];
      setSelectedItems(newArray);
    }
  };
  const handleSelectSectionClick = (group) => {
    const sectionOptionsArray = optionsDisplay?.filter((option) => option?.[groupingField] === group)?.map((option) => option?.[valueField]);
    if (selectAllChecked) setSelectAllChecked(false);
    setSelectedItems(sectionOptionsArray);
  };

  const handleSelectSearchTerm = () => {
    const searchTermArray = optionsDisplay?.filter((option) => !option?.type)?.map((option) => option?.[valueField]);
    if (selectAllChecked) setSelectAllChecked(false);
    setSelectedItems(searchTermArray);
  };
  const listItems = (props) => {
    const { data, index, style } = props;
    if (data[index]?.type) {
      return (
        <ListItem
          disableGutters
          dense
          disablePadding
          divider
          style={style}
          key={index}
          component="div"
        >
          <ListItemText disableTypography>
            <Typography noWrap>
              <OverflowTooltip>
                {data?.[index]?.[labelField]}
              </OverflowTooltip>
            </Typography>
          </ListItemText>
          <Button
            variant="contained"
            sx={{
              padding: '0px',
              minWidth: '140px',
            }}
            onClick={() => handleSelectSectionClick(data[index]?.[labelField])}
          >
            {t('selectionList.onlyGroup')}
          </Button>
        </ListItem>
      );
    }
    return (
      <ListItem
        disableGutters
        dense
        disablePadding
        divider
        style={style}
        key={index}
        component="div"
      >
        <ListItemButton
          onClick={() => handleSelectItem(data?.[index]?.[valueField])}
          disableGutters
          dense
        >
          <ListItemIcon>
            <Checkbox
              checked={selectedItems?.includes(data?.[index]?.[valueField])}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography noWrap>
              <OverflowTooltip>
                {data?.[index]?.[labelField]}
              </OverflowTooltip>
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
      >
        <Grid
          item
          width={50}
        >
          <FormControlLabel
            control={<Checkbox sx={{ padding: '0px 9px 5px 9px' }} />}
            label="All"
            labelPlacement="bottom"
            checked={selectAllChecked}
            onChange={(e) => handleSelectAllClick(e.target.checked)}
            sx={{
              marginLeft: '0px',
              marginRight: '0px',
              marginBottom: '0px',
            }}
          />
        </Grid>
        <Grid
          item
          xs
        >
          <Search
            noPadding
            inputSize="small"
            width="100%"
            searchTerm={searchTerm}
            onSearchTermEntered={(searchItem) => onSearchTermEntered(searchItem)}
            label={t('search.title')}
            placeholder={t('search.searchItems')}
          />
        </Grid>
        {searchTerm ? (
          <Grid
            item
            xs={12}
            display="flex"
            alignItes="center"
            justifyContent="flex-end"
            mb={1}
          >
            <Button
              variant="contained"
              sx={{
                padding: '0px',
                minWidth: '140px',
              }}
              onClick={() => handleSelectSearchTerm()}
            >
              {t('selectionList.onlySearch')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <FixedSizeList
        itemData={optionsDisplay}
        height={height}
        width="100%"
        itemSize={50}
        itemCount={optionsDisplay?.length}
        overscanCount={5}
      >
        {listItems}
      </FixedSizeList>
    </Box>
  );
}
