import React from 'react';
import { Grid } from '@mui/material';

export default function GroupCard({ widget, WidgetRenderer }) {
  return (
    <Grid container spacing={2}>
      {widget.subWidgets?.map((widget, index) => {
        const sx = { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } };
        if (widget.widthXs === 0) sx.display.xs = 'none';
        if (widget.widthSm === 0) sx.display.sm = 'none';
        if (widget.widthMd === 0) sx.display.md = 'none';
        if (widget.widthLg === 0) sx.display.lg = 'none';
        if (widget.widthXl === 0) sx.display.xl = 'none';
        return (
          <Grid
            key={`${widget.type}-${index}`}
            item
            xs={widget.widthXs || undefined}
            sm={widget.widthSm || undefined}
            md={widget.widthMd || undefined}
            lg={widget.widthLg || undefined}
            xl={widget.widthXl || undefined}
            sx={sx}
          >
            <WidgetRenderer widget={widget} />
          </Grid>
        );
      })}
    </Grid>
  );
}
