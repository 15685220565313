import * as React from 'react';
import { styled } from '@mui/system';
import { Pagination } from 'components/Pagination/Pagination';
import { TdComponent } from './TdComponent';
import { TrComponent } from './TrComponent';
// -- COMPONENT
export function TableFooter({
  columnsNumber,
  pagesCount,
  currentPage,
  onPaginationChange,
  displayItemsCountSelector,
  initialItemsCount,
  isBrowserFirefox,
  noStickyHeaderFooter,
}) {
  return (
    <Tfoot
      isBrowserFirefox={isBrowserFirefox}
      noStickyHeaderFooter={noStickyHeaderFooter}
    >
      <TrComponent>
        <TdComponent colSpan={columnsNumber}>
          <Pagination
            pagesCount={pagesCount}
            currentPageNumber={currentPage}
            onPaginationChange={onPaginationChange}
            displayItemsCountSelector={displayItemsCountSelector}
            initialItemsCount={initialItemsCount}
          />
        </TdComponent>
      </TrComponent>
    </Tfoot>
  );
}
// -- STYLED
const Tfoot = styled('tfoot')`
  background-color: ${(props) => props.theme.table.colors.tFootBackgroundColor};
  ${(props) => props.isBrowserFirefox && !props.noStickyHeaderFooter
  && `
    position: sticky;
    display: block;
    bottom: 0;
    z-index: 2;
  `}
  & > tr {
    td {
      background-color: ${(props) => props.theme.table.colors.tFootBackgroundColor};
    }

    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
`;
