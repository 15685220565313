/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { Form } from 'react-final-form';
import { PlatformContext } from '@upptic/module-directory';
import { useQuery } from '@apollo/client';

import ActionItemFormSection from './ActionItemFormSection';
import { actionItemCategoriesGql } from '../gql';

const ActionItemFormModal = ({
  data,
  open,
  onClose,
  onSubmit,
  isUserSpecific,
  linkedEntity,
  categoryFilter,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const hasLinkedEntity = linkedEntity || data?.linkedEntity;
  const { data: categoryData } = useQuery(actionItemCategoriesGql, {
    skip: !clientCode,
    onError: () => {},
    context: {
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      filter: categoryFilter || {},
    },
  });
  const actionItemCategories = useMemo(() => {
    if (categoryData && categoryData?.actionItemCategories?.length !== 0) {
      return categoryData.actionItemCategories.map((item) => ({ value: item.code, label: item.name }));
    }
    return [];
  }, [categoryData]);
  const initialValues = useMemo(() => ({
    name: '',
    status: 'open',
    type: 'action',
    actionItemCategory: { code: actionItemCategories?.[0]?.value || null },
    ...data,
    assignees: data?.members && data?.members?.length !== 0
      ? data?.members?.filter((member) => member.type === 'assignee')
      : [],
    watchers: data?.members && data?.members?.length !== 0
      ? data?.members?.filter((member) => member.type === 'watcher')
      : [],
  }), [data, actionItemCategories]);
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <Form
        onSubmit={(values) => (data ? onSubmit(values, data?.code) : onSubmit(values))}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, pristine, values }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Card
              sx={{ border: '1px solid' }}
            >
              <DialogTitle backgroundColor={theme.palette.secondary.main}>{data ? t('modal.editTitle') : t('modal.title')}</DialogTitle>
              <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
                <ActionItemFormSection
                  values={values}
                  includeAudit={!!data}
                  includeApplications={!!data && !isUserSpecific}
                  hasLinkedEntity={hasLinkedEntity}
                  actionItemCategories={actionItemCategories}
                />
                <Stack
                  sx={{ width: '100%' }}
                  spacing={2}
                  pt={2}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    sx={{ minWidth: '130px' }}
                    onClick={() => onClose()}
                  >
                    {tg('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ minWidth: '130px' }}
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    {tg('save')}
                  </Button>
                </Stack>
              </DialogContent>
            </Card>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default ActionItemFormModal;
