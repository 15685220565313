import React from 'react';
import { Grid } from '@mui/material';
import { DateRangeElement } from './ScoreCardElements/DateRangeElement';
import DiscordOverviewChartElement from './CommunitiyElements/DiscordOverviewChartElement';

function DiscordCard({ widget }) {
  const renderElements = (element) => {
    switch (element.type) {
      case 'discordOverview':
        return (<DiscordOverviewChartElement element={element} />);
      case 'dateRange':
        return (<DateRangeElement element={element} />);
      default:
        return (<p>{element.type}</p>);
    }
  };
  return (
    <Grid container>
      {widget.params.elements?.map((element, index) => {
        const sx = { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } };
        if (element.widthXs === 0) sx.display.xs = 'none';
        if (element.widthSm === 0) sx.display.sm = 'none';
        if (element.widthMd === 0) sx.display.md = 'none';
        if (element.widthLg === 0) sx.display.lg = 'none';
        if (element.widthXl === 0) sx.display.xl = 'none';
        return (
          <Grid
            key={`${element.type}-${index}`}
            item
            xs={element.widthXs || undefined}
            sm={element.widthSm || undefined}
            md={element.widthMd || undefined}
            lg={element.widthLg || undefined}
            xl={element.widthXl || undefined}
            sx={sx}
          >
            {renderElements(element)}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DiscordCard;
