import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckboxInput from '../forms/CheckboxInput';
import TextInput from '../forms/TextInput';
import Icon, { Icons } from '../Icon';
import { validators } from '../../utils';

export const OptionRow = ({
  row,
  index,
  rows,
  column,
  myRef,
  boxSize,
  rowFields,
  updateValue,
  onChecked,
  deleteRow,
  data,
  onRowClick,
  marked,
}) => {
  const { t } = useTranslation('creativeGeneral');
  const theme = useTheme();
  const onRowDeleteClick = (e, rowId, columnId, row) => {
    e.stopPropagation();
    deleteRow(rowId, columnId, row);
  };
  const renderRowFields = (field, fieldIndex) => {
    if (field.type === 'checkbox') {
      return (
        <RowFieldWrapper key={`row-${row.id}-field-${fieldIndex}`}>
          <CheckboxInput
            style={{ margin: 0 }}
            key={`row-${row.id}-field-${fieldIndex}`}
            value={row.isDefault}
            onChange={() => onChecked(row.id, column.id)}
            label={field.label}
          />
        </RowFieldWrapper>
      );
    }
    if (rows.length === index + 1) {
      return (
        <TextFieldWrapper
          key={`row-${row.id}-field-${fieldIndex}`}
          ref={myRef}
        >
          <Field
            name={`placeholder${row.id}`}
            // eslint-disable-next-line no-unused-vars
            validate={(value) => validators.required(row.value)}
            key={`row-${row.id}-field-${fieldIndex}`}
          >
            {({ input, meta }) => (
              <TextInput
                {...input}
                value={row.value}
                onChange={(e) => {
                  updateValue(e, row.id, column.id);
                  input.onChange(e.target.value);
                }}
                placeholder={field.placeholder}
                error={meta.submitError && !meta.dirtySinceLastSubmit ? meta.submitError : (meta.touched && meta.error)}
                showError={false}
                disabled={data && data.inUse && row.code}
              />
            )}
          </Field>
        </TextFieldWrapper>
      );
    }
    return (
      <TextFieldWrapper key={`row-${row.id}-field-${fieldIndex}`}>
        <Field
          name={`placeholder${row.id}`}
          // eslint-disable-next-line no-unused-vars
          validate={(value) => validators.required(row.value)}
          key={`row-${row.id}-field-${fieldIndex}`}
        >
          {({ input, meta }) => (
            <TextInput
              {...input}
              value={row.value}
              onChange={(e) => {
                updateValue(e, row.id, column.id);
                input.onChange(e.target.value);
              }}
              placeholder={field.placeholder}
              error={meta.submitError && !meta.dirtySinceLastSubmit ? meta.submitError : (meta.touched && meta.error)}
              showError={false}
              disabled={data && data.inUse && row.code}
            />
          )}
        </Field>
      </TextFieldWrapper>
    );
  };
  const isActive = useMemo(() => {
    if (row?.code) {
      if (marked?.code === row?.code) {
        return true;
      }
    }
    if (row?.rowCode && marked?.rowCode === row?.rowCode) {
      return true;
    }
    return false;
  }, [marked, row]);
  return (
    <Draggable
      draggableId={row.id}
      index={index}
      key={row.id}
    >
      {(provided) => (
        <RowWrapper
          {...provided.draggableProps}
          ref={provided.innerRef}
          key={`row-${row.id}`}
          onClick={() => (onRowClick ? onRowClick(row) : undefined)}
        >
          <Row
            boxSize={boxSize}
            isActive={marked ? isActive : false}
            theme={theme}
          >
            <DragHandleWrapper
              key={`row-${row.id}`}
              {...provided.dragHandleProps}
            >
              <Icon
                icon={Icons.faGripVertical}
              />
            </DragHandleWrapper>
            {rowFields.map((field, fieldIndex) => renderRowFields(field, fieldIndex))}
            {!data || !data.inUse || (data.inUse && !row.code) ? (
              <DeleteIconWrapper>
                <Icon
                  icon={Icons.faTrash}
                  onClick={(e) => onRowDeleteClick(e, row.id, column.id, row)}
                  style={{ zIndex: 10 }}
                />
              </DeleteIconWrapper>
            ) : (
              <DeleteIconWrapper disabled>
                <Tooltip
                  placement="top"
                  title={t('creativeSettings.deleteTooltip')}
                >
                  <DeleteIcon />
                </Tooltip>
              </DeleteIconWrapper>
            )}
          </Row>
        </RowWrapper>
      )}
    </Draggable>
  );
};

const DeleteIconWrapper = styled.span`
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 8px;
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  ${(props) => props.disabled
  && `
  color: rgba(151, 67, 235, 0.3);
  &:hover {
    color: rgba(151, 67, 235, 0.3);
    cursor: default;
  }
  `};
`;

const RowFieldWrapper = styled.div`
  margin: 0 8px 0 8px;
`;

const TextFieldWrapper = styled.div`
  width: 100%;
  margin: 0 8px 0 8px;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin 300ms ease;
  z-index: 0;
`;

const Row = styled.div`
  width: 95%;
  height: ${(props) => props.boxSize}px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3px;
  margin-bottom: 3px;
  background-color: ${(props) => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.background.default)};
  &:hover {
    background-color:  ${(props) => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.primary.dark)};
  }
`;

const DragHandleWrapper = styled.span`
  width: 35px;
  height: 100%;
  font-size: 20px;
  padding: 0 8px 0 8px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor: grab;
    color: rgba(255, 255, 255, 1);
  }
`;
