import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  Grid,
} from '@mui/material';

export const ReportCategory = ({
  onChange,
  data,
  sectionValue,
  setSectionValue,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const availableOptions = [];
  const options = data.gdsReports.map((report) => {
    availableOptions.push(report.code);
    return { label: report.name, value: report };
  });

  useEffect(() => {
    if ((sectionValue && value && !availableOptions.includes(sectionValue)) || (!sectionValue && value)) {
      setValue(null);
      return;
    }
    if (sectionValue && !value && availableOptions.includes(sectionValue)) {
      const findOption = options.find((option) => option.value.code === sectionValue);
      setValue(findOption);
    }
  }, [sectionValue]);
  return (
    <Grid
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      item
      xs
      sx={{
        minWidth: '185px',
        maxWidth: '250px !important',
        '& .MuiFormControl-root > label': {
          maxWidth: 'calc(100% - 40px) !important',
        },
      }}
    >
      <Autocomplete
        id={data.code}
        renderInput={(params) => (
          <TextField
            {...params}
            label={data.name}
            variant="filled"
          />
        )}
        options={options}
        fullWidth
        sx={{
          maxWidth: '250px',
        }}
        open={open}
        onChange={(event, newValue) => {
          if (!newValue) {
            onChange(newValue);
            setValue(newValue);
            setSectionValue(newValue);
            return;
          }
          onChange(newValue);
          setValue(newValue);
          setSectionValue(newValue.value.code);
        }}
        value={value}
      />
    </Grid>
  );
};
