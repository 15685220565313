import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export default function Tooltip({
  id,
  place,
  effect = 'solid',
  children,
  delayHide,
  clickable,
}) {
  return (
    <Wrapper>
      <ReactTooltip
        id={id}
        place={place}
        effect={effect}
        className="product-icon"
        arrowColor="#5d3393"
        delayHide={delayHide}
        clickable={clickable}
      >
        {children}
      </ReactTooltip>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
    .__react_component_tooltip.show {
      opacity: 1;
    }
    .product-icon {
      background: #3c3c51;
      color: #fff;

      border: 2px solid #5d3393;
      border-radius: 8px;
       &.place-top {
        &:after {
        border-top-color: #5d3393 !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
        }
      }
    }
`;
