import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment-timezone';
import theme from 'components/themes';
import downarrow from 'assets/images/down-arrow.svg';
import { getDateInCurrentTimestamp } from '../../utils/dates';

import 'react-datepicker/dist/react-datepicker.css';
import FormError from './FormError';

export default function DatePicker({
  value,
  onChange: onChangeCallback,
  dateFormat = 'MMM dd,yyyy',
  placeholderText = 'MMM DD, YYYY',
  disabled,
  error,
}) {
  const onChange = (val) => {
    if (!val) {
      onChangeCallback(val);
      return;
    }

    // take date string and remove timezone part because of bug in DatePicker
    onChangeCallback(moment.utc(val.toString().split(' GMT')[0]).toDate());
  };

  return (
    <>
      <DatepickerWrapper
        disabled={disabled}
        error={error}
      >
        <ReactDatePicker
          selected={getDateInCurrentTimestamp(value)}
          onChange={onChange}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={4}
          disabled={disabled}
        />
      </DatepickerWrapper>
      {error && <FormError>{error}</FormError>}
    </>
  );
}

const DatepickerWrapper = styled.div`
  .react-datepicker__input-container input {
    height: 38px;
    width: 100%;
    border: 1px solid ${theme.colors.background};
    background-color: ${theme.colors.inputs};
    color: ${theme.colors.white};
    font-size: 16px;
    padding: 10px 16px;
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
    ${(props) => props.error
    && `
      box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
      border: 1px solid ${theme.colors.notificationRed};
      z-index: 2;
    `};
  }

  .react-datepicker__input-container input:focus {
    outline: none;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker {
    padding: 15px;
  }

  .react-datepicker, .react-datepicker__header {
    background-color: ${theme.colors.datePickerBackground};
    color: ${theme.colors.white};
    border: none;
  }

  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    margin-top: 1.2rem;
  }

  input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: ${theme.colors.datePickerSelected};
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.purple};
  }

  .react-datepicker__current-month, .react-datepicker__day, .react-datepicker__day-name {
    color: ${theme.colors.white};
  }

  .react-datepicker__year-dropdown {
    background-color: ${theme.colors.inputs};
    width: 40%;
    border: 1px solid ${theme.colors.background};
  }

  .react-datepicker__month-dropdown {
    background-color: ${theme.colors.inputs};
    border: 1px solid ${theme.colors.background};
  }

  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__month-read-view--selected-month:after {
    content: '';
    background-image: url(${downarrow});
    background-repeat: no-repeat;
    background-size: cover;
    height: 9px;
    width: 11px;
  }

  .react-datepicker__month-read-view--selected-month {
    width: 107px;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
  }

  .react-datepicker__year-read-view--selected-year:after {
    content: '';
    background-image: url(${downarrow});
    background-repeat: no-repeat;
    background-size: cover;
    height: 9px;
    width: 11px;
  }

  .react-datepicker__year-read-view--selected-year {
    width: 72px;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
  }

  .react-datepicker__month-read-view, .react-datepicker__year-read-view {
    border: 0px;
  }

  .react-datepicker__month-dropdown-container--scroll {
    background-color: ${theme.colors.inputs};
    width: 110px;
  }

  .react-datepicker__year-dropdown-container--scroll {
    background-color: ${theme.colors.inputs};
    width: 75px;
  }

  .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    display: none;
  }

  .react-datepicker__month-option:hover, .react-datepicker__year-option:hover{
    background-color: ${theme.colors.purple};
  }

  .react-datepicker__year-option--selected_year, .react-datepicker__month-option--selected_month {
    background-color: ${theme.colors.datePickerSelected};
  }

  .react-datepicker__navigation--years-upcoming, .react-datepicker__navigation--years-previous {
    height: 20px;
    width: 20px;
  }

  .react-datepicker__navigation--years-upcoming::before {
    border-color: ${theme.colors.white};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 4px;
    width: 9px;
    transform: rotate(315deg);
    right: 6px;
    top: 12px;
  }

  .react-datepicker__navigation--years-previous::before {
    border-color: ${theme.colors.white};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 4px;
    width: 9px;
    transform: rotate(135deg);
    right: 6px;
    top: 0;
  }
  
  // *** Lines below change the triangle color

  .react-datepicker-popper[data-placement^=top]
  .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: ${theme.colors.datePickerBackground};
  }

  .react-datepicker-popper[data-placement^=bottom]
  .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: ${theme.colors.datePickerBackground};
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: ${theme.colors.datePickerBackground};
  }

  .react-datepicker-popper[data-placement^=top]
  .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    border-top-color: ${theme.colors.datePickerBackground};
  }

  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border-top-color: ${theme.colors.datePickerBackground};
  }
`;
