import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { clientAppBudgetsGql, reportingAppMetricsGql } from './gql';
import { formatData } from '../../utils/formatters';

export const getWidgetQueryInfo = (type = 'reportingAppMetrics') => {
  if (type === 'clientAppBudgets') return { gql: clientAppBudgetsGql, dataKey: 'clientAppBudgets' };
  return { gql: reportingAppMetricsGql, dataKey: 'reportingAppMetrics.items' };
};

export const useGetDividerSx = (elementSx, element) => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const elementDivider = element.divider;
  const dividerSx = {
    ...elementSx,
    '::after': {
      content: '""',
      background: 'white',
      position: 'absolute',
      top: '10%',
      right: 0,
      height: '80%',
      width: '1px',
    },
  };
  if (smDown && !elementDivider?.xs) return elementSx;
  if (smDown && elementDivider?.xs) return dividerSx;
  if (mdDown && !elementDivider?.sm) return elementSx;
  if (mdDown && elementDivider?.sm) return dividerSx;
  if (lgDown && !elementDivider?.md) return elementSx;
  if (lgDown && elementDivider?.md) return dividerSx;
  if (xlDown && !elementDivider?.lg) return elementSx;
  if (xlDown && elementDivider?.lg) return dividerSx;
  if (xlUp && elementDivider?.xl) return dividerSx;
  return elementSx;
};

export const useGetDateRange = (dateRange, paybackWindow) => {
  switch (dateRange) {
    case 'mtd':
      return ({
        startDate: moment().startOf('month'),
        endDate: moment(),
      });
    case 'lastMonth':
      return ({
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      });
    case 'last14days':
      return ({
        startDate: moment().subtract(13, 'days'),
        endDate: moment(),
      });
    case 'last30days':
      return ({
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
      });
    case 'last60days':
      return ({
        startDate: moment().subtract(59, 'days'),
        endDate: moment(),
      });
    case 'last90days':
      return ({
        startDate: moment().subtract(89, 'days'),
        endDate: moment(),
      });
    case 'paybackWindow':
      return ({
        startDate: moment().subtract((paybackWindow - 1), 'days'),
        endDate: moment(),
      });
    case 'paybackWindow2x':
      return ({
        startDate: moment().subtract(((paybackWindow * 2) - 1), 'days'),
        endDate: moment(),
      });
    default:
      return null;
  }
};

export const useFormatDateRangeText = (dateRange, identifier) => {
  const { t } = useTranslation('ui');
  if (identifier === 'mtd') return '';
  if (dateRange?.startDate && dateRange?.endDate) {
    return t('dateRange.range', {
      startDate: formatData(dateRange.startDate, 'date'),
      endDate: formatData(dateRange.endDate, 'date'),
    });
  }
  return '';
};

export const useGetMetricsFilter = (dataSourceFilter, modelTypeState) => {
  if (!dataSourceFilter) return ({});
  if (modelTypeState && dataSourceFilter) {
    return ({
      ...dataSourceFilter,
      modelType: modelTypeState,
    });
  }
  return dataSourceFilter;
};

export const useResolveLink = (path, params, prefix = ':') => {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, value);
  });
  return newPath;
};
