// eslint-disable-next-line import/no-extraneous-dependencies
import ModuleDirectory from '@upptic/module-directory';
import enLocale from './locales/en';

const moduleDirectory = new ModuleDirectory();
moduleDirectory.addLocale('en', enLocale);

export * from './components';
export * from './utils';
export * as assets from './assets';
export * from './MUIStyledComponents';
export * from './MUIComponents';
