import React from 'react';
import styled, { keyframes } from 'styled-components';
import themes from './themes';

const ripple = keyframes`
  from {
    transform: translate3d(-200%, -200%, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(-50%, -50%, 0);
    opacity: .7;
  }
`;
const Card = styled.div`
  color: hsl(0, 0%, 100%);
  position: relative;
  padding: 0.5em 0.9em;
  background: ${themes.colors.purple};
  border-radius: ${themes.borderRadius.curved};
  border: solid 2px transparent;
  user-select: none;
  cursor: pointer;
  :hover {
    background: ${themes.colors.lighterPurple};
  }
  font-size: 20px;
  font-weight: bold;
`;
const MutedCard = styled(Card)`
  color: hsl(0, 0%, 50%);
  background: ${themes.colors.background};
  cursor: auto;
  :hover {
    background: #452c6f;
  }
`;
const GlowingCard = styled(Card)`
  box-shadow: 0 0.6em 26px 2px rgba(151, 67, 235, 0.8);
  color: hsl(0, 0%, 100%);
  &::before {
    content: " ";
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(151, 67, 235, 0.4);
    border-radius: 18px;
    padding: 37px;
    animation: ${ripple} 0.5s 0s ease-out;
    animation-fill-mode: both;
  }
  &::after {
    content: " ";
    opacity: 0;
    border-radius: 12px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 1px ${themes.colors.purple};
    animation: ${ripple} 0.5s 0s ease-out;
    animation-fill-mode: both;
    cursor: auto;
  }
  :hover {
    background: ${themes.colors.purple};
  }
`;
const BorderedCard = styled(Card)`
  background: ${themes.colors.background};
  border: solid 2px ${themes.colors.purple};
  :hover {
    background: #452c6f;
  }
`;
function Step({ children, muted = false, glowing = false, bordered = false }) {
  if (muted) return (<MutedCard>{children}</MutedCard>);
  if (glowing) return (<GlowingCard>{children}</GlowingCard>);
  if (bordered) return (<BorderedCard>{children}</BorderedCard>);
  return (<Card>{children}</Card>);
}
export default Step;
