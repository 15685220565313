import React, { useState, useEffect } from 'react';
import {
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import FormHeader from '../Forms/FormHeader';
import { updateActionItemGql, updateUserActionItemGql } from '../gql';
import RichTextField from '../Forms/RichTextField';

const ActionItemDescription = ({
  description,
  sx = { marginTop: '8px' },
  clientCode,
  actionItemCode,
  isUserSpecific,
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const [value, setValue] = useState(description || '');

  useEffect(() => {
    if (description) setValue(description);
  }, [description]);

  const [updateActionItem] = useMutation(updateActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });

  const [updateUserActionItem] = useMutation(updateUserActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });

  const handleOnChange = (htmlString) => {
    setValue(htmlString);
  };
  const handleOnSave = async (htmlString) => {
    if ((description && description !== htmlString) || (!description && htmlString !== '')) {
      const payload = {
        variables: {
          clientCode,
          actionItemCode,
          actionItem: {
            description: htmlString,
          },
        },
      };
      if (isUserSpecific) {
        await updateUserActionItem(payload);
        return;
      }
      await updateActionItem(payload);
    }
  };
  return (
    <Box
      sx={sx}
    >
      <FormHeader
        title={t('labels.description')}
        sx={{ minHeight: '40px' }}
      />
      <RichTextField
        value={value}
        onChange={handleOnChange}
        minRows={1}
        onBlur={(htmlString) => handleOnSave(htmlString)}
      />
    </Box>
  );
};

export default ActionItemDescription;
