import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex } from '@rebass/grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormLabel from './FormLabel';
import FormSection from './FormSection';
import TextInput from './TextInput';
import SelectInput from './SelectInput/SelectInput';
import Button from './Button';
import MonthPicker from './MonthPicker';
import DatePicker from './DatePicker';
import AuditFields from './AuditFields';
import CountrySelectInput from './CountrySelectInput';
import LocaleSelectInput from './LocaleSelectInput';
import NumberInput from './NumberInput';
import TextArea from './TextArea';
import UserSelectInput from './UserSelectInput';
import SingleUploadInput from './SingleUploadInput';
import CheckBoxInput from './CheckboxInput';
import Tags from './Tags';
import Spinner from '../Spinner';
import { isEqual } from '../../utils/validators/validators';
import StyledLinkButton from '../StyledLinkButton';
import DualList from '../DualList';
import { timezones } from './data/timezones';
import Icon from '../Icon';
import OptionBuilder from '../OptionBuilder/OptionBuilder';
import FormError from './FormError';
import { HTMLInclude } from './HTMLInclude';
import { Indicator } from './Indicator';
import { FormSelectWButton } from './FormSelectWButton';

export default function FormSectionWFields({ fields, withButtons = false, submitting, pristine, onCancel }) {
  const { t: tg } = useTranslation('general');
  // For multiple columns in 1 section, set each fields' columnIndex to choose the column it is displayed in
  // columnIndex = 0 by default and references the first column
  // not setting a columnIndex on any field results in 1 column
  /*   const fields = [
    {
      name: 'name',
      type: 'text', // optional
      label: 'Application Name',
      placeholder: 'Enter Application Name',
      isRequired: false,
      isDisabled: false,
      width: 0.5,
      validate: validators.required,
      formatter: formatters.trim,
    },
    {
      name: 'status',
      type: 'select', // optional
      label: 'Application Status',
      isRequired: false,
      isDisabled: false,
      width: 0.5,
      options: [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }],
    },
    {
      name: 'subheadline',
      type: 'headline',
      label: 'My first subheadline',
    },
    {
      name: 'audit',
      type: 'audit',
    },
  ]; */

  const renderFormField = (field, meta, input) => {
    if (field.type === 'select') {
      return (
        <SelectInput
          {...input}
          key={field.name}
          options={field.options}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          isSearchable={field.isSearchable}
          isClearable={field.isClearable}
          isMulti={field.isMulti}
          labelKey={field.labelKey}
          valueKey={field.valueKey}
          light
          loadOptions={field.loadOptions}
          readOnlyStyles={field.readOnlyStyles}
        />
      );
    }
    if (field.type === 'month') {
      return (
        <MonthPicker
          {...input}
          key={field.name}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          {...field.inputProps}
        />
      );
    }

    if (field.type === 'date') {
      return (
        <DatePicker
          {...input}
          key={field.name}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          {...field.inputProps}
        />
      );
    }

    if (field.type === 'country') {
      return (
        <CountrySelectInput
          {...input}
          key={field.name}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          isClearable={field.isClearable}
          filter={field.filter}
          {...field.inputProps}
        />
      );
    }

    if (field.type === 'locale') {
      return (
        <LocaleSelectInput
          {...input}
          key={field.name}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          {...field.inputProps}
        />
      );
    }

    if (['percentage', 'currency', 'number'].includes(field.type)) {
      let inputSufix = null;
      if (field.type === 'percentage') inputSufix = '%';
      if (field.type === 'currency') inputSufix = '$';
      return (
        <NumberInput
          {...input}
          key={field.name}
          inputSufix={field.inputSufix || inputSufix}
          placeholder={field.placeholder}
          disabled={field.isDisabled}
          error={meta.touched && meta.error}
          decimalSpaces={4}
          {...field.inputProps}
        />
      );
    }

    if (field.type === 'textArea') {
      return (
        <TextArea
          {...input}
          maxLength={field.maxLength}
          key={field.name}
          placeholder={field.placeholder}
          style={field.style}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          {...field.inputProps}
          autoFocus={field.autoFocus}
        />
      );
    }

    if (field.type === 'user') {
      return (
        <UserSelectInput
          {...input}
          key={field.name}
          includeUppticUser={field.includeUppticUser}
          clientCode={field.clientCode}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          isSearchable={field.isSearchable}
          isClearable={field.isClearable}
          light
          placeholder={field.placeholder}
          activeOnly={field.activeOnly !== undefined ? field.activeOnly : true}
        />
      );
    }

    if (field.type === 'singleUpload') {
      return (
        <PreviewWrapper>
          {field.isDisabled && !input.value ? (
            <DisabledPlaceholder>
              {field.disabledPlaceholderText || tg('disabledPlaceholderText')}
            </DisabledPlaceholder>
          ) : (
            <SingleUploadInput
              placeholder={field.placeholder}
              onUpload={(files) => {
                input.onChange(files[0]);
              }}
              accept={field.accept}
              maxFiles={field.maxFiles}
              disabled={field.isDisabled}
              maxSingleFilesize={field.maxSingleFilesize}
              fileActions={field.fileActions}
              fileType={field.fileType}
              file={input.value}
              displayTitle={field.displayTitle}
              onRemove={() => {
                input.onChange(null);
              }}
            />
          ) }
        </PreviewWrapper>
      );
    }

    if (field.type === 'checkbox') {
      return (
        <>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="38px"
          >
            <CheckBoxInput
              {...input}
              key={field.name}
              error={meta.error}
              style={field.style}
              noMargin={field.noMargin}
              disabled={field.isDisabled}
            />
          </Flex>
          {meta.error && <FormError>{meta.error}</FormError>}
        </>
      );
    }

    if (field.type === 'tags') {
      if (field.loading) {
        return (
          <Spinner
            style={{ marginTop: 0 }}
          />
        );
      }
      return (
        <Tags
          {...input}
          tags={input.value}
          handleDelete={(i) => {
            const filteredTags = input.value.filter((tag, index) => index !== i);
            input.onChange(filteredTags);
          }}
          handleAddition={(tag) => {
            if (!tag.code) {
              field.handleTagCreate(tag)
                .then((res) => {
                  input.onChange([...input?.value || [], { ...tag, code: res.code, color: res.color }]);
                });
              return;
            }
            input.onChange([...input?.value || [], tag]);
          }}
          suggestions={field.suggestions}
          placeholder={field.placeholder}
          disabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'button') {
      return (
        <FieldButton
          key={field.name}
          onClick={field.onClick}
          disabled={field.disabled}
        >
          {field.name}
        </FieldButton>
      );
    }

    if (field.type === 'dualList') {
      return (
        <DualList
          {...input}
          options={field.options}
          valueField={field.valueField}
          labelField={field.labelField}
          selected={input.value}
          error={meta.touched && meta.error}
          onChange={(value) => {
            input.onChange(value);
          }}
        />
      );
    }

    if (field.type === 'timezone') {
      return (
        <SelectInput
          {...input}
          key={field.name}
          options={timezones}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          isSearchable
          labelKey="name"
          light
        />
      );
    }

    if (field.type === 'optionBuilder') {
      return (
        <OptionBuilder
          {...input}
          key={field.name}
          error={meta.touched && meta.error}
          onChange={(value) => {
            input.onChange(value);
          }}
          boxSize={field.boxSize}
          containerSize={field.containerSize}
          rowFields={field.rowFields}
          value={input.value}
          data={field.data}
        />
      );
    }

    if (field.type === 'html') {
      return (
        <HTMLInclude
          string={field.string}
          key={field.name}
        />
      );
    }

    if (field.type === 'indicator') {
      return (
        <Indicator
          key={field.name}
          color={field.color}
          subType={field.subType}
          value={input.value}
          emptyText={field.emptyText}
          preceedingText={field.preceedingText}
        />
      );
    }

    if (field.type === 'selectWButton') {
      return (
        <FormSelectWButton
          {...input}
          key={field.name}
          options={field.options}
          valid={!(meta.touched && meta.error)}
          error={meta.touched && meta.error}
          isDisabled={field.isDisabled}
          buttonName={field.buttonName}
          onClick={field.onClick}
          readOnlyStyles={field.readOnlyStyles}
        />
      );
    }

    return (
      <TextInput
        {...input}
        key={field.name}
        type={field.type === 'link' ? 'url' : field.type}
        placeholder={field.placeholder}
        disabled={field.isDisabled}
        error={meta.touched && meta.error}
        onChange={(event) => {
          if (field.onChangeFormat) {
            const formattedValue = field.onChangeFormat(event.target.value);
            input.onChange(formattedValue);
            return;
          }
          input.onChange(event.target.value);
        }}
        {...field.inputProps}
      />
    );
  };

  const columns = {};

  for (const field of fields) {
    const columnIndex = field.columnIndex || 0;
    if (!columns[columnIndex]) columns[columnIndex] = [];
    columns[columnIndex].push(field);
  }

  const columnArray = Object.values(columns);

  const defaultWidth = columnArray.length === 1 ? 1 / 2 : 1;

  let firstHeader = true;
  const renderLabelButton = (button, index) => {
    if (button.link) {
      return (
        <StyledLinkButton
          key={index}
          to={button.link}
          style={{ height: '28px' }}
        >
          {button.name}
        </StyledLinkButton>
      );
    }
    return (
      <LabelButton
        key={index}
        disabled={button.disabled}
        onClick={button.onClick}
      >
        {button.name}
      </LabelButton>
    );
  };
  const renderColumn = (columnData, index) => (
    <Flex
      flex="1 1 0"
      flexWrap="wrap"
      alignSelf="flex-start"
      key={index}
    >
      {columnData.map((field) => {
        if (field.type === 'audit') return null;
        if (field.isHidden) return null;
        if (field.type === 'headline') {
          const style = (firstHeader) ? { marginTop: 0 } : {};
          firstHeader = false;
          return (
            <Box
              key={field.name}
              width={field.width || defaultWidth}
              pr={15}
              pb={15}
            >
              <SubsectionHeadline style={style}>{field.label}</SubsectionHeadline>
            </Box>
          );
        }
        if (field.type === 'infoText') {
          return (
            <Box
              key={field.name}
              width={field.width || defaultWidth}
              pr={15}
              pb={15}
            >
              <p style={{ textAlign: 'center' }}>{field.text}</p>
            </Box>
          );
        }
        if (field.type === 'checkbox') {
          return (
            <Flex
              key={field.name}
              width={field.width || defaultWidth}
              flexDirection="column"
              alignItems="center"
              pr={15}
              pb={15}
            >
              <FormLabel
                name={field.name}
                spacing={false}
                labelStyle={{ textAlign: 'center' }}
              >
                {field.label}
              </FormLabel>
              <Field
                name={field.name}
                validate={field.validate}
                formatOnBlur
                format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                key={field.fieldKey}
              >
                {({ input, meta }) => renderFormField(field, meta, input)}
              </Field>
            </Flex>
          );
        }
        return (
          <FieldBox
            key={field.name}
            width={field.width || defaultWidth}
            pr={15}
            pb={15}
          >
            <div>
              {field.buttons ? (
                <LabelWrapper>
                  <FormLabel
                    name={field.name}
                    spacing={false}
                  >
                    {field.label}
                  </FormLabel>
                  <LabelButtonsWrapper>
                    {field.buttons && field.buttons.map(renderLabelButton)}
                  </LabelButtonsWrapper>
                </LabelWrapper>
              ) : (
                <FormLabel
                  name={field.name}
                  spacing={false}
                >
                  {field.label}
                </FormLabel>
              )}
              {field.type === 'tags' || field.type === 'dualList' ? (
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                  isEqual={(a, b) => isEqual(a, b, field.type)}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
              ) : field.icon ? (
                <FieldWithIconWrapper>
                  <FlexFieldWrapper>
                    <Field
                      name={field.name}
                      validate={field.validate}
                      formatOnBlur
                      format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                      key={field.fieldKey}
                    >
                      {({ input, meta }) => renderFormField(field, meta, input)}
                    </Field>
                  </FlexFieldWrapper>
                  {field.icon.onClick ? (
                    <IconsButtonWrapper
                      buttonColor={field.icon.buttonColor}
                      onClick={field.icon.onClick}
                    >
                      <Icon icon={field.icon.icon} />
                    </IconsButtonWrapper>
                  ) : (
                    <IconsWrapper
                      buttonColor={field.icon.buttonColor}
                      href={field.icon.url}
                      target={field.icon.target}
                    >
                      <Icon icon={field.icon.icon} />
                    </IconsWrapper>
                  )}
                </FieldWithIconWrapper>
              ) : (
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
              )}
            </div>
          </FieldBox>
        );
      })}
    </Flex>
  );

  return (
    <FormSection style={{ marginBottom: '0', color: 'white' }}>
      <Flex
        flexDirection="column"
      >
        <Flex>
          {columnArray.map((fields, index) => renderColumn(fields, index))}
        </Flex>
        {fields.map((field) => {
          if (field.type === 'audit') {
            return (
              <Box
                key={field.name}
                width={1}
              >
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                >
                  {({ input }) => (
                    <AuditFields audit={input.value} />
                  )}
                </Field>
              </Box>
            );
          }
          return null;
        })}
      </Flex>
      {withButtons && (
      <Flex
        justifyContent="flex-end"
        m={15}
      >
        <FormButton
          onClick={onCancel}
          type="button"
        >
          {tg('cancel')}
        </FormButton>
        <FormButton
          type="submit"
          disabled={submitting || pristine}
        >
          {tg('save')}
        </FormButton>
      </Flex>
      )}
    </FormSection>
  );
}

const FormButton = styled(Button)`
  min-width: 130px;
  &:not(:last-of-type) {
    margin-right: 30px;
  }
`;

const SubsectionHeadline = styled.h4`
  margin-top: 15px;
  border-bottom: 1px solid white;
`;

const PreviewWrapper = styled.div`
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisabledPlaceholder = styled.div`
  color: #fff;
  opacity: 0.5;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelButtonsWrapper = styled.div`
  display: flex;
`;

const LabelButton = styled(Button)`
  min-width: 130px;
  height: 28px;
  &:not(:last-of-type) {
    margin-right: 30px;
  }
`;

const FieldButton = styled(Button)`
  min-width: 130px;
  height: 36px;
  position: absolute;
  bottom: 17px;
`;

const FieldBox = styled(Box)`
  position: relative;
`;

const FieldWithIconWrapper = styled.div`
  display: flex;
`;

const FlexFieldWrapper = styled.div`
  flex: 1;
`;

const IconsWrapper = styled.a`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 0px 5px;
  background-color: ${(props) => (props.buttonColor ? props.buttonColor : '#0059B7')};
  opacity: 0.8;
  text-decoration: none;
  color: white;
  border: 1px solid #23233a;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    color: white;
  }
`;

const IconsButtonWrapper = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 0px 5px;
  background-color: ${(props) => (props.buttonColor ? props.buttonColor : '#0059B7')};
  opacity: 0.8;
  text-decoration: none;
  color: white;
  border: 1px solid #23233a;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    color: white;
  }
`;
