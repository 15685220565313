import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import Icon, { Icons } from '../../components/Icon';

export const DraggableDLOptionComponent = ({
  option,
  onOptionClick,
  getLabel,
  boxSize = 40,
  index,
  CustomOptionComponent,
}) => {
  const theme = useTheme();
  const [marked, setMarked] = useState(false);
  const onClick = () => {
    setMarked(!marked);
    onOptionClick(option);
  };
  const label = useMemo(() => getLabel(option), [getLabel, option]);
  return (
    <Draggable
      draggableId={option.id}
      index={index}
      key={option.id}
    >
      {(provided) => (
        <RowWrapper
          onClick={() => onClick()}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Row
            boxSize={boxSize}
            isActive={marked}
            theme={theme}
          >
            <DragHandleWrapper
              {...provided.dragHandleProps}
            >
              <Icon
                icon={Icons.faGripVertical}
              />
            </DragHandleWrapper>
            {CustomOptionComponent ? (
              <CustomOptionComponent
                option={option}
                onOptionClick={onOptionClick}
                label={label}
                marked={marked}
                setMarked={setMarked}
              />
            ) : (
              <Box mx={1}>
                <Typography>
                  {label}
                </Typography>
              </Box>
            )}
          </Row>
        </RowWrapper>
      )}
    </Draggable>
  );
};

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin 300ms ease;
  cursor: pointer;
`;

const Row = styled.div`
  width: 95%;
  height: ${(props) => props.boxSize}px;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 6px;
  background-color: ${(props) => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.background.default)};
  &:hover {
    background-color: ${(props) => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.primary.dark)};
  }
`;

const DragHandleWrapper = styled.span`
  width: 35px;
  height: 100%;
  font-size: 20px;
  padding: 0 8px 0 8px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor: grab;
    color: rgba(255, 255, 255, 1);
  }
`;
