import React, { useEffect } from 'react';
import styled from 'styled-components';

export const MediaGalleryThumbnail = ({ item, onClick, activeMedia }) => {
  useEffect(() => {
    if (activeMedia) {
      document.getElementById(item.code).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activeMedia]);
  return (
    <ThumbnailWrapper
      onClick={() => onClick(item.index)}
      activeMedia={activeMedia}
      id={item.code}
      key={item.code}
    >
      <Image
        key={item.code}
        src={item.thumbnail}
      />
    </ThumbnailWrapper>
  );
};

const ThumbnailWrapper = styled.button`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: center;
  background: transparent;
  align-items: center;
  border: 4px solid rgba(151, 67, 235, 0);
  ${(props) => props.activeMedia
  && `
  border: 4px solid rgba(151, 67, 235, 1);
  `}
  transition: border 0.4s ease;
`;

const Image = styled.img`
  width: 100%;
`;
