import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { Flex } from '@rebass/grid';
import FormLabel from '../forms/FormLabel';
import { Switch } from '../forms/Switch';
import FormSectionWFields from '../forms/FormSectionWFields';

export default function Entitlement({
  entitlements,
  setEntitlements,
  fieldsArray,
  entitlementTag,
  iconTitle,
  svg,
  isEntitlementActive,
  width,
  name,
  style,
  onClick,
  form,
  isDisabled,
  canAccessModule,
}) {
  const { t } = useTranslation('clientEntitlements');
  const IconComponent = svg;
  const isActive = isEntitlementActive(entitlementTag);
  const className = isActive ? `product-icon-${entitlementTag} active` : `product-icon-${entitlementTag}`;
  const fieldState = form.getFieldState(name);
  const fieldValue = useMemo(() => {
    if (fieldState) {
      return fieldState.value;
    }
    return null;
  }, [fieldState]);
  const fieldInitial = useMemo(() => {
    if (fieldState) {
      return fieldState.initial;
    }
    return null;
  }, [fieldState]);

  const alterEntitlementsArray = (entitlement) => {
    const entitlementsArray = entitlements || [];
    let inArray = false;
    for (const item of entitlementsArray) {
      if (item.productFamily === entitlement.productFamily) {
        item.status = entitlement.status;
        inArray = true;
      }
    }
    if (!inArray) {
      entitlementsArray.push({
        productFamily: entitlement.productFamily,
        status: entitlement.status,
      });
    }
    setEntitlements(entitlementsArray);
  };

  return (
    <>
      <Flex
        width={width || 1 / 3}
        mr={15}
      >
        <LeftColumnWrapper>
          <IconWrapper
            onClick={canAccessModule && fieldValue === true && fieldInitial === true ? onClick : null}
            isClickable={!!(canAccessModule && fieldValue === true && fieldInitial === true)}
            fill={isActive ? 'rgba(151, 67, 235, 0.8)' : 'rgba(255, 255, 255, 0.2)'}
            className={className}
          >
            <IconComponent
              style={{
                minWidth: '35px',
                height: '35px',
              }}
            />
            <span>{iconTitle}</span>
          </IconWrapper>
        </LeftColumnWrapper>
        <RightColumnWrapper
          style={style}
        >
          <SwitchWrapper>
            <Field
              name={name}
            >
              {({ input }) => (
                <>
                  <FormLabel
                    name={name}
                    spacing={false}
                  >
                    <LabelSpan>{isActive ? t('enabled') : t('disabled')}</LabelSpan>
                  </FormLabel>
                  <Switch
                    {...input}
                    onChange={(value) => {
                      alterEntitlementsArray({ productFamily: entitlementTag, status: value });
                      input.onChange(value);
                    }}
                    disabled={isDisabled}
                  />
                </>
              )}
            </Field>
          </SwitchWrapper>
          <SelectWrapper>
            <FormSectionWFields fields={fieldsArray} />
          </SelectWrapper>
        </RightColumnWrapper>
      </Flex>
    </>
  );
}

const LeftColumnWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  svg {
    fill: ${(props) => props.fill};
  }
  ${(props) => props.isClickable
  && `
    cursor: pointer;
    &:hover svg {
      fill: rgba(151, 67, 235, 1);
    }
  `}
`;

const RightColumnWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SwitchWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-around;
`;

const LabelSpan = styled.span`
  font-size: 20px;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;
