import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Box, Link } from '@mui/material';
import Button from '../../Button';
import themes from '../../../themes/index';

export default function AssetActions({
  isHovered,
  actions = [],
}) {
  return (
    <>
      {isHovered && (
        <Actions>
          {actions.map((action, index) => {
            if (action.externalLink) {
              return (
                <Box
                  key={index}
                  component={Link}
                  href={action.externalLink}
                  target={action.target || '_blank'}
                  rel="noopener"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '148px',
                    padding: 0,
                    minHeight: '35px',
                    maxHeight: '35px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    fontSize: '12px',
                    borderRadius: themes.borderRadius.sharp,
                    margin: '5px auto',
                    pointerEvents: 'auto',
                    color: '#fff',
                    textDecoration: 'none',
                    border: '1px solid #fff',

                    '&:hover': {
                      color: themes.colors.purple,
                      background: '#fff',
                      textDecoration: 'none',
                    },
                  }}
                >
                  {action.name}
                </Box>
              );
            }
            return (
              <ActionItem
                key={index}
                outline
                onClick={action.onClick}
              >
                {action.name}
              </ActionItem>
            );
          })}
        </Actions>
      )}
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${hexToRgba(themes.colors.purple, 0.7)};
  border-radius: 8px;
  padding: 10px;
  pointer-events: none;
`;

const ActionItem = styled(Button)`
  width: 148px;
  padding: 0;
  min-height: 35px;
  max-height: 35px;
  min-width: 100%;
  max-width: 100%;
  font-size: 12px;
  border-radius: ${themes.borderRadius.sharp};
  margin: 5px auto;
  pointer-events: auto;

  &:hover {
    color: ${themes.colors.purple};
    background: #fff;
  }
`;
