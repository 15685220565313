import React from 'react';
import styled from 'styled-components';
import themes from 'components/themes';

function FormLabel({ children, className = '', name, spacing = true, optional = false, labelStyle = {} }) {
  return (
    <Wrapper
      spacing={spacing ? 35 : 0}
    >
      <Label
        htmlFor={name}
        className={className}
        style={labelStyle}
      >
        {children}
      </Label>
      {optional && <OptionalLabel>(Optional)</OptionalLabel>}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  margin-top: ${(props) => `${props.spacing}px`};
  display: flex;
  justify-content: space-between;
`;
const Label = styled.label`
  font-size: 16px;
  color: ${themes.colors.white};
  width: 100%;
`;
const OptionalLabel = styled.label`
  margin-bottom: 12px;
  opacity: 0.4;
  font-size: 14px;
  color: #ffffff;
`;
export default FormLabel;
