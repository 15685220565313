import React from 'react';
import styled from 'styled-components';
import themes from 'components/themes';

function CheckboxInput(props) {
  const { style = {} } = props;
  const valid = !props.error;

  const defaultStyles = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    fontSize: '16px',
    color: themes.colors.white,
  };

  const combinedStyles = { ...defaultStyles, ...style };
  return (
    <Label
      htmlFor={props.name}
      style={combinedStyles}
    >
      <Input
        light={props.light}
        {...props}
        id={props.name}
        checked={props.status ? props.value === 'active' : !!props.value}
        type="checkbox"
      />
      <StyledInput
        checked={props.status ? props.value === 'active' : !!props.value}
        valid={valid}
        disabled={props.disabled}
        noMargin={props.noMargin}
      />
      {props.label}
    </Label>
  );
}
const Input = styled.input`
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
`;
const StyledInput = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.7);
  display: inline-block;
  background-color: ${(props) => (props.checked && props.disabled ? themes.colors.white : props.checked ? themes.colors.purple : 'transparent')};
  margin-right: ${(props) => (props.noMargin ? '0' : '12px')} ;

  &:hover {
    cursor: pointer;
    border: solid 1px rgba(255, 255, 255, 1);
  }

  ${(props) => props.disabled && `
    opacity: 0.7;
    &:hover {
      cursor: default;
      border: solid 1px rgba(255, 255, 255, 0.7);
    }
  `};

  ${(props) => !props.valid
    && `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;
const Label = styled.label`
  ${(props) => { props.style; }}
`;
export default CheckboxInput;
