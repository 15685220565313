/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';

import { formatData } from '../../../utils/formatters/formatters';
import { getWidgetQueryInfo } from '../helper';
import ElementTitle from '../GenericElements/ElementTitle';

function HorizontalNumberWSource({ element, appReportingCurrency }) {
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;

  const queryInfo = getWidgetQueryInfo(element?.dataSource?.queryType);

  const { data } = useQuery(queryInfo.gql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource?.metrics,
      filter: element.dataSource?.filter,
      dimensions: element.dataSource?.dimensions,
      dateRange: element.dataSource?.dateRange,
    },
  });

  const value = useMemo(() => {
    const values = _.get(data, queryInfo.dataKey, null);
    if (!values?.length) return null;
    return values[0][element.dataSource.metrics[0]];
  }, [_.get(data, queryInfo.dataKey)]);

  const dataPointFormat = useMemo(() => {
    if (element.format?.type === 'currency' && appReportingCurrency) {
      return ({
        ...element.format,
        currency: appReportingCurrency,
      });
    }
    return element?.format;
  }, [element?.format, appReportingCurrency]);

  return (
    <>
      {element.title && <ElementTitle title={element.title} />}
      <Typography variant="body2" component="div">
        {formatData(value, element.format?.type || 'number', dataPointFormat)}
      </Typography>
    </>
  );
}

export default observer(HorizontalNumberWSource);
