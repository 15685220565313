/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';
import { CSVLink } from 'react-csv';
import { paramCase } from 'param-case';
import { get, groupBy } from 'lodash';
import { createTrend } from 'trendline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import { reportingAppMetricsGql } from '../gql';
import { formatChartDate } from '../../../utils/formatters';
import ChartGenerator, { colorPalette, contrastColorPalette } from '../../ChartGenerator/ChartGenerator';
import { formatData } from '../../../utils/formatters/formatters';
import ElementTitle from '../GenericElements/ElementTitle';
import { useGetMetricsFilter } from '../helper';

function ChartElement({ element, appReportingCurrency, modelType }) {
  const { t } = useTranslation('ui');
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;
  const isCsvEnabled = element.csv?.enabled;
  const isTitle = element.title;
  const elementHeight = element.heightFactor ? (250 * element.heightFactor) : 250;
  const chartHeight = !isTitle && !isCsvEnabled
    ? elementHeight
    : (elementHeight - 40);

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);

  const { data, loading } = useQuery(reportingAppMetricsGql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource.metrics,
      filter: metricsFilter,
      dimensions: element.dataSource.dimensions,
      dateRange: element.dataSource?.dateRange,
      sort: element.dataSource?.sort,
    },
  });

  const payloadFormatter = (payload) => {
    if (!payload) return [];
    if (element?.tooltip?.additionalMetrics) {
      const dataArray = [...payload];
      for (const metric of element?.tooltip?.additionalMetrics) {
        const findMetricValue = payload?.[0]?.payload?.[metric?.metric];
        const additionalMetric = {
          name: metric?.label,
          value: !Number.isNaN(findMetricValue) ? findMetricValue : null,
          unit: {
            type: metric?.unit,
            currencyStyle: metric?.currencyStyle,
          },
          hideIcon: true,
        };
        dataArray.push(additionalMetric);
      }
      return dataArray;
    }
    return payload;
  };

  const xAxisTickFormat = (tick) => {
    if (element?.xAxis?.format === 'date') {
      return formatChartDate(tick, '', 'short');
    }
    if (element?.xAxis?.format === 'number') {
      return formatData(tick, 'number');
    }
    return tick;
  };

  const tooltipLabelFormatter = (tick) => {
    if (element?.xAxis?.format === 'date') {
      return formatChartDate(tick);
    }
    if (element?.xAxis?.format === 'number') {
      return formatData(tick, 'number');
    }
    return tick;
  };

  const chartDefinition = {
    xs: 12,
    type: 'sparklineChart',
    headline: { label: element?.title, align: 'center' },
    dataKey: 'metrics',
    height: chartHeight,
    layout: element?.layout ? element.layout : 'horizontal',
    xAxis: {
      label: element?.xAxis?.label ? {
        value: element?.xAxis?.currency && appReportingCurrency
          ? `${element.xAxis.label} (${t(`currency.${appReportingCurrency}`)})`
          : element.xAxis.label,
        fill: '#666',
        dy: 15,
      } : null,
      type: element?.xAxis?.type,
      domain: element?.xAxis?.type === 'number' ? [0, (dataMax) => Math.ceil(dataMax * 1.15)] : undefined,
      dataKey: element?.layout !== 'vertical' ? element.dataSource.dimensions[0] : undefined,
      tickFormatter: element?.xAxis?.format ? (tick) => xAxisTickFormat(tick) : undefined,
    },
    zAxis: element?.zAxis,
    yAxis: element.yAxis.map((yAxis, index) => ({
      yAxisId: element?.layout !== 'vertical' ? yAxis.id || index : undefined,
      tickLine: false,
      axisLine: false,
      orientation: yAxis.orientation,
      type: yAxis?.type,
      dataKey: yAxis?.dataKey,
      formatter: element?.layout === 'vertical' ? (item) => item : undefined,
      label: yAxis.label ? {
        value: yAxis?.currency && appReportingCurrency
          ? `${yAxis.label} (${t(`currency.${appReportingCurrency}`)})`
          : yAxis.label,
        angle: -90,
        fill: '#666',
        dx: yAxis.id > 1 || index > 0 ? 30 : element?.layout === 'vertical' ? -80 : -30,
      } : null,
      domain: ['auto', (dataMax) => Math.ceil(dataMax * 1.15)],
    })),
    hideLegend: true,
    hideChartActions: true,
    hideHeadline: true,
    tooltip: {
      payloadFormatter: (payload) => (element?.tooltip?.additionalMetrics ? payloadFormatter(payload) : payload),
      formatter: (value, item) => {
        const dataPointFormat = () => {
          if (item?.unit?.type === 'currency' && appReportingCurrency) {
            return ({
              ...item?.unit,
              currency: appReportingCurrency,
            });
          }
          return ({});
        };
        return formatData(value, item.unit?.type || 'number', dataPointFormat());
      },
      labelFormatter: (tick) => tooltipLabelFormatter(tick),
    },
    elements: element.chartElements.map((element, index) => {
      if (element.type === 'line') {
        return ({
          params: { type: 'monotoneX', unit: element.format, stroke: colorPalette[index] },
          ...element,
        });
      }
      if (element.type === 'scatter') {
        return ({
          params: {
            type: 'monotoneX',
            unit: element.format,
            fill: element?.colorContrastIndex ? contrastColorPalette[element?.colorContrastIndex] : contrastColorPalette[0] },
          ...element,
        });
      }
      if (element.type === 'bar') {
        return ({
          params: { type: 'monotoneX', unit: element.format, barColor: colorPalette[index] },
          ...element,
        });
      }
      return ({
        params: { type: 'monotoneX', unit: element.format, strokeDasharray: element.strokeDasharray },
        ...element,
      });
    }),
  };

  const values = useMemo(() => {
    if (!data?.reportingAppMetrics?.items.length) return { metrics: [] };
    if (element.dataSource.groupNonPrimaryDimension) {
      const result = groupBy(data.reportingAppMetrics.items, (row) => row[element.dataSource.dimensions[0]]);
      const metrics = Object.values(result).reduce((acc, group) => {
        const subGroups = group.reduce((acc, singleEntry) => {
          const key = element.dataSource.dimensions.filter((_, index) => index !== 0)
            .map((dim) => singleEntry[dim])
            .join('_');
          acc[key] = singleEntry;
          return acc;
        }, {});

        const item = {
          [element.dataSource.dimensions[0]]: group[0][element.dataSource.dimensions[0]],
          subGroups,
        };
        acc.push(item);
        return acc;
      }, []);
      return { metrics };
    }
    return { metrics: data.reportingAppMetrics.items };
  }, [data?.reportingAppMetrics?.items]);

  const valuesWTrend = useMemo(() => {
    const trendlines = chartDefinition.elements.filter(({ type }) => type === 'trendline');
    if (!trendlines.length || !values.metrics.length) return values;

    // eslint-disable-next-line guard-for-in
    for (const index in trendlines) {
      const trendline = trendlines[index];
      const trendlineVals = values.metrics.map((val) => ({
        key: element.xAxis?.format === 'date' ? new Date(val[element.dataSource.dimensions[0]]).getTime() : val[element.dataSource.dimensions[0]],
        value: get(val, trendline.dataKey),
      }));
      const trend = createTrend(trendlineVals, 'key', 'value');
      values.metrics.forEach((val) => {
        const xValue = element.xAxis?.format === 'date'
          ? new Date(val[element.dataSource.dimensions[0]])
          : val[element.dataSource.dimensions[0]];
        const weightCalc = trend.calcY(element.xAxis?.format === 'date' ? xValue.getTime() : xValue);
        val.weight = !Number.isNaN(weightCalc) ? weightCalc : null;
      });
      trendline.dataKey = 'weight';
    }
    return values;
  }, [values?.metrics]);

  const csvData = useMemo(() => {
    if (!valuesWTrend || valuesWTrend?.metrics?.length === 0) return [];
    if (element.xAxis?.format === 'date') {
      return valuesWTrend?.metrics?.map((metric) => ({
        ...metric,
        [element.dataSource.dimensions[0]]: formatChartDate(metric[element.dataSource.dimensions[0]]),
      }));
    }
    return valuesWTrend?.metrics;
  }, [valuesWTrend]);

  return (
    <Box sx={{ height: elementHeight, textAlign: 'center' }}>
      <Grid
        container
      >
        {isTitle && (
          <Grid
            item
            xs
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={40}
          >
            <ElementTitle title={element.title} ml={isCsvEnabled ? 5 : 0.5} />
          </Grid>
        )}
        {isCsvEnabled && (
          <Grid
            item
            width={40}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CSVLink
              data={csvData}
              headers={element.csv.headers}
              filename={`${element?.csv?.filename || paramCase(element.title || '') || 'export'
              }-${new Date().getTime()}.csv`}
            >
              <IconButton>
                <CloudDownloadIcon color="primary" />
              </IconButton>
            </CSVLink>
          </Grid>
        )}
        <ChartGenerator
          data={valuesWTrend}
          definition={{ elements: [chartDefinition] }}
          spacing={0}
          loading={loading}
          appReportingCurrency={appReportingCurrency}
        />
      </Grid>
    </Box>
  );
}

export default observer(ChartElement);
