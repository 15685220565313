import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dequal } from 'dequal';
import { uniqBy } from 'lodash';

import { useDebounce } from '../../utils/hooks';
import DateRangePicker from '../DateRangePicker';
import DatePicker from '../DatePicker';
import QueryBuilderContext from './QueryBuilderContext';

const Value = React.memo(
  (props) => {
    const {
      field,
      value,
      operator,
      onChange,
      error,
    } = props;
    const { t } = useTranslation('ui', { keyPrefix: 'queryBuilder' });
    const [currentValue, setCurrentValue] = useState(value);
    const debouncedValue = useDebounce(currentValue, 200);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectOptionCache, setSelectOptionCache] = useState([]);
    const [loading, setLoading] = useState(false);
    const multiple = ['$in', '$nin'].includes(operator);
    const context = useContext(QueryBuilderContext);

    useEffect(() => {
      if (currentValue === value) return;
      setCurrentValue(value);
    }, [value]);

    useEffect(() => {
      if (currentValue === value) return;
      onChange(debouncedValue);
    }, [debouncedValue]);

    const selectValue = useMemo(() => {
      const selectOptionsToSearchIn = uniqBy([...selectOptionCache, ...selectOptions], field.valueKey || 'value');
      if (field?.type !== 'select') return null;
      if (!multiple) return selectOptionsToSearchIn?.find((op) => currentValue === op[field.valueKey || 'value']) || null;
      if (!currentValue || !Array.isArray(currentValue)) return [];
      return currentValue.map((val) => {
        let resolvedValue = selectOptionsToSearchIn.find((op) => op[field.valueKey || 'value'] === val);
        // fallback if option is not cached used passed value
        if (!resolvedValue) resolvedValue = { [field.valueKey || 'value']: val, [field.labelKey || 'label']: val };
        return resolvedValue;
      });
    }, [currentValue, value]);

    useEffect(() => { if (field?.options) setSelectOptions(field?.options); }, [field?.options]);

    const asyncSearchSelectCallback = useCallback((newOptions) => {
      setSelectOptions([...newOptions]);
    }, []);

    const asyncLoadingCallback = useCallback((val) => {
      setLoading(val);
    }, []);

    if (!field || !operator) return null;
    let inputSufix = null;
    switch (field?.type) {
      case 'date':
        if (operator === '$range') {
          return (
            <DateRangePicker
              value={currentValue || null}
              fullWidth
              startLabel={field.startLabel || t('range.start')}
              endLabel={field.endLabel || t('range.end')}
              onChange={(values) => setCurrentValue(values.map((value) => {
                if (value === null) return value;
                const date = new Date(value);
                return !Number.isNaN(date.getTime()) ? date.toISOString() : date;
              }))}
              minDate={field.minDate}
              size="small"
            />
          );
        }

        return (
          <DatePicker
            value={currentValue || null}
            label={field.label}
            fullWidth
            size="small"
            onChange={(item) => {
              if (item !== null) {
                const createDate = new Date(item);
                // eslint-disable-next-line no-restricted-globals
                const isValid = createDate instanceof Date && !isNaN(createDate);
                if (isValid) {
                  setCurrentValue(createDate.toISOString());
                  return;
                }
                setCurrentValue(createDate);
                return;
              }
              setCurrentValue(item);
            }}
            minDate={field.minDate}
          />
        );
      case 'select': {
        const {
          labelKey = 'label',
          valueKey = 'value',
        } = field;

        let autocompleteValue = selectValue;
        if (multiple && !Array.isArray(autocompleteValue)) autocompleteValue = [];

        return (
          <Autocomplete
            getOptionLabel={(option) => option[labelKey]}
            getOptionKey={(option) => option[valueKey]}
            options={selectOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={context.showError && error}
                helperText={context.showError && error}
                label="Value"
              />
            )}
            size="small"
            multiple={multiple}
            fullWidth
            // style={{ paddingTop: 4, width: 'auto' }}
            value={autocompleteValue}
            isOptionEqualToValue={(option, val) => option[valueKey] === val[valueKey]}
            placeholder={field.valuePlaceholder}
            loading={loading}
            onInputChange={field.onInputChange && ((e, value) => field.onInputChange(e, value, asyncSearchSelectCallback, asyncLoadingCallback))}
            onChange={(event, newValue) => {
              if (multiple) {
                setSelectOptionCache((prev) => [...prev, ...newValue]);
                setCurrentValue(newValue.map((option) => option[valueKey]));
                return;
              }
              setSelectOptionCache((prev) => [...prev, newValue]);
              setCurrentValue(newValue?.[valueKey]);
            }}
          />
        );
      }
      case 'percentage':
      case 'currency':
      case 'number':
        if (field.type === 'percentage') inputSufix = '%';
        if (field.type === 'currency') inputSufix = '$';
        return (
          <TextField
            type="number"
            onChange={(event) => {
              setCurrentValue(event.target.value);
            }}
            InputProps={{
              startAdornment: inputSufix
                ? <InputAdornment position="start">{inputSufix}</InputAdornment>
                : undefined,
              inputProps: { ...field.inputProps },
            }}
            variant="filled"
            placeholder={field.valuePlaceholder}
            value={currentValue}
          />
        );
      default:
        return (
          <TextField
            value={currentValue || ''}
            size="small"
            hiddenLabel
            label={t('value')}
            fullWidth
            placeholder={field.valuePlaceholder || t('searchTerm')}
            onChange={(event) => {
              setCurrentValue(event.target.value);
            }}
          />
        );
    }
  },
  // Skip re-rendering if the value didn't change.
  (prevProps, nextProps) => dequal(prevProps, nextProps),
);

Value.whyDidYouRender = false;

export default Value;
