import React from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../Icon';
import StyledLinkButton from '../StyledLinkButton';
import StyledLink from '../StyledLink';
import Button from './Button';
import MoreActionsMenu from './MoreActionsMenu';

/**
 * @param {object} param
 * @param {string} param.title
 * @param {object[]} param.buttons
 * @param {string} param.buttons[].link
 * @param {string} param.buttons[].name
 * @param {Boolean} param.buttons[].disabled
 * @param {Function} param.buttons[].onClick
 */
const FormHeader = ({ title, buttons, infoTitle, breadcrumbs, subtitle, style = {} }) => {
  const defaultStyles = {
    width: '100%',
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const combinedStyles = { ...defaultStyles, ...style };
  const renderButton = (button, index) => {
    if (button.link && button.margin) {
      return (
        <StyledLinkButton
          key={index}
          to={button.link}
          style={{ marginLeft: '30px' }}
          disabled={button.disabled}
        >
          {button.name}
        </StyledLinkButton>
      );
    }
    if (button.link) {
      return (
        <StyledLinkButton
          key={index}
          to={button.link}
          disabled={button.disabled}
        >
          {button.name}
        </StyledLinkButton>
      );
    }
    if (button.moreInfoMenu) {
      if (!button.menuItems.length) return null;
      return (
        <MoreActionsMenu
          key={index}
          menuItems={button.menuItems}
        />
      );
    }
    if (button.icon) {
      return (
        <ExpandButtonWrapper
          key={button.color}
          onClick={button.onClick}
        >
          <Icon
            icon={button.icon}
            color={button.color}
            size="2x"
          />
        </ExpandButtonWrapper>
      );
    }
    return (
      <FormButton
        key={index}
        disabled={button.disabled}
        type={!button.onClick ? 'submit' : undefined}
        onClick={button.onClick}
      >
        {button.name}
      </FormButton>
    );
  };

  const renderBreadcrumbs = (breadcrumb, index) => (
    <StyledLink
      key={index}
      to={breadcrumb.link}
    >
      <LinkText>{breadcrumb.name}</LinkText>
      <BreadcrumbIcon
        icon={Icons.faChevronRight}
        color="#cacad0"
        style={{ marginRight: '15px' }}
      />
    </StyledLink>
  );

  return (
    <HeaderWrapper style={combinedStyles}>
      <HeadlineWrapper>
        <FormHeadline>{title}</FormHeadline>
        <SubtitleWrapper>
          {breadcrumbs ? breadcrumbs.map(renderBreadcrumbs) : null}
          {subtitle || null}
        </SubtitleWrapper>
      </HeadlineWrapper>
      {infoTitle ? <FormInfoTitle>{infoTitle}</FormInfoTitle> : null}
      <ButtonWrapper>
        {buttons && buttons.map(renderButton)}
      </ButtonWrapper>
    </HeaderWrapper>
  );
};

const FormButton = styled(Button)`
  margin-left: 30px;
  min-width: 130px;
`;

const HeaderWrapper = styled.div`
  ${(props) => { props.styles; }}
`;

const ButtonWrapper = styled.div`
  position: relative;
  min-width: 130px;
  display: flex;
  justify-content: right;
  margin-right: 15px;
`;

export const FormHeadline = styled.h3`
  font-size: 21px;
  margin: 0;
  color: #fff;
  font-weight: 600;
`;

export const FormInfoTitle = styled.h3`
  font-size: 21px;
  margin: 0;
  color: #3BD0FF; //Final color TBD
  font-weight: 600;
`;

const LinkText = styled.span`
  color: #cacad0;
  margin-right: 15px;
  &:hover {
    color: #fff;
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
`;

const BreadcrumbIcon = styled(Icon)`
  width: 15px;
  height: 15px;
`;

const ExpandButtonWrapper = styled.div`
  height: 44px;
  width: 60px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export default FormHeader;
