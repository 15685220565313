import React from 'react';
import styled from 'styled-components';
import { truncate } from '../utils/strings';
import Tooltip from './Tooltip';
// -- COMPONENT
export const Text = ({ value, displayedLength, ...rest }) => (
  <TextContent {...rest}>
    {value.length > displayedLength ? (
      <>
        <div
          data-for={`text-${value}`}
          data-tip={value}
        >
          {truncate(value, displayedLength)}
        </div>
        <Tooltip
          id={`text-${value}`}
          place="top"
        />
      </>
    ) : (<div color="inherit">{value}</div>)}
  </TextContent>
);
const TextContent = styled.div``;
