import React from 'react';
import { Box, Typography } from '@mui/material';

import { useFormatDateRangeText, useGetDateRange } from '../helper';

export const DateRangeElement = ({ element, paybackWindow }) => {
  const getDateRange = useGetDateRange(element?.dateRange, paybackWindow);
  const formatDateRangeText = useFormatDateRangeText(getDateRange, element?.dateRange);
  return (
    <Box
      height={20}
      display="flex"
      alignItems="center"
    >
      <Typography
        variant="subtitle1"
        noWrap
      >
        {formatDateRangeText}
      </Typography>
    </Box>
  );
};
