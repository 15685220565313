import React, { useContext } from 'react';
import {
  Grid,
  Button,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlatformContext } from '@upptic/module-directory';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import FormSectionWFields from '../Forms/FormSectionWFields';
import { validators } from '../../utils';
import FormHeader from '../Forms/FormHeader';
import { createActionItemGql } from '../gql';

const CommentPanel = ({
  linkedEntity,
  onClose,
  internalButtons,
  externalButtons,
  concept,
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'actionItems' });
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const creativeLead = concept?.creativeLead;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  let buttonClicked = null;

  const [createActionItem, { loading }] = useMutation(createActionItemGql, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ['actionItems'],
    awaitRefetchQueries: true,
  });

  const formatMembersForSubmit = (values) => {
    const members = [];
    if (values?.assignees && values?.assignees?.length !== 0) {
      for (const assignee of values?.assignees) {
        members.push({
          status: 'open',
          type: 'assignee',
          user: {
            emailAddress: assignee?.user?.emailAddress,
          },
        });
      }
    }
    if (values?.watchers && values?.watchers?.length !== 0) {
      for (const watcher of values?.watchers) {
        members.push({
          status: 'open',
          type: 'watcher',
          user: {
            emailAddress: watcher?.user?.emailAddress,
          },
        });
      }
    }
    return members;
  };

  const onSubmit = async (values) => {
    if (buttonClicked) {
      const prefix = {
        reject: t('rejectPrefix'),
        approve: t('approvePrefix'),
      };
      onClose();
      const actionItem = {
        actionItemCategory: { code: 'uppticCreativeFeedback' },
        endDate: values?.endDate ? new Date(values?.endDate) : undefined,
        name: prefix?.[buttonClicked?.id] + values?.name,
        status: 'open',
        type: 'comment',
        description: values?.description && values?.description !== '' ? values.description : prefix?.[buttonClicked?.id] + values?.name,
        members: values?.assignees || values?.watchers ? formatMembersForSubmit(values) : undefined,
        linkedEntity,
        application: { code: selectedApplicationCode },
      };
      const { errors } = await createActionItem({
        variables: {
          clientCode,
          actionItem,
        },
      });
      if (!errors) {
        buttonClicked?.onClick();
      }
      return;
    }
    const actionItem = {
      actionItemCategory: { code: 'uppticCreativeFeedback' },
      endDate: values?.endDate ? new Date(values?.endDate) : undefined,
      name: values?.name,
      status: 'open',
      type: 'comment',
      description: values?.description && values?.description !== '' ? values.description : values?.name,
      members: values?.assignees || values?.watchers ? formatMembersForSubmit(values) : undefined,
      linkedEntity,
      application: { code: selectedApplicationCode },
    };
    await createActionItem({
      variables: {
        clientCode,
        actionItem,
      },
    });
  };

  const fields = [
    {
      name: 'name',
      label: t('labels.name'),
      columnIndex: 0,
      xs: 12,
      validate: validators.required,
    },
    {
      name: 'assignees',
      type: 'users',
      label: t('labels.assignees'),
      multiple: true,
      placeholder: t('labels.assignees'),
      columnIndex: 0,
      xs: 12,
    },
    {
      name: 'watchers',
      type: 'users',
      label: t('labels.watchers'),
      multiple: true,
      placeholder: t('labels.watchers'),
      columnIndex: 0,
      xs: 12,
    },
    {
      name: 'endDate',
      type: 'date',
      label: t('labels.dueDate'),
      columnIndex: 1,
      xs: 12,
    },
    {
      name: 'description',
      type: 'richTextarea',
      label: t('labels.description'),
      placeholder: t('labels.description'),
      columnIndex: 1,
      minRows: 2,
      maxRows: 3,
      xs: 12,
      validate: externalButtons ? validators.required : undefined,
    },
  ];
  const initialValues = {
    name: '',
    status: 'open',
    type: 'comment',
    description: '',
    assignees: creativeLead ? [{ user: creativeLead }] : [],
  };

  const renderButton = (button, index, submitting, pristine) => {
    if (button.id === 'reject') {
      return (
        <Button
          key={index}
          variant="contained"
          sx={{
            minWidth: '130px',
            '&:not(last-of-type)': {
              marginBottom: '45px',
            },
          }}
          onClick={() => { buttonClicked = button; }}
          startIcon={button?.startIcon}
          disabled={submitting || button.isDisabled}
          type="submit"
        >
          {button.name}
        </Button>
      );
    }
    return (
      <Button
        key={index}
        variant="contained"
        sx={{
          minWidth: '130px',
          '&:not(last-of-type)': {
            marginBottom: '45px',
          },
        }}
        onClick={() => {
          if (pristine) {
            button.onClick();
            return;
          }
          buttonClicked = button;
        }}
        startIcon={button?.startIcon}
        disabled={submitting || button.isDisabled}
        type={pristine ? 'button' : 'submit'}
      >
        {button.name}
      </Button>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Grid
            container
          >
            <Grid
              item
              xs={internalButtons ? 12 : true}
              sx={{ border: '1px solid' }}
            >
              <FormHeader
                title={t('addComment')}
                buttons={internalButtons
                  ? [{ name: tg('add'), disabled: submitting || pristine }]
                  : []}
                sx={{
                  padding: '16px 16px 0px 16px',
                }}
              />
              {loading ? (
                <Box
                  height={240}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress size="6rem" />
                </Box>
              ) : (
                <FormSectionWFields fields={fields} />
              )}
            </Grid>
            {externalButtons && externalButtons?.length !== 0 ? (
              <Grid
                item
                width={290}
              >
                <Stack
                  sx={{ width: '100%', height: '100%', paddingLeft: '24px' }}
                  spacing={2}
                  justifyContent="center"
                >
                  {externalButtons.map((button, index) => renderButton(button, index, submitting, pristine))}
                </Stack>
              </Grid>
            ) : null}
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default CommentPanel;
