import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import themes from '../themes/index';
import Icon, { Icons } from '../Icon';

function AssetInput({ title, children, disabled = false, rootProps = {}, style = {} }) {
  const defaultStyles = {
    padding: '15px',
    height: '44px',
    borderRadius: '8px',
    border: '1px dashed',
    borderColor: themes.colors.borderGrey, // set separately to avoid issues when overriding
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: disabled ? 'not-allowed !important' : 'pointer',
  };

  const combinedStyles = { ...defaultStyles, ...style };
  return (
    <EmptyBox
      {...rootProps}
      style={combinedStyles}
      disableHover={!title}
    >
      {!!children && children}
      {title && (
        <Flex
          alignItems="center"
          flexDirection="column"
        >
          <Icon
            icon={Icons.faPlusCircle}
            color={themes.colors.purple}
            style={{ marginBottom: '10px', fontSize: '22px' }}
          />
          <Placeholder>{title}</Placeholder>
        </Flex>
      )}
    </EmptyBox>
  );
}
const EmptyBox = styled.div`
  ${(props) => { props.style; }}

  
  :hover {
    ${(props) => !props.disableHover && 'background-color: rgba(171, 104, 239, 0.2);'}
    transition: 0.5s;
  }

  :focus {
    outline: 0;
  }
`;
const Placeholder = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.53px;
  user-select: none;
  white-space: pre-line;
  text-align: center;
`;

export default AssetInput;
