import React, { useState, useMemo } from 'react';
import {
  Tooltip,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Icon from '../components/Icon';

export default function ClipboardTooltipButton({
  button,
  index,
  sx,
}) {
  const { t } = useTranslation('integrations');
  const [clipboardOpen, setClipboardOpen] = useState(false);
  const findIcon = useMemo(() => {
    if (button.icon) return <Icon icon={button.icon} />;
    if (button.muiIcon) return button.muiIcon;
    return <ContentCopyIcon />;
  }, [button]);
  return (
    <Tooltip
      title={clipboardOpen ? (button.tooltip.clipboardTitle || t('copied')) : button.tooltip.title}
      placement={button.tooltip.placement || 'top'}
      disableInteractive
      onClose={() => {
        setTimeout(() => {
          setClipboardOpen(false);
        }, 100);
      }}
      leaveDelay={clipboardOpen ? 500 : 0}
    >
      <Button
        variant="contained"
        key={index}
        onClick={(event) => {
          event.stopPropagation();
          navigator.clipboard.writeText(button.value);
          setClipboardOpen(true);
        }}
        disabled={button.disabled}
        sx={sx || {
          minWidth: '40px',
          height: '40px',
          padding: '0 5px',
        }}
      >
        {findIcon}
      </Button>
    </Tooltip>
  );
}
