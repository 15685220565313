import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  useTheme,
  Box,
  Typography,
  Stack,
} from '@mui/material';

function StringBuilder({
  input,
  meta,
  buttons,
  textFieldLabel,
  error,
  helperText,
  id,
  buttonSectionLabel,
  disabled,
}) {
  const theme = useTheme();
  const inputRef = useRef();
  const [value, setValue] = useState(input.value);
  const [cursorPosition, setCursorPosition] = useState(inputRef?.current?.selectionStart);
  const handleButtonClick = (button) => {
    const originalStr = inputRef?.current?.value;
    const newStr = button.value;
    const newStrLength = newStr?.length;
    const insertPosition = inputRef?.current?.selectionStart;
    const newString = originalStr.slice(0, insertPosition)
      + newStr + originalStr.slice(insertPosition);
    const newCursorPosition = insertPosition + newStrLength;
    setValue(newString);
    setCursorPosition(newCursorPosition);
    // need this to ensure the new cursor position is set before the field is refocused
    // to keep the cursor after the newly entered variable
    setTimeout(() => inputRef?.current?.focus(), 500);
  };
  useEffect(() => {
    input.onChange(value);
  }, [value]);
  const renderButton = (button, index) => (
    <Button
      key={index}
      disabled={button.disabled || disabled}
      type="button"
      onClick={() => handleButtonClick(button)}
      variant="contained"
      sx={{
        minWidth: '130px',
        margin: '8px',
        textTransform: 'none',
        ...button.sx,
      }}
    >
      {button.name}
    </Button>
  );
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Typography>{buttonSectionLabel}</Typography>
      <Box
        width="100%"
        display="flex"
        mb={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
        >
          {buttons?.map(renderButton)}
        </Stack>
      </Box>
      <Box
        width="100%"
      >
        <TextField
          id={id}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          fullWidth
          variant="filled"
          label={textFieldLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
          sx={{
            '& .MuiInputLabel-root': {
              color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
            },
          }}
          inputRef={inputRef}
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(cursorPosition, cursorPosition);
          }}
        />
      </Box>
    </Box>
  );
}

export default StringBuilder;
