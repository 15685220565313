import * as React from 'react';
import { usePagination, useSortBy, useTable, useExpanded } from 'react-table';
import { Table } from './components/TableComponent';

export function DynamicTable({
  onPaginationChange,
  data,
  defaultItemsPerPage,
  columns,
  initialSortBy,
  scrollable,
  layoutFixed,
  maxHeight = '500px',
  fullHeight,
  pageCount,
  currentPage,
  expanded,
  filters,
  ...rest
}) {
  const tableConfig = React.useMemo(() => {
    const usedHooks = [useSortBy, usePagination];
    const params = {
      disableMultiSort: false,
      initialState: {},
    };
    if (initialSortBy) {
      params.initialState = {
        ...params.initialState,
        sortBy: initialSortBy, // puts initialSortBy element in array because react-table requires this
      };
    }
    if (expanded) {
      usedHooks.push(useExpanded);
      params.getSubRows = (row) => row[expanded] || [];
      params.paginateExpandedRows = !expanded;
      params.autoResetExpanded = false;
    }
    return {
      usedHooks,
      params,
    };
  }, [initialSortBy, expanded]);

  const tableData = React.useMemo(() => {
    if (data !== undefined) return data;
    return [];
  }, [data]);

  const getRowId = React.useCallback((row, relativeIndex, parent) => {
    if (row.code) return row.code;
    return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
  }, []);

  const { page, rows, getTableProps, getTableBodyProps, headerGroups, prepareRow } = useTable({
    columns,
    data: tableData,
    manualPagination: true,
    pageCount,
    ...tableConfig.params,
    getRowId,
  },
  ...tableConfig.usedHooks);

  const footerProps = React.useMemo(() => ({
    columnsNumber: columns.length,
    currentPage,
    onPaginationChange,
    pagesCount: pageCount,
    displayItemsCountSelector: true,
    initialItemsCount: defaultItemsPerPage,
    disableMultiSort: true,
  }), [
    columns,
    onPaginationChange,
    defaultItemsPerPage,
    currentPage,
    pageCount,
  ]);

  const rowsToDisplay = React.useMemo(() => page || rows, [page, rows]);

  return (
    <>
      <Table
        rows={rowsToDisplay}
        columnsNumber={columns.length}
        footerProps={footerProps}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        scrollable={scrollable}
        maxHeight={maxHeight}
        fullHeight={fullHeight}
        layoutFixed={layoutFixed}
        filters={filters}
        {...rest}
      />
    </>
  );
}
