/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Link as MUILink,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@mui/material';
import { PlatformContext } from '@upptic/module-directory';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Error } from '../../components/Error';
import ReportFirstClassCard from './ReportFirstClassCard';

const ReportFirstClassPage = ({
  onReportChange,
  categoryData,
  selectedReport,
  error,
  loading,
}) => {
  const platformStore = useContext(PlatformContext);
  const { t } = useTranslation('ui', { keyPrefix: 'reports' });
  const themes = useTheme();

  const options = categoryData?.gdsReports?.map((report) => ({ label: report.name, value: report.code }));
  const report = useMemo(() => {
    if (!categoryData) return null;
    const report = categoryData?.gdsReports?.find((report) => report.code === selectedReport);
    return report;
  }, [selectedReport, categoryData]);

  const handleButtonChange = (event, newValue) => {
    const findReport = categoryData?.gdsReports?.find((report) => report.code === newValue);
    onReportChange(findReport);
  };

  const renderCategories = () => {
    if (options && options?.length !== 0) {
      return options.map((option, index) => (
        <ToggleButton
          key={index}
          value={option.value}
        >
          {option.label}
        </ToggleButton>
      ));
    }
    return null;
  };

  const renderReport = () => {
    if (selectedReport) {
      return (
        <ReportFirstClassCard
          selectedReport={report}
        />
      );
    }
    return null;
  };
  if (platformStore.applications.length < 1) {
    return (
      <Typography
        variant="h4"
        textAlign="center"
        mt={4}
      >
        {t('noApplications')}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
      >
        <CircularProgress size="6rem" />
      </Box>
    );
  }

  if (!categoryData) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          mb={2}
          mt={4}
        >
          <Typography variant="h5">{t('growthZeroStateText')}</Typography>
        </Box>
        <Box>
          <MUILink
            component={Link}
            to={`/${platformStore.currentClient.code}/client/integrations`}
          >
            {t('integrationLink')}
          </MUILink>
        </Box>
      </Box>
    );
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box
      flexGrow={1}
      px={3}
      pt={1}
      pb={3}
      style={{ textAlign: 'center' }}
    >
      <Box
        display="flex"
        alignItems="center"
        pb={0.5}
        sx={{
          '& .MuiToggleButtonGroup-grouped': {
            marginTop: '4px',
            marginBottom: '4px',
            padding: '4px',
            borderRadius: '4px !important',
          },
          '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: '1px solid !important',
          },
          '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            marginRight: '8px',
          },
          '& .MuiToggleButton-root.Mui-selected': {
            color: '#fff',
            backgroundColor: themes.palette.primary.main,
            '&:hover': {
              backgroundColor: '#ac68ee',
            },
          },
        }}
      >
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={handleButtonChange}
          value={selectedReport}
          sx={{ flexWrap: 'wrap' }}
        >
          {renderCategories()}
        </ToggleButtonGroup>
      </Box>
      <Grid
        container
      >
        <Grid
          item
          xs={12}
        >
          {selectedReport ? (
            renderReport()
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportFirstClassPage;
