import React from 'react';
import styled from 'styled-components';
import Button from '../../forms/Button';
import StyledLink from '../../StyledLink';
import Icon from '../../Icon';

/**
 * @param {object} param
 * @param {object[]} param.buttons
 * @param {string} param.buttons[].to
 * @param {string} param.buttons[].icon
 * @param {function} param.buttons[].onClick
 */
const ActionCell = ({ buttons }) => (
  <Wrapper>
    {buttons.map((button, index) => {
      if (button.to) {
        return (
          <StyledLink
            key={index}
            to={button.to}
            children={
              (
                <ActionButton>
                  <Icon icon={button.icon} />
                </ActionButton>
              )
            }
          />
        );
      }
      if (button.externalLink) {
        return (
          <a
            href={button.externalLink}
            target={button.target}
          >
            <ActionButton>
              <Icon icon={button.icon} />
            </ActionButton>
          </a>
        );
      }
      return (
        <ActionButton
          key={index}
          onClick={button.onClick}
        >
          <Icon icon={button.icon} />
        </ActionButton>
      );
    })}
  </Wrapper>
);

export default ActionCell;

const ActionButton = styled(Button)`
  min-width: 40px;
  height: 40px;
  padding: 0 5px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    margin-right: 15px;
  }
  
`;
