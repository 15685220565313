import React, { useMemo } from 'react';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { formatters } from '../../../utils';

const splitIntoChunks = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

export default function CustomTooltip({
  active,
  payload,
  label,
  mainAxisTickFormatter,
  valueFormatter,
  widthMultiplier = 250,
  CustomTooltipComponent,
  colorPalette,
  chartData,
  payloadFormatter,
}) {
  const formatPayload = useMemo(() => {
    if (payloadFormatter) return payloadFormatter(payload);
    return payload;
  }, [payloadFormatter, payload]);
  if (!active || !payload || !payload.length) return null;
  if (!valueFormatter) valueFormatter = (item) => formatters.formatData(item, 'number', { decimalPlaces: 0 });

  const sortFunc = typeof payload[0].value === 'string'
    ? (a, b) => a.value.localeCompare(b.value)
    : (a, b) => b.value - a.value; // descending order
  payload = [...payload].sort(sortFunc);

  const renderIcon = (item) => {
    const SIZE = 14;
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    if (item.stroke) {
      return (
        <svg height="14" width="14">
          <path
            strokeWidth={2}
            fill="none"
            stroke={item.stroke}
            d={`M0,${halfSize}h${thirdSize}
              A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
              H${SIZE}M${2 * thirdSize},${halfSize}
              A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
            className="recharts-legend-icon"
          />
        </svg>
      );
    }

    return (
      <svg height="14" width="14">
        <path
          stroke="none"
          fill={item.stroke || item.fill}
          d={`M0,${SIZE / 8}h${SIZE}v${(32 * 3) / 4}h${-SIZE}z`}
          className="recharts-legend-icon"
        />
      </svg>
    );
  };
  const metricChunks = splitIntoChunks(formatPayload, 15);
  return (
    CustomTooltipComponent ? (
      <CustomTooltipComponent
        payload={payload}
        active={active}
        label={label}
        mainAxisTickFormatter={mainAxisTickFormatter}
        valueFormatter={valueFormatter}
        widthMultiplier={widthMultiplier}
        metricChunks={metricChunks}
        renderIcon={renderIcon}
        colorPalette={colorPalette}
        chartData={chartData}
      />
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        p={0.5}
        sx={{
          backgroundColor: '#fff',
          opacity: '0.9',
          width: metricChunks.length * widthMultiplier,
        }}
      >
        <Typography
          variant="body2"
          ml={0.5}
          color="secondary"
        >
          {mainAxisTickFormatter ? mainAxisTickFormatter(label) : label}
        </Typography>
        <Grid container>
          {
            metricChunks.map((chunk) => (
              <Grid
                item
                xs={parseInt(12 / metricChunks.length)}
                sx={{
                  '& .MuiListItem-divider': {
                    '&:not(:last-of-type)': {
                      borderBottom: '1px solid black',
                    },
                  },
                }}
              >
                <List
                  sx={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  {chunk.map((item) => (
                    <ListItem
                      dense
                      divider
                      secondaryAction={(
                        <Typography
                          color="secondary"
                          variant="body2"
                        >
                          <span style={{ fontWeight: 'bolder' }}>
                            {valueFormatter(item.value, item)}
                          </span>
                        </Typography>
                      )}
                    >
                      <ListItemIcon
                        sx={{ minWidth: '24px' }}
                      >
                        {!item?.hideIcon ? renderIcon(item) : null}
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        sx={{
                          marginTop: '0px',
                          marginBottom: '0px',
                        }}
                        primary={(
                          <Typography
                            color="secondary"
                            variant="body2"
                            style={{ wordWrap: 'break-word' }}
                          >
                            {`${item.name}: `}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    )
  );
}
