import React, { useMemo } from 'react';
import SelectInput from './SelectInput/SelectInput';
// eslint-disable-next-line import/extensions
import countries from './data/countries.json';

export default function CountrySelectInput(props) {
  const getOptions = useMemo(() => {
    let options = [
      { value: 'US', label: 'United States' },
      ...countries.map(({ Code, Name }) => ({ label: Name, value: Code })),
    ];
    if (props.filter) {
      options = options.filter(props.filter);
    }
    return options;
  }, [props.filter]);

  return (
    <SelectInput
      {...props}
      isSearchable
      options={getOptions}
    />
  );
}
