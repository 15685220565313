import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import roundCachedIcon from 'assets/images/ic-round-cached.png';
import Spinner from './Spinner';

function Refresh({ datetime, pending = false, onClick }) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      pl={30}
      pt={32}
    >
      <DateBox>
        Data from:
        {datetime}
      </DateBox>
      <RefreshData>
        {pending ? (
          <RefreshingContainer>
            Refreshing Data
            {' '}
            <RefreshSpinner />
          </RefreshingContainer>
        ) : (
          <>
            Refresh Data:
            {' '}
            <RefreshIcon
              alt=""
              onClick={onClick}
              src={roundCachedIcon}
            />
          </>
        )}
      </RefreshData>
    </Flex>
  );
}
const DateBox = styled(Box)`
  font-size: 12px;
  color: #858592;
`;
const RefreshData = styled(Box)`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: #fff;
`;
const RefreshingContainer = styled.div`
  opacity: 0.7;
  display: flex;
  align-items: center;
`;
const RefreshSpinner = styled(Spinner)`
  margin-left: 5px;
  display: inline-block;

  .spinner-border {
    width: 26px !important;
    height: 26px !important;
  }
`;
const RefreshIcon = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;
`;
export default Refresh;
