import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Select, {
  components as selectComponents,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import hexToRgba from 'hex-to-rgba';
import theme from 'components/themes';
import { SelectDropdownOptions } from './constants/selectDropdownOptions';
import FormError from '../FormError';

const makeSelectStyles = ({ valid, light, readOnlyStyles }) => {
  const controlBoxShadow = (isFocused) => {
    if (isFocused) {
      return `0 0 15px 0 ${theme.colors.infoBlueBoxShadow}`;
    }
    if (!valid) {
      return `0 0 15px 0 ${theme.colors.notificationRedBoxShadow}`;
    }
    return 'none';
  };
  const controlBorder = (isFocused) => {
    if (isFocused) {
      return theme.colors.infoBlue;
    }
    if (!valid) {
      return theme.colors.notificationRed;
    }
    return theme.colors.background;
  };
  const singleOptionBackground = (isSelected, isFocused) => {
    if (isFocused) {
      return hexToRgba(theme.colors.purple, 0.5);
    }
    return (isSelected
      ? hexToRgba(theme.colors.purple, 0.3)
      : theme.colors.inputs
    );
  };
  const disabledOpacity = (isDisabled) => {
    if (readOnlyStyles) return 1.0;
    if (isDisabled) {
      return 0.5;
    }
    if (light) {
      return 0.8;
    }
    return 1.0;
  };
  return {
    control: (styles, { menuIsOpen, isFocused, isDisabled }) => ({
      ...styles,
      backgroundColor: theme.colors.inputs,
      color: theme.colors.white,
      borderColor: controlBorder(isFocused),
      borderRadius: '2px',
      boxShadow: controlBoxShadow(isFocused),
      opacity: disabledOpacity(isDisabled),
      ':hover': {
        borderColor: menuIsOpen
          ? theme.colors.infoBlue
          : valid
            ? theme.colors.background
            : theme.colors.notificationRed,
      },
    }),
    // @ts-ignore
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      backgroundColor: singleOptionBackground(isSelected, isFocused),
      ':hover': {
        backgroundColor: hexToRgba(theme.colors.purple, 0.5),
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.colors.white,
      opacity: 0.4,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.colors.white,
    }),
    multiValue: (styles) => ({
      ...styles,
      color: theme.colors.white,
      backgroundColor: theme.colors.backgroundLight,
      border: `1px solid ${theme.colors.purple}`,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.colors.white,
      fontSize: '14px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        cursor: 'pointer',
        color: theme.colors.white,
        backgroundColor: theme.colors.backgroundLight,
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: theme.colors.white,
      ':hover': {
        color: theme.colors.white,
        cursor: 'pointer',
      },
    }),
    clearIndicator: (styles) => ({
      ...styles,
      display: 'inherit',
      color: theme.colors.white,
      ':hover': {
        color: theme.colors.white,
        cursor: 'pointer',
      },
    }),
    input: (styles) => ({ ...styles, color: theme.colors.white }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.colors.inputs,
      color: theme.colors.white,
      margin: 0,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      borderRadius: '0 0 4px 4px',
    }),
  };
};
function SelectInput({
  placeholder = 'Select',
  value,
  defaultValue,
  options = [],
  onChange,
  valid = true,
  error,
  name,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  isDisabled = false,
  labelKey = 'label',
  valueKey = 'value',
  menuPosition = SelectDropdownOptions.auto,
  formatGroupLabel,
  group = false,
  components = {},
  light,
  loadOptions,
  readOnlyStyles,
}) {
  const [cachedOptions, setCachedOptions] = useState([]);

  const allOptions = useMemo(() => [...options, ...cachedOptions], [options, cachedOptions]);

  const selectedOption = useMemo(() => {
    if (group) {
      for (const item of allOptions) {
        const singleValue = item.options.find((o) => o[valueKey] === value);
        if (singleValue) return singleValue;
      }
    }
    if (!isMulti) {
      const singleValue = allOptions.find((o) => o[valueKey] === value);
      return singleValue || null;
    }
    if (value) {
      return value.reduce((acc, selectedOption) => {
        const option = allOptions.find((curOption) => curOption[valueKey] === selectedOption);
        if (!option) return acc;
        acc.push(option);
        return acc;
      }, []);
    }
    return null;
  }, [value, allOptions, isMulti, valueKey, group]);

  const styles = useMemo(() => makeSelectStyles({ valid, light, readOnlyStyles }), [valid, light, readOnlyStyles]);

  const handleChange = useCallback((option) => {
    let valueToSet = '';
    if (option) valueToSet = option[valueKey];
    valueToSet !== value && onChange(valueToSet);

    if (loadOptions && option && !allOptions.find((cOption) => cOption[valueKey] === option[valueKey])) {
      setCachedOptions([...cachedOptions, option]);
    }
  }, [onChange, valueKey, value, allOptions, cachedOptions, loadOptions]);

  const handleMultiChange = useCallback((optionsList) => {
    if (optionsList) return onChange(optionsList.map((option) => option[valueKey]));
    return onChange([]);
  }, [onChange, valueKey]);

  const getLabel = (option) => option[labelKey];
  const getValue = (option) => option[valueKey];

  const OptionComponent = (props) => {
    if (selectedOption && selectedOption[valueKey] === props.data[valueKey]) {
      return null;
    }
    if (components && components.Option) {
      return <components.Option {...props} />;
    }
    return <selectComponents.Option {...props} />;
  };
  const SelectComponent = (loadOptions) ? AsyncSelect : Select;

  return (
    <Wrapper>
      <SelectComponent
        isClearable={isClearable}
        name={name}
        styles={styles}
        value={selectedOption}
        defaultValue={defaultValue}
        options={options}
        // @ts-ignore
        onChange={isMulti ? handleMultiChange : handleChange}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isMulti={isMulti}
        noOptionsMessage={() => null}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        menuPlacement={menuPosition}
        formatGroupLabel={formatGroupLabel}
        components={{ ...components, Option: OptionComponent }}
        light={light}
        loadOptions={loadOptions}
        cacheOptions={!!loadOptions}
        defaultOptions={!!loadOptions}
      />
      {!valid ? <FormError>{error}</FormError> : null}
    </Wrapper>
  );
}
export default SelectInput;

const Wrapper = styled.div`
  width: 100%;
`;
