import React from 'react';
import styled from 'styled-components';

const defaultStyles = {
  backgroundColor: '#13132b',
  padding: '15px 15px',
  marginBottom: '30px',
  borderRadius: '8px',
};

function FormSection({
  children,
  style = {},
}) {
  const combinedStyles = { ...defaultStyles, ...style };
  return (
    <Wrapper
      style={combinedStyles}
    >
      {children}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  ${(props) => { props.style; }}
`;
export default FormSection;
