/* eslint-disable max-len */
import gql from 'graphql-tag';

export const clientUsersGql = gql`
query clientUsers($clientCode: String!, $filter: Filter){
  clientUsers(clientCode: $clientCode, filter: $filter){
    emailAddress
    firstName
    lastLoginDate
    lastName
    roles {
      name
    }
    status
  }
}
`;

export const actionItemCategoriesGql = gql`
query actionItemCategories($clientCode: String!, $filter: Filter) {
  actionItemCategories(clientCode: $clientCode, filter: $filter) {
    code
    color
    name
    owner
    parentActionItemCategory {
      code
      name
    }
    status
  }
}
`;

export const actionItemsGql = gql`
query actionItems($clientCode: String!, $filter: Filter) {
  actionItems(clientCode: $clientCode, filter: $filter) {
    actionItemCategory {
      code
      name
    }
    audit {
      createBy {
        emailAddress
        firstName
        lastName
      }
      createDate
      updateBy {
        emailAddress
        firstName
        lastName
      }
      updateDate
    }
    application {
      code
      name
    }
    code
    endDate
    linkedEntity {
      code
      name
      type
      url
    }
    members {
      status
      type
      user {
        emailAddress
        firstName
        lastName
      }
    }
    name
    startDate
    status
    type
    description
  }
}
`;

export const createActionItemGql = gql`
mutation createActionItem($clientCode: String!, $actionItem: CreateActionItem!) {
  createActionItem(clientCode: $clientCode, actionItem: $actionItem) {
    code
    name
  }
}
`;

export const updateActionItemGql = gql`
mutation updateActionItem($clientCode: String!, $actionItemCode: String!, $actionItem: UpdateActionItem!) {
  updateActionItem(clientCode: $clientCode, actionItemCode: $actionItemCode, actionItem: $actionItem)
}
`;

export const updateUserActionItemGql = gql`
mutation updateUserActionItem($actionItemCode: String!, $actionItem: UpdateActionItem!) {
  updateUserActionItem(actionItemCode: $actionItemCode, actionItem: $actionItem)
}
`;

export const deleteActionItemGql = gql`
mutation deleteActionItem($clientCode: String!, $actionItemCode: String!) {
  deleteActionItem(clientCode: $clientCode, actionItemCode: $actionItemCode)
}
`;

export const deleteUserActionItemGql = gql`
mutation deleteUserActionItem($actionItemCode: String!) {
  deleteUserActionItem(actionItemCode: $actionItemCode)
}
`;

export const addActionItemMemberGql = gql`
mutation addActionItemMember($clientCode: String!, $actionItemCode: String!, $member: CreateActionItemMember!) {
  addActionItemMember(clientCode: $clientCode, actionItemCode: $actionItemCode, member: $member)
}
`;

export const updateActionItemMemberGql = gql`
mutation updateActionItemMember($clientCode: String!, $actionItemCode: String!, $emailAddress: String!, $member: UpdateActionItemMember!, $type: ActionItemMemberType!) {
  updateActionItemMember(clientCode: $clientCode, actionItemCode: $actionItemCode, emailAddress: $emailAddress, member: $member, type: $type)
}
`;

export const deleteActionItemMemberGql = gql`
mutation deleteActionItemMember($clientCode: String!, $actionItemCode: String!, $emailAddress: String!, $type: ActionItemMemberType!) {
  deleteActionItemMember(clientCode: $clientCode, actionItemCode: $actionItemCode, emailAddress: $emailAddress, type: $type)
}
`;

export const addUserActionItemMemberGql = gql`
mutation addUserActionItemMember($actionItemCode: String!, $member: CreateActionItemMember!) {
  addUserActionItemMember(actionItemCode: $actionItemCode, member: $member)
}
`;

export const updateUserActionItemMemberGql = gql`
mutation updateUserActionItemMember($actionItemCode: String!, $emailAddress: String!, $member: UpdateActionItemMember!, $type: ActionItemMemberType!) {
  updateUserActionItemMember(actionItemCode: $actionItemCode, emailAddress: $emailAddress, member: $member, type: $type)
}
`;

export const deleteUserActionItemMemberGql = gql`
mutation deleteUserActionItemMember($actionItemCode: String!, $emailAddress: String!, $type: ActionItemMemberType!) {
  deleteUserActionItemMember(actionItemCode: $actionItemCode, emailAddress: $emailAddress, type: $type)
}
`;

export const currentUserActionItemsGql = gql`
query currentUserActionItems($filter: Filter) {
  currentUserActionItems(filter: $filter) {
    actionItemCategory {
      code
      name
    }
    audit {
      createBy {
        emailAddress
        firstName
        lastName
      }
      createDate
      updateBy {
        emailAddress
        firstName
        lastName
      }
      updateDate
    }
    client {
      code
      name
    }
    application {
      code
      name
    }
    code
    description
    endDate
    id
    linkedEntity {
      code
      name
      type
      url
    }
    members {
      status
      type
      user {
        emailAddress
        firstName
        lastName
      }
    }
    name
    startDate
    status
    type
  }
}
`;

export const applicationCreativeConceptsGql = gql`
query applicationCreativeConcepts (
  $clientCode: String!,
  $applicationCode: String!,
  $filter: Filter,
  $limit: Int,
  $offset: Int,
  $sort: [String!]
) {
  applicationCreativeConcepts(
    clientCode: $clientCode,
    applicationCode: $applicationCode,
    filter: $filter,
    limit: $limit,
    offset: $offset,
    sort: $sort
  ) {
    totalItemCount
    items {
      internalId
      downloadUrl
      uaLead {
        emailAddress
        firstName
        lastName
      }
      productionAssetCount
      code
      name
      status
      workflowStatus
      type
      description
      conceptImage
      isPreview
      isPinned
      creativeLead {
        emailAddress
        firstName
        lastName
      }
      assignedUser {
        emailAddress
        firstName
        lastName
      }
      deliveryDate
      targetDeliveryDate
      parentCreativeConcept {
        code
        name
      }
      modelType
      creativeNode {
        code
        name
        pathNames
      }
      isPinned
    }
  }
}
`;
