import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../Icon';
import themes from '../themes/index';
import Popover from '../Popover';
import Button from './Button';

function MoreActionsMenu({ menuItems }) {
  const [menu, setMenu] = useState(false);
  const showMenu = () => setMenu(!menu);

  useEffect(() => {
    const handleClick = (evt) => {
      const targetedElement = evt.target;
      if (targetedElement.classList.value.includes('ellipsis-menu')) {
        document.removeEventListener('click', handleClick);
        return;
      }
      showMenu();
      document.removeEventListener('click', handleClick);
    };
    if (menu) {
      document.addEventListener('click', handleClick);
    }
    return function cleanUpListener() {
      document.removeEventListener('click', handleClick);
    };
  }, [menu]);

  return (
    <>
      <EllipsisButton
        className="ellipsis-menu"
        onClick={() => showMenu()}
        icon={<Icon icon={Icons.faEllipsisV} />}
        iconOnly
      />
      {menu ? (
        <MenuWrapper>
          {menuItems.length !== 0 ? menuItems.map((item) => {
            if (item.onClick) {
              if (item.popover) {
                return (
                  <Popover
                    key={item.name}
                    content="Copied"
                  >
                    <MenuItem
                      key={item.name}
                      onClick={() => { item.onClick(); showMenu(); }}
                      className="ellipsis-menu"
                    >
                      {item.name}
                    </MenuItem>
                  </Popover>
                );
              }
              return (
                <MenuItem
                  key={item.name}
                  onClick={() => { item.onClick(); showMenu(); }}
                  className="ellipsis-menu"
                >
                  {item.name}
                </MenuItem>
              );
            }
            if (item.to) {
              return (
                <BaseLink
                  key={item.name}
                  href={item.to}
                  target={item.target || '_blank'}
                >
                  <MenuItem
                    key={item.name}
                    className="ellipsis-menu"
                    onClick={() => showMenu()}
                  >
                    {item.name}
                  </MenuItem>
                </BaseLink>
              );
            }
            return null;
          }) : null}
        </MenuWrapper>
      ) : null}
    </>
  );
}

const EllipsisButton = styled(Button)`
  min-width: 30px;
  height: 44px;
  margin-right: 15px;
  padding: 0 12px;
`;

const MenuWrapper = styled.div`
  position: absolute;
  left: -147px;
  top: 44px;
  display: flex;
  width: 180px;
  flex-direction: column;
  text-decoration: none;
  z-index: 10;
  background-color: #07071e;
  box-shadow: inset 0.5px 0.5px 0px 0px rgba(151, 67, 235, 1),
    inset -0.5px -0.5px 0px 0px rgba(151, 67, 235, 1);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify: content: flex-start;
  padding: 2px 8px;
  width: 100%;
  height: 44px;
  color: ${themes.colors.white};
  &:hover {
    color: #ffffff;
    background-color: rgba(151, 67, 235, 0.5);
    cursor: pointer;
  }
`;

const BaseLink = styled.a`
  color: ${themes.colors.white};
  &:hover {
    text-decoration: none;
  }
`;

export default MoreActionsMenu;
