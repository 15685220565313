/* eslint-disable no-continue */
import React, { useMemo, useCallback } from 'react';
import {
  GridToolbarContainer,
  GridRowModes,
} from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
} from '@mui/material';
import {
  randomId,
} from '@mui/x-data-grid-generator';

export default function Toolbar(props) {
  const { setGridRows, setRowModesModel, apiRef, gridRows, formattedColumns, toolbarButtons, rowModesModel } = props;
  const hasNewRow = useMemo(() => {
    const findNewRow = gridRows.find((row) => row.isNew);
    if (findNewRow) return true;
    return false;
  }, [gridRows]);
  const createFieldsArray = useMemo(() => {
    if (toolbarButtons) {
      return formattedColumns.map((column) => column.field);
    }
    return [];
  }, [gridRows]);

  const newRowObject = useMemo(() => {
    if (!formattedColumns.length) return {};
    const fields = {};
    for (const { field, defaultValue = null } of formattedColumns) {
      if (field === 'actions' || field === undefined) continue;
      fields[field] = defaultValue || '';
    }
    return fields;
  }, [formattedColumns]);

  const getRowIndex = useCallback((id) => apiRef.current.getRowIndexRelativeToVisibleRows(id), [apiRef]);

  const handleAddClick = async () => {
    const id = randomId();
    const resetGridRowModes = new Promise((resolve) => {
      const newRowModesModel = {};
      const idsArray = Object.keys(rowModesModel);
      if (idsArray?.length === 0) resolve(newRowModesModel);
      for (const id of idsArray) {
        newRowModesModel[id] = { mode: GridRowModes.View, ignoreModifications: true };
      }
      resolve(newRowModesModel);
    });
    await resetGridRowModes
      .then((res) => {
        setGridRows((oldRows) => [...oldRows, { id, isNew: true, ...newRowObject }]);
        setRowModesModel({
          ...res,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: createFieldsArray[0] },
        });
        setTimeout(() => {
          apiRef.current.scrollToIndexes({
            rowIndex: getRowIndex(id),
          });
        }, 400);
      });
  };

  const renderButton = (button, index) => {
    if (button.type === 'add') {
      return (
        <Button
          key={index}
          color="primary"
          variant="contained"
          sx={{ minWidth: '130px' }}
          startIcon={<AddIcon />}
          onClick={() => handleAddClick()}
          disabled={hasNewRow || button.disabled}
        >
          {button.label}
        </Button>
      );
    }
    return (
      <Button
        key={index}
        color="primary"
        variant="contained"
        sx={{ minWidth: '130px' }}
        startIcon={button.IconComponent}
        onClick={() => button.onClick(props)}
        disabled={button.disabled}
      >
        {button.label}
      </Button>
    );
  };

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: 'flex-end',
        padding: '8px',
      }}
    >
      {toolbarButtons && toolbarButtons?.map(renderButton)}
    </GridToolbarContainer>
  );
}
