/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { PlatformContext } from '@upptic/module-directory';
import { observer } from 'mobx-react';

import { reportingAppMetricsGql } from '../gql';
import { formatData } from '../../../utils/formatters/formatters';
import { getWidgetQueryInfo, useGetDividerSx, useGetMetricsFilter } from '../helper';
import ElementTitle from '../GenericElements/ElementTitle';

function BigNumberElement({ element, appReportingCurrency, modelType }) {
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const applicationCode = platformStore.selectedApplication?.code;
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const elementHeight = element.heightFactor ? (100 * element.heightFactor) : 100;
  const baseElementSx = {
    height: elementHeight,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };
  const elementSx = useGetDividerSx(baseElementSx, element);

  const queryInfo = getWidgetQueryInfo(element?.dataSource?.queryType);

  const metricsFilter = useGetMetricsFilter(element.dataSource?.filter, modelType);
  const comparisonFilter = useGetMetricsFilter(element.comparison?.dataSource?.filter, modelType);

  const { data } = useQuery(queryInfo.gql, {
    skip: !element.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.dataSource.metrics,
      filter: metricsFilter,
      dimensions: element.dataSource.dimensions,
      dateRange: element.dataSource?.dateRange,
      sort: element.dataSource?.sort,
    },
  });
  const { data: camparisonData } = useQuery(reportingAppMetricsGql, {
    skip: !element.comparison?.dataSource,
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      applicationCode,
      metrics: element.comparison?.dataSource?.metrics,
      filter: comparisonFilter,
      dimensions: element.comparison?.dataSource?.dimensions,
      dateRange: element.comparison?.dataSource?.dateRange,
      sort: element.dataSource?.sort,
    },
  });

  const value = useMemo(() => {
    const values = _.get(data, queryInfo.dataKey, null);
    if (!values?.length) return null;
    return values[0][element.dataSource.metrics[0]];
  }, [_.get(data, queryInfo.dataKey)]);

  const camparisonValue = useMemo(() => {
    if (!camparisonData?.reportingAppMetrics?.items.length) return null;
    return camparisonData.reportingAppMetrics.items[0][element.comparison.dataSource.metrics[0]];
  }, [camparisonData?.reportingAppMetrics?.items]);

  const dataPointFormat = useMemo(() => {
    if (element.format?.type === 'currency' && appReportingCurrency) {
      return ({
        ...element.format,
        currency: appReportingCurrency,
      });
    }
    return element?.format;
  }, [element?.format, appReportingCurrency]);

  return (
    <Box
      sx={elementSx}
    >
      {element.title && <ElementTitle title={element.title} />}
      <Typography
        variant={element.heightFactor ? 'h4' : 'h5'}
        component="div"
        mt={element.heightFactor ? 2 : 1}
        noWrap
        width="100%"
        sx={smDown ? { fontSize: '30px' } : element.heightFactor && {
          fontSize: '2.5rem',
        }}
      >
        {formatData(value, element.format?.type || 'number', dataPointFormat)}
      </Typography>
      {element.comparison && (
        <Typography
          variant="body"
          component="div"
          width="100%"
          mt={element.heightFactor ? 1 : 0.5}
          noWrap
        >
          {formatData(camparisonValue, element.comparison?.format?.type || 'percentage', element.format)}
          {' '}
          {element.comparison.label}
        </Typography>
      )}
    </Box>
  );
}

export default observer(BigNumberElement);
