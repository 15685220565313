import React from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';

import WizardStep from './WizardStep';
import themes from '../themes';

function WizardSteps({ currentStep, maxStep, steps, onChange }) {
  const renderBridge = (index) => (maxStep < index || (maxStep === index && maxStep !== currentStep) ? (
    <Bridge />
  ) : (
    <ColoredBridge />
  ));

  let stepIndex = 0;
  return (
    <Flex mb={25}>
      {steps.map((step, index) => {
        stepIndex++;

        return (
          <Flex
            width={1}
            key={`step-${index}`}
          >
            {index !== 0 && renderBridge(index)}
            <WizardStep
              key={`step-${index}`}
              step={stepIndex}
              title={step.name}
              current={currentStep === index}
              finished={maxStep > index}
              inProgress={index === maxStep}
              width={1 / steps.length}
              onClick={() => onChange(index)}
            />
            {steps.length !== index + 1 && renderBridge(index + 1)}
          </Flex>
        );
      })}
    </Flex>
  );
}

const Bridge = styled.div`
  background: ${themes.colors.background};
  margin: 2.5em 0;
  width: 25%;
  height: 2px;
  box-shadow: -1px 1px 0px 1px transparent;
`;

const ColoredBridge = styled(Bridge)`
  background: ${themes.colors.purple};
  box-shadow: -1px 1px 1px 1px ${themes.colors.purple};
  height: 1px;
`;

export default WizardSteps;
