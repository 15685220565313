import React from 'react';
import styled from 'styled-components';
import theme from 'components/themes';
import FormError from './FormError';

function TextArea(props) {
  const valid = !props.error;
  const { style = {} } = props;
  const defaultStyles = {
    outline: 'none',
    width: '100%',
    padding: '12px 15px',
    height: '88px',
    borderRadius: '2px',
    border: `solid 1px ${theme.colors.background}`,
    backgroundColor: theme.colors.inputs,
    color: theme.colors.white,
    fontSize: '16px',
    opacity: props.disabled ? '0.5' : '0.8',
  };
  const combinedStyles = { ...defaultStyles, ...style };
  return (
    <>
      <Input
        {...props}
        id={props.name}
        valid={valid}
        style={combinedStyles}
      />
      {!valid && <FormError>{props.error}</FormError>}
    </>
  );
}
const Input = styled.textarea`
  ${(props) => { props.style; }}

  ${(props) => !props.valid
    && `
    box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
    border: 1px solid ${theme.colors.notificationRed};
  `};

  ::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
export default TextArea;
