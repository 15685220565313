import React from 'react';
import {
  MenuItem,
  MenuList,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function MoreActionsMenu({ menuItems }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        variant="contained"
        sx={{
          padding: '6px 6px',
          width: '30px',
          minWidth: 0,
        }}
        ref={anchorRef}
        id="more-info-button"
        aria-controls={open ? 'more-info-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={() => handleToggle()}
      >
        <MoreVertIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="more-info-menu"
                  aria-labelledby="more-info-button"
                  // eslint-disable-next-line react/jsx-no-bind
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.length !== 0 ? menuItems.map((item) => {
                    if (item.onClick) {
                      return (
                        <MenuItem
                          key={item.name}
                          onClick={(event) => { handleClose(event); item.onClick(); }}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    }
                    if (item.link) {
                      return (
                        <MenuItem
                          key={item.name}
                          component={Link}
                          to={item.link}
                          target={item.target || '_blank'}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    }
                    if (item.to) {
                      return (
                        <MenuItem
                          key={item.name}
                          component="a"
                          href={item.to}
                          target={item.target || '_blank'}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    }
                    return null;
                  }) : null}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default MoreActionsMenu;
